import React, {useEffect} from 'react';
import LoginHeader from "../Components/LoginHeader/LoginHeader";
import {Layout, Spin} from "antd";
import AuthActions from "./LoginPage/reducer";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import ButtonComponent from "../Components/Button";
import './CheckEmailCode.less';
import Images from "../Images";
import Image from "../Components/Image";

const CheckEmailCode = (
  {
    confirmEmailInit,
    fetching
  }
) => {

  let navigate = useNavigate();;
  const {hash} = useParams();

  useEffect(() => {
    document.title = 'Onboarding - Dropship';
    if (hash) {
      confirmEmailInit(hash);
    }
    let head = document.querySelector('head');
    let script = document.createElement('script')
    script.text = "!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');twq('config','oepmf');"
    head.appendChild(script)
    return () => {
      head.removeChild(script);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [])


  return (
    <Layout>
      <div className="login-row">
        <LoginHeader withLogout={true}/>

        <Spin spinning={fetching}>
          <div className="login-content check-email-code">
            <div className="page-content">
              <div>
                <h2>Email Confirmed</h2>
                <div className={'text'}>
                  <p>Click below to continue</p>
                </div>
                <div className="img-wrapper">
                  <Image src={Images.emailConfirmed} />
                </div>
              </div>
            </div>
            <ButtonComponent type="primary"
                             className="btn-primary btn-primary_animation"
                             style={{margin: '0 auto'}}
                             onClick={() => navigate(`/sign-up/confirm-email/${hash}`)}
            >
              Continue
            </ButtonComponent>
          </div>
        </Spin>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  codeValid: state.auth?.code_valid,
  fetching: state?.auth?.fetching
})

const mapDispatchToProps = (dispatch) => ({
  confirmEmailInit: (hash) => dispatch(AuthActions.confirmEmailInitRequest(hash)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckEmailCode);
