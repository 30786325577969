import React from 'react';

const SimilarWeb = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.7538 5.86593C16.9657 6.19537 17.153 6.53979 17.3145 6.8964C17.4759 7.25291 17.6109 7.62072 17.7184 7.99676C17.826 8.3729 17.9057 8.75618 17.957 9.14382C18.0084 9.53136 18.0312 9.92217 18.0252 10.3131C18.0199 10.7039 17.9859 11.0939 17.9235 11.4799C17.861 11.8658 17.7704 12.2467 17.6523 12.6196C17.5341 12.9925 17.3887 13.3563 17.2173 13.7082C17.0459 14.0601 16.8489 14.3992 16.6278 14.7226C16.4773 14.942 16.316 15.1537 16.1444 15.3573C15.9727 15.5607 15.7911 15.7556 15.6001 15.9413C15.4091 16.127 15.2089 16.3031 15.0003 16.4691C14.7918 16.6351 14.5751 16.7908 14.3511 16.9356C14.1836 17.0371 14.0877 17.0088 14.0433 16.9711C13.9568 16.8979 14.0949 16.6471 14.0949 16.6471H14.092C14.414 16.0057 14.6029 15.0935 14.6029 14.2317C14.6029 13.9952 14.5872 13.7614 14.5614 13.5327C14.4541 11.8369 13.1526 10.4686 11.1455 8.9271C10.3298 8.29904 9.70016 7.74564 9.25647 7.21286C9.15129 7.08923 9.0604 6.96709 8.97669 6.84346L9.15129 7.07079C8.78781 6.59332 8.57106 5.99382 8.57106 5.34157C8.57106 3.77584 9.82102 2.50704 11.3658 2.5C11.4876 2.50069 11.6092 2.50595 11.7306 2.51557C11.8519 2.52518 11.9728 2.53916 12.0931 2.5576C12.2135 2.57604 12.333 2.59884 12.4516 2.62591C12.5702 2.65307 12.6878 2.6845 12.8041 2.72019L13.2263 2.86226C14.6522 3.42637 15.8952 4.53675 16.7538 5.86593Z"
        fill="#F4732A" />
      <path
        d="M10.6646 11.6604C10.8619 11.8115 11.0409 11.9847 11.1983 12.1764C11.3557 12.3681 11.4904 12.5772 11.5996 12.7995C11.7089 13.0218 11.7921 13.2557 11.8476 13.4969C11.903 13.7381 11.9303 13.9847 11.929 14.2321C11.9308 14.5485 11.8855 14.8635 11.7947 15.1669C11.7039 15.4702 11.5686 15.7587 11.3932 16.0229C11.2177 16.2871 11.0039 16.5242 10.7588 16.7265C10.5137 16.9288 10.2399 17.0942 9.94625 17.2172H9.94405C9.94405 17.2172 7.5628 18.285 4.78661 16.256H4.78871C4.64987 16.1565 4.51522 16.0514 4.38497 15.941C4.25473 15.8305 4.12917 15.7149 4.00841 15.5943C3.88775 15.4737 3.77208 15.3482 3.66161 15.2182C3.55124 15.0883 3.44626 14.9538 3.34697 14.8153C3.10246 14.4553 2.88771 14.0763 2.70472 13.6819C2.52183 13.2877 2.3714 12.8792 2.25474 12.4607C2.13817 12.0423 2.05597 11.6152 2.00872 11.1835C1.96158 10.7519 1.94969 10.3172 1.97316 9.88366C2.00902 9.20118 2.13258 8.52613 2.34084 7.87477C2.5491 7.22342 2.84036 6.60121 3.20754 6.02312C3.57471 5.44513 4.0148 4.91612 4.51712 4.44887C5.01944 3.98171 5.57989 3.58019 6.1847 3.25402C6.58973 3.06298 6.40364 3.45012 6.40364 3.45012V3.453C6.30775 3.67249 6.22405 3.89695 6.15613 4.12785C5.4055 6.66476 6.77501 8.72213 9.40817 10.6296L10.6653 11.6597L10.6646 11.6604Z"
        fill="#092540" />
    </svg>
  );
};

export default SimilarWeb;
