import React from 'react';

const AdSpotCheckmark = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_5423_16764)">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M7.17527 1.11783C7.63088 0.66222 8.36957 0.66222 8.82518 1.11783L10.2585 2.55116C10.2898 2.58242 10.3322 2.59998 10.3764 2.59998H12.2336C12.8779 2.59998 13.4002 3.12231 13.4002 3.76665V5.62384C13.4002 5.66804 13.4178 5.71043 13.449 5.74169L14.8824 7.17502C15.338 7.63064 15.338 8.36933 14.8824 8.82494L13.449 10.2583C13.4178 10.2895 13.4002 10.3319 13.4002 10.3761V12.2333C13.4002 12.8776 12.8779 13.4 12.2336 13.4H10.3764C10.3322 13.4 10.2898 13.4175 10.2585 13.4488L8.82518 14.8821C8.36957 15.3377 7.63088 15.3377 7.17527 14.8821L5.74193 13.4488C5.71068 13.4175 5.66828 13.4 5.62408 13.4H3.76689C3.12256 13.4 2.60022 12.8776 2.60022 12.2333V10.3761C2.60022 10.3319 2.58267 10.2895 2.55141 10.2583L1.11808 8.82494C0.662464 8.36933 0.662464 7.63063 1.11808 7.17502L2.55141 5.74169C2.58267 5.71043 2.60022 5.66804 2.60022 5.62384V3.76665C2.60022 3.12232 3.12256 2.59998 3.76689 2.59998H5.62408C5.66828 2.59998 5.71068 2.58242 5.74193 2.55116L7.17527 1.11783ZM10.2908 6.57873C10.4484 6.352 10.3924 6.04042 10.1657 5.88278C9.93897 5.72515 9.62738 5.78117 9.46975 6.0079L7.18508 9.29403L6.11747 8.35347C5.91027 8.17093 5.59432 8.19092 5.41177 8.39813C5.22923 8.60533 5.24922 8.92128 5.45642 9.10382L6.94491 10.4152C7.05189 10.5094 7.19401 10.5535 7.33555 10.5364C7.47709 10.5192 7.60458 10.4425 7.68596 10.3254L10.2908 6.57873Z"
              fill="#225AEA" />
      </g>
      <defs>
        <clipPath id="clip0_5423_16764">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AdSpotCheckmark;
