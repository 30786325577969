import React from 'react';
import cls from 'classname';
import { useSelector } from 'react-redux';

const DatabaseGlobe = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M10 17.7082C14.2572 17.7082 17.7084 14.257 17.7084 9.99984C17.7084 5.74264 14.2572 2.2915 10 2.2915M10 17.7082C5.74283 17.7082 2.29169 14.257 2.29169 9.99984C2.29169 5.74264 5.74283 2.2915 10 2.2915M10 17.7082C8.04401 17.7082 6.45835 14.257 6.45835 9.99984C6.45835 5.74264 8.04401 2.2915 10 2.2915M10 17.7082C11.956 17.7082 13.5417 14.257 13.5417 9.99984C13.5417 5.74264 11.956 2.2915 10 2.2915M17.5 9.99984H2.50002"
        stroke={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, 0.26)"}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
}
export default DatabaseGlobe;
