import React from 'react';

const SalesTrackerDashboard = ({width, height}) => {
  return (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 1C12 0.447715 11.5523 0 11 0C10.4477 0 10 0.447715 10 1V3.56609C10.3271 3.5225 10.6609 3.5 11 3.5C11.3391 3.5 11.6729 3.5225 12 3.56609V1Z" fill="#F9F9FC"/>
    <path d="M1 10H3.56609C3.5225 10.3271 3.5 10.6609 3.5 11C3.5 11.3391 3.5225 11.6729 3.56609 12H1C0.447715 12 0 11.5523 0 11C0 10.4477 0.447715 10 1 10Z" fill="#F9F9FC"/>
    <path d="M10 18.4342V21C10 21.5523 10.4477 22 11 22C11.5523 22 12 21.5523 12 21V18.4339C11.6729 18.4775 11.3391 18.5 11 18.5C10.6611 18.5 10.327 18.4778 10 18.4342L10.0003 15.0003C10.0003 14.448 10.448 14.0003 11.0003 14.0003C11.5526 14.0003 12.0003 14.448 12.0003 15.0003L12 18.4339C15.3422 17.9886 17.9889 15.3425 18.4342 12.0003H15.0003C14.448 12.0003 14.0003 11.5526 14.0003 11.0003C14.0003 10.448 14.448 10.0003 15.0003 10.0003H18.434C18.4775 10.3274 18.5 10.6611 18.5 11C18.5 11.3391 18.4778 11.6732 18.4342 12.0003L21 12C21.5523 12 22 11.5523 22 11C22 10.4477 21.5523 10 21 10L18.434 10.0003C17.9885 6.65825 15.3421 4.01138 12 3.56609L12.0003 7.00032C12.0003 7.55261 11.5526 8.00032 11.0003 8.00032C10.448 8.00032 10.0003 7.55261 10.0003 7.00032L10 3.56609C6.65778 4.01139 4.01139 6.65778 3.56609 10L7.00032 10.0003C7.55261 10.0003 8.00032 10.448 8.00032 11.0003C8.00032 11.5526 7.55261 12.0003 7.00032 12.0003L3.56609 12C4.01138 15.3421 6.65793 17.9888 10 18.4342Z" fill="#F9F9FC"/>
  </svg>
  );
};

export default SalesTrackerDashboard;
