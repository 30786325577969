import React from 'react';

const AdSpotChartLike = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.6263 8.95866H3.1263C2.66606 8.95866 2.29297 9.33175 2.29297 9.79199V16.042C2.29297 16.5022 2.66606 16.8753 3.1263 16.8753H5.6263M5.6263 16.8753V9.16699L8.93527 2.74369C9.07817 2.46631 9.36507 2.29199 9.6771 2.29199C10.6999 2.29199 11.4853 3.20479 11.3278 4.21541L10.8481 7.29199H15.2171C16.7477 7.29199 17.9189 8.65495 17.6886 10.1681L16.9912 14.7514C16.8053 15.9728 15.7551 16.8753 14.5196 16.8753H5.6263Z"
        stroke="#225AEA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AdSpotChartLike;
