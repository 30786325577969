import React from 'react';
import cls from 'classname';
import { useSelector } from 'react-redux';

const DatabaseCalendar = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.875 6.45833V4.79167C16.875 3.87119 16.1288 3.125 15.2083 3.125H4.79167C3.87119 3.125 3.125 3.87119 3.125 4.79167V6.45833M16.875 6.45833V15.2083C16.875 16.1288 16.1288 16.875 15.2083 16.875H4.79167C3.87119 16.875 3.125 16.1288 3.125 15.2083V6.45833M16.875 6.45833H3.125M6.66667 10V9.99167M10 10V9.99167M6.66667 13.3333V13.325M10 13.3333V13.325M13.3333 10V9.99167M6.875 10C6.875 10.1151 6.78173 10.2083 6.66667 10.2083C6.55161 10.2083 6.45833 10.1151 6.45833 10C6.45833 9.88494 6.55161 9.79167 6.66667 9.79167C6.78173 9.79167 6.875 9.88494 6.875 10ZM10.2083 10C10.2083 10.1151 10.1151 10.2083 10 10.2083C9.88494 10.2083 9.79167 10.1151 9.79167 10C9.79167 9.88494 9.88494 9.79167 10 9.79167C10.1151 9.79167 10.2083 9.88494 10.2083 10ZM6.875 13.3333C6.875 13.4484 6.78173 13.5417 6.66667 13.5417C6.55161 13.5417 6.45833 13.4484 6.45833 13.3333C6.45833 13.2183 6.55161 13.125 6.66667 13.125C6.78173 13.125 6.875 13.2183 6.875 13.3333ZM10.2083 13.3333C10.2083 13.4484 10.1151 13.5417 10 13.5417C9.88494 13.5417 9.79167 13.4484 9.79167 13.3333C9.79167 13.2183 9.88494 13.125 10 13.125C10.1151 13.125 10.2083 13.2183 10.2083 13.3333ZM13.5417 10C13.5417 10.1151 13.4484 10.2083 13.3333 10.2083C13.2183 10.2083 13.125 10.1151 13.125 10C13.125 9.88494 13.2183 9.79167 13.3333 9.79167C13.4484 9.79167 13.5417 9.88494 13.5417 10Z"
        stroke={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, 0.26)"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
export default DatabaseCalendar;
