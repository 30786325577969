import React from 'react';
import { useSelector } from 'react-redux';

const ArrowDoubleVertical = () => {
  const theme = useSelector((state) => state?.nav?.theme);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={theme === 'light' ? '#434C69' : '#ffffff5c'}
        d="M5.33203 6.00002L7.52729 3.80476C7.78764 3.54441 8.20975 3.54441 8.4701 3.80476L10.6654 6.00002M10.6654 10L8.4701 12.1953C8.20975 12.4556 7.78764 12.4556 7.52729 12.1953L5.33203 10"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowDoubleVertical;
