import React from 'react';
import { useSelector } from 'react-redux';

const AdSpotCarousel = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M14.5833 4.79167V15.2083M14.5833 4.79167C14.5833 3.87119 13.8371 3.125 12.9167 3.125H7.08332C6.16285 3.125 5.41666 3.87119 5.41666 4.79167M14.5833 4.79167H16.0417C16.9621 4.79167 17.7083 5.53786 17.7083 6.45833V13.5417C17.7083 14.4621 16.9621 15.2083 16.0417 15.2083H14.5833M14.5833 15.2083C14.5833 16.1288 13.8371 16.875 12.9167 16.875H7.08332C6.16285 16.875 5.41666 16.1288 5.41666 15.2083M5.41666 15.2083V4.79167M5.41666 15.2083H3.95832C3.03785 15.2083 2.29166 14.4621 2.29166 13.5417V6.45833C2.29166 5.53786 3.03785 4.79167 3.95832 4.79167H5.41666"
        stroke={theme === 'light' ? '#707BA0' : 'rgba(255, 255, 255, 0.36)'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>
  );
};

export default AdSpotCarousel;
