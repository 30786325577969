import React, { useState } from 'react';
import Icon from '../../../Icon';
import { useTranslation } from 'react-i18next';
import { Divider, Dropdown } from 'antd';
import AdHeaderModal from './AdHeaderModal';
import acc from 'accounting';
import { useNavigate } from 'react-router';

const getClassForCreditsTotal = (creditsTotal, creditsLeft) => {
  const percentageLeft = (creditsLeft / creditsTotal) * 100;

  if (percentageLeft > 50) {
    return 'green';
  } else if (percentageLeft > 25 && percentageLeft <= 50) {
    return 'orange';
  } else if (percentageLeft >= 0 && percentageLeft <= 25) {
    return 'red';
  }
  return '';
};

const AdTopPageHeader = ({
  icon,
  title,
  subtitle,
  takeTourHandler = null,
  watchTutorialHandler = null,
  skeleton,
  isMobile,
  modalTitle = '',
  modalText = '',
  isPrem,
  isTrial,
  isYearly,
  creditsLeft,
  creditsTotal,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(null);

  const handleVisibleChange = (value) => setVisible(value);

  if (skeleton)
    return (
      <>
      <div className="sales-tracker-top-page-header">
        <div className="title-wrapper">
          {isMobile ? null : (
            <span
              className={'link skeleton'}
              style={{ width: 48, height: 48 }}
            />
          )}
          <div className="sales-tracker-top-page-subheader">
            <span
              className={'link skeleton'}
              style={{ width: isMobile ? 120 : 240, height: isMobile ? 20 : 30 }}
            />
            <span
              className={'link skeleton'}
              style={{ width: isMobile ? 280 : 337, height: 20 }}
            />
          </div>
        </div>
        <div className={'sales-tracker-top-page-limits-wrapper'}>
          <span
            className={'link skeleton'}
            style={{ width: isMobile ? 20 : 300, height: isMobile ? 20 : 36, marginRight: 12 }}
          />
        </div>
      </div>
      {isMobile && (<div className="fadspot-top-page-credits-mobile">
        <span
          className={'link skeleton'}
          style={{ width: "100%", height: 35 }}
        />
        <Divider type={'horizontal'} style={{ margin: 1 }}/>
        <span
          className={'link skeleton'}
          style={{ width: "100%", height: 35 }}
        />
        </div>
      )}
      </>
    );

  return (
    <>
      <div className="sales-tracker-top-page-header">
        <div className="title-wrapper">
          <div className="title-wrapper-icon">
            <Icon type={icon} role={'icon'} />
          </div>
          <div className="sales-tracker-top-page-subheader">
            <h3 className="sales-tracker-top-page-title">{t(title)}</h3>
            <h4 className="sales-tracker-top-page-subtitle">{t(subtitle)}</h4>
          </div>
        </div>
        {!isMobile && (
          <div className="sales-tracker-top-page-credits">
            <span className="sales-tracker-top-page-credits-title">
              {t('Search credits')}
              <Icon
                type={'attention_outline'}
                role="button"
                width={16}
                height={16}
                tooltipProps={{
                  trigger: 'hover',
                  placement: 'bottom',
                  overlayClassName: 'subscription-tooltip scheduled',
                  getPopupContainer: (trigger) => trigger.parentNode,
                }}
                titleText={t(
                  isYearly
                    ? 'Each ad seen is 1 credit used. Credits reset every 30 days.'
                    : 'Each ad seen is 1 credit used. Credits reset on your next billing cycle.',
                )}
              />
            </span>
            <span className="sales-tracker-top-page-credits-value">
              <span
                className={`total-${getClassForCreditsTotal(
                  creditsTotal,
                  creditsLeft,
                )}`}
              >
                {acc.formatNumber(creditsLeft, 0, ',')}
              </span>
              <span className={'separator'}>/</span>
              <span className={'maximum'}>
                {acc.formatNumber(creditsTotal, 0, ',')}
              </span>
            </span>
          </div>
        )}
        {takeTourHandler || watchTutorialHandler ? (
          isMobile ? (
            <span
              className={'sales-tracker-top-page-header-btn'}
              onClick={() => {
                setVisibleModal('header_modal');
              }}
            >
              <Icon type={'three_dots'} role={'icon'} />
            </span>
          ) : (
            <Dropdown
              destroyPopupOnHide={true}
              getPopupContainer={(trigger) => trigger.parentNode}
              onOpenChange={handleVisibleChange}
              placement="bottomRight"
              dropdownRender={() => (
                <div className={'sales-tracker-top-page-header-dropdown'}>
                  {takeTourHandler ? (
                    <div
                      className="sales-tracker-top-page-header-dropdown-option tour"
                      onClick={() => {
                        takeTourHandler();
                        setVisible(false);
                      }}
                    >
                      <Icon type="take_tour" role="icon" />
                      <span>{t('Take tour')}</span>
                    </div>
                  ) : null}
                  {watchTutorialHandler ? (
                    <div
                      className="sales-tracker-top-page-header-dropdown-option tutorial"
                      onClick={() => {
                        watchTutorialHandler();
                        setVisible(false);
                      }}
                    >
                      <Icon type={'watch_tutorial'} role={'icon'} />
                      <span>{t('Watch tutorial')}</span>
                    </div>
                  ) : null}
                  {(!isPrem || isTrial) && (
                    <div
                      className="sales-tracker-top-page-header-dropdown-option tutorial"
                      onClick={() => {
                        setVisibleModal(false);
                        navigate('/setting/plan');
                      }}
                    >
                      <>
                        <Icon type={'increase_limit'} role={'icon'} />
                        <span>{t('Increase limits')}</span>
                      </>
                    </div>
                  )}
                </div>
              )}
              trigger={['click']}
              open={visible}
            >
              <span className={'sales-tracker-top-page-header-btn'}>
                <Icon type={'three_dots'} role={'icon'} />
              </span>
            </Dropdown>
          )
        ) : null}
        <AdHeaderModal
          setModal={setVisibleModal}
          isMobile={isMobile}
          visible={visibleModal}
          takeTourHandler={takeTourHandler}
          watchTutorialHandler={watchTutorialHandler}
          title={modalTitle}
          text={modalText}
          isPrem={isPrem}
          isTrial={isTrial}
        />
      </div>
      {isMobile && (

      <div className="fadspot-top-page-credits-mobile">
        <span className="credits-title">
          {t('Search credits')}
        </span>
        <span className="credits-value">
          <span
            className={`total-${getClassForCreditsTotal(
              creditsTotal,
              creditsLeft,
            )}`}
          >
            {acc.formatNumber(creditsLeft, 0, ',')}
          </span>
          <span className={'separator'}>/</span>
          <span className={'maximum'}>
            {acc.formatNumber(creditsTotal, 0, ',')}
          </span>
        </span>
      </div>
      )}
    </>
  );
};

export default AdTopPageHeader;
