import React from 'react';
import { useSelector } from 'react-redux';

const LockedChapter = (
  {
    outline = false
  }) => {

  const theme = useSelector(state => state.nav.theme);

  return outline ?
    (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M10.0007 1.875C7.69946 1.875 5.83398 3.74048 5.83398 6.04167V7.5H5.62565C4.36 7.5 3.33398 8.52601 3.33398 9.79167V16.0417C3.33398 17.3073 4.36 18.3333 5.62565 18.3333H14.3757C15.6413 18.3333 16.6673 17.3073 16.6673 16.0417V9.79167C16.6673 8.52601 15.6413 7.5 14.3757 7.5H14.1673V6.04167C14.1673 3.74048 12.3018 1.875 10.0007 1.875ZM12.9173 7.5V6.04167C12.9173 4.43084 11.6115 3.125 10.0007 3.125C8.38982 3.125 7.08398 4.43084 7.08398 6.04167V7.5H12.9173ZM10.0007 11.0417C10.3458 11.0417 10.6257 11.3215 10.6257 11.6667V14.1667C10.6257 14.5118 10.3458 14.7917 10.0007 14.7917C9.65547 14.7917 9.37565 14.5118 9.37565 14.1667V11.6667C9.37565 11.3215 9.65547 11.0417 10.0007 11.0417Z"
              fill="#DF7009" />
      </svg>
    )
    :
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M13.5413 8.125V6.04167C13.5413 4.08566 11.9557 2.5 9.99967 2.5C8.04367 2.5 6.45801 4.08566 6.45801 6.04167V8.125M9.99967 11.6667V14.1667M5.62467 17.7083H14.3747C15.2951 17.7083 16.0413 16.9621 16.0413 16.0417V9.79167C16.0413 8.87119 15.2951 8.125 14.3747 8.125H5.62467C4.7042 8.125 3.95801 8.87119 3.95801 9.79167V16.0417C3.95801 16.9621 4.7042 17.7083 5.62467 17.7083Z"
        stroke={theme === 'light' ? '#A1AAC8' : 'rgba(255, 255, 255, 0.259)'}
        strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
};

export default LockedChapter;
