
export const inputOptions = [
  {
    key: 'ad_spend',
    label: 'Ad spend',
    placeholder: 'Amount ($)',
  },
  {
    key: 'adset_amount',
    label: 'Adset amount',
    placeholder: 'Amount',
  },
  {
    key: 'reach_eu',
    label: 'Reach (EU)',
    placeholder: 'Amount',
  },
  {
    key: 'ads_running_time',
    label: 'Ads running time',
    placeholder: 'Amount (Days)',
  },
  {
    key: 'selling_price',
    label: 'Selling price',
    placeholder: 'Price ($)',
  },
];

export const checkboxOptions = [
  {
    "id": 18,
    "value": "Appliances"
  },
  {
    "id": 8,
    "value": "Grocery & Gourmet Food"
  },
  {
    "id": 1,
    "value": "Arts Crafts & Sewing"
  },
  {
    "id": 5,
    "value": "Health & Household"
  },
  {
    "id": 10,
    "value": "Automotive"
  },
  {
    "id": 2,
    "value": "Home & Kitchen & Dining"
  },
  {
    "id": 9,
    "value": "Baby"
  },
  {
    "id": 6,
    "value": "Musical Instruments"
  },
  {
    "id": 16,
    "value": "Beauty & Personal Care"
  },
  {
    "id": 7,
    "value": "Office Products"
  },
  {
    "id": 17,
    "value": "Camera & Photo"
  },
  {
    "id": 3,
    "value": "Patio Lawn & Garden"
  },
  {
    "id": 0,
    "value": "Clothing & Shoes & Jewelry"
  },
  {
    "id": 12,
    "value": "Pet Supplies"
  },
  {
    "id": 15,
    "value": "Cell Phones & Accessories"
  },
  {
    "id": 14,
    "value": "Sports & Outdoors"
  },
  {
    "id": 20,
    "value": "Computers & Accessories"
  },
  {
    "id": 11,
    "value": "Tools & Home Improvement"
  },
  {
    "id": 13,
    "value": "Digital Goods"
  },
  {
    "id": 4,
    "value": "Toys & Games"
  },
  {
    "id": 19,
    "value": "Electronics"
  }
];

export const callToActionOptions = [
  {
    "id": "Shop Now",
    "value": "Shop Now",
    "count": 14911606
  },
  {
    "id": "Learn More",
    "value": "Learn More",
    "count": 2328881
  },
  {
    "id": "Send Message",
    "value": "Send Message",
    "count": 758714
  },
  {
    "id": "Book Now",
    "value": "Book Now",
    "count": 615274
  },
  {
    "id": "Get Offer",
    "value": "Get Offer",
    "count": 494759
  },
  {
    "id": "Order Now",
    "value": "Order Now",
    "count": 475513
  },
  {
    "id": "Sign Up",
    "value": "Sign Up",
    "count": 439486
  },
  {
    "id": "Apply Now",
    "value": "Apply Now",
    "count": 326682
  },
  {
    "id": "Buy Now",
    "value": "Buy Now",
    "count": 196059
  },
  {
    "id": "See Menu",
    "value": "See Menu",
    "count": 136534
  },
  {
    "id": "Get Quote",
    "value": "Get Quote",
    "count": 107806
  },
  {
    "id": "Like Page",
    "value": "Like Page",
    "count": 96712
  },
  {
    "id": "Download",
    "value": "Download",
    "count": 94919
  },
  {
    "id": "Shop now",
    "value": "Shop now",
    "count": 72134
  },
  {
    "id": "Subscribe",
    "value": "Subscribe",
    "count": 70668
  },
  {
    "id": "Contact Us",
    "value": "Contact Us",
    "count": 67198
  },
  {
    "id": "Play Game",
    "value": "Play Game",
    "count": 57689
  },
  {
    "id": "Watch More",
    "value": "Watch More",
    "count": 41721
  },
  {
    "id": "Donate Now",
    "value": "Donate Now",
    "count": 28277
  },
  {
    "id": "Listen Now",
    "value": "Listen Now",
    "count": 23703
  },
  {
    "id": "Get Directions",
    "value": "Get Directions",
    "count": 17430
  },
  {
    "id": "Interested",
    "value": "Interested",
    "count": 16351
  },
  {
    "id": "Buy Tickets",
    "value": "Buy Tickets",
    "count": 7760
  },
  {
    "id": "Open Link",
    "value": "Open Link",
    "count": 7698
  },
  {
    "id": "No Button",
    "value": "No Button",
    "count": 6082
  },
  {
    "id": "Get Showtime",
    "value": "Get Showtime",
    "count": 3949
  },
  {
    "id": "Send WhatsApp Message",
    "value": "Send WhatsApp Message",
    "count": 2078
  },
  {
    "id": "Install Now",
    "value": "Install Now",
    "count": 2019
  },
  {
    "id": "Request Time",
    "value": "Request Time",
    "count": 1705
  },
  {
    "id": "Call Now",
    "value": "Call Now",
    "count": 1515
  },
  {
    "id": "Get Showtimes",
    "value": "Get Showtimes",
    "count": 1105
  },
  {
    "id": "Inquire now",
    "value": "Inquire now",
    "count": 1057
  },
  {
    "id": "See More",
    "value": "See More",
    "count": 993
  },
  {
    "id": "Get Access",
    "value": "Get Access",
    "count": 895
  },
  {
    "id": "WA Message",
    "value": "WA Message",
    "count": 350
  },
  {
    "id": "Sell now",
    "value": "Sell now",
    "count": 234
  },
  {
    "id": "learn more",
    "value": "learn more",
    "count": 202
  },
  {
    "id": "Get promotions",
    "value": "Get promotions",
    "count": 200
  },
  {
    "id": "Wa Message",
    "value": "Wa Message",
    "count": 174
  },
  {
    "id": "see menu",
    "value": "see menu",
    "count": 154
  },
  {
    "id": "Donate",
    "value": "Donate",
    "count": 120
  },
  {
    "id": "Message Page",
    "value": "Message Page",
    "count": 93
  },
  {
    "id": "Get updates",
    "value": "Get updates",
    "count": 76
  },
  {
    "id": "Save",
    "value": "Save",
    "count": 59
  },
  {
    "id": "Book Travel",
    "value": "Book Travel",
    "count": 37
  },
  {
    "id": "Use App",
    "value": "Use App",
    "count": 37
  },
  {
    "id": "INTERESTED",
    "value": "INTERESTED",
    "count": 34
  },
  {
    "id": "Try in camera",
    "value": "Try in camera",
    "count": 34
  }
];

export const domainOptions = [
  {
    "id": ".ac.in",
    "value": ".ac.in",
    "count": 2441
  },
  {
    "id": ".ac.uk",
    "value": ".ac.uk",
    "count": 9868
  },
  {
    "id": ".academy",
    "value": ".academy",
    "count": 4630
  },
  {
    "id": ".ae",
    "value": ".ae",
    "count": 53506
  },
  {
    "id": ".ai",
    "value": ".ai",
    "count": 23178
  },
  {
    "id": ".amsterdam",
    "value": ".amsterdam",
    "count": 1674
  },
  {
    "id": ".app",
    "value": ".app",
    "count": 8343
  },
  {
    "id": ".asia",
    "value": ".asia",
    "count": 8462
  },
  {
    "id": ".at",
    "value": ".at",
    "count": 2245
  },
  {
    "id": ".be",
    "value": ".be",
    "count": 12656
  },
  {
    "id": ".bg",
    "value": ".bg",
    "count": 3978
  },
  {
    "id": ".ca",
    "value": ".ca",
    "count": 231692
  },
  {
    "id": ".cc",
    "value": ".cc",
    "count": 12416
  },
  {
    "id": ".ch",
    "value": ".ch",
    "count": 9969
  },
  {
    "id": ".cl",
    "value": ".cl",
    "count": 5086
  },
  {
    "id": ".click",
    "value": ".click",
    "count": 4126
  },
  {
    "id": ".clothing",
    "value": ".clothing",
    "count": 3446
  },
  {
    "id": ".club",
    "value": ".club",
    "count": 5420
  },
  {
    "id": ".cn",
    "value": ".cn",
    "count": 1765
  },
  {
    "id": ".co",
    "value": ".co",
    "count": 228710
  },
  {
    "id": ".co.id",
    "value": ".co.id",
    "count": 11370
  },
  {
    "id": ".co.il",
    "value": ".co.il",
    "count": 3125
  },
  {
    "id": ".co.in",
    "value": ".co.in",
    "count": 84598
  },
  {
    "id": ".co.ke",
    "value": ".co.ke",
    "count": 11060
  },
  {
    "id": ".co.nz",
    "value": ".co.nz",
    "count": 67474
  },
  {
    "id": ".co.th",
    "value": ".co.th",
    "count": 14374
  },
  {
    "id": ".co.uk",
    "value": ".co.uk",
    "count": 327090
  },
  {
    "id": ".co.za",
    "value": ".co.za",
    "count": 134489
  },
  {
    "id": ".com",
    "value": ".com",
    "count": 17487489
  },
  {
    "id": ".com.ar",
    "value": ".com.ar",
    "count": 3836
  },
  {
    "id": ".com.au",
    "value": ".com.au",
    "count": 561948
  },
  {
    "id": ".com.bd",
    "value": ".com.bd",
    "count": 73295
  },
  {
    "id": ".com.br",
    "value": ".com.br",
    "count": 143045
  },
  {
    "id": ".com.de",
    "value": ".com.de",
    "count": 6629
  },
  {
    "id": ".com.eg",
    "value": ".com.eg",
    "count": 21179
  },
  {
    "id": ".com.hk",
    "value": ".com.hk",
    "count": 846
  },
  {
    "id": ".com.kw",
    "value": ".com.kw",
    "count": 3846
  },
  {
    "id": ".com.lb",
    "value": ".com.lb",
    "count": 2007
  },
  {
    "id": ".com.mt",
    "value": ".com.mt",
    "count": 3168
  },
  {
    "id": ".com.mx",
    "value": ".com.mx",
    "count": 5376
  },
  {
    "id": ".com.my",
    "value": ".com.my",
    "count": 27443
  },
  {
    "id": ".com.ng",
    "value": ".com.ng",
    "count": 6111
  },
  {
    "id": ".com.np",
    "value": ".com.np",
    "count": 18462
  },
  {
    "id": ".com.pe",
    "value": ".com.pe",
    "count": 1161
  },
  {
    "id": ".com.ph",
    "value": ".com.ph",
    "count": 83517
  },
  {
    "id": ".com.pk",
    "value": ".com.pk",
    "count": 156717
  },
  {
    "id": ".com.pl",
    "value": ".com.pl",
    "count": 3402
  },
  {
    "id": ".com.sa",
    "value": ".com.sa",
    "count": 2693
  },
  {
    "id": ".com.sg",
    "value": ".com.sg",
    "count": 11179
  },
  {
    "id": ".com.tr",
    "value": ".com.tr",
    "count": 5416
  },
  {
    "id": ".com.ua",
    "value": ".com.ua",
    "count": 5052
  },
  {
    "id": ".com.vn",
    "value": ".com.vn",
    "count": 5537
  },
  {
    "id": ".cy",
    "value": ".cy",
    "count": 1351
  },
  {
    "id": ".cz",
    "value": ".cz",
    "count": 6940
  },
  {
    "id": ".de",
    "value": ".de",
    "count": 41327
  },
  {
    "id": ".design",
    "value": ".design",
    "count": 3647
  },
  {
    "id": ".dk",
    "value": ".dk",
    "count": 17029
  },
  {
    "id": ".edu",
    "value": ".edu",
    "count": 38386
  },
  {
    "id": ".edu.au",
    "value": ".edu.au",
    "count": 13894
  },
  {
    "id": ".edu.pk",
    "value": ".edu.pk",
    "count": 7144
  },
  {
    "id": ".ee",
    "value": ".ee",
    "count": 9362
  },
  {
    "id": ".eg",
    "value": ".eg",
    "count": 3922
  },
  {
    "id": ".es",
    "value": ".es",
    "count": 8026
  },
  {
    "id": ".eu",
    "value": ".eu",
    "count": 76095
  },
  {
    "id": ".fr",
    "value": ".fr",
    "count": 12839
  },
  {
    "id": ".fun",
    "value": ".fun",
    "count": 3424
  },
  {
    "id": ".ge",
    "value": ".ge",
    "count": 5880
  },
  {
    "id": ".gg",
    "value": ".gg",
    "count": 2867
  },
  {
    "id": ".global",
    "value": ".global",
    "count": 14680
  },
  {
    "id": ".gov",
    "value": ".gov",
    "count": 2277
  },
  {
    "id": ".gov.au",
    "value": ".gov.au",
    "count": 2105
  },
  {
    "id": ".gr",
    "value": ".gr",
    "count": 15393
  },
  {
    "id": ".hu",
    "value": ".hu",
    "count": 11566
  },
  {
    "id": ".ie",
    "value": ".ie",
    "count": 18943
  },
  {
    "id": ".in",
    "value": ".in",
    "count": 461897
  },
  {
    "id": ".inc",
    "value": ".inc",
    "count": 923
  },
  {
    "id": ".info",
    "value": ".info",
    "count": 7627
  },
  {
    "id": ".io",
    "value": ".io",
    "count": 62114
  },
  {
    "id": ".it",
    "value": ".it",
    "count": 31644
  },
  {
    "id": ".jp",
    "value": ".jp",
    "count": 3049
  },
  {
    "id": ".la",
    "value": ".la",
    "count": 4552
  },
  {
    "id": ".life",
    "value": ".life",
    "count": 9916
  },
  {
    "id": ".link",
    "value": ".link",
    "count": 9763
  },
  {
    "id": ".live",
    "value": ".live",
    "count": 5680
  },
  {
    "id": ".lk",
    "value": ".lk",
    "count": 18946
  },
  {
    "id": ".lt",
    "value": ".lt",
    "count": 8908
  },
  {
    "id": ".ly",
    "value": ".ly",
    "count": 5119
  },
  {
    "id": ".ma",
    "value": ".ma",
    "count": 25271
  },
  {
    "id": ".me",
    "value": ".me",
    "count": 97784
  },
  {
    "id": ".mk",
    "value": ".mk",
    "count": 4963
  },
  {
    "id": ".mu",
    "value": ".mu",
    "count": 3823
  },
  {
    "id": ".my",
    "value": ".my",
    "count": 11216
  },
  {
    "id": ".myshopify.com",
    "value": ".myshopify.com",
    "count": 88472
  },
  {
    "id": ".net",
    "value": ".net",
    "count": 148545
  },
  {
    "id": ".ng",
    "value": ".ng",
    "count": 9104
  },
  {
    "id": ".nl",
    "value": ".nl",
    "count": 21186
  },
  {
    "id": ".no",
    "value": ".no",
    "count": 3207
  },
  {
    "id": ".online",
    "value": ".online",
    "count": 30588
  },
  {
    "id": ".org",
    "value": ".org",
    "count": 156785
  },
  {
    "id": ".org.au",
    "value": ".org.au",
    "count": 5590
  },
  {
    "id": ".org.uk",
    "value": ".org.uk",
    "count": 10323
  },
  {
    "id": ".ph",
    "value": ".ph",
    "count": 258872
  },
  {
    "id": ".pk",
    "value": ".pk",
    "count": 444837
  },
  {
    "id": ".pl",
    "value": ".pl",
    "count": 35824
  },
  {
    "id": ".pro",
    "value": ".pro",
    "count": 9356
  },
  {
    "id": ".pt",
    "value": ".pt",
    "count": 9391
  },
  {
    "id": ".qa",
    "value": ".qa",
    "count": 4939
  },
  {
    "id": ".ro",
    "value": ".ro",
    "count": 26564
  },
  {
    "id": ".sa",
    "value": ".sa",
    "count": 5204
  },
  {
    "id": ".se",
    "value": ".se",
    "count": 17075
  },
  {
    "id": ".sg",
    "value": ".sg",
    "count": 11437
  },
  {
    "id": ".shop",
    "value": ".shop",
    "count": 109101
  },
  {
    "id": ".shopping",
    "value": ".shopping",
    "count": 34988
  },
  {
    "id": ".site",
    "value": ".site",
    "count": 9035
  },
  {
    "id": ".sk",
    "value": ".sk",
    "count": 3499
  },
  {
    "id": ".space",
    "value": ".space",
    "count": 2756
  },
  {
    "id": ".st",
    "value": ".st",
    "count": 1040
  },
  {
    "id": ".store",
    "value": ".store",
    "count": 86612
  },
  {
    "id": ".tech",
    "value": ".tech",
    "count": 5184
  },
  {
    "id": ".tn",
    "value": ".tn",
    "count": 8165
  },
  {
    "id": ".to",
    "value": ".to",
    "count": 3848
  },
  {
    "id": ".today",
    "value": ".today",
    "count": 2796
  },
  {
    "id": ".top",
    "value": ".top",
    "count": 16772
  },
  {
    "id": ".tv",
    "value": ".tv",
    "count": 4051
  },
  {
    "id": ".uk",
    "value": ".uk",
    "count": 3283
  },
  {
    "id": ".us",
    "value": ".us",
    "count": 27861
  },
  {
    "id": ".vercel.app",
    "value": ".vercel.app",
    "count": 196
  },
  {
    "id": ".vip",
    "value": ".vip",
    "count": 3326
  },
  {
    "id": ".vn",
    "value": ".vn",
    "count": 2651
  },
  {
    "id": ".world",
    "value": ".world",
    "count": 6171
  },
  {
    "id": ".xyz",
    "value": ".xyz",
    "count": 20622
  }
];

export const languageOptions = [
  {
    "id": "Abkhazian",
    "value": "Abkhazian",
    "count": 27
  },
  {
    "id": "Afar",
    "value": "Afar",
    "count": 328
  },
  {
    "id": "Afrikaans",
    "value": "Afrikaans",
    "count": 2262
  },
  {
    "id": "Akan",
    "value": "Akan",
    "count": 143
  },
  {
    "id": "Albanian",
    "value": "Albanian",
    "count": 19928
  },
  {
    "id": "Amharic",
    "value": "Amharic",
    "count": 860
  },
  {
    "id": "Arabic",
    "value": "Arabic",
    "count": 562708
  },
  {
    "id": "Armenian",
    "value": "Armenian",
    "count": 6145
  },
  {
    "id": "Assamese",
    "value": "Assamese",
    "count": 53
  },
  {
    "id": "Aymara",
    "value": "Aymara",
    "count": 155
  },
  {
    "id": "Azerbaijani",
    "value": "Azerbaijani",
    "count": 11508
  },
  {
    "id": "Bangla",
    "value": "Bangla",
    "count": 243351
  },
  {
    "id": "Bashkir",
    "value": "Bashkir",
    "count": 23
  },
  {
    "id": "Basque",
    "value": "Basque",
    "count": 499
  },
  {
    "id": "Belarusian",
    "value": "Belarusian",
    "count": 43
  },
  {
    "id": "Bislama",
    "value": "Bislama",
    "count": 362
  },
  {
    "id": "Bosnian",
    "value": "Bosnian",
    "count": 6456
  },
  {
    "id": "Breton",
    "value": "Breton",
    "count": 257
  },
  {
    "id": "Bulgarian",
    "value": "Bulgarian",
    "count": 46404
  },
  {
    "id": "Burmese",
    "value": "Burmese",
    "count": 18448
  },
  {
    "id": "Catalan",
    "value": "Catalan",
    "count": 1861
  },
  {
    "id": "Cebuano",
    "value": "Cebuano",
    "count": 935
  },
  {
    "id": "Chinese",
    "value": "Chinese",
    "count": 100951
  },
  {
    "id": "Corsican",
    "value": "Corsican",
    "count": 995
  },
  {
    "id": "Croatian",
    "value": "Croatian",
    "count": 25468
  },
  {
    "id": "Czech",
    "value": "Czech",
    "count": 86763
  },
  {
    "id": "Danish",
    "value": "Danish",
    "count": 82898
  },
  {
    "id": "Divehi",
    "value": "Divehi",
    "count": 374
  },
  {
    "id": "Dutch",
    "value": "Dutch",
    "count": 144443
  },
  {
    "id": "English",
    "value": "English",
    "count": 6861510
  },
  {
    "id": "Esperanto",
    "value": "Esperanto",
    "count": 1061
  },
  {
    "id": "Estonian",
    "value": "Estonian",
    "count": 33168
  },
  {
    "id": "Faroese",
    "value": "Faroese",
    "count": 448
  },
  {
    "id": "Fijian",
    "value": "Fijian",
    "count": 290
  },
  {
    "id": "Finnish",
    "value": "Finnish",
    "count": 19761
  },
  {
    "id": "French",
    "value": "French",
    "count": 445256
  },
  {
    "id": "Galician",
    "value": "Galician",
    "count": 7313
  },
  {
    "id": "Ganda",
    "value": "Ganda",
    "count": 179
  },
  {
    "id": "Georgian",
    "value": "Georgian",
    "count": 84497
  },
  {
    "id": "German",
    "value": "German",
    "count": 327738
  },
  {
    "id": "Greek",
    "value": "Greek",
    "count": 76238
  },
  {
    "id": "Guarani",
    "value": "Guarani",
    "count": 585
  },
  {
    "id": "Gujarati",
    "value": "Gujarati",
    "count": 1476
  },
  {
    "id": "Haitian Creole",
    "value": "Haitian Creole",
    "count": 523
  },
  {
    "id": "Hausa",
    "value": "Hausa",
    "count": 356
  },
  {
    "id": "Hawaiian",
    "value": "Hawaiian",
    "count": 154
  },
  {
    "id": "Hebrew",
    "value": "Hebrew",
    "count": 84224
  },
  {
    "id": "Hindi",
    "value": "Hindi",
    "count": 13000
  },
  {
    "id": "Hmong",
    "value": "Hmong",
    "count": 406
  },
  {
    "id": "Hungarian",
    "value": "Hungarian",
    "count": 113664
  },
  {
    "id": "Icelandic",
    "value": "Icelandic",
    "count": 3816
  },
  {
    "id": "Igbo",
    "value": "Igbo",
    "count": 66
  },
  {
    "id": "Indonesian",
    "value": "Indonesian",
    "count": 145189
  },
  {
    "id": "Interlingua",
    "value": "Interlingua",
    "count": 395
  },
  {
    "id": "Interlingue",
    "value": "Interlingue",
    "count": 487
  },
  {
    "id": "Inuktitut",
    "value": "Inuktitut",
    "count": 20
  },
  {
    "id": "Inupiaq",
    "value": "Inupiaq",
    "count": 24
  },
  {
    "id": "Irish",
    "value": "Irish",
    "count": 351
  },
  {
    "id": "Italian",
    "value": "Italian",
    "count": 247678
  },
  {
    "id": "Japanese",
    "value": "Japanese",
    "count": 70474
  },
  {
    "id": "Javanese",
    "value": "Javanese",
    "count": 555
  },
  {
    "id": "Kalaallisut",
    "value": "Kalaallisut",
    "count": 370
  },
  {
    "id": "Kannada",
    "value": "Kannada",
    "count": 363
  },
  {
    "id": "Kazakh",
    "value": "Kazakh",
    "count": 237
  },
  {
    "id": "Khasi",
    "value": "Khasi",
    "count": 334
  },
  {
    "id": "Khmer",
    "value": "Khmer",
    "count": 46469
  },
  {
    "id": "Kinyarwanda",
    "value": "Kinyarwanda",
    "count": 582
  },
  {
    "id": "Klingon",
    "value": "Klingon",
    "count": 441
  },
  {
    "id": "Korean",
    "value": "Korean",
    "count": 13874
  },
  {
    "id": "Kurdish",
    "value": "Kurdish",
    "count": 1900
  },
  {
    "id": "Kyrgyz",
    "value": "Kyrgyz",
    "count": 61
  },
  {
    "id": "Lao",
    "value": "Lao",
    "count": 16127
  },
  {
    "id": "Latin",
    "value": "Latin",
    "count": 2477
  },
  {
    "id": "Latvian",
    "value": "Latvian",
    "count": 22645
  },
  {
    "id": "Lingala",
    "value": "Lingala",
    "count": 253
  },
  {
    "id": "Lithuanian",
    "value": "Lithuanian",
    "count": 90821
  },
  {
    "id": "Luxembourgish",
    "value": "Luxembourgish",
    "count": 393
  },
  {
    "id": "Macedonian",
    "value": "Macedonian",
    "count": 15679
  },
  {
    "id": "Malagasy",
    "value": "Malagasy",
    "count": 1910
  },
  {
    "id": "Malay",
    "value": "Malay",
    "count": 33921
  },
  {
    "id": "Malayalam",
    "value": "Malayalam",
    "count": 1336
  },
  {
    "id": "Maltese",
    "value": "Maltese",
    "count": 647
  },
  {
    "id": "Manx",
    "value": "Manx",
    "count": 316
  },
  {
    "id": "Maori",
    "value": "Maori",
    "count": 399
  },
  {
    "id": "Marathi",
    "value": "Marathi",
    "count": 1601
  },
  {
    "id": "Mongolian",
    "value": "Mongolian",
    "count": 30293
  },
  {
    "id": "Morisyen",
    "value": "Morisyen",
    "count": 249
  },
  {
    "id": "Nauru",
    "value": "Nauru",
    "count": 110
  },
  {
    "id": "Nepali",
    "value": "Nepali",
    "count": 13046
  },
  {
    "id": "Northern Sotho",
    "value": "Northern Sotho",
    "count": 32
  },
  {
    "id": "Norwegian",
    "value": "Norwegian",
    "count": 29678
  },
  {
    "id": "Norwegian Nynorsk",
    "value": "Norwegian Nynorsk",
    "count": 2888
  },
  {
    "id": "Nyanja",
    "value": "Nyanja",
    "count": 183
  },
  {
    "id": "Occitan",
    "value": "Occitan",
    "count": 637
  },
  {
    "id": "Odia",
    "value": "Odia",
    "count": 58
  },
  {
    "id": "Oromo",
    "value": "Oromo",
    "count": 375
  },
  {
    "id": "Pashto",
    "value": "Pashto",
    "count": 404
  },
  {
    "id": "Persian",
    "value": "Persian",
    "count": 1615
  },
  {
    "id": "Polish",
    "value": "Polish",
    "count": 336201
  },
  {
    "id": "Portuguese",
    "value": "Portuguese",
    "count": 1448637
  },
  {
    "id": "Punjabi",
    "value": "Punjabi",
    "count": 204
  },
  {
    "id": "Quechua",
    "value": "Quechua",
    "count": 298
  },
  {
    "id": "Romanian",
    "value": "Romanian",
    "count": 125745
  },
  {
    "id": "Romansh",
    "value": "Romansh",
    "count": 434
  },
  {
    "id": "Rundi",
    "value": "Rundi",
    "count": 124
  },
  {
    "id": "Russian",
    "value": "Russian",
    "count": 30987
  },
  {
    "id": "Samoan",
    "value": "Samoan",
    "count": 225
  },
  {
    "id": "Sango",
    "value": "Sango",
    "count": 15
  },
  {
    "id": "Sanskrit",
    "value": "Sanskrit",
    "count": 477
  },
  {
    "id": "Scots",
    "value": "Scots",
    "count": 1023
  },
  {
    "id": "Scottish Gaelic",
    "value": "Scottish Gaelic",
    "count": 408
  },
  {
    "id": "Serbian",
    "value": "Serbian",
    "count": 24879
  },
  {
    "id": "Seselwa Creole French",
    "value": "Seselwa Creole French",
    "count": 227
  },
  {
    "id": "Shona",
    "value": "Shona",
    "count": 159
  },
  {
    "id": "Sindhi",
    "value": "Sindhi",
    "count": 208
  },
  {
    "id": "Sinhala",
    "value": "Sinhala",
    "count": 9273
  },
  {
    "id": "Slovak",
    "value": "Slovak",
    "count": 43107
  },
  {
    "id": "Slovenian",
    "value": "Slovenian",
    "count": 24013
  },
  {
    "id": "Somali",
    "value": "Somali",
    "count": 1438
  },
  {
    "id": "Southern Sotho",
    "value": "Southern Sotho",
    "count": 172
  },
  {
    "id": "Spanish",
    "value": "Spanish",
    "count": 841692
  },
  {
    "id": "Sundanese",
    "value": "Sundanese",
    "count": 332
  },
  {
    "id": "Swahili",
    "value": "Swahili",
    "count": 622
  },
  {
    "id": "Swati",
    "value": "Swati",
    "count": 114
  },
  {
    "id": "Swedish",
    "value": "Swedish",
    "count": 97020
  },
  {
    "id": "Tagalog",
    "value": "Tagalog",
    "count": 30609
  },
  {
    "id": "Tajik",
    "value": "Tajik",
    "count": 31
  },
  {
    "id": "Tamil",
    "value": "Tamil",
    "count": 1028
  },
  {
    "id": "Tatar",
    "value": "Tatar",
    "count": 167
  },
  {
    "id": "Telugu",
    "value": "Telugu",
    "count": 1016
  },
  {
    "id": "Thai",
    "value": "Thai",
    "count": 384273
  },
  {
    "id": "Tigrinya",
    "value": "Tigrinya",
    "count": 15
  },
  {
    "id": "Tongan",
    "value": "Tongan",
    "count": 213
  },
  {
    "id": "Tsonga",
    "value": "Tsonga",
    "count": 158
  },
  {
    "id": "Tswana",
    "value": "Tswana",
    "count": 225
  },
  {
    "id": "Turkish",
    "value": "Turkish",
    "count": 90873
  },
  {
    "id": "Turkmen",
    "value": "Turkmen",
    "count": 436
  },
  {
    "id": "Ukrainian",
    "value": "Ukrainian",
    "count": 63393
  },
  {
    "id": "Urdu",
    "value": "Urdu",
    "count": 14768
  },
  {
    "id": "Uyghur",
    "value": "Uyghur",
    "count": 39
  },
  {
    "id": "Uzbek",
    "value": "Uzbek",
    "count": 2254
  },
  {
    "id": "Venda",
    "value": "Venda",
    "count": 74
  },
  {
    "id": "Vietnamese",
    "value": "Vietnamese",
    "count": 790528
  },
  {
    "id": "Volapük",
    "value": "Volapük",
    "count": 268
  },
  {
    "id": "Waray",
    "value": "Waray",
    "count": 934
  },
  {
    "id": "Welsh",
    "value": "Welsh",
    "count": 371
  },
  {
    "id": "Western Frisian",
    "value": "Western Frisian",
    "count": 363
  },
  {
    "id": "Wolof",
    "value": "Wolof",
    "count": 252
  },
  {
    "id": "Xhosa",
    "value": "Xhosa",
    "count": 2820
  },
  {
    "id": "Yoruba",
    "value": "Yoruba",
    "count": 158
  },
  {
    "id": "Zhuang",
    "value": "Zhuang",
    "count": 89
  },
  {
    "id": "Zulu",
    "value": "Zulu",
    "count": 105
  },
  {
    "id": "bh",
    "value": "bh",
    "count": 91
  },
  {
    "id": "un",
    "value": "un",
    "count": 131517
  }
];

export const providerOptions = [
  {
    "id": "shopify",
    "value": "Shopify",
    "count": 6665474
  },
  {
    "id": "alibabaminisite",
    "value": "Alibaba Minisite",
    "count": 1011445
  },
  {
    "id": "woocommerce",
    "value": "WooCommerce",
    "count": 734479
  },
  {
    "id": "custom",
    "value": "Custom Cart",
    "count": 664766
  },
  {
    "id": "magento",
    "value": "Magento",
    "count": 248826
  },
  {
    "id": "commentsold",
    "value": "CommentSold",
    "count": 93776
  },
  {
    "id": "bigcommerce",
    "value": "BigCommerce",
    "count": 76298
  },
  {
    "id": "demandware",
    "value": "Salesforce Commerce Cloud",
    "count": 74249
  },
  {
    "id": "ecbeing",
    "value": "ecbeing",
    "count": 70302
  },
  {
    "id": "prestashop",
    "value": "PrestaShop",
    "count": 26870
  },
  {
    "id": "shopline",
    "value": "SHOPLINE",
    "count": 24328
  },
  {
    "id": "spring",
    "value": "Spring",
    "count": 23727
  },
  {
    "id": "shoplazza",
    "value": "Shoplazza",
    "count": 21200
  },
  {
    "id": "hcl",
    "value": "HCL Commerce",
    "count": 19876
  },
  {
    "id": "opencart",
    "value": "OpenCart",
    "count": 18178
  },
  {
    "id": "centra",
    "value": "Centra",
    "count": 17052
  },
  {
    "id": "idosell",
    "value": "IdoSell",
    "count": 12614
  },
  {
    "id": "spreecommerce",
    "value": "Spree Commerce",
    "count": 12043
  },
  {
    "id": "thehutgroup",
    "value": "THG Ingenuity",
    "count": 10595
  },
  {
    "id": "shopbase",
    "value": "ShopBase",
    "count": 9264
  },
  {
    "id": "sap",
    "value": "SAP Commerce Cloud",
    "count": 8978
  },
  {
    "id": "squarespace",
    "value": "Squarespace",
    "count": 8551
  },
  {
    "id": "vtex",
    "value": "VTEX",
    "count": 8226
  },
  {
    "id": "shoper",
    "value": "Shoper",
    "count": 5557
  },
  {
    "id": "shopdeck",
    "value": "Shopdeck",
    "count": 5347
  },
  {
    "id": "tiendanube",
    "value": "Tiendanube",
    "count": 5123
  },
  {
    "id": "ecwid",
    "value": "Ecwid",
    "count": 4884
  },
  {
    "id": "dukaan",
    "value": "Dukaan",
    "count": 3933
  },
  {
    "id": "nop",
    "value": "nopCommerce",
    "count": 3925
  },
  {
    "id": "kajabi",
    "value": "Kajabi",
    "count": 3251
  },
  {
    "id": "oemsaas",
    "value": "OEMSaaS",
    "count": 3123
  },
  {
    "id": "sirclo",
    "value": "SIRCLO",
    "count": 2899
  },
  {
    "id": "shoptet",
    "value": "Shoptet",
    "count": 2805
  },
  {
    "id": "ticimax",
    "value": "Ticimax",
    "count": 2615
  },
  {
    "id": "cscart",
    "value": "Cscart",
    "count": 2583
  },
  {
    "id": "unas",
    "value": "UNAS",
    "count": 2566
  },
  {
    "id": "jetshop",
    "value": "Jetshop",
    "count": 2478
  },
  {
    "id": "teechip",
    "value": "TeeChip",
    "count": 2470
  },
  {
    "id": "drupal",
    "value": "Drupal Commerce",
    "count": 2181
  },
  {
    "id": "youcan",
    "value": "YouCan",
    "count": 2112
  },
  {
    "id": "lightspeed",
    "value": "Lightspeed",
    "count": 2065
  },
  {
    "id": "shopware",
    "value": "Shopware",
    "count": 1997
  },
  {
    "id": "intershop",
    "value": "Intershop",
    "count": 1856
  },
  {
    "id": "odoo",
    "value": "Odoo",
    "count": 1658
  },
  {
    "id": "cafe24",
    "value": "Cafe24",
    "count": 1348
  },
  {
    "id": "funpinpin",
    "value": "FunPinPin",
    "count": 1264
  },
  {
    "id": "miva",
    "value": "Miva Merchant",
    "count": 1259
  },
  {
    "id": "mercado",
    "value": "Mercado Shops",
    "count": 1144
  },
  {
    "id": "square",
    "value": "Square Online",
    "count": 997
  },
  {
    "id": "ikas",
    "value": "ikas",
    "count": 877
  },
  {
    "id": "stackcommerce",
    "value": "StackCommerce",
    "count": 796
  },
  {
    "id": "yahoo",
    "value": "Yahoo Store",
    "count": 770
  },
  {
    "id": "godaddy",
    "value": "GoDaddy Online Store",
    "count": 764
  },
  {
    "id": "prom",
    "value": "prom",
    "count": 760
  },
  {
    "id": "shopyy",
    "value": "Shopyy",
    "count": 723
  },
  {
    "id": "ideasoft",
    "value": "IdeaSoft",
    "count": 717
  },
  {
    "id": "webflow",
    "value": "Webflow",
    "count": 716
  },
  {
    "id": "tossdown",
    "value": "tossdown",
    "count": 713
  },
  {
    "id": "funnelish",
    "value": "Funnelish",
    "count": 598
  },
  {
    "id": "yampi",
    "value": "Yampi",
    "count": 507
  },
  {
    "id": "haravan",
    "value": "Haravan",
    "count": 497
  },
  {
    "id": "xshoppy",
    "value": "XShoppy",
    "count": 490
  },
  {
    "id": "3dcart",
    "value": "3DCart",
    "count": 471
  },
  {
    "id": "visualsoft",
    "value": "Visualsoft",
    "count": 440
  },
  {
    "id": "teachable",
    "value": "Teachable",
    "count": 394
  },
  {
    "id": "atg",
    "value": "Oracle Commerce",
    "count": 372
  },
  {
    "id": "aspdotnetstorefront",
    "value": "AspDotNetStorefront",
    "count": 366
  },
  {
    "id": "storeino",
    "value": "Storeino",
    "count": 363
  },
  {
    "id": "quickbutik",
    "value": "Quickbutik",
    "count": 325
  },
  {
    "id": "viskan",
    "value": "Viskan",
    "count": 320
  },
  {
    "id": "volusion",
    "value": "Volusion",
    "count": 314
  },
  {
    "id": "virtuemart",
    "value": "Virtuemart",
    "count": 313
  },
  {
    "id": "merchantpro",
    "value": "MerchantPro",
    "count": 292
  },
  {
    "id": "shoprenter",
    "value": "Shoprenter",
    "count": 275
  },
  {
    "id": "salla",
    "value": "Salla",
    "count": 261
  },
  {
    "id": "netsuite",
    "value": "NetSuite SuiteCommerce",
    "count": 249
  },
  {
    "id": "vendre",
    "value": "Vendre",
    "count": 241
  },
  {
    "id": "fbits",
    "value": "Fbits",
    "count": 227
  },
  {
    "id": "americommerce",
    "value": "AmeriCommerce",
    "count": 213
  },
  {
    "id": "82cart",
    "value": "82Cart",
    "count": 186
  },
  {
    "id": "zammit",
    "value": "Zammit",
    "count": 181
  },
  {
    "id": "askas",
    "value": "Askås",
    "count": 172
  },
  {
    "id": "tray",
    "value": "Tray Commerce",
    "count": 171
  },
  {
    "id": "ueeshop",
    "value": "ueeshop",
    "count": 166
  },
  {
    "id": "expandcart",
    "value": "ExpandCart",
    "count": 133
  },
  {
    "id": "easystore",
    "value": "EasyStore",
    "count": 129
  },
  {
    "id": "imweb",
    "value": "I'm Web",
    "count": 125
  },
  {
    "id": "unicart",
    "value": "Unicart",
    "count": 123
  },
  {
    "id": "commercehq",
    "value": "CommerceHQ",
    "count": 115
  },
  {
    "id": "meshop",
    "value": "Meshop",
    "count": 113
  },
  {
    "id": "theprintbar",
    "value": "Theprintbar",
    "count": 111
  },
  {
    "id": "instamojo",
    "value": "Instamojo",
    "count": 109
  },
  {
    "id": "sapo",
    "value": "Sapo",
    "count": 99
  },
  {
    "id": "neto",
    "value": "neto",
    "count": 85
  },
  {
    "id": "wix",
    "value": "Wix",
    "count": 80
  },
  {
    "id": "jtl",
    "value": "JTL-Shop",
    "count": 61
  },
  {
    "id": "coachannel",
    "value": "Coachannel",
    "count": 59
  },
  {
    "id": "29next",
    "value": "29Next",
    "count": 57
  },
  {
    "id": "fourthwall",
    "value": "Fourthwall",
    "count": 48
  },
  {
    "id": "cartx",
    "value": "cartX",
    "count": 44
  },
  {
    "id": "gumroad",
    "value": "Gumroad",
    "count": 43
  },
  {
    "id": "shopwp",
    "value": "Shopwp",
    "count": 42
  },
  {
    "id": "tokotalk",
    "value": "TokoTalk",
    "count": 40
  },
  {
    "id": "jouwweb",
    "value": "JouwWeb",
    "count": 37
  },
  {
    "id": "zid",
    "value": "Zid",
    "count": 37
  },
  {
    "id": "zencart",
    "value": "Zencart",
    "count": 34
  },
  {
    "id": "workarea",
    "value": "Workarea",
    "count": 33
  },
  {
    "id": "magazord",
    "value": "Magazord",
    "count": 29
  },
  {
    "id": "dropify",
    "value": "Dropify",
    "count": 27
  },
  {
    "id": "thinkific",
    "value": "Thinkific",
    "count": 25
  },
  {
    "id": "commerce7",
    "value": "Commerce7",
    "count": 24
  },
  {
    "id": "ccvshop",
    "value": "CCV Shop",
    "count": 23
  },
  {
    "id": "irroba",
    "value": "Irroba",
    "count": 23
  },
  {
    "id": "makeshop",
    "value": "MakeShop",
    "count": 23
  },
  {
    "id": "saffire",
    "value": "Saffire",
    "count": 23
  },
  {
    "id": "zvendo",
    "value": "zVendo",
    "count": 22
  },
  {
    "id": "grazecart",
    "value": "GrazeCart",
    "count": 21
  },
  {
    "id": "winedirect",
    "value": "WineDirect",
    "count": 21
  },
  {
    "id": "epages",
    "value": "ePages",
    "count": 20
  },
  {
    "id": "sitecore",
    "value": "Sitecore Commerce",
    "count": 20
  },
  {
    "id": "abicart",
    "value": "Abicart",
    "count": 19
  },
  {
    "id": "cityhive",
    "value": "City Hive",
    "count": 18
  },
  {
    "id": "cococart",
    "value": "Cococart",
    "count": 18
  },
  {
    "id": "oscommerce",
    "value": "Oscommerce",
    "count": 18
  },
  {
    "id": "fenicio",
    "value": "Fenicio",
    "count": 16
  },
  {
    "id": "snipcart",
    "value": "Snipcart",
    "count": 16
  },
  {
    "id": "storehippo",
    "value": "StoreHippo",
    "count": 16
  },
  {
    "id": "easyorders",
    "value": "Easyorders",
    "count": 15
  },
  {
    "id": "wikinggruppen",
    "value": "Wikinggruppen",
    "count": 15
  },
  {
    "id": "bigcartel",
    "value": "Big Cartel",
    "count": 14
  },
  {
    "id": "cartzy",
    "value": "Cartzy",
    "count": 14
  },
  {
    "id": "makane",
    "value": "MakanE",
    "count": 14
  },
  {
    "id": "shopcada",
    "value": "Shopcada",
    "count": 14
  },
  {
    "id": "weeblycommerce",
    "value": "Weebly Commerce",
    "count": 14
  },
  {
    "id": "beatstars",
    "value": "BeatStars",
    "count": 13
  },
  {
    "id": "jumpseller",
    "value": "Jumpseller",
    "count": 13
  },
  {
    "id": "norce",
    "value": "Norce",
    "count": 12
  },
  {
    "id": "corksy",
    "value": "Corksy",
    "count": 11
  },
  {
    "id": "nhngodo",
    "value": "NHN Godo",
    "count": 11
  },
  {
    "id": "podia",
    "value": "Podia",
    "count": 11
  },
  {
    "id": "wuilt",
    "value": "Wuilt",
    "count": 11
  },
  {
    "id": "linx",
    "value": "Linx Commerce",
    "count": 10
  },
  {
    "id": "unknown",
    "value": "Unknown",
    "count": 12980201
  }
];

export const countryOptions = [
  {
    "id": "73d4a414-7db6-4420-afe9-deddb81946d4",
    "code": "AED",
    "name": "United Arab Emirates Dirham",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/United-Arab-Emirates.svg"
  },
  {
    "id": "8cdf0af5-c8aa-4501-894e-8d289644308a",
    "code": "AFN",
    "name": "Afghan Afghani",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Afghanistan.svg"
  },
  {
    "id": "dc4886b1-7457-46d9-8f11-dc5ead9ef7ef",
    "code": "ALL",
    "name": "Albanian Lek",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Albania.svg"
  },
  {
    "id": "69158203-b4d9-4226-ba42-ec3cec713d1c",
    "code": "AMD",
    "name": "Armenian Dram",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Armenia.svg"
  },
  {
    "id": "e7910732-4480-4d56-b4d8-cd9b9063ae43",
    "code": "AOA",
    "name": "Angolan Kwanza",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Angola.svg"
  },
  {
    "id": "1a05bd90-246f-4447-8a90-62c72df2badb",
    "code": "ARS",
    "name": "Argentine Peso",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Argentina.svg"
  },
  {
    "id": "03b70ffd-78c1-47af-a43b-b06e3ae568c0",
    "code": "AUD",
    "name": "Australian Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Australia.svg"
  },
  {
    "id": "fb48704e-634c-4999-b095-befd3eaee32f",
    "code": "AZN",
    "name": "Azerbaijani Manat",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Azerbaijan.svg"
  },
  {
    "id": "3cece827-a032-4e35-9ad3-17cd7b0a08af",
    "code": "BAM",
    "name": "Bosnia And Herzegovina Convertible Mark",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Bosnia-and-Herzegovina.svg"
  },
  {
    "id": "09ddb625-7615-4c0a-923c-ec6d50e42c9c",
    "code": "BBD",
    "name": "Barbadian Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Barbados.svg"
  },
  {
    "id": "1526e7e0-a1bf-4154-b8ed-f5caff03ac67",
    "code": "BDT",
    "name": "Bangladeshi Taka",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Bangladesh.svg"
  },
  {
    "id": "eac119b8-1a40-44f2-87e0-268f1aa9fd30",
    "code": "BGN",
    "name": "Bulgarian Lev",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Bulgaria.svg"
  },
  {
    "id": "0cf57a31-ba34-4f62-82f5-8baab83fcda9",
    "code": "BHD",
    "name": "Bahraini Dinar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Bahrain.svg"
  },
  {
    "id": "5eaddf0e-390d-4c08-a514-9cae12a0d627",
    "code": "BIF",
    "name": "Burundian Franc",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Burundi.svg"
  },
  {
    "id": "aa1eedb8-2977-41d9-9bf0-a93784dcc155",
    "code": "BND",
    "name": "Brunei Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Brunei.svg"
  },
  {
    "id": "d9fcc0e0-d063-4098-8a68-a22968d086f8",
    "code": "BOB",
    "name": "Bolivian Boliviano",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Bolivia.svg"
  },
  {
    "id": "7ad79d0a-26fc-4722-b5f2-d5f4ddf090e2",
    "code": "BRL",
    "name": "Brazilian Real",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Brazil.svg"
  },
  {
    "id": "8721a989-9038-4bb2-957a-7ab34838c596",
    "code": "BSD",
    "name": "Bahamian Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Bahamas.svg"
  },
  {
    "id": "6f835ad8-211d-4223-b320-db0cd90aaa13",
    "code": "BTN",
    "name": "Bhutanese Ngultrum",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Bhutan.svg"
  },
  {
    "id": "2583f71d-3896-4048-b0d4-8b837293f3df",
    "code": "BWP",
    "name": "Botswana Pula",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Botswana.svg"
  },
  {
    "id": "05e201db-04f4-47f7-818b-c87166cbc6e0",
    "code": "BYN",
    "name": "Belarusian Ruble",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Belarus.svg"
  },
  {
    "id": "9ddd233b-21f2-4f19-99b3-956d3a813e1b",
    "code": "BZD",
    "name": "Belize Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Belize.svg"
  },
  {
    "id": "770cfce3-611a-491d-8510-d127afb80c35",
    "code": "CAD",
    "name": "Canadian Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Canada.svg"
  },
  {
    "id": "9c249d7c-03db-4502-885e-ac236ab1ada1",
    "code": "CDF",
    "name": "Congolese Franc",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Democratic-Republic-of-Congo.svg"
  },
  {
    "id": "9f0ee408-b8bd-4d8c-8f9c-7781a692d5e9",
    "code": "CHF",
    "name": "Swiss Franc",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Liechtenstein.svg"
  },
  {
    "id": "0e112c76-2249-4a64-b70f-659db3ce54b1",
    "code": "CLP",
    "name": "Chilean Peso",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Chile.svg"
  },
  {
    "id": "7e5bf149-a03a-4949-8eb4-2ebe6f70656e",
    "code": "CNY",
    "name": "Chinese Yuan",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/China.svg"
  },
  {
    "id": "84d78c9e-b194-4aa8-9de6-4193c881242b",
    "code": "COP",
    "name": "Colombian Peso",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Colombia.svg"
  },
  {
    "id": "60177c45-dc89-4829-a406-8871cafe6a1a",
    "code": "CRC",
    "name": "Costa Rican Colón",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Costa-Rica.svg"
  },
  {
    "id": "9f8f2c58-cd1e-46d1-a3eb-331cd1dc6366",
    "code": "CVE",
    "name": "Cape Verdean Escudo",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Cape-Verde.svg"
  },
  {
    "id": "987fa102-fdbe-4380-aca8-f1cb8854fab6",
    "code": "CZK",
    "name": "Czech Koruna",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Czech-Republic.svg"
  },
  {
    "id": "f59b533b-b902-4046-b359-6428ce6e4735",
    "code": "DJF",
    "name": "Djiboutian Franc",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Djibouti.svg"
  },
  {
    "id": "2cd18c42-c285-438f-bb57-33aa6c5db68a",
    "code": "DKK",
    "name": "Danish Krone",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Denmark.svg"
  },
  {
    "id": "d6236369-f3db-47bf-8a70-9ca05e01a77f",
    "code": "DOP",
    "name": "Dominican Peso",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Dominican-Republic.svg"
  },
  {
    "id": "8541e62e-578b-4213-87d2-857e2f89ff5e",
    "code": "DZD",
    "name": "Algerian Dinar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Algeria.svg"
  },
  {
    "id": "6680735b-7a40-4331-99f7-d154682679e1",
    "code": "EGP",
    "name": "Egyptian Pound",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Egypt.svg"
  },
  {
    "id": "bc98e4d6-4574-4071-99d3-781e1d178b1c",
    "code": "ERN",
    "name": "Eritrean Nakfa",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Eritrea.svg"
  },
  {
    "id": "65dfef54-ecaf-401c-9643-a9fb41e2d8dd",
    "code": "ETB",
    "name": "Ethiopian Birr",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Ethiopia.svg"
  },
  {
    "id": "f067af40-6486-44fe-91b8-487aeb5c2021",
    "code": "EUR",
    "name": "Euro",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/European-Union.svg"
  },
  {
    "id": "6a461b10-d73d-437b-8e31-e8df2a1da886",
    "code": "FJD",
    "name": "Fijian Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Fiji.svg"
  },
  {
    "id": "ba33b3d5-12f3-49c0-8e0f-3f4ef510098a",
    "code": "GBP",
    "name": "British Pound",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/United-Kingdom.svg"
  },
  {
    "id": "ba5f4e9c-9561-4b2b-a41c-6c071f14eb5b",
    "code": "GEL",
    "name": "Georgian Lari",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Georgia.svg"
  },
  {
    "id": "4b370261-8641-4645-a013-375dc677b229",
    "code": "GHS",
    "name": "Ghanaian Cedi",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Ghana.svg"
  },
  {
    "id": "5371573c-9656-4c89-932f-462e245f9741",
    "code": "GMD",
    "name": "Gambian Dalasi",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Gambia.svg"
  },
  {
    "id": "8157dd73-9de9-40ef-ae71-ab72e78702c6",
    "code": "GNF",
    "name": "Guinean Franc",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Guinea.svg"
  },
  {
    "id": "fa11f637-0170-4d63-98ef-d310959026ef",
    "code": "GTQ",
    "name": "Guatemalan Quetzal",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Guatemala.svg"
  },
  {
    "id": "508fd8c6-0dd3-4cb8-bdb1-2e9841891fda",
    "code": "GYD",
    "name": "Guyanese Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Guyana.svg"
  },
  {
    "id": "6a518ba6-aa53-46cf-abba-8fbdcbfbed20",
    "code": "HNL",
    "name": "Honduran Lempira",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Honduras.svg"
  },
  {
    "id": "4a351007-e191-4c64-809a-d6d7b6473850",
    "code": "HRK",
    "name": "Croatian Kuna",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Croatia.svg"
  },
  {
    "id": "a0ea605f-1f55-4426-adaa-2bc27cd7166c",
    "code": "HTG",
    "name": "Haitian Gourde",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Haiti.svg"
  },
  {
    "id": "a95ba3dc-af5d-4915-8af2-5bb68785cbf2",
    "code": "HUF",
    "name": "Hungarian Forint",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Hungary.svg"
  },
  {
    "id": "c9d53f0f-9d90-49dd-9899-d0450b45e5c8",
    "code": "IDR",
    "name": "Indonesian Rupiah",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Indonesia.svg"
  },
  {
    "id": "293e0437-3283-40b2-a757-8beec188a47e",
    "code": "ILS",
    "name": "Israeli New Shekel",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Israel.svg"
  },
  {
    "id": "bcb8cb10-5d4e-4f2c-bb68-40c96b7e0461",
    "code": "INR",
    "name": "Indian Rupee",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/India.svg"
  },
  {
    "id": "7e3c8c17-105a-40ee-bc33-d87d01f6c219",
    "code": "IQD",
    "name": "Iraqi Dinar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Iraq.svg"
  },
  {
    "id": "fc62ad4d-2c68-4b13-972e-ec589b5a6633",
    "code": "IRR",
    "name": "Iranian Rial",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Iran.svg"
  },
  {
    "id": "3ae05f24-fb59-4617-9090-f5f5b7c970dd",
    "code": "ISK",
    "name": "Icelandic Króna",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Iceland.svg"
  },
  {
    "id": "457b5cff-aa0a-4e4f-b25c-32ec7692e238",
    "code": "JMD",
    "name": "Jamaican Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Jamaica.svg"
  },
  {
    "id": "bdb06c1a-ba84-4fdd-a5a0-d39d8dc362ce",
    "code": "JOD",
    "name": "Jordanian Dinar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Jordan.svg"
  },
  {
    "id": "61cfece9-6e54-49ab-8ddd-dc18d88909a3",
    "code": "JPY",
    "name": "Japanese Yen",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Japan.svg"
  },
  {
    "id": "7f4fb787-0835-4ae8-a2e3-6b2adc92b29f",
    "code": "KES",
    "name": "Kenyan Shilling",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Kenya.svg"
  },
  {
    "id": "c34f38fd-7778-414a-9513-f07a623ace77",
    "code": "KGS",
    "name": "Kyrgyzstani Som",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Kyrgyzstan.svg"
  },
  {
    "id": "fd514e47-6d16-4891-855e-0a7445de2185",
    "code": "KHR",
    "name": "Cambodian Riel",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Cambodia.svg"
  },
  {
    "id": "68f1ca79-94f7-4b1f-89d6-e2cd6cd6afa9",
    "code": "KMF",
    "name": "Comorian Franc",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Comoros.svg"
  },
  {
    "id": "6c0f4292-47fc-4aa0-a1b0-4fbe2dcbf961",
    "code": "KRW",
    "name": "South Korean Won",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/South-Korea.svg"
  },
  {
    "id": "18319b1f-e1d5-4864-8038-abacec42dbad",
    "code": "KWD",
    "name": "Kuwaiti Dinar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Kuwai.svg"
  },
  {
    "id": "2c36675c-4db1-4a40-a977-806ee7100c1f",
    "code": "KZT",
    "name": "Kazakhstani Tenge",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Kazakhstan.svg"
  },
  {
    "id": "113b8d07-34e5-4222-ac1c-05d66619fde2",
    "code": "LAK",
    "name": "Lao Kip",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Laos.svg"
  },
  {
    "id": "16167394-8390-4e2d-8148-377b923b3463",
    "code": "LBP",
    "name": "Lebanese Pound",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Lebanon.svg"
  },
  {
    "id": "8f5beb5b-2afa-4778-bbe7-f72f93ffc69a",
    "code": "LKR",
    "name": "Sri Lankan Rupee",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Sri-Lanka.svg"
  },
  {
    "id": "320a41b0-c27d-41df-ac0b-8ecce1e324c2",
    "code": "LRD",
    "name": "Liberian Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Liberia.svg"
  },
  {
    "id": "8c0b891d-c793-42f4-bf31-e6b5c81e02db",
    "code": "LSL",
    "name": "Lesotho Loti",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Lesotho.svg"
  },
  {
    "id": "5065e0be-99c6-42c9-855d-e61b68e4d66a",
    "code": "LYD",
    "name": "Libyan Dinar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Libya.svg"
  },
  {
    "id": "93ed67d7-1599-416f-ae85-a8daa3b9e6b0",
    "code": "MAD",
    "name": "Moroccan Dirham",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Morocco.svg"
  },
  {
    "id": "faa78c4c-ec4e-4dd2-a0d7-f3f68db118be",
    "code": "MDL",
    "name": "Moldovan Leu",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Moldova.svg"
  },
  {
    "id": "fe1fdb78-5ace-46a0-b09f-5c888ebcbc1b",
    "code": "MGA",
    "name": "Malagasy Ariary",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Madagascar.svg"
  },
  {
    "id": "0637becb-79c5-41df-9662-8c275acc16dc",
    "code": "MKD",
    "name": "Macedonian Denar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/North-Macedonia.svg"
  },
  {
    "id": "62953a3a-cf03-4c09-a0a8-8c1d3dad35ea",
    "code": "MMK",
    "name": "Burmese Kyat",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Myanmar.svg"
  },
  {
    "id": "e5642a0e-264d-4a18-8ad0-9eb92dfcc0eb",
    "code": "MNT",
    "name": "Mongolian Tugrug",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Mongolia.svg"
  },
  {
    "id": "f0dbab54-afa1-4778-96da-bd22b9e17e55",
    "code": "MUR",
    "name": "Mauritian Rupee",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Mauritius.svg"
  },
  {
    "id": "9b29f4b4-626c-4af9-90cb-f18eaa8dd475",
    "code": "MVR",
    "name": "Maldivian Rufiyaa",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Maldives.svg"
  },
  {
    "id": "b91bdbc5-c9b6-45d9-8ff7-5bd096cb2e24",
    "code": "MWK",
    "name": "Malawian Kwacha",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Malawi.svg"
  },
  {
    "id": "f68e462b-518a-4f46-bfaf-3afcefe35c78",
    "code": "MXN",
    "name": "Mexican Peso",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Mexico.svg"
  },
  {
    "id": "d3c38883-a665-4005-8178-420790646859",
    "code": "MYR",
    "name": "Malaysian Ringgit",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Malaysia.svg"
  },
  {
    "id": "d86ee9e6-3ce5-4efe-8266-53573809aa15",
    "code": "MZN",
    "name": "Mozambican Metical",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Mozambique.svg"
  },
  {
    "id": "ac5fe2f3-061f-4573-9760-e770d73193f8",
    "code": "NAD",
    "name": "Namibian Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Namibia.svg"
  },
  {
    "id": "37313a40-1fe8-4ef7-9e33-34654636c63e",
    "code": "NGN",
    "name": "Nigerian Naira",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Nigeria.svg"
  },
  {
    "id": "ba07d0e7-0a0c-4266-bb04-cc767b296bcd",
    "code": "NIO",
    "name": "Nicaraguan Cordoba",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Nicaragua.svg"
  },
  {
    "id": "d0552a1a-efed-4e26-b73f-c1745f42c408",
    "code": "NOK",
    "name": "Norwegian Krone",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Norway.svg"
  },
  {
    "id": "4045131a-70c5-4662-add7-fa89a5b62608",
    "code": "NPR",
    "name": "Nepalese Rupee",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Nepal.svg"
  },
  {
    "id": "cf3a7bb9-4215-4541-90a1-bfa5a7adffe6",
    "code": "NZD",
    "name": "New Zealand Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/New-Zealand.svg"
  },
  {
    "id": "5fc09c97-5126-49f6-93e6-0435994a5a65",
    "code": "OMR",
    "name": "Omani Rial",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Oman.svg"
  },
  {
    "id": "5dcab9cf-ba85-41ef-84ea-81a19b6724bc",
    "code": "PAB",
    "name": "Panamanian Balboa",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Panama.svg"
  },
  {
    "id": "b6b2ee08-66a0-4ca0-95bd-a5b46dc0b824",
    "code": "PEN",
    "name": "Peruvian Sol",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Peru.svg"
  },
  {
    "id": "b2b57f5d-2707-4b5f-8401-572c37d10a50",
    "code": "PGK",
    "name": "Papua New Guinean Kina",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Papua-New-Guinea.svg"
  },
  {
    "id": "30386f1a-6868-4af9-a934-3f84b7eebe9c",
    "code": "PHP",
    "name": "Philippine Peso",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Philippines.svg"
  },
  {
    "id": "1cc6b4de-558b-48bd-996e-14898ce03b72",
    "code": "PKR",
    "name": "Pakistani Rupee",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Pakistan.svg"
  },
  {
    "id": "0221ff54-29ea-4e50-aa2c-df78f377257f",
    "code": "PLN",
    "name": "Polish Zloty",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Poland.svg"
  },
  {
    "id": "ee2fa33a-1420-418d-861f-026f4d6bef6c",
    "code": "PYG",
    "name": "Paraguayan guaraní",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Paraguay.svg"
  },
  {
    "id": "df6784f6-dd89-4786-8881-4d5a73cf86e1",
    "code": "QAR",
    "name": "Qatari Riyal",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Qatar.svg"
  },
  {
    "id": "51a02735-902d-467f-8482-3dd94271eef6",
    "code": "RON",
    "name": "Romanian Leu",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Romania.svg"
  },
  {
    "id": "603e7ccc-17d2-4a93-9c4b-9ab819dee739",
    "code": "RSD",
    "name": "Serbian Dinar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Serbia.svg"
  },
  {
    "id": "4e07553e-f17b-4c49-b724-692ada80c488",
    "code": "RUB",
    "name": "Russian Ruble",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Russia.svg"
  },
  {
    "id": "ef0f8848-608a-4e4d-9d67-045816e8af09",
    "code": "RWF",
    "name": "Rwandan Franc",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Rwanda.svg"
  },
  {
    "id": "72d140ee-649e-4e9a-9318-30049ce197d2",
    "code": "SAR",
    "name": "Saudi Riyal",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Saudi-Arabia.svg"
  },
  {
    "id": "88a755c8-277c-4f63-a564-526af8ad5389",
    "code": "SBD",
    "name": "Solomon Islands Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Solomon-Islands.svg"
  },
  {
    "id": "a599c16a-4c03-4317-a16b-8671cd514b2c",
    "code": "SCR",
    "name": "Seychellois Rupee",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Seychelles.svg"
  },
  {
    "id": "525d0a4b-408d-446e-8e17-c91bd0e4476d",
    "code": "SDG",
    "name": "Sudanese Pound",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Sudan.svg"
  },
  {
    "id": "f624e0cd-81ba-40dd-85b1-1a9bae139fe1",
    "code": "SEK",
    "name": "Swedish Krona",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Sweden.svg"
  },
  {
    "id": "9fa4595b-2bda-4f24-bdfd-f748cce661e4",
    "code": "SGD",
    "name": "Singapore Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Singapore.svg"
  },
  {
    "id": "17a96281-9420-414c-9fd7-74909a49f331",
    "code": "SLL",
    "name": "Sierra Leonean Leone",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Sierra-Leone.svg"
  },
  {
    "id": "63532819-78fa-4e0b-8844-a0bc280b0537",
    "code": "SOS",
    "name": "Somali Shilling",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Somalia.svg"
  },
  {
    "id": "7161606d-5470-4925-8580-4e0de80be40c",
    "code": "SRD",
    "name": "Surinamese Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Suriname.svg"
  },
  {
    "id": "d659db46-c15c-4c50-92aa-767a0397e45a",
    "code": "SSP",
    "name": "South Sudanese Pound",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/South-Sudan.svg"
  },
  {
    "id": "eb6cd5fe-efeb-43e4-b85c-4ddf1f1933b0",
    "code": "STD",
    "name": "Sao Tome And Principe Dobra",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Sao-Tome-and-Principe.svg"
  },
  {
    "id": "36bf97d9-17ee-4552-b44e-b3d5a4332ef0",
    "code": "SYP",
    "name": "Syrian Pound",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Syria.svg"
  },
  {
    "id": "ae58160e-582b-4605-91b3-1ed10686783b",
    "code": "SZL",
    "name": "Swazi Lilangeni",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Eswatini.svg"
  },
  {
    "id": "8d54c62f-8282-4a01-97dc-00a255bac106",
    "code": "THB",
    "name": "Thai Baht",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Thailand.svg"
  },
  {
    "id": "80c376d7-1f27-4441-b357-5e255c531cdd",
    "code": "TJS",
    "name": "Tajikistani Somoni",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Tajikistan.svg"
  },
  {
    "id": "d0744c54-40e1-4db7-b8ea-56db35cda931",
    "code": "TMT",
    "name": "Turkmenistan Manat",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Turkmenistan.svg"
  },
  {
    "id": "5240f9b2-d05f-42d1-9259-c362bdee0f2a",
    "code": "TND",
    "name": "Tunisian Dinar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Tunisia.svg"
  },
  {
    "id": "01e603cd-fc42-4798-afb9-c141fa746628",
    "code": "TOP",
    "name": "Tongan Pa'Anga",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Tonga.svg"
  },
  {
    "id": "dabaeb55-c0da-4e51-94e7-4104da096952",
    "code": "TRY",
    "name": "Turkish Lira",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Turkey.svg"
  },
  {
    "id": "11de5ee1-ff93-4082-a788-ad8618682a5c",
    "code": "TTD",
    "name": "Trinidad And Tobago Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Trinidad-and-Tobago.svg"
  },
  {
    "id": "3f16fdf5-54a0-4929-bb0b-cb448acb009c",
    "code": "TWD",
    "name": "New Taiwan Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Taiwan.svg"
  },
  {
    "id": "7626a6a5-6290-4266-8348-153842f15887",
    "code": "TZS",
    "name": "Tanzanian Shilling",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Tanzania.svg"
  },
  {
    "id": "2e6227c1-b6aa-448c-861b-f9a473e6d4d5",
    "code": "UAH",
    "name": "Ukrainian Hryvnia",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Ukraine.svg"
  },
  {
    "id": "883ae3a7-8cd6-4122-b8e1-f08490574fef",
    "code": "UGX",
    "name": "Ugandan Shilling",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Uganda.svg"
  },
  {
    "id": "9bf735cf-fe4e-4d15-a808-ebfb8ecf8a81",
    "code": "USD",
    "name": "United States Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/United-States.svg"
  },
  {
    "id": "f156a69d-f6b4-45ce-bf99-4816bb92d304",
    "code": "UYU",
    "name": "Uruguayan Peso",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Uruguay.svg"
  },
  {
    "id": "15d8cb0e-064b-4801-80fc-515d1bb5c9ec",
    "code": "UZS",
    "name": "Uzbekistani Som",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Uzbekistan.svg"
  },
  {
    "id": "2db23522-3d19-4d55-82d8-f64848e6ca02",
    "code": "VEF",
    "name": "Venezuelan bolívar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Venezuela.svg"
  },
  {
    "id": "b0b10dad-c29c-4233-8631-6a0574b068ab",
    "code": "VND",
    "name": "Vietnamese Dong",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Vietnam.svg"
  },
  {
    "id": "bf64da8a-53ef-4bcd-a1a4-a95a243053fb",
    "code": "VUV",
    "name": "Vanuatu Vatu",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Vanuatu.svg"
  },
  {
    "id": "442b395f-5810-43d2-bbf7-98643b4ff86f",
    "code": "WST",
    "name": "Samoan Tala",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Samoa.svg"
  },
  {
    "id": "1bb69ce1-17bb-4bd0-b4fa-6161728943db",
    "code": "XAF",
    "name": "Central African Cfa Franc",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Cameroon.svg"
  },
  {
    "id": "8821e7c5-4d77-49b9-8bdf-c76ff7673f3c",
    "code": "XCD",
    "name": "East Caribbean Dollar",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Antigua-and-Barbuda.svg"
  },
  {
    "id": "80bffdac-ac4c-4a80-9674-67fe8a8be4c2",
    "code": "XOF",
    "name": "West African Cfa Franc",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Benin.svg"
  },
  {
    "id": "5f9a32ed-9cb8-493a-a526-7c2b006be649",
    "code": "YER",
    "name": "Yemeni Rial",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Yemen.svg"
  },
  {
    "id": "5490ad61-f6d7-430a-acae-c8aff043d6cc",
    "code": "ZAR",
    "name": "South African Rand",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/South-Africa.svg"
  },
  {
    "id": "a9cb53e2-ef4c-4bfa-823d-819f6ac37738",
    "code": "ZMW",
    "name": "Zambian Kwacha",
    "flag": "https://dropship-media-stage.s3.amazonaws.com/product_database/currencies/Zambia.svg"
  }
];

export const statusOptions = [
  {
    id: 'active',
    value: 'Active'
  },
  {
    id: 'inactive',
    value: 'Inactive'
  },
];
