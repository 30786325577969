import React from 'react';
import { useSelector } from 'react-redux';

const AdSpotVideo = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M12.7083 7.2915V3.95817C12.7083 3.0377 11.9621 2.2915 11.0417 2.2915H3.95834C3.03786 2.2915 2.29167 3.0377 2.29167 3.95817V11.0415C2.29167 11.962 3.03786 12.7082 3.95834 12.7082H7.29167M2.58334 9.9165L4.07864 8.93683C4.63738 8.57075 5.36072 8.57365 5.91651 8.94418L6.98238 9.65476M5.00001 5.20817H5.41667M12.0833 12.5H12.5M5.62501 5.20817C5.62501 5.43829 5.43846 5.62484 5.20834 5.62484C4.97822 5.62484 4.79167 5.43829 4.79167 5.20817C4.79167 4.97805 4.97822 4.7915 5.20834 4.7915C5.43846 4.7915 5.62501 4.97805 5.62501 5.20817ZM11.4583 10.9998V13.9998L13.7917 12.4998L11.4583 10.9998ZM16.0417 17.7082H8.95834C8.03786 17.7082 7.29167 16.962 7.29167 16.0415V8.95817C7.29167 8.0377 8.03786 7.2915 8.95834 7.2915H16.0417C16.9621 7.2915 17.7083 8.0377 17.7083 8.95817V16.0415C17.7083 16.962 16.9621 17.7082 16.0417 17.7082Z"
        stroke={theme === 'light' ? '#707BA0' : 'rgba(255, 255, 255, 0.36)'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>
  );
};

export default AdSpotVideo;
