import React from 'react';
import cls from 'classname';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Dropdown, Switch } from 'antd';
import Icon from '../../../Icon';
import './ChartBlock.less';

//diff titles for diff reports
const countTitle = {
  revenue: 'Total Revenue',
  sales: 'Total Sales',
  unique_products_sold: 'Total Unique Products Sold',
  ad_spot: 'Ad Engagement',
  ad_spot_date: 'Ad creation date',
};

const title = {
  revenue: 'Revenue and Returns',
  sales: 'Sales and Returns',
  unique_products_sold: 'Unique Products Sold and Returns',
  ad_spot: 'Ad engagement',
  ad_spot_date: 'Ad creation date',
};

const ChartBlock = ({
  type,
  subTitle,
  totalCount,
  link,
  isMobile = false,
  adsInfo = false,
  adsQuantity = 4,
  sameLandingPageToggleText = null,
  historicalDataViewToggleText = null,
  sameLandingPage = false,
  setSameLandingPage = () => null,
  historicalDataView = true,
  setHistoricalDataView = () => null,
  id = null,
  showRawData = false,
  rawTotalCount,
  returnsCount,
  rawReturnsCount,
  withReturns = false,
  isLineChart,
  setIsLineChart,
  ...props
}) => {
  const { t } = useTranslation();

  //total number format
  let totalFormat = (value) =>
    new Intl.NumberFormat(
      'en-US',
      type === 'revenue'
        ? { style: 'currency', currency: 'USD' }
        : { style: 'decimal', maximumFractionDigits: 0 },
    ).format(value);

  return (
    <div className="chart-block" id={id}>
      <div className="chart-block_header">
        <span className="chart-block_title">{t(title[type])}</span>
        {isMobile && (
          <>
            <span className="chart-block_total-count">
              {totalFormat(totalCount)}
            </span>
            <span className="chart-block_sub-title" style={{ marginBottom: 8 }}>
              {subTitle}
            </span>
          </>
        )}
        {
          //link to report page
          link && (
            <Link
              className="chart-block_link"
              to={{
                pathname: link,
                state: {
                  name: t(title[type]),
                  link: `/sales-tracker/store/${title}`,
                },
              }}
            >
              {t('View Report')}
              <Icon type={'arrow_link'} role={'icon'} />
            </Link>
          )
        }
        <div className="period-filter">
          {historicalDataViewToggleText && (
            <div
              className={cls('period-filter-wrapper-icons', {
                mobile: isMobile,
              })}
            >
              <div
                className={cls('period-filter', {
                  active: historicalDataView,
                })}
                onClick={() => setHistoricalDataView((prev) => !prev)} // false
                style={{ cursor: 'pointer' }}
              >
                {historicalDataViewToggleText?.[0]}
              </div>
              <div
                className={cls('period-filter', {
                  active: !historicalDataView,
                })}
                onClick={() => setHistoricalDataView((prev) => !prev)} // true
                style={{ cursor: 'pointer' }}
              >
                {historicalDataViewToggleText?.[1]}
              </div>
            </div>
          )}
          <div className="period-filter-wrapper-icons">
            <div
              className={cls('period-filter', {
                active: isLineChart,
              })}
              onClick={() => setIsLineChart(true)}
              style={{ cursor: 'pointer' }}
            >
              <Icon type={`line_chart`} role={'icon'} />
            </div>
            <div
              className={cls('period-filter', {
                active: !isLineChart,
              })}
              onClick={() => setIsLineChart(false)}
              style={{ cursor: 'pointer' }}
            >
              <Icon type={`bar_chart`} role={'icon'} />
            </div>
          </div>
        </div>
      </div>
      {!isMobile && (
        <span className="chart-block_total-count">
          {totalFormat(totalCount)}
          {subTitle && <Divider type={'vertical'} />}
          <span
            style={{ display: isMobile ? 'block' : 'inline-block' }}
            className="chart-block_sub-title"
          >
            {subTitle}
          </span>
        </span>
      )}
      {props.children}
    </div>
  );
};

export default ChartBlock;
