import React from 'react';
import { useSelector } from 'react-redux';

const AdSpotFilterGlobus = () => {
  const theme = useSelector((state) => state?.nav?.theme);

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={theme === 'light' ? '#A1AAC8' : '#ffffff5c'}
        d="M9.99935 17.7087C14.2565 17.7087 17.7077 14.2575 17.7077 10.0003C17.7077 5.74313 14.2565 2.29199 9.99935 2.29199M9.99935 17.7087C5.74215 17.7087 2.29102 14.2575 2.29102 10.0003C2.29102 5.74313 5.74215 2.29199 9.99935 2.29199M9.99935 17.7087C8.04334 17.7087 6.45768 14.2575 6.45768 10.0003C6.45768 5.74313 8.04334 2.29199 9.99935 2.29199M9.99935 17.7087C11.9554 17.7087 13.541 14.2575 13.541 10.0003C13.541 5.74313 11.9554 2.29199 9.99935 2.29199M17.4993 10.0003H2.49935"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default AdSpotFilterGlobus;
