import React from 'react';

const Pictures = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          d="M5.48816 11.1785L6.01849 11.7088L5.48816 11.1785ZM7.84518 11.1785L8.37551 10.6482L7.84518 11.1785ZM4.79167 3.875H15.2083V2.375H4.79167V3.875ZM16.125 4.79167V15.2083H17.625V4.79167H16.125ZM3.65533 14.072L6.01849 11.7088L4.95783 10.6482L2.59467 13.0113L3.65533 14.072ZM3.875 15.2083V13.5417H2.375V15.2083H3.875ZM3.875 13.5417V4.79167H2.375V13.5417H3.875ZM7.31485 11.7088L13.0113 17.4053L14.072 16.3447L8.37551 10.6482L7.31485 11.7088ZM15.2083 16.125H13.5417V17.625H15.2083V16.125ZM13.5417 16.125H4.79167V17.625H13.5417V16.125ZM6.01849 11.7088C6.37647 11.3509 6.95687 11.3509 7.31485 11.7088L8.37551 10.6482C7.43174 9.70441 5.90159 9.70441 4.95783 10.6482L6.01849 11.7088ZM2.375 15.2083C2.375 16.543 3.45698 17.625 4.79167 17.625V16.125C4.28541 16.125 3.875 15.7146 3.875 15.2083H2.375ZM16.125 15.2083C16.125 15.7146 15.7146 16.125 15.2083 16.125V17.625C16.543 17.625 17.625 16.543 17.625 15.2083H16.125ZM15.2083 3.875C15.7146 3.875 16.125 4.28541 16.125 4.79167H17.625C17.625 3.45698 16.543 2.375 15.2083 2.375V3.875ZM4.79167 2.375C3.45698 2.375 2.375 3.45698 2.375 4.79167H3.875C3.875 4.28541 4.28541 3.875 4.79167 3.875V2.375ZM13 7.91667C13 8.42293 12.5896 8.83333 12.0833 8.83333V10.3333C13.418 10.3333 14.5 9.25135 14.5 7.91667H13ZM12.0833 8.83333C11.5771 8.83333 11.1667 8.42293 11.1667 7.91667H9.66667C9.66667 9.25135 10.7486 10.3333 12.0833 10.3333V8.83333ZM11.1667 7.91667C11.1667 7.41041 11.5771 7 12.0833 7V5.5C10.7486 5.5 9.66667 6.58198 9.66667 7.91667H11.1667ZM12.0833 7C12.5896 7 13 7.41041 13 7.91667H14.5C14.5 6.58198 13.418 5.5 12.0833 5.5V7Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default Pictures;
