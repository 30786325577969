import {API, encryptedData} from '../../Services/Api';
const request = new API();

export const getAdLibraryFilters = () =>
  request.api.get(`ad_library/choice_filters/`, null);

export const getAdLibraryPresets = () =>
  request.api.get(`ad_library/filter_presets/`, null);

export const createAdLibraryPreset = (data) =>
  request.api.post(`ad_library/filter_presets/`, encryptedData(data));

export const deleteAdLibraryPreset = (record) =>
  request.api.delete(`ad_library/filter_presets/${record.id}/`, null);

export const updateAdLibraryPreset = (data) =>
  request.api.patch(`ad_library/filter_presets/${data.record.id}/`, encryptedData({name: data.name}));

export const getAdLibraryAds = (data) =>
  request.api.post(`ad_library/search/`, encryptedData({...data}));

