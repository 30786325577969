import React from "react";

const BlockStatisticsSkeleton = ({ isMobile }) => {
  const statBlocks = [
    { title: 100, value: 160 },
    { title: 150, value: 125 },
    { title: 155, value: 105 },
    { title: 90, value: 100 },
    { title: 100, value: 75 },
    { title: 90, value: 70 },
    { title: 90, value: 120 },
  ];

  return (
    <>
      {isMobile ?
        <div className="main-stats">
          <div className="main-stats-blocks-wrapper">
            {statBlocks.map(el => (
              <div key={el.value} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span className="link skeleton" style={{ width: el.title, height: 20 }} />
                <span className="link skeleton" style={{ width: el.value, height: 24, marginTop: 4 }} />
              </div>
            ))}
          </div>
        </div>
        :
        <div className="main-stats">
          <div className="main-stats-blocks-wrapper">
            {statBlocks.map(el => (
              <div key={el.value} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <span className="link skeleton" style={{ width: el.title, height: 20 }} />
                <span className="link skeleton" style={{ width: el.value, height: 24, marginTop: 4 }} />
              </div>
            ))}
          </div>
        </div>
      }
    </>
  )
}

export default BlockStatisticsSkeleton;
