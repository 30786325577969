import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import { Row, Col, Divider, Checkbox, Switch, Spin, Modal, Collapse } from 'antd';

import ProductDetailsCard from '../../ProductDetailsPage/components/ProductDetailsCard';
import ButtonComponent from '../../../Components/Button';
import MinMaxInput from './MinMaxInput';
import Icon from '../../../Icon';

import LoadFilterPresetBlock from './LoadFilterPresetBlock';
import DeleteFilterPresetBlock from './DeleteFilterPresetBlock';
import SaveFilterPresetBlock from './SaveFilterPresetBlock';
import DropdownDateBlock from './DropdownDateBlock';
import { useSelector } from 'react-redux';
import { DefaultMsgSaga } from '../../../Components/Notification/notification-message';
import openNotification, { openNotificationWithIcon } from '../../../Components/Notification/index';
import InputProductFilter from './InputProductFilter';
import FiltersBlockSkeleton from './FiltersBlockSkeleton';
import DropdownBlockTest from "./DropdownBlockTest";
import dayjs from "dayjs";
import {ArrowSelectDown} from "../../../Icon/img";
import QuickSearch from '../../AdSpotPage/components/QuickSearch';
import './FiltersBlock.less';
import './DatabaseProducts.less';
const {Panel} = Collapse;

const minMaxInputsData = [
  {
    field: 'price',
    iconType: 'database_price',
    text: 'Price',
    tooltip: 'The price products are sold for in USD'
  }, {
    field: 'sales',
    iconType: 'database_sales',
    text: 'Monthly Sales',
    tooltip: 'Monthly sales amount per product'
  }, {
    field: 'revenue',
    iconType: 'database_revenue',
    text: 'Monthly Revenue',
    tooltip: 'Monthly revenue generated per product'
  }, {
    field: 'products',
    iconType: 'database_store_products',
    text: 'Store products',
    tooltip: 'Amount of product listings in the store'
  }, {
    field: 'images',
    iconType: 'database_product_images',
    text: 'Product images',
    tooltip: 'Amount of images the product listing has'
  }, {
    field: 'variants',
    iconType: 'database_variants',
    text: 'Variants',
    tooltip: 'Amount of variants the product listing has'
  }];

const switchFilterOptions = [
  {text: 'Exclude Unavailable Products', key: 'exclude_unavailable', disabled: false},
  {text: 'Only Dropshipping Products', key: 'only_dropshipping', disabled: false, tag: 'beta'},
  {text: 'Only POD Products', key: 'only_print', disabled: false, tag: 'beta'},
];

const keyWords = ['Title', 'Description', 'Domain'];

const dateOptions = [
  { id: 0, name: 'Last 7 days' },
  { id: 1, name: 'Last 30 days' },
  { id: 2, name: 'Last 90 days' },
  { id: 3, name: 'Last 6 months' },
  { id: 4, name: 'Last 12 months' },
];

export const FiltersBlock = (
  {
    fetching,
    skeleton,
    isMobile,
    theme,
    savePreset,
    deletePreset,
    updatePreset,
    getProducts,
    setShowTable,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    sortOrder,
    setSortOrder,
    isChanged,
    setIsChanged,
    setEnabled,
    disabledSearch,
    currentScroll,
    productsLoading
  }) => {

  const rerenderKey = useRef(new Date().getTime());

  const quickSearch = useSelector(store => store.productDatabase.presets.quick_search) || [];

  const currencyOptions = useSelector(store => store.productDatabase.filters.store_currency) || [];
  const domainOptions = useSelector(store => store.productDatabase.filters.domain_tld) || [];
  const languageOptions = useSelector(store => store.productDatabase.filters.store_language) || [];
  const plainOptions = useSelector(store => store.productDatabase.filters.categories) || [];

  const [showAdvanced, setShowAdvanced] = useState(false);

  //category checked checkboxes
  const [checkedList, setCheckedList] = useState([]);
  //category select all
  const [checkAll, setCheckAll] = useState(true);

  const [switchFilters, setSwitchFilters] = useState({
    exclude_top_brands: false,
    exclude_unavailable: true,
    only_dropshipping: false,
    only_print: false
  });
  const [inputFilters, setInputFilters] = useState({
    title: { include: null, exclude: null },
    description: { include: null, exclude: null },
    domain: { include: null, exclude: null },
  });
  const [minMaxFilters, setMinMaxFilters] = useState({
    price: { min: null, max: null },
    sales: { min: null, max: null },
    revenue: { min: null, max: null },
    products: { min: null, max: null },
    images: { min: null, max: null },
    variants: { min: null, max: null },
  });
  const [dropdownFilters, setDropdownFilters] = useState({
    product_created_at: { min: null, max: null, id: null },
    store_created_at: { min: null, max: null, id: null },
    language: 'All',
    currency: 'All',
    domain_tld: 'All',
  });

  //show/hide modal
  const [visible, setVisible] = useState(false);
  //initial search
  const [defaultSearch, setDefaultSearch] = useState(true);
  //set modal type
  const [modal, setModal] = useState('');
  //preset to delete from user presets
  const [recordToDelete, setRecordToDelete] = useState(null);
  //applied preset
  const [loadedPreset, setLoadedPreset] = useState(null);

  //first render for skeleton
  const firstLoading = useRef(true);
  const { t } = useTranslation();

  //if filters were changed from default
  const checkIfChanged = () => {
    if (
      Object.keys(switchFilters).some((key) => {
        if (key === 'exclude_unavailable') return switchFilters[key] !== true;
        return switchFilters[key] !== false;
      }) ||
      Object.keys(inputFilters).some((key) => inputFilters[key].include !== null || inputFilters[key].exclude !== null) ||
      Object.keys(minMaxFilters).some((key) => minMaxFilters[key].min !== null || minMaxFilters[key].max !== null) ||
      checkedList.length ||
      Object.keys(dropdownFilters).some((key) => {
        if (key === 'store_created_at' || key === 'product_created_at') {
          return dropdownFilters[key].min !== null || dropdownFilters[key].max !== null || dropdownFilters[key].id !== null;
        } else return dropdownFilters[key] !== 'All';
      })
    ) {
      if (!isChanged) setIsChanged(true);
    } else if (isChanged) setIsChanged(firstLoading);
  };

  //function to parse preset period dynamically
  const parseDates = key => {
    let quantity = dropdownFilters?.[key]?.id?.name?.split(' ')[1];
    let value = dropdownFilters?.[key]?.id?.name?.split(' ')[2];
    let max = new Date(dayjs().endOf('day')).toISOString().split('T')[0];
    let min = new Date(dayjs().endOf('day').subtract(quantity, value)).toISOString().split('T')[0];
    return {min: min, max: max}
  }

  //search api call
  const applyFilters = (new_search=false, data=null) => {
    //do not search without categories
    if ((!checkedList.length || disabledSearch) && !Boolean(data)) return;
    if (new_search) setDefaultSearch(false);

    getProducts(data ?
      data
      :
      {
        page: pageNumber,
        page_size: pageSize,
        ordering: [sortOrder],
        new_search: new_search,
        filters: {
          categories: checkedList,
          ...switchFilters,
          ...inputFilters,
          ...minMaxFilters,
          product_created_at: dropdownFilters?.product_created_at?.id ? parseDates('product_created_at') : {
            min: dropdownFilters?.product_created_at?.min,
            max: dropdownFilters?.product_created_at?.max
          },
          store_created_at: dropdownFilters?.store_created_at?.id ? parseDates('store_created_at') : {
            min: dropdownFilters?.store_created_at?.min,
            max: dropdownFilters?.store_created_at?.max
          },
          language: dropdownFilters.language === 'All' ? null : dropdownFilters.language,
          currency: dropdownFilters.currency === 'All' ? null : dropdownFilters.currency,
          domain_tld: dropdownFilters.domain_tld === 'All' ? null : dropdownFilters.domain_tld,
        },
      }
    );
    //hide banner, show table
    setShowTable(true);
    setEnabled(true);
    currentScroll.current = null;
  };

  //search when pagination/loaded preset changed except 1st render
  useEffect(() => {
    if (defaultSearch) {
      applyFilters(false, {
        "page": pageNumber,
        "page_size": pageSize,
        "ordering": [sortOrder],
        "timestamp": Math.floor((new Date().getTime())/3600000)
      })
    }
    else {
      applyFilters();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pageNumber, pageSize, sortOrder]);

  useEffect(() => {
    if (firstLoading.current) firstLoading.current = false;
    else {
      if (loadedPreset !== null) {
        applyFilters(true);
        if (!loadedPreset?.key?.includes('defined')) setLoadedPreset(null);
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [loadedPreset]);

  //check filters changes, disable/enable search btn
  useEffect(() => {
    checkIfChanged();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [switchFilters, minMaxFilters, inputFilters, checkedList, dropdownFilters]);

  useEffect(() => {
    setCheckedList(plainOptions.map(el => el.id));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [plainOptions])

  //toggle select/deselect all on preset load
  useEffect(() => {
    setCheckAll(checkedList.length === plainOptions.length);
  }, [checkedList, plainOptions]);

  const renderHeader = (
    <>
      <span className="title">
        {t('Search For Products')}
      </span>

      <div className="link-block">
        <span className={cls('link', { disabled: false })}
              onClick={() => {
                setModal('loadFilterPreset');
                setVisible(true);
              }}
        >
        {t('Load Filter Preset')}
        </span>

        <span className={cls('link', {
          disabled: checkedList.length === plainOptions.length ? false : !isChanged,
        })}
              onClick={() => {
                setVisible(true);
                setModal('saveFilterPreset');
              }}
        >
        {t('Save Filter Preset')}
      </span>
      </div>
    </>);

  //set filters values on preset load
  const loadPreset = (data) => {
    if (disabledSearch) {
      openNotification({
        message: t('You have reached your daily search limit, upgrade to unlock unlimited searches'),
        style: { minWidth: '716px' },
        type: 'error',
      });
    } else {
      let name = data.name;
      Object.keys(data.value).forEach(val => {
        if (val === 'categories') setCheckedList(() => ([...data.value[val]]));
        if (val === 'switchFilters') setSwitchFilters(state => ({...state, ...data.value[val]}));
        if (val === 'inputFilters') setInputFilters(state => ({...state, ...data.value[val]}));
        if (val === 'minMaxFilters') setMinMaxFilters(state => ({...state, ...data.value[val]}));
        if (val === 'dropdownFilters') setDropdownFilters(state => ({...state, ...data.value[val]}));
      });
      openNotificationWithIcon({
        style: {minWidth: '716px'},
        className: 'notification notification--save',
        message: (
          <DefaultMsgSaga
            text={
              <span className="preset-notification-block">
                preset_load_name_
              </span>
            }
            icon="notification_success"
            iconOutline={true}
            withTranslate={true}
            preset={name}
          />
        ),
      });
    }
  };

  //modal types
  const modalBlocks = {
    loadFilterPreset: <LoadFilterPresetBlock fetching={fetching}
                                             theme={theme}
                                             setVisible={setVisible}
                                             setRecordToDelete={setRecordToDelete}
                                             setModal={setModal}
                                             setPageSize={setPageSize}
                                             setPageNumber={setPageNumber}
                                             updatePreset={updatePreset}
                                             loadPreset={loadPreset}
                                             setLoadedPreset={setLoadedPreset}
                                             setSortOrder={setSortOrder}
                                             category={'productDatabase'}
    />,
    deleteFilterPreset: <DeleteFilterPresetBlock setModal={setModal}
                                                 deleteFilter={deletePreset}
                                                 record={recordToDelete}
    />,
    saveFilterPreset: <SaveFilterPresetBlock setVisible={setVisible}
                                             saveFilterPreset={savePreset}
                                             filters={{
                                               categories: [...checkedList],
                                               switchFilters: { ...switchFilters },
                                               inputFilters: { ...inputFilters },
                                               minMaxFilters: { ...minMaxFilters },
                                               dropdownFilters: { ...dropdownFilters },
                                             }}
                                             category={'productDatabase'}
    />,
  };

  const modalBlocksWidth = {
    loadFilterPreset: 800,
    deleteFilterPreset: 512,
    saveFilterPreset: 566,
  };

  //category checkbox click handler
  const onChange = list => {
    setCheckedList(list);
    setCheckAll(list.length === plainOptions.length);
  };

  //select/deselect all click handler
  const onCheckAllChange = checked => {
    setCheckedList(checked ? plainOptions.map(el => el.id) : []);
    setCheckAll(checked);
  };

  //reset filters and pagination to avoid new search discrepancies
  const resetFilters = () => {
    rerenderKey.current = new Date().getTime();
    Object.keys(switchFilters).forEach((key) => {
      if (key === 'exclude_unavailable') setSwitchFilters(state => ({...state, [key]: true}));
      else setSwitchFilters(state => ({...state, [key]: false}));
    });
    Object.keys(inputFilters).forEach((key) => setInputFilters(state => ({ ...state, [key]: { include: null, exclude: null } })));
    Object.keys(minMaxFilters).forEach((key) => setMinMaxFilters(state => ({ ...state, [key]: { min: null, max: null } })));
    setCheckedList(plainOptions.map(el => el.id));
    setCheckAll(false);
    Object.keys(dropdownFilters).forEach((key) => {
      if (key === 'product_created_at' || key === 'store_created_at') {
        setDropdownFilters(state => ({ ...state, [key]: { min: null, max: null, id: null } }));
      } else setDropdownFilters(state => ({ ...state, [key]: 'All' }));
    });
    setPageSize(50);
    setPageNumber(1);
    setSortOrder(null);
    setLoadedPreset(null);
  };

  //switch filters handler
  const onChangeSwitch = (value, name) => {
    if (value) {
      if (name === 'only_dropshipping') {
        setSwitchFilters(state => ({ ...state, [name]: value, 'only_print': false }))
      } else if (name === 'only_print') {
        setSwitchFilters(state => ({ ...state, [name]: value, 'only_dropshipping': false }))
      } else setSwitchFilters(state => ({ ...state, [name]: value }))
    }
    else setSwitchFilters(state => ({ ...state, [name]: value }));
  };

  //tag inputs include/exclude handler
  const onChangeInputFilter = (e, field, type) => {
    e.persist();
    setInputFilters(state => ({ ...state, [field]: { ...state[field], [type]: state[field][type] === null ? e.target.value.split(',')[0].trim() + ',' : state[field][type] + ',' + e.target.value.split(',')[0].trim() } }));
  };

  const onPasteInputFilter = (e, field, type) => {
    e.persist();
    let val = e.clipboardData.getData('text').split(',');
    for (let i = 0; i < val.length; i++) {
      setInputFilters(state => ({ ...state, [field]: { ...state[field], [type]: state[field][type] === null ? val?.[i].trim() + ',' : [...new Set([...state[field][type].split(','), val?.[i].trim()])].join(',') } }));
    }
  };

  const onChangeMinMaxFilter = useCallback((e, field, type) => {
    e.persist();
    const parsedValue = e.target.value.replace(/\$\s?|(,*)/g, '');
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(parsedValue) && reg.test(parsedValue)) || parsedValue === '') {
      setMinMaxFilters(state => ({ ...state, [field]: { ...state[field], [type]: e.target.value.trim().length ? +parsedValue : null } }));
    }
  }, []);

  const handleSearch =(new_search=false) => {
    if (pageSize === 50 && pageNumber === 1) applyFilters(new_search);
    else {
      setPageSize(50);
      setPageNumber(1);
      setDefaultSearch(false);
    }
  }

  if (skeleton) return <FiltersBlockSkeleton isMobile={isMobile}/>;
  return (
    <>
      <ProductDetailsCard header={renderHeader}
                          skeleton={skeleton}
                          withLine={!isMobile}
                          headerClassName={'filters-header'}
                          bodyClassName={'filters-body pd-body'}
      >
        <Row className="product-main-block_wrapper" gutter={[48, 0]}>
          <Col xs={24} md={12} className="block-with-label block-with-label-custom">
            <div className="block-with-label_label">
              {t('Filters')}
              <Icon
                type="attention_outline"
                role="button"
                titleText={'Min/Max ' + t('filter options')}
                tooltipProps={{
                  placement: 'right',
                  destroyTooltipOnHide: true,
                  trigger: isMobile ? 'click' : 'hover',
                }}
              />
            </div>
            {
              minMaxInputsData
                .map(el => (
                  <MinMaxInput data={minMaxFilters}
                               field={el.field}
                               iconType={el.iconType}
                               text={el.text}
                               tooltip={el.tooltip}
                               key={el.iconType}
                               onChange={onChangeMinMaxFilter}
                               theme={theme}
                  />)
                )
            }
          </Col>

          {
            isMobile ?
              null
              :
              <Col xs={24} md={12} className="block-with-label">

                <div className="block-with-label_label space_between">
                  <div>
                    {t('Select one or multiple categories')}
                    <span className="important">*</span>
                  </div>
                  <span
                    className={cls('link', { red: checkAll })}
                    onClick={() => onCheckAllChange(!checkAll)}
                  >
                {t(checkAll ? 'Deselect All' : 'Select All')}
              </span>
                </div>

                <Checkbox.Group
                  value={checkedList}
                  onChange={onChange}
                  style={{ width: '100%' }}
                >
                  <Row gutter={[0, 16]} style={{ maxWidth: 600, display: 'flex', justifyContent: 'space-between', rowGap: 8 }}>
                    {
                      plainOptions.map((el, index) => (
                        <div style={{width: index % 2 !== 0 ? '42%' : '48%'}} key={el.id}>
                          <Checkbox value={el.id}>{el.value}</Checkbox>
                        </div>
                      ))
                    }
                  </Row>
                </Checkbox.Group>

              </Col>
          }

          <Collapse collapsible={'header'} ghost={true}>
            <Panel key={1} showArrow={false} header={
              <div className="advanced-filters-toggle-wrapper" onClick={() => setShowAdvanced(!showAdvanced)}>
                <div className="advanced-filters-toggle-divider"/>
                <span className={cls('advanced-filters-toggle')}
                >{t('Advanced Filters')} <ArrowSelectDown/> </span>
                <div className="advanced-filters-toggle-divider"/>
              </div>
            }>
            <div className="dropdowns-wrapper">
                <DropdownDateBlock
                  label={'Product creation date'}
                  id={'product_created_at'}
                  iconType={'database_calendar'}
                  data={dateOptions}
                  dropdownFilters={dropdownFilters}
                  setDropdownFilters={setDropdownFilters}
                  isMobile={isMobile}
                  hideTimeZone={true}
                />

                <DropdownDateBlock
                  label={'Store creation date'}
                  id={'store_created_at'}
                  iconType={'database_calendar'}
                  data={dateOptions}
                  dropdownFilters={dropdownFilters}
                  setDropdownFilters={setDropdownFilters}
                  isMobile={isMobile}
                  hideTimeZone={true}
                />

                <DropdownBlockTest
                  label={'Store language'}
                  id={'language'}
                  dropdownFilters={dropdownFilters}
                  setDropdownFilters={setDropdownFilters}
                  iconType={'database_globe'}
                  searchPlaceholder={'Search for language...'}
                  data={languageOptions}
                />

                <DropdownBlockTest
                  label={'Store currency'}
                  id={'currency'}
                  dropdownFilters={dropdownFilters}
                  setDropdownFilters={setDropdownFilters}
                  iconType={'database_currency'}
                  searchPlaceholder={'Search for currency...'}
                  data={currencyOptions}
                />

                <DropdownBlockTest
                  label={'Domain TLD'}
                  id={'domain_tld'}
                  dropdownFilters={dropdownFilters}
                  setDropdownFilters={setDropdownFilters}
                  iconType={'database_domain'}
                  searchPlaceholder={'Search for TLD...'}
                  data={domainOptions}
                />
             </div>

              <div gutter={[24, 0]} style={{display: 'flex', marginBottom: isMobile ? 12 : 24, flexDirection: isMobile ? 'column' : 'row', padding: isMobile ? '0 24px' : 0}}>
                <Col xs={24} md={12} style={{maxWidth: isMobile ? '100%' : '50%', paddingRight: isMobile ? 0 : 12, paddingLeft: isMobile ? 0 : 24}}>
                  <div className="input-filter-wrapper">
                    {keyWords.map(el =>
                      <InputProductFilter key={rerenderKey.current + 'include_' + el.toLowerCase()}
                                          el={el}
                                          onChangeInputFilter={onChangeInputFilter}
                                          onPasteInputFilter={onPasteInputFilter}
                                          inputFilters={inputFilters}
                                          setInputFilters={setInputFilters}
                                          isPd={true}
                                          type={'include'}/>,
                    )}
                  </div>
                </Col>

                <Col xs={24} md={12} style={{maxWidth: isMobile ? '100%' : '50%', paddingLeft: isMobile ? 0 : 12, paddingRight: isMobile ? 0 : 24, marginTop: isMobile ? 12 : 0}}>
                  <div className="input-filter-wrapper">
                    {keyWords.map(el =>
                      <InputProductFilter key={'exclude_' + el.toLowerCase()}
                                          el={el}
                                          onChangeInputFilter={onChangeInputFilter}
                                          onPasteInputFilter={onPasteInputFilter}
                                          inputFilters={inputFilters}
                                          setInputFilters={setInputFilters}
                                          type={'exclude'}/>,
                    )}
                  </div>
                </Col>
              </div>
            </Panel>
          </Collapse>

          <Divider style={{ margin: isMobile ? '0 23px' : '0 23px 24px', minWidth: 'auto' }}/>

          <Col xs={24} md={24}>
            <div className="switch-wrapper" style={{ marginBottom: isMobile && 16 }}>
              <div className="switch-options-wrapper">
                {switchFilterOptions.map(el =>
                  <div key={el.key + 1} className="switch-option">
                    <Switch checked={switchFilters[el.key]}
                            name={el.key}
                            key={el.key}
                            onChange={(state) => onChangeSwitch(state, el.key)}
                            disabled={el.disabled}
                    />
                    <p onClick={() => onChangeSwitch(!switchFilters[el.key], el.key)}
                          style={{cursor: 'pointer', pointerEvents: el.disabled ? 'none': 'unset'}}
                    >
                      {t(el.text)}
                      {
                        el?.tag ?
                          <span className={'switch-option-tag'}>{el?.tag}</span>
                          :
                          null
                      }
                    </p>
                  </div>)}
              </div>
              <div className="switch-button-wrapper">
                <ButtonComponent className={'switch-button-reset'}
                                 text={t('Reset Filter')}
                                 onClick={resetFilters}
                />
                <ButtonComponent className={'switch-button-apply switch-button-reset'}
                                 text={t('Search')}
                                 onClick={() => handleSearch(true)}
                                 disabled={disabledSearch || !checkedList.length || productsLoading}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Modal
          className="change-modal custom-modal"
          getContainer={() => document.getElementById('global-wrap')}
          {...(isMobile ? { transitionName: '' } : null)}
          open={visible}
          centered={!isMobile}
          closeIcon={
            <Icon role="icon" type="close_modal" color="#707BA0" opacity={1}/>
          }
          width={modalBlocksWidth[modal]}
          footer={null}
          closable="true"
          onCancel={() => setVisible(false)}
          destroyOnClose
        >
          <Spin size="large" spinning={fetching}>
            {modalBlocks[modal]}
          </Spin>
        </Modal>
      </ProductDetailsCard>
      {
        quickSearch.length ?
          <QuickSearch loadPreset={loadPreset}
                       setLoadedPreset={setLoadedPreset}
                       loadedPreset={loadedPreset}
                       skeleton={false}
                       isMobile={isMobile}
                       data={[...quickSearch]}
          />
          :
          null
      }
    </>
  );
};

export default FiltersBlock;
