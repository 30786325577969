import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../Components/Modal';
import { Divider, Spin } from 'antd';
import Icon from '../../../Icon';
import utils from '../../../Utils/utils';
import acc from 'accounting';

const AdLibraryItemAdSpendModal = (
  {
    visible,
    data,
    setModal,
    isMobile
  }
) => {

  const { t } = useTranslation();

  return (
    <Modal handleClose={() => setModal(null)}
           title={`${t('Ad spend and Engagement')} for ${data}`}
           isMobile={isMobile}
           width={828}
           className="collection-options-modal collection-modal product-actions-modal aditem-info-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={false}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              t(`Text placeholder, could explain here what is active ads and why it’s important to use it in analysis.`)
            }
          </p>
          <Divider style={{ marginBottom: '24px 0' }} />
          <div className='aditem-info-modal-info-wrapper'>
            <span className="aditem-info-modal-info-title">
              {t('Ad spend')}
            </span>
            <span className="aditem-info-modal-info-text">
              <span className="value">
                ${
                  acc.formatNumber(234233.23, 2, ',', '.')
                }
              </span>
              <Divider style={{ marginBottom: '0 12px' }} type="vertical" />
              <span className="status">
                <Icon type={'refresh'} role={'icon'} />
                {
                  `${t('Updated')} ${utils.durationAsString(new Date().toISOString(), new Date().toISOString(), t)}`
                }
              </span>
            </span>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default AdLibraryItemAdSpendModal;
