import React from 'react';
import PropTypes from 'prop-types';
import './TopPageLimits.less'

const TopPageLimits = ({ view, limits, t, firstOnClick, secondOnClick }) => (
  <div className="top-page-limits-wrapper">
    <div className="top-page-limits">
      <div className="top-page-limits-title">
        {t('Tracking limit')}
      </div>
      {view === 'stores' && (
        <>
          <div className='limits-vertical-divider' />
          <div className="limits-values">
            <span className='green'>{limits?.stores?.used}</span>/
            <span className='second-color'>{limits?.stores?.allowed}</span>
            <span className='limits-values-title'>{t('Stores')}</span>
          </div>
        </>
      )}
      {view === 'products' && (
        <>
          <div className='limits-vertical-divider' />
          <div className="limits-values">
            <span className='green'>{limits?.products?.used}</span>/
            <span className='second-color'>{limits?.products?.allowed}</span>
            <span className='limits-values-title'>{t('Products')}</span>
          </div>
        </>
      )}
      {view === 'both' && (
        <div className='both-limits-wrapper'>
          <div className='limits-vertical-divider' />
          <div className="limits-values" onClick={firstOnClick}>
            <span className='orange'>{limits?.stores?.used}</span>/
            <span className='second-color'>{limits?.stores?.allowed}</span>
            <span className='limits-values-title'>{t('Stores')}</span>
          </div>
          <div className='limits-vertical-divider-mobile' />
          <div className="limits-values" onClick={secondOnClick}>
            <span className='orange'>{limits?.products?.used}</span>/
            <span className='second-color'>{limits?.products?.allowed}</span>
            <span className='limits-values-title'>{t('Products')}</span>
          </div>
        </div>
      )}
    </div>
  </div>

);

TopPageLimits.propTypes = {
  view: PropTypes.string.isRequired,
  limits: PropTypes.shape({
    stores: PropTypes.shape({
      used: PropTypes.number.isRequired,
      allowed: PropTypes.number.isRequired,
    }),
    products: PropTypes.shape({
      used: PropTypes.number.isRequired,
      allowed: PropTypes.number.isRequired,
    }),
  }).isRequired,
  t: PropTypes.func.isRequired,
  firstOnClick: PropTypes.func,
  secondOnClick: PropTypes.func,
};

export default TopPageLimits;
