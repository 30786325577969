import React from 'react';
import {Divider} from "antd";

const ChartInfoSkeleton = ({ isMobile }) => {
  return (
    <div className={'chart-block'}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 8,
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span
                className="link skeleton"
                style={{ width: 122, height: 24 }}
              />
            </div>
            {isMobile ? null : (
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <span
                  className="link skeleton"
                  style={{ width: 145, height: 40 }}
                />
                <span
                  className="link skeleton"
                  style={{ width: 80, height: 40 }}
                />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', marginBottom: 8 }}>
            <span
              className="link skeleton"
              style={{ width: 280, height: 40 }}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <span
              className="link skeleton"
              style={{ width: '100%', height: 350 }}
            />
          </div>
          <Divider type={'horizontal'} style={{ margin: '16px auto' }} />
          <div className="fadspot-page-overview-chart-stats">
            {[152, 159, 133].map((el) => (
              <div key={el} className="fadspot-page-overview-chart-stat">
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span
                    className="link skeleton"
                    style={{ width: el, height: 24 }}
                  />
                  {isMobile ? (
                    <span
                      className="link skeleton"
                      style={{ width: 60, height: 24 }}
                    />
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
  );
};

export default ChartInfoSkeleton;
