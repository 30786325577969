import React from 'react';

const AdLibraryDashboard = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <g filter="url(#filter0_dd_5430_10289)">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M10 3C9.17157 3 8.5 3.67157 8.5 4.5V19.5C8.5 20.3284 9.17157 21 10 21H14C14.8284 21 15.5 20.3284 15.5 19.5V4.5C15.5 3.67157 14.8284 3 14 3H10ZM10 7.5C10 7.22386 10.2239 7 10.5 7H13.5C13.7761 7 14 7.22386 14 7.5V8.5C14 8.77614 13.7761 9 13.5 9H10.5C10.2239 9 10 8.77614 10 8.5V7.5ZM10.5 15C10.2239 15 10 15.2239 10 15.5V16.5C10 16.7761 10.2239 17 10.5 17H13.5C13.7761 17 14 16.7761 14 16.5V15.5C14 15.2239 13.7761 15 13.5 15H10.5Z"
              fill="#F9F9FC" />
        <path
          d="M5.5 5C4.67157 5 4 5.67157 4 6.5V19.5C4 20.3284 4.67157 21 5.5 21H6C6.82843 21 7.5 20.3284 7.5 19.5V6.5C7.5 5.67157 6.82843 5 6 5H5.5Z"
          fill="#F9F9FC" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15.6635 8.44189C15.4351 7.64556 15.8956 6.81489 16.6919 6.58654L18.6144 6.03527C19.4108 5.80692 20.2414 6.26737 20.4698 7.06371L23.7774 18.5988C24.0058 19.3952 23.5453 20.2258 22.749 20.4542L20.8265 21.0055C20.0301 21.2338 19.1995 20.7734 18.9711 19.977L15.6635 8.44189Z"
              fill="#F9F9FC" />
      </g>
      <defs>
        <filter id="filter0_dd_5430_10289" x="-2" y="-3" width="34" height="35" filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5430_10289" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dx="1" dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_5430_10289" result="effect2_dropShadow_5430_10289" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_5430_10289" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default AdLibraryDashboard;
