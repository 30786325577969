import React from 'react';
const AdSpotDashboard = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.785 0.291818C14.3648 -0.5292 16.998 1.39613 16.998 4.10345V4.12648C18.7233 4.57052 19.998 6.13662 19.998 8.00046C19.998 9.8643 18.7233 11.4304 16.998 11.8744V11.8975C16.998 14.6048 14.3648 16.5301 11.785 15.7091L11.6374 15.6621C11.0069 17.0407 9.61565 18.0005 7.99805 18.0005C5.78891 18.0005 3.99805 16.2096 3.99805 14.0005V13.2215L2.78224 12.8336C1.12384 12.3045 -0.00195312 10.7637 -0.00195312 9.0229V6.97802C-0.00195312 5.23727 1.12385 3.69638 2.78224 3.16727L4.57386 2.59566C4.62029 2.57374 4.66869 2.55529 4.7187 2.54064L11.785 0.291818ZM5.99805 13.8674V14.0005C5.99805 15.105 6.89348 16.0005 7.99805 16.0005C8.71806 16.0005 9.35089 15.6196 9.70326 15.0466L5.99805 13.8674ZM17.998 8.00046C17.998 8.74074 17.5958 9.38708 16.998 9.73289V6.26802C17.5958 6.61383 17.998 7.26018 17.998 8.00046ZM4.00024 4.87799V11.1229L3.39014 10.9283C2.56095 10.6637 1.99805 9.89328 1.99805 9.0229V6.97802C1.99805 6.10764 2.56095 5.3372 3.39014 5.07264L4.00024 4.87799Z"
        fill="#F9F9FC"
      />
    </svg>
  );
};
export default AdSpotDashboard;
