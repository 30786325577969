import React, { useState } from 'react';
import './MainBlockStat.less';
import { Divider } from 'antd';

const MainBlockStat = ({ t, title, value, onClick, isMobile }) => {

  const [visible, setVisible] = useState(false);

  const mouseOver = e => {
    let target = e.target;
    const { scrollWidth, clientWidth } = target;
    setVisible(true);
    if (scrollWidth > clientWidth) target.classList.add('overflowing')
  }

  const mouseLeave = e => {
    let target = e.target;
    setVisible(false);
    target.classList.remove('overflowing')
  }

  if(!title) {
    return null;
  }

  return (
    <div className={'main-block-stat-wrapper'}>
      <h4 className="main-block-stat-title">
        {t(title)}
      </h4>
      {Boolean(onClick) ?
        <div className={"main-block-stat-value main-block-stat-value--clickable"}
          data-text={value}
          onClick={onClick}
          onMouseOver={mouseOver}
          onMouseLeave={mouseLeave}
        >
          {isMobile ? <>
            <span className="main-block-view-all mobile">View</span>
              <Divider type={'vertical'} className="main-block-view-divider" />
            <span className="main-block-view-value">
              {title?.includes('Tracked By') ? `${value} Others` : value}
            </span>
          </> : <>
            <span className="main-block-view-value">
              {title?.includes('Tracked By') ? `${value} Others` : value}
            </span>
              <Divider type={'vertical'} className="main-block-view-divider" />
            <span className="main-block-view-all">View</span>
          </>}
        </div>
        :
        <p className={"main-block-stat-value"}
          data-text={value}
          onMouseOver={mouseOver}
          onMouseLeave={mouseLeave}
        >
          {title?.includes('Tracked By') ? `${value} Others` : value}
        </p>
      }
    </div>
  );
};

export default MainBlockStat;
