import React from 'react';
import ButtonComponent from "../../../Components/Button";
import {Divider} from "antd";
import {useTranslation} from "react-i18next";
import './DeleteFilterPresetBlock.less'

//delete modal called from load preset table
const DeleteFilterPresetBlock = (props) => {
  const {setModal, deleteFilter, record} = props;
  const { t } = useTranslation();

  return (
    <div className={'load-filter-preset-wrapper delete-filter-preset-wrapper'}>
      <div className="load-filter-preset-title">
        {t('Remove "_name_" preset', {name: record.name})}?
      </div>
      <p className="load-filter-preset-subtitle">
        {t('Are you sure you want to remove this preset? This action is irreversible and can’t be undone.')}
      </p>

      <Divider className={'delete-preset-divider'} />

      <div className="delete-filter-button-wrapper">
        <ButtonComponent className={'delete-preset-button-cancel'}
                         text={t('Cancel')}
                         onClick={() => setModal('loadFilterPreset')}
        />
        <ButtonComponent className={'delete-preset-button-apply delete-preset-button-cancel'}
                         text={t('Remove')}
                         onClick={() => {
                           //api call to delete
                           deleteFilter(record)
                           setModal('loadFilterPreset')
                         }}
        />
      </div>
    </div>
  );
};

export default DeleteFilterPresetBlock;
