import React from 'react';
import { useSelector } from 'react-redux';

const DropshippingAds = () => {
  const theme = useSelector((state) => state?.nav?.theme);

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={theme === 'light' ? '#707BA0' : '#ffffff42'}
        d="M12.709 5.62533V3.95866C12.709 3.03818 11.9628 2.29199 11.0423 2.29199H5.62565C4.70518 2.29199 3.95898 3.03818 3.95898 3.95866V12.7087C3.95898 13.6291 4.70518 14.3753 5.62565 14.3753H7.29232M11.6673 9.79199V11.667M11.6673 11.667V13.542M11.6673 11.667H9.79232M11.6673 11.667H13.5423M14.3757 17.7087H8.95898C8.03851 17.7087 7.29232 16.9625 7.29232 16.042V7.29199C7.29232 6.37152 8.03851 5.62533 8.95898 5.62533H14.3757C15.2961 5.62533 16.0423 6.37152 16.0423 7.29199V16.042C16.0423 16.9625 15.2961 17.7087 14.3757 17.7087Z"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DropshippingAds;
