import React, {useState} from 'react';
import utils from "../../../Utils/utils";
import Image from "../../../Components/Image";
import Icon from '../../../Icon';
import Images from "../../../Images";
import TooltipColumnIcon from "../../ProductDatabasePage/components/TooltipColumnIcon";
import ButtonComponent from "../../../Components/Button";
import {useTranslation} from "react-i18next";
import {Divider, Dropdown} from "antd";
import dayjs from "dayjs";
import MainBlockStat from "./MainBlockStat";
import IconWithText from "../../../Components/Text";
import './MainBlockNew.less';

const MainBlockNew = ({data, titleLink, autoDS=false, productPage=false, handleOpenModalShopifyConnect=null, handleOpenModalAutoDSConnect=null, setVisible, setModal, isMobile=false}) => {

  const {t} = useTranslation();

  const statBlocks = [
    {title: productPage ? 'Product Tracked By' : 'Store Tracked By', value: data?.totalInfo?.[productPage ? 'product_tracked_by' : 'store_tracked_by']},
    {title: productPage ? 'Product creation date' : 'Store creation date', value: dayjs(data?.record?.created_at).format('MMM DD, YYYY')},
    {title: 'Store Theme', value: data?.totalInfo?.theme?.name},
    {title: 'Store language', value: productPage ? data?.record?.store?.language : data?.record?.language},
    {title: 'Store products', value: productPage ? data?.record?.store?.products_count : data?.record?.products_count, onClick: () => {
        setModal('storeProducts');
        setVisible('true');
      }},
    {title: 'Store Apps', value: 'View List', onClick: () => {
        setModal('storeApps');
        setVisible('true');
      }},
  ];

  const mouseOver = e => {
    let target = e.target;
    const {scrollWidth, clientWidth} = target;
    if (scrollWidth > clientWidth) target.classList.add('overflowing')
  }

  const mouseLeave = e => {
    let target = e.target;
    target.classList.remove('overflowing')
  }

  return (
    <div className="report-main-block report-main-block-new">
      <div className="main-info-wrapper">
        <div className="main-info">
          <div className={'report-main-block-logo'}>
            {
              data?.logo
                ? <img src={data?.logo}
                       alt=""
                       onError={utils.addDefaultSrc}
                       width="62" height="62"
                />
                : <Image src={Images.defaultImg} small={true}/>
            }
          </div>

          <div className="main-title">
            {titleLink ?
              <a href={titleLink}
                 target={'_blank'}
                 rel="noopener noreferrer"
              >
                <span className="report-main-block_title"
                      onMouseOver={mouseOver}
                      onMouseLeave={mouseLeave}
                      data-text={data?.title}
                >
                  {data?.title}
                </span>
              </a>
              :
              <span className="report-main-block_title">
                <span className={'ellipsis'}
                      onMouseOver={mouseOver}
                      onMouseLeave={mouseLeave}
                      data-text={data?.title}
                >
                  {data?.title}
                </span>
                {
                  data?.record?.scraping_status === 'untrackable' ?
                    <span className={'report-main-block_title-tag'}
                          onClick={() => {
                            setModal('untrackableDelete');
                            setVisible(true);
                          }}
                    >
                        {
                          t('untrackable')
                        }
                      </span>
                    :
                    null
                }
              </span>
            }

            <div className="main-link">
              <a className={'report-main-block_link chart-block_link'}
                 target={'_blank'}
                 href={data?.link ? `https://${data?.link}` : '#'}
                 rel="noopener noreferrer" >
                <span>{data?.link}</span>
              </a>
              <TooltipColumnIcon record={data?.record} isST={!productPage}/>
            </div>
          </div>

        </div>
        {
          isMobile ?
            null
            :
            <div className={'report-main-block-new-button-wrapper'}>

              {
                autoDS ?
                  <DropdownRender data={data?.record}
                                  t={t}
                                  handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                                  handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                  />
                  :
                  null
              }
              <ButtonComponent className={'report-main-block-new-button'}
                               text={''}
                               onClick={() => window.open(data?.totalInfo?.facebook_add_library, '_blank')}
              >
                <Icon role={'icon'} type={'search_facebook'} isDark={true}/>
                {t('View Ad Library')}
              </ButtonComponent>
            </div>
        }
      </div>
      <Divider style={{margin: '16px auto'}} type={'horizontal'} />
      <div className="main-stats">
        <div className="stat-blocks-wrapper">
          {statBlocks.map(el => (
            <MainBlockStat key={el?.title} {...el} t={t}/>
          ))}
        </div>
        {data?.totalInfo?.social_channels?.length > 0 &&
          <div className="channels-wrapper">
            <h4 className="main-block-stat-title">
              {t('Social Channels')}
            </h4>
            <div className="main-block-stat-value">
              {data?.totalInfo?.social_channels?.length > 0 && data?.totalInfo?.social_channels?.map(el => (
                <a
                  key={el?.platform}
                  href={el?.link ? el?.link : '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link with-img"
                >
                  <Icon role={'button'} type={`search_${el?.platform}`} />
                </a>
              ))}
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default MainBlockNew;

const DropdownRender = ({data, handleOpenModalShopifyConnect, handleOpenModalAutoDSConnect, t}) => {

  const [visibleDropdown, setVisibleDropdown] = useState(false);

  const handleInnerVisibleChange = (value) => {
    setVisibleDropdown(value);
  };

  return (
    <Dropdown
      open={visibleDropdown}
      onOpenChange={handleInnerVisibleChange}
      dropdownRender={() => (
        <ul className={'filter-preset-action-dropdown database-action-dropdown'} style={{width: 'unset'}}>
          <li className="action-dropdown_item no-hover"
              onClick={() => {
                setVisibleDropdown(false);
                handleOpenModalShopifyConnect(`${data?.store?.id}_${data?.id}`)
              }}>
            <IconWithText
              size={24}
              icon={() => <Icon type={'shopify_btn_icon'} role={'icon'} isLogo={true} /> }
              text={t('Import Product to Shopify')}
            />
          </li>
          <li className="action-dropdown_item"
              onClick={() => {
                setVisibleDropdown(false);
                handleOpenModalAutoDSConnect({
                  'source': 'shopify',
                  'product_id': `${data?.store?.id}_${data?.id}`
                })
              }}>
            <IconWithText
              icon={() => <Icon type={'auto_ds'} role={'icon'} /> }
              text={t('Import Product to AutoDS')}
            />
          </li>
        </ul>
      )}
      destroyPopupOnHide={true}
      trigger={['click']}
      placement="bottomRight"
      getPopupContainer={() => document.getElementById('product-database-table-body')}
      overlayClassName={'filter-preset-cell-menu-wrapper'}
    >
      <span className={'product-actions-options'}>
        <Icon type="three_dots" role="button" width={24} height={24}/>
      </span>
    </Dropdown>
  )
}
