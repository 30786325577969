import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import SalesTrackerCreators from './reducer';
import TopPageHeader from './pages/components/TopPageHeader';
import PageOptions from './components/PageOptions';
import PopularToday from './components/PopularToday';
import UserTrackings from './components/UserTrackings';
import VideoTutorialModal from '../ShopifySearch/components/VideoTutorialModal';
import LimitChangeModal from '../../Components/BillingSubPage/LimitChangeModal';
import DeleteTrackingModal from './components/DeleteTrackingModal';
import CanNotRemoveTracking from '../ProductDatabasePage/components/CanNotRemoveTracking';
import GatheringDataModal from './components/GatheringDataModal';
import UntrackableDeleteModal from './components/UntrackableDeleteModal';
import './styles.less';

const SalesTrackerPage = (
  {
    userInfo,
    isMobile,
    salesTracker,
    setView,
    dashboardInfo,
    getStores,
    getProducts
  }) => {

  const {stores, loading} = salesTracker;

  const skeleton = loading;

  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;

  const { view } = useParams();
  const navigate = useNavigate();
  const isYearly = userInfo?.subscriptions?.[0]?.plan?.price_type?.id !== 'monthly';

  const [firstLoading, setFirstLoading] = useState(true);
  const [displayFormat, setDisplayFormat] = useState('details');
  const [inputText, setInputText] = useState('');
  const [modal, setModal] = useState(null);
  const [recordToDelete, setRecordToDelete] = useState(null);

  function viewHandler(view) {
    navigate(`/sales-tracker/${view}`);
    setView(view);
  }

  function disconnectHandler() {
    console.log('trash disconnectHandler');
  }

  useEffect(() => {
    document.title = `Sales Tracker - Dropship`;
  }, []);

  useEffect(() => {
    if (firstLoading) {
      setFirstLoading(false);
    } else {
      if (view === 'stores') {
        getStores({page: 1,page_size: 50});
      } else {
        getProducts({page: 1, page_size: 100});
      }
    }
  }, [JSON.stringify(userInfo?.subscriptions?.[0])]);

  return (
    <div className={'product-database-page sales-tracker-page'}>
      <TopPageHeader icon={'sales_tracker_dashboard'}
                     title={'My trackings'}
                     subtitle={'Track sales of stores and products'}
                     takeTourHandler={() => window.Intercom('startTour', 433088)}
                     watchTutorialHandler={() => setModal('tutorial')}
                     increaseLimitsHandler={() => setModal('edit_limits')}
                     skeleton={skeleton}
                     isMobile={isMobile}
                     withCredits={false}
                     withLimits={{
                       stores: {
                         used: stores?.limits?.stores?.used,
                         allowed: stores?.limits?.stores?.allowed
                       },
                       products: {
                         used: stores?.limits?.products?.used,
                         allowed: stores?.limits?.products?.allowed,
                       }}}
                     isYearly={isYearly}
                     isTrial={isTrial}
      />
      <PageOptions displayFormat={displayFormat}
                   setDisplayFormat={setDisplayFormat}
                   view={view}
                   setView={viewHandler}
                   setInputText={setInputText}
                   inputText={inputText}
                   skeleton={skeleton}
                   disabled={false}
      />
      <PopularToday view={view}
                    skeleton={skeleton}
                    dispayFormat={displayFormat}
      />
      <UserTrackings view={view}
                     setModal={setModal}
                     setRecordToDelete={setRecordToDelete}
                     skeleton={skeleton}
      />

      <LimitChangeModal setModal={setModal}
                        visible={modal === 'edit_limits'}
                        isMobile={isMobile}
                        data={{
                          storeLimit: dashboardInfo?.limits?.stores?.allowed < 50 ? 50: dashboardInfo?.limits?.stores?.allowed,
                          productLimit: dashboardInfo?.limits?.products?.allowed < 100 ? 100: dashboardInfo?.limits?.products?.allowed,
                          subscription: userInfo?.subscriptions?.[0]
                        }}
      />
      <DeleteTrackingModal setModal={setModal}
                           visible={modal === 'delete_tracking'}
                           isMobile={isMobile}
                           record={recordToDelete}
                           disconnectHandler={disconnectHandler}
                           view={view}
      />
      <UntrackableDeleteModal setModal={setModal}
                              visible={modal === 'untrackable_delete_tracking'}
                              isMobile={isMobile}
                              record={recordToDelete}
                              disconnectHandler={disconnectHandler}
                              view={view}
      />
      <GatheringDataModal setModal={setModal}
                          visible={modal === 'gathering_data'}
                          isMobile={isMobile}
      />
      <CanNotRemoveTracking setModal={setModal}
                            visible={modal === 'can_not_remove_tracking'}
                            isMobile={isMobile}
      />
      <VideoTutorialModal visible={modal === 'tutorial'}
                          setModal={setModal}
                          isMobile={isMobile}
                          link={'https://www.youtube.com/watch?v=ViFABtDOpDw'}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  salesTracker: state.salesTracker,
  dashboardInfo: state.dashboard.result,
});

const mapDispatchToProps = (dispatch) => ({
  setView: (data) => dispatch(SalesTrackerCreators.setView(data)),
  getStores: (data) => dispatch(SalesTrackerCreators.getTrackingStoresRequest(data)),
  getProducts: (data) => dispatch(SalesTrackerCreators.getTrackingProductsRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesTrackerPage);
