import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import { Dropdown, Spin } from 'antd';
import Icon from '../../../Icon';
import InputComponent from '../../../Components/Input';
import ArrowSelectDown from '../../../Icon/img/ArrowSelectDown';
import _ from 'lodash';
import Modal from '../../../Components/Modal';
import utils from '../../../Utils/utils';
import './DropdownBlock.less';


const DropdownModal = (
  {
    id,
    isMobile,
    visible,
    setModal,
    t,
    cls,
    label,
    search,
    handleChangeSearch,
    checkAll,
    allClickHandler,
    data,
    localData,
    clickHandler,
    checkedList,
    searchPlaceholder,
  },
) => {

  return (
    <Modal handleOk={null}
           handleClose={() => {
             setModal(null);
           }}
           title={t(label)}
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal products-filter-modal"
           open={visible}
           destroyOnClose
    >
      <div style={{ height: 16 }} />
      <Spin spinning={false}>
        <div className={'collection-modal-content'}>
          <div className="dropdown-database-menu" style={{ maxHeight: 304 }}>
            {
              searchPlaceholder ?
                <InputComponent className={'dropdown-search'}
                                prefix={<Icon type="database_search" role="icon" />}
                                placeholder={t(searchPlaceholder)}
                                value={search}
                                onChange={handleChangeSearch}
                />
                :
                null
            }

            <ul className="dropdown-database-list" style={{ maxHeight: 'calc(304px - 64px)' }}>
              {
                //hide main checkbox on search
                search && searchPlaceholder
                  ? null
                  : (
                    <div className={cls('dropdown-database-list-item  test-item', {
                      'dropdown-database-list-item--active': checkAll,
                    })}
                         onClick={allClickHandler}
                    >
                      <div className={cls('dropdown-database-list-item-checkbox', {
                        'dropdown-database-list-item-checkbox--active': checkAll,
                      })} />
                      {t('All')}
                    </div>)
              }

              {
                !search || data.filter(el => [el?.name, el?.value, el?.code].some(el => el?.toLowerCase().includes(search.toLowerCase()))).length
                  ?
                  localData.current.map(el => (
                    <div key={el.id}
                         onClick={(e) => clickHandler(el, e)}
                         className={cls('dropdown-database-list-item  test-item', {
                           'dropdown-database-list-item-hidden': !([el?.name, el?.value, el?.code].some(el => el?.toLowerCase().includes(search.toLowerCase()))),
                           'dropdown-database-list-item--active': checkedList.some(elem => el.id === elem.id) || checkAll,
                         })}>

                      <div className={cls('dropdown-database-list-item-checkbox', {
                        'dropdown-database-list-item-checkbox--active': checkedList.some(elem => el.id === elem.id) || checkAll,
                      })} />

                      {
                        el?.flag && id !== 'country' ? (
                            <div style={{ display: 'flex', alignItems: 'center', width: 'calc(100% - 30px)' }}>
                              <div className={'info-wrap'}>
                                <span>{el.code}</span>
                                <span>{el.name}</span>
                              </div>
                            </div>)
                          :
                          <span className={'value'}>
                                    {
                                      el?.flag && id === 'country' ?
                                        <img src={el?.flag}
                                             alt=""
                                             onError={utils.addDefaultSrc}
                                             className={'country-icon'}
                                        />
                                        :
                                        null
                                    }
                            {el.value || el?.name}
                                  </span>
                      }
                    </div>
                  ))
                  :
                  <div className={'dropdown-database-list empty'}>
                    <div className="dropdown-database-list-item"
                         style={{ display: 'flex', alignItems: 'center' }}>
                    <span className={'link skeleton'}
                          style={{ width: 20, height: 20 }}
                    />
                      <span className={'link skeleton'}
                            style={{ width: 120, height: 20, marginLeft: 8 }}
                      />
                    </div>
                    <div className="empty-state_wrapper-select">
                      {t('No options found')}
                    </div>
                    <p className={'empty-state_wrapper-text'}>
                      {t('Try changing your search query or try again.')}
                    </p>
                  </div>
              }

            </ul>
          </div>
        </div>
      </Spin>
      <div style={{ height: 16 }} />
    </Modal>
  );
};

const DropdownBlockTest = (
  {
    label,
    iconType,
    id,
    dropdownFilters,
    setDropdownFilters,
    searchPlaceholder,
    data,
    visibleModal,
    setVisibleModal,
    isMobile,
  }) => {

  const { t } = useTranslation();
  //state toggle show/hide dropdown
  const [visible, setVisible] = useState(false);
  //checked checkboxes
  const [checkedList, setCheckedList] = useState([]);
  //check all checkbox state
  const [checkAll, setCheckAll] = useState(false);
  //search query in dropdown
  const [search, setSearch] = useState('');

  const localData = useRef([]);
  const isDisabledTransition = useSelector(state => state.nav.disabledTransition);

  //check all if no initial value and nothing selected on close
  useEffect(() => {
    if (!visible && !checkedList.length && (!dropdownFilters[id] || !dropdownFilters[id].length)) {
      setCheckedList(data);
      setDropdownFilters(state => ({ ...state, [id]: 'All' }));
      setCheckAll(true);
      localData.current = [...data];
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [visible]);

  //set initial value on load
  useEffect(() => {
    if (dropdownFilters[id] === 'All') {
      setCheckedList(data);
      setCheckAll(true);
    } else if (!dropdownFilters[id]) {
      setCheckedList([]);
      setCheckAll(false);
    } else if (dropdownFilters[id].length) {
      let newList = [];
      for (let i = 0; i < dropdownFilters[id].length; i++) {
        data.forEach(el => {
          if (el.id === dropdownFilters[id][i])
            newList.push(el);
        });
      }
      setCheckedList(newList);
      setCheckAll(dropdownFilters[id].length === data.length);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dropdownFilters]);

  useEffect(() => {
    localData.current = [...data];
  }, [data]);

  useEffect(() => {
    if (checkedList.length === data.length) {
      setDropdownFilters(state => ({ ...state, [id]: 'All' }));
      localData.current = [...data];
    }
    //use only checkbox id in API
    else setDropdownFilters(state => ({ ...state, [id]: checkedList.map(el => el.id) }));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [checkedList]);

  const handleVisibleChange = (value) => {
    setVisible(value);
    setSearch('');
  };

  //checkbox click handle
  const clickHandler = (val, e) => {
    e.target.classList.toggle('dropdown-database-list-item--active');
    if (checkedList.some(el => el.id === val.id)) {
      let res = checkedList.filter(el => el.id !== val.id);
      setCheckedList(res);
      setCheckAll(checkedList.length === data.length);
      let newData = _.differenceBy([...data], res, 'id');
      localData.current = [...res, ...newData];
    } else {
      let res = [...checkedList, val];
      setCheckedList(res);
      setCheckAll(checkedList.length === data.length);
      let newData = [...localData.current].filter(el => el.id !== val.id);
      localData.current = [val, ...newData];
    }
  };

  //main checkbox handler
  const allClickHandler = e => {
    let checked;
    if (e.target.classList.contains('dropdown-database-list-item-checkbox')) {
      e.target.classList.toggle('dropdown-database-list-item-checkbox--active');
      checked = e.target.classList.contains('dropdown-database-list-item-checkbox--active');
    } else {
      e.target.classList.toggle('dropdown-database-list-item--active');
      checked = e.target.classList.contains('dropdown-database-list-item--active');
    }
    setCheckedList(checked ? data : []);
    if (checked) setDropdownFilters(state => ({ ...state, [id]: 'All' }));
    else setDropdownFilters(state => ({ ...state, [id]: null }));
    setCheckAll(checked);
    localData.current = [...data];
  };

  const handleChangeSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  //text to display after closed dropdown
  function getSelectValue() {
    let result = null;

    switch (true) {
      case checkedList.length === 1 && checkedList.length !== data.length:
        result = checkedList[0]['value'] ? checkedList[0]['value'] : checkedList[0]['name'];
        break;
      case checkedList.length === data.length:
        result = t('All');
        break;
      case checkedList.length > 1:
        result = t('Mixed Values');
        break;
      default:
        result = t('Select Option');
        break;
    }

    return result;
  }

  if (isMobile) {
    return (
      <div className={cls(`select-block ${id}`, {
        'disabled-transition': isDisabledTransition,
      })}>
        <span className="select-block_label">{t(label)}</span>
        <div className={'dropdown-database ant-dropdown-trigger'}
             onClick={() => setVisibleModal(label)}
        >
          <Icon role="icon" type={iconType} />
          <span className="dropdown-database_value">
            {
              getSelectValue()
            }
          </span>
          <ArrowSelectDown />
        </div>
        <DropdownModal isMobile={isMobile}
                       visible={visibleModal === label}
                       setModal={setVisibleModal}
                       t={t}
                       id={id}
                       cls={cls}
                       label={label}
                       search={search}
                       handleChangeSearch={handleChangeSearch}
                       checkAll={checkAll}
                       allClickHandler={allClickHandler}
                       data={data}
                       localData={localData}
                       clickHandler={clickHandler}
                       checkedList={checkedList}
                       searchPlaceholder={searchPlaceholder}
        />
      </div>
    );
  }

  return (
    <div className={cls(`select-block ${id}`, {
      'disabled-transition': isDisabledTransition,
    })}>
      <span className="select-block_label">{t(label)}</span>
      <Dropdown overlayClassName={'dropdown-database-wrapper'}
                getPopupContainer={(trigger) => trigger.parentNode}
                onOpenChange={handleVisibleChange}
                placement={'bottom'}
                dropdownRender={() => (
                  <div className="dropdown-database-menu" style={{ maxHeight: 304 }}>
                    {
                      searchPlaceholder ?
                        <InputComponent
                          className={'dropdown-search'}
                          prefix={<Icon type="database_search" role="icon" />}
                          placeholder={t(searchPlaceholder)}
                          value={search}
                          onChange={handleChangeSearch}
                        />
                        :
                        null
                    }
                    <ul className="dropdown-database-list" style={{ maxHeight: 'calc(304px - 64px)' }}>
                      {
                        //hide main checkbox on search
                        search && searchPlaceholder
                          ? null
                          : (
                            <div className={cls('dropdown-database-list-item  test-item', {
                              'dropdown-database-list-item--active': checkAll,
                            })}
                                 onClick={allClickHandler}
                            >
                              <div className={cls('dropdown-database-list-item-checkbox', {
                                'dropdown-database-list-item-checkbox--active': checkAll,
                              })} />
                              {t('All')}
                            </div>)
                      }

                      {
                        !search || data.filter(el => [el?.name, el?.value, el?.code].some(el => el?.toLowerCase().includes(search.toLowerCase()))).length
                          ?
                          localData.current.map(el => (
                            <div key={el.id}
                                 onClick={(e) => clickHandler(el, e)}
                                 className={cls('dropdown-database-list-item  test-item', {
                                   'dropdown-database-list-item-hidden': !([el?.name, el?.value, el?.code].some(el => el?.toLowerCase().includes(search.toLowerCase()))),
                                   'dropdown-database-list-item--active': checkedList.some(elem => el.id === elem.id) || checkAll,
                                 })}>

                              <div className={cls('dropdown-database-list-item-checkbox', {
                                'dropdown-database-list-item-checkbox--active': checkedList.some(elem => el.id === elem.id) || checkAll,
                              })} />

                              {
                                el?.flag && id !== 'country' ? (
                                    <div style={{ display: 'flex', alignItems: 'center', width: 'calc(100% - 30px)' }}>
                                      <div className={'info-wrap'}>
                                        <span>{el.code}</span>
                                        <span>{el.name}</span>
                                      </div>
                                    </div>)
                                  :
                                  <span className={'value'}>
                                    {
                                      el?.flag && id === 'country' ?
                                        <img src={el?.flag}
                                             alt=""
                                             onError={utils.addDefaultSrc}
                                             className={'country-icon'}
                                        />
                                        :
                                        null
                                    }
                                    {el.value || el?.name}
                                  </span>
                              }
                            </div>
                          ))
                          :
                          <div className={'dropdown-database-list empty'}>
                            <div className="dropdown-database-list-item"
                                 style={{ display: 'flex', alignItems: 'center' }}>
                              <span className={'link skeleton'}
                                    style={{ width: 20, height: 20 }}
                              />
                              <span className={'link skeleton'}
                                    style={{ width: 120, height: 20, marginLeft: 8 }}
                              />
                            </div>
                            <div className="empty-state_wrapper-select">
                              {t('No options found')}
                            </div>
                            <p className={"empty-state_wrapper-text"}>
                              {t('Try changing your search query or try again.')}
                            </p>
                          </div>
                      }

                    </ul>
                  </div>
                )}
                trigger={['click']}
                open={visible}
      >
        <div className={cls('dropdown-database', { 'ant-dropdown-open': visible }, id)}>
          <Icon role="icon" type={iconType} />
          <span className="dropdown-database_value">
            {
              getSelectValue()
            }
          </span>
          <ArrowSelectDown />
        </div>
      </Dropdown>
    </div>
  );
};

const areEqual = (prev, next) => {
  if (prev?.dropdownFilters?.[prev.id] === 'All') return next?.dropdownFilters?.[next.id] === 'All' && prev?.visibleModal === next?.visibleModal;
  else return JSON.stringify(prev?.dropdownFilters?.[prev.id]) === JSON.stringify(next?.dropdownFilters?.[next.id]) && prev?.visibleModal === next?.visibleModal;
};

export default React.memo(DropdownBlockTest, areEqual);
