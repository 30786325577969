import React from 'react';
import { useSelector } from 'react-redux';

const LockedBlockGrey = () => {
  const theme = useSelector(state => state?.nav?.theme);
  return (
    <>
      {theme === 'light' ?
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="10" fill="#F2F3F8" />
          <path fillRule="evenodd" clip-rule="evenodd" d="M20.0007 11.875C17.6995 11.875 15.834 13.7405 15.834 16.0417V17.5H15.6257C14.36 17.5 13.334 18.526 13.334 19.7917V26.0417C13.334 27.3073 14.36 28.3333 15.6257 28.3333H24.3757C25.6413 28.3333 26.6673 27.3073 26.6673 26.0417V19.7917C26.6673 18.526 25.6413 17.5 24.3757 17.5H24.1673V16.0417C24.1673 13.7405 22.3018 11.875 20.0007 11.875ZM22.9173 17.5V16.0417C22.9173 14.4308 21.6115 13.125 20.0007 13.125C18.3898 13.125 17.084 14.4308 17.084 16.0417V17.5H22.9173ZM20.0007 21.0417C20.3458 21.0417 20.6257 21.3215 20.6257 21.6667V24.1667C20.6257 24.5118 20.3458 24.7917 20.0007 24.7917C19.6555 24.7917 19.3757 24.5118 19.3757 24.1667V21.6667C19.3757 21.3215 19.6555 21.0417 20.0007 21.0417Z" fill="#C5CCE3" />
        </svg>
        :
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="10" fill="#1D1D1D" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9987 11.875C17.6975 11.875 15.832 13.7405 15.832 16.0417V17.5H15.6237C14.358 17.5 13.332 18.526 13.332 19.7917V26.0417C13.332 27.3073 14.358 28.3333 15.6237 28.3333H24.3737C25.6394 28.3333 26.6654 27.3073 26.6654 26.0417V19.7917C26.6654 18.526 25.6393 17.5 24.3737 17.5H24.1654V16.0417C24.1654 13.7405 22.2999 11.875 19.9987 11.875ZM22.9154 17.5V16.0417C22.9154 14.4308 21.6095 13.125 19.9987 13.125C18.3879 13.125 17.082 14.4308 17.082 16.0417V17.5H22.9154ZM19.9987 21.0417C20.3439 21.0417 20.6237 21.3215 20.6237 21.6667V24.1667C20.6237 24.5118 20.3439 24.7917 19.9987 24.7917C19.6535 24.7917 19.3737 24.5118 19.3737 24.1667V21.6667C19.3737 21.3215 19.6535 21.0417 19.9987 21.0417Z" fill="white" fill-opacity="0.26" />
        </svg>
      }
    </>
  )
};

export default LockedBlockGrey;
