import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router";
import cls from "classname";
import NavHeader from "../components/NavHeader";
import { connect } from "react-redux";
import Creators from '../reducer';
import { OverlayScrollbar } from "../../../Components/ScrollBar";
import AdItemOverview from "./AdItemOverview";
import AdItemProducts from "./AdItemProducts";
import AdItemAllAdsPage from "./AdItemAllAdsPage";
import './AdItemPage.less';
import AdItemNavigation from '../components/AdItemNavigation';
import AdSpotEngagementNotice from '../components/AdSpotEngagementNotice';
import MainBlockNewAdSpot from '../components/MainBlockNewAdSpot';

const AdItemPage = (
  {
    isMobile,
    loading,
    selectedAdv,
    getAds,
    userInfo,
    getOverviewMainInfo,
    overviewInfo,
    creditsLeft,
    sortOrder,
    setSortOrder,
  }) => {

  const { id, mode, search } = useParams();
  let params = new URLSearchParams(search);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Ad Spot - Dropship`;
  }, [])

  const innerContent = {
    'all_ads': <AdItemAllAdsPage id={id}
                                 isMobile={isMobile}
                                 loading={loading}
                                 selectedAdv={selectedAdv}
                                 userInfo={userInfo}
                                 getAds={getAds}
                                 getOverviewMainInfo={getOverviewMainInfo}
                                 mainInfo={overviewInfo?.mainInfo}
                                 creditsLeft={creditsLeft}
                                 setSortOrder={setSortOrder}
                                 sortOrder={sortOrder}
    />,
    'overview': <AdItemOverview />,
    'products': <AdItemProducts />,
    'detailed': <AdItemProducts sortOrder={sortOrder} setSortOrder={setSortOrder}/>
  }

  return (
    <OverlayScrollbar visibility="hidden">
      <AdItemNavigation
        mainInfo={overviewInfo?.mainInfo}
        isMobile={isMobile}
        mode={mode}
      />
      {(mode === 'overview' || mode === 'detailed') && (
        <AdSpotEngagementNotice loading={overviewInfo?.mainInfo?.mainInfoLoading} isMobile={isMobile} />
      )}
      {mode !== 'detailed' &&
        <MainBlockNewAdSpot
          data={overviewInfo?.mainInfo}
          isMobile={isMobile}
          isShopify={params.get('internal_shop_id')}
          skeleton={overviewInfo?.mainInfo?.mainInfoLoading}
        />
      }
      {mode !== 'detailed' &&
        <NavHeader
          mainInfo={overviewInfo?.mainInfo}
          navigate={navigate}
          isMobile={isMobile}
        />
      }
      {
        innerContent[mode]
      }
    </OverlayScrollbar>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state?.nav?.isMobile,
  loading: state.adSpot.selectedAdvLoading,
  selectedAdv: state.adSpot.selectedAdv,
  view: state.adSpot.view,
  userInfo: state.auth.userInfo,
  overviewInfo: state?.adSpot?.innerPagesInfo?.overview,
  creditsLeft: state.adSpot.creditsLeft,
  sortOrder: state.adSpot.selectedSort,
});

const mapDispatchToProps = (dispatch) => ({
  getAds: (data) => dispatch(Creators.getAdsByIDRequest(data)),
  getOverviewMainInfo: (data) => dispatch(Creators.getOverviewMainInfoRequest(data)),
  setSortOrder: (data) => dispatch(Creators.setSelectedSort(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdItemPage);
