import React, { useEffect, useState } from 'react';
import cls from 'classname';
import Icon from '../../../Icon';
import { Dropdown, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import ProductView from '../../ProductsPage/components/product-view';
import PresetsModal from './PresetsModal';
import './PageOptions.less';

const { Search } = Input;

const PageOptions = (
  {
    setShowFilters,
    showFilters,
    setModal,
    view,
    setView,
    showTable,
    isChanged,
    setInputText,
    inputText,
    skeleton,
    isMobile,
    disableShowFilters,
    disabledSearch,
    isAdmin,
    getProducts,
    productsAdd,
    pageNumber,
    pageSize,
    sortOrder,
    setSortOrder,
    setShowTable,
    setEnabled,
    currentScroll,
    setPageSize,
    setPageNumber,
    setLoadedPreset,
    loadedPreset,
  }
) => {

  const {t} = useTranslation();

  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(null);

  const handleVisibleChange = (value) => !disabledSearch && setVisible(value);

  const handleSearch = (e) => {
    setInputText(e.target.value.trim());
  }

  const applyFilters = (new_search = false, force = false) => {
    if ((!disableShowFilters || (disabledSearch && !isAdmin)) && !force) return;
    getProducts({
      productsAdd,
      page: pageNumber,
      page_size: pageSize,
      ordering: [sortOrder],
      new_search: new_search,
      filters: {
        global_search: inputText
      }
    });
    setShowTable(true);
    setEnabled(true);
    currentScroll.current = null;
  };

  const handleSubmitSearch = () => {
    if (!disableShowFilters || disabledSearch) return null;
    applyFilters(true, true);
    setPageSize(50);
    setPageNumber(1);
    setSortOrder(null);
  };

  useEffect(() => {
    applyFilters();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pageNumber, pageSize, sortOrder]);

  useEffect(() => {
    if (Boolean(loadedPreset)) {
      applyFilters(true, true);
      setLoadedPreset(null);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [loadedPreset]);

  if (skeleton) return (
    <div className={'page-options-wrapper'}>
      <div className="search-options">
        <div className="search">
          <span className={'link skeleton'}
                style={{ width: 400, height: 40 }}
          />
          <span className={'link skeleton'}
                style={{ width: 70, height: 40 }}
          />
        </div>
        <span className={'link skeleton'}
              style={{ width: 92, height: 40 }}
        />
      </div>
      <div className={'view-options'}>
        <span className={'link skeleton'}
              style={{ width: isMobile ? 'calc(100% - 129px - 72px - 24px)' : 129, height: 40 }}
        />
        {
          isMobile ?
            <span className={'link skeleton'}
                  style={{ width: 129, height: 40 }}
            />
            :
            null
        }
        <span className={'link skeleton'}
              style={{ width: 72, height: 40 }}
        />
      </div>
    </div>
  );

  return (
    <div className={'page-options-wrapper'}>
      <div className="search-options">
        <div className="search">
          <Search placeholder={t('Search for competitors by product name...')}
                  className={'search-input'}
                  onChange={(e) => {
                    e.persist();
                    handleSearch(e);
                  }}
                  defaultValue={inputText && inputText}
                  {...(inputText ? { value: inputText } : null)}
                  prefix={<Icon type="search_icon"
                                role="icon"
                  />}
          />
          <div className={cls('search-submit', {
            'disabled': !inputText || disabledSearch,
          })}
               onClick={handleSubmitSearch}
          >
            {t('Search')}
          </div>
        </div>
        {
          isMobile ?
            null
            :
            <span className={cls('filters', {
              active: showFilters,
              disabled: disableShowFilters,
            })}
                  onClick={() => {
                    if (disableShowFilters) return null;
                    setShowFilters(!showFilters);
                  }}
            >
          <Icon type={'filter_icon'} role={'icon'} />
              {t('Filters')}
        </span>
        }
      </div>
      <div className={'view-options'}>
        {
          isMobile ?
            <span className={cls('filters', {
              active: showFilters,
              disabled: disableShowFilters,
            })}
                  onClick={() => {
                    if (disableShowFilters) return null;
                    setShowFilters(!showFilters);
                  }}
            >
          <Icon type={'filter_icon'} role={'icon'} />
              {t('Filters')}
        </span>
            :
            null
        }
        {
          isMobile ?
            <span className={'presets'}
                  onClick={() => !disabledSearch && setVisibleModal('presets_modal')}
            >
              <Icon type={'preset_icon'} role={'icon'} />
              {t('Presets')}
              <Icon type="arrow_select_down"
                    role="icon"
              />
            </span>
            :
            <Dropdown destroyPopupOnHide={true}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onOpenChange={handleVisibleChange}
                      placement="bottomRight"
                      dropdownRender={() => (
                        <div className={'sales-tracker-top-page-header-dropdown'}>
                          <div className="sales-tracker-top-page-header-dropdown-option"
                               onClick={() => {
                                 setVisible(false);
                                 setModal('load_preset');
                               }}
                          >
                            <Icon type="load_preset" role="icon" />
                            <span>
                          {t('Load preset')}
                        </span>
                          </div>
                          <div className={cls('sales-tracker-top-page-header-dropdown-option', {
                            disabled: !isChanged,
                          })}
                               onClick={() => {
                                 if (isChanged) {
                                   setVisible(false);
                                   setModal('save_preset');
                                 }
                               }}
                          >
                            <Icon type={'save_preset'}
                                  role={'icon'}
                            />
                            <span>
                          {t('Save this search as preset')}
                        </span>
                          </div>
                        </div>
                      )}
                      trigger={['click']}
                      open={visible}
            >
          <span className={'presets'}>
          <Icon type={'preset_icon'} role={'icon'} />
            {t('Presets')}
            <Icon type="arrow_select_down"
                  role="icon"
            />
        </span>
            </Dropdown>
        }
        <ProductView view={view}
                     setView={showTable ? (val) => setView(val) : () => null}
        />
      </div>
      <PresetsModal setModal={setModal}
                    setVisibleModal={setVisibleModal}
                    isMobile={isMobile}
                    visible={visibleModal}
                    isChanged={isChanged}
      />
    </div>
  );
};

export default PageOptions;
