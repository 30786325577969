import React from 'react';
import { useSelector } from 'react-redux';

const DatabaseProvider = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M14.2708 16.0418H7.43748C4.13528 16.0418 1.45831 13.3369 1.45831 10.0002C1.45831 6.66344 4.13528 3.9585 7.43748 3.9585C9.63375 3.9585 11.5534 5.15502 12.5929 6.93814C12.8117 7.3134 13.2548 7.51076 13.6851 7.45113C13.8766 7.42459 14.0721 7.41088 14.2708 7.41088C16.6295 7.41088 18.5416 9.34298 18.5416 11.7264C18.5416 14.1097 16.6295 16.0418 14.2708 16.0418Z"
        stroke={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, 0.26)"}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DatabaseProvider;
