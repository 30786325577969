import React from 'react'

const OrangeInfo = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.95964 9.16699H10.0013L10.0013 13.542M17.7096 10.0003C17.7096 14.2575 14.2585 17.7087 10.0013 17.7087C5.74411 17.7087 2.29297 14.2575 2.29297 10.0003C2.29297 5.74313 5.74411 2.29199 10.0013 2.29199C14.2585 2.29199 17.7096 5.74313 17.7096 10.0003Z" stroke="#DF7009" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.9987 6.125C9.69954 6.125 9.45703 6.36751 9.45703 6.66667C9.45703 6.96582 9.69954 7.20833 9.9987 7.20833C10.2979 7.20833 10.5404 6.96582 10.5404 6.66667C10.5404 6.36751 10.2979 6.125 9.9987 6.125Z" fill="#DF7009" stroke="#DF7009" strokeWidth="0.25"/>
    </svg>
  )
}

export default OrangeInfo
