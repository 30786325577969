import React from 'react';
import { useSelector } from 'react-redux';


const SingleLockedBlockGrey = () => {
  const theme = useSelector(state => state?.nav?.theme);
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clip-rule="evenodd" d="M15.9987 7.875C13.6975 7.875 11.832 9.74048 11.832 12.0417V13.5H11.6237C10.358 13.5 9.33203 14.526 9.33203 15.7917V22.0417C9.33203 23.3073 10.358 24.3333 11.6237 24.3333H20.3737C21.6394 24.3333 22.6654 23.3073 22.6654 22.0417V15.7917C22.6654 14.526 21.6393 13.5 20.3737 13.5H20.1654V12.0417C20.1654 9.74048 18.2999 7.875 15.9987 7.875ZM18.9154 13.5V12.0417C18.9154 10.4308 17.6095 9.125 15.9987 9.125C14.3879 9.125 13.082 10.4308 13.082 12.0417V13.5H18.9154ZM15.9987 17.0417C16.3439 17.0417 16.6237 17.3215 16.6237 17.6667V20.1667C16.6237 20.5118 16.3439 20.7917 15.9987 20.7917C15.6535 20.7917 15.3737 20.5118 15.3737 20.1667V17.6667C15.3737 17.3215 15.6535 17.0417 15.9987 17.0417Z" fill={theme === 'light' ? "#C5CCE3" : "rgba(255, 255, 255, 0.26)"} fillOpacity="1" />
    </svg>
  );
}

export default SingleLockedBlockGrey;
