import React from 'react';
import {useNavigate} from "react-router-dom";
import cls from 'classname';
import {formatDuration} from "../../../Utils/utils";
import Icon from "../../../Icon";
import { useTranslation } from 'react-i18next';
import './UniversityVideoCard.less';

const UniversityVideoCard = (
  {
    info,
    chapter,
    setSelected,
    setIsShort
  }
) => {

  const {id, title, description, duration, thumbnail, completed, chapter_id, progress} = info;

  const navigate = useNavigate();
  const {t} = useTranslation();

  const clickHandler = () => {
    setSelected(chapter);
    setIsShort(true);
    navigate(`/dropship-university/${chapter?.id}/${chapter_id}/${id}`, {
      state: {
        name: title
      }
    })
  }

  return (
    <div className={'university-video-card-wrapper'}
         onClick={clickHandler}
    >
        <div className={cls('university-video-card-image', {
          completed: completed
        })}
             style={{backgroundImage: `url("${thumbnail}")`}}
        >
          <Icon type={'play_video_btn'}
                role={'icon'}
          />
        </div>
      <div className="university-video-card-info">
        <h4 className="university-video-card-title">
          {title}
        </h4>
        <p className="university-video-card-description">
          {description}
        </p>
        <div className={cls("university-video-card-status", {
          completed: completed
        })}>
          {
            completed ?
              <span>
                <Icon role={'icon'}
                      type={'university_watched'}
                />
                {t('Completed')}
              </span>
              :
              <span>
                {formatDuration(Math.floor(progress * duration / 100))}
              </span>
          }
          <div className={'progress-wrapper'}>
            <div className="progress"
                 style={{width: `${progress}%`}}
            />
          </div>
          <span>
            {formatDuration(duration)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default UniversityVideoCard;
