import { Divider } from 'antd';
import React from 'react';

const CardInfoSkeleton = ({ isMobile }) => {
  return (
    <div className="fadspot-page-overview-post-wrapper">
      <div className="fadspot-page-overview-post" style={{ display: "flex", flexDirection: "column" }}>
        <span className="link skeleton" style={{width: 130, height: 24, marginBottom: 18, marginTop: isMobile ? 18 : 0}}/>
        <span className="fadspot-page-item-wrapper link skeleton"/>
      </div>
      <div className="fadspot-page-overview-info-wrapper">
        <div className="fadspot-page-overview-info-block" style={{ display: "block" }}>
          <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
            <span className="link skeleton" style={{width: 230, height: 24}}/>
            {isMobile ? null : <span className="link skeleton" style={{width: 50, height: 24}}/>}
          </div>
          <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", width: "100%", justifyContent: "space-between", marginTop: 16, gap: isMobile && 8 }}>
            <span className="link skeleton" style={{width: isMobile ? "100%" : 290, height: 60}}/>
            <span className="link skeleton" style={{width: isMobile ? "100%" : 290, height: 60}}/>
            <span className="link skeleton" style={{width: isMobile ? "100%" : 290, height: 60}}/>
          </div>
          <Divider type="horizontal" />
        </div>
        <div className="fadspot-page-overview-timeline fadspot-page-overview-info-block">
          <span className="link skeleton" style={{width: 91, height: 24}}/>
          <div>
            <div className="item-wrapper">
              <div className="item-image"/>
              <div className={'item-info'}>
                <span className="link skeleton" style={{width: 93, height: 20}}/>
                <span className="link skeleton" style={{width: 188, height: 20}}/>
              </div>
            </div>
            <div className="badge" style={{width: 56, height: 24}} />
            <div className="item-wrapper">
              <div className="item-image"/>
              <div className={'item-info'}>
                <span className="link skeleton" style={{width: 76, height: 20}}/>
                <span className="link skeleton" style={{width: 89, height: 20}}/>
              </div>
            </div>
            <div className="badge" style={{width: 56, height: 24}} />
            <div className="item-wrapper">
              <div className="item-image"/>
              <div className={'item-info'}>
                <span className="link skeleton" style={{width: 43, height: 20}}/>
                <span className="link skeleton" style={{width: 89, height: 20}}/>
              </div>
            </div>
          </div>
        </div>
        <div className="fadspot-page-overview-landing-same fadspot-page-overview-info-block">
          <Divider type="horizontal"/>
          <span className="link skeleton" style={{width: 225, height: 24, marginTop: 4}}/>
          <span className="link skeleton" style={{width: 30, height: 30}}/>
        </div>
      </div>
    </div>
  );
};

export default CardInfoSkeleton;
