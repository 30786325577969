import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import OnboardingActions from '../Redux/OnboardingRedux';
import CategoryActions from '../Redux/CategoryRedux';
import PlanActions from '../Redux/PlanRedux';
import { Layout, Spin } from 'antd';
import StepTitle from '../Components/StepTitle';
import PlanCardOnboarding from '../Components/PlanCardOnboarding';
import SwitchSubscriptionPeriod from '../Components/SwitchSubscriptionPeriod';
import { descriptionPlanWithoutName } from '../Utils/utils';
import { useParams } from 'react-router';
import AuthActions from './LoginPage/reducer';
import Images from '../Images';
import './OnboardingPlanNew.less';

const OnboardingPlanNew = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isMobile, onBoarding, category, plan, fetching, confirmEmail, userInfo } = props;

  const { focusCategory, selectedCategory, selectedPlan, isYearlySubscription, stripeLoading } = onBoarding;
  const priceType = isYearlySubscription ? 'yearly' : 'monthly';

  let navigate = useNavigate();
  const { hash } = useParams();

  const step = { title: 'Start free trial', text: 'Select a plan to start your 7-day free trial' };

  const categoriesList = category?.categories || [];
  const plansList = plan?.plans || [];
 
  useEffect(() => {
    document.title = 'Onboarding - Dropship';
    if (!userInfo) {
      if (hash) {
        confirmEmail(hash);
      }
    } else {
      if (window.location.pathname.includes('confirm')) navigate('/onboarding/plan');
      if (!categoriesList?.length) props.getCategories();
      if (!plansList?.length) props.getPlans();
    }
    let head = document.querySelector('head');
    let script = document.createElement('script');
    script.text = '!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);},s.version=\'1.1\',s.queue=[],u=t.createElement(n),u.async=!0,u.src=\'https://static.ads-twitter.com/uwt.js\',a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,\'script\');twq(\'config\',\'oepmf\');';
    head.appendChild(script);
    return () => {
      head.removeChild(script);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (categoriesList?.length) {
      props.setFocusCategory(categoriesList?.[0]);
      if (!selectedCategory.includes(categoriesList?.[0]?.id)) props.setSelectedCategory([categoriesList?.[0]?.id]);
      handleFocusCategory(categoriesList?.[0]);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [categoriesList?.length]);

  function handleFocusCategory(category) {
    if (!category.status) return;
    props.setFocusCategory(category);
  }

  function handleNext(selectedPlan) {
    if (selectedCategory.length === Object.keys(selectedPlan).length) {
      let [categoryId, plan] = Object.entries(selectedPlan)[0];
      let categoryIdWithPeriod = category?.categoriesMap?.[categoryId]?.prices?.[priceType]?.id;
      props.saveSubscription({
        'plan': { [categoryIdWithPeriod]: plan?.prices?.[priceType] },
      });
    }
  }

  function handleDeleteSelectPlan() {
    const newSelectedPlans = { ...selectedPlan };
    delete newSelectedPlans[focusCategory.id];
    props.setSelectedPlan(newSelectedPlans);
  }

  function handleSelectPlan(plan) {
    if (selectedPlan?.[focusCategory?.id]?.id === plan?.id) {
      handleDeleteSelectPlan();
    } else {
      if (userInfo?.use_stripe) {
        props.setSelectedPlan({ ...selectedPlan, [focusCategory?.id]: plan });
        props.createStripeSession({
          plan_pricing: plan?.prices?.[priceType]?.id,
          ...(props.offerId && { promo_offer_id: props.offerId }),
        });
      } else {
        props.setSelectedPlan({ ...selectedPlan, [focusCategory?.id]: plan });
        handleNext({ ...selectedPlan, [focusCategory?.id]: plan });
      }
    }
  }

  return (
    <Layout>
      <div className="login-content onboarding-plan">
        <img src={Images.logo} alt="" className={'login-logo'} />
        <div className={'onboarding-plan-header'}>
          <StepTitle stepInfo={step} />
          <SwitchSubscriptionPeriod />
        </div>
        <Spin spinning={fetching || stripeLoading}>
          <div className="onboarding-plan-cards-wrapper">
            {plansList
                  ?.filter(el => el?.status)
                  .map((el, index) => (
                    <PlanCardOnboarding plan={el}
                      key={el?.id}
                      selectedPlan={selectedPlan?.[focusCategory?.id]}
                      planIndex={index}
                      description={descriptionPlanWithoutName[el?.name]}
                      onSelectPlan={handleSelectPlan}
                      maxProducts={plan?.maxProducts}
                      isMobile={isMobile}
                      isOnboarding={true}
                    />
                  ))
            }
          </div>
          <div className='logout-wrapper'>
            <div className='logout-wrapper-divider'/>
            <div className='logout-wrapper-text'>{t('Already have an account?')} <span onClick={() => dispatch(AuthActions.logout())}>{t('Log out')}</span></div>
          </div>
        </Spin>
      </div>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    isDesktop: state.nav.isDesktop,
    isMobile: state.nav.isMobile,
    userName: state.auth.userInfo?.first_name,
    userInfo: state.auth.userInfo,
    offerId: state.auth.offerId,
    onBoarding: state.onBoarding,
    category: state.category,
    plan: state.plan,
    fetching: state?.auth?.fetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCategories: () => dispatch(CategoryActions.categoriesRequest()),
  confirmEmail: (hash) => dispatch(AuthActions.confirmEmailRequest(hash)),
  getPlans: () => dispatch(PlanActions.plansRequest()),
  setSelectedCategory: (category) => dispatch(OnboardingActions.setSelectedCategory(category)),
  setFocusCategory: (category) => dispatch(OnboardingActions.focusCategory(category)),
  setSelectedPlan: (plan) => dispatch(OnboardingActions.setSelectedPlan(plan)),
  createStripeSession: (data) => dispatch(OnboardingActions.createStripeSessionRequest(data)),
  setFocusPlan: (plan) => dispatch(OnboardingActions.focusPlan(plan)),
  focusPlanReset: () => dispatch(OnboardingActions.focusPlanReset()),
  saveSubscription: (plan) => dispatch(OnboardingActions.saveSubscriptionRequest(plan)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingPlanNew);
