import React from 'react';

const IncreaseLimit = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99935 10.8333L7.29102 8.125M4.31507 16.04C1.54057 13.0155 1.61846 8.31299 4.54873 5.38272C7.55903 2.37243 12.4397 2.37243 15.45 5.38272C18.3802 8.31299 18.4581 13.0155 15.6836 16.04"
        stroke="#434C69"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IncreaseLimit;
