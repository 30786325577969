import React, {useState} from 'react';

const SearchTiktok = ({ width = 24, height = 24 }) => {

  const [reversedIcon, setReversedIcon] = useState(false);

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width={width}
         height={height}
         viewBox="0 0 24 24"
         onMouseEnter={() => setReversedIcon(true)}
         onMouseLeave={() => setReversedIcon(false)}
         fill="none">
      <path
        d="M17.1767 8.66347C18.7201 9.77064 20.6107 10.4221 22.6528 10.4221V6.47861C22.2663 6.47869 21.8808 6.43825 21.5027 6.35786V9.46193C19.4609 9.46193 17.5705 8.81049 16.0267 7.7034V15.7509C16.0267 19.7766 12.7748 23.0399 8.76358 23.0399C7.2669 23.0399 5.8758 22.5858 4.72021 21.807C6.03913 23.1604 7.87845 24 9.91334 24C13.9248 24 17.1769 20.7367 17.1769 16.7108V8.66347H17.1767ZM18.5954 4.68499C17.8066 3.82023 17.2888 2.70267 17.1767 1.46717V0.959961H16.0869C16.3612 2.53028 17.2969 3.87187 18.5954 4.68499ZM7.2573 18.7178C6.81663 18.1379 6.57849 17.4286 6.57955 16.6992C6.57955 14.858 8.06696 13.3651 9.90202 13.3651C10.244 13.365 10.584 13.4176 10.9099 13.5214V9.48977C10.529 9.43739 10.1446 9.41515 9.76035 9.4233V12.5613C9.43419 12.4575 9.09408 12.4048 8.75201 12.4051C6.91695 12.4051 5.42963 13.8979 5.42963 15.7393C5.42963 17.0414 6.17309 18.1687 7.2573 18.7178Z"
        fill={reversedIcon ? "#225aea" : "#FF004F"}/>
      <path
        d="M16.0265 7.70332C17.5702 8.81041 19.4607 9.46185 21.5025 9.46185V6.35778C20.3628 6.11414 19.3538 5.5164 18.5951 4.68499C17.2966 3.87179 16.361 2.5302 16.0867 0.959961H13.2241V16.7106C13.2176 18.5468 11.7327 20.0336 9.90162 20.0336C8.82257 20.0336 7.86394 19.5174 7.25682 18.7178C6.17269 18.1687 5.42923 17.0413 5.42923 15.7394C5.42923 13.8981 6.91655 12.4052 8.75161 12.4052C9.1032 12.4052 9.44208 12.4602 9.75995 12.5614V9.42338C5.81921 9.50509 2.6499 12.7365 2.6499 16.7107C2.6499 18.6946 3.43913 20.4931 4.72006 21.8071C5.87565 22.5858 7.26675 23.04 8.76342 23.04C12.7747 23.04 16.0265 19.7765 16.0265 15.7509V7.70332H16.0265Z"
        fill={reversedIcon ? "#225aea" : "black"}/>
      <path
        d="M21.5025 6.3578V5.51848C20.4748 5.52005 19.4672 5.23119 18.5952 4.68493C19.3671 5.53306 20.3835 6.11787 21.5025 6.3578ZM16.0867 0.959983C16.0605 0.809911 16.0404 0.658851 16.0265 0.507214V0H12.074V15.7508C12.0677 17.5868 10.5829 19.0736 8.75164 19.0736C8.214 19.0736 7.70638 18.9455 7.25685 18.7179C7.86397 19.5174 8.82259 20.0336 9.90164 20.0336C11.7326 20.0336 13.2177 18.5469 13.2241 16.7107V0.959983H16.0867ZM9.76014 9.42341V8.52989C9.42988 8.48459 9.09691 8.46186 8.76353 8.46202C4.75192 8.46194 1.5 11.7254 1.5 15.7508C1.5 18.2745 2.77806 20.4987 4.72017 21.807C3.43924 20.493 2.65001 18.6944 2.65001 16.7106C2.65001 12.7365 5.81924 9.50511 9.76014 9.42341Z"
        fill={reversedIcon ? "#225aea" : "#00F2EA"}/>
    </svg>
  );
};

export default SearchTiktok;
