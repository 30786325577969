import React from 'react';

export const TrashPaper = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.54578 3.74659L11.3541 2.56374C11.9086 2.201 12.6233 2.201 13.1778 2.56374L14.2974 3.2961C14.7269 3.57706 14.9861 4.05779 14.9861 4.57356C14.9861 5.1562 15.3162 5.68781 15.8363 5.94299L16.7675 6.3998C17.7203 6.86727 18.0073 8.09757 17.3608 8.94338L15.8928 10.8638M9.54578 3.74659L5.2728 4.39159C4.37219 4.52754 3.74473 5.36354 3.86061 6.27312L4.10544 8.19486M9.54578 3.74659L11.3592 5.97072M4.10544 8.19486L3.2328 8.62297C2.65721 8.90535 2.29199 9.49361 2.29199 10.1384V10.872C2.29199 11.4218 2.56825 11.9342 3.02608 12.2337C3.42317 12.4934 3.68681 12.9158 3.74706 13.3887L4.00068 15.3794C4.06674 15.898 4.36799 16.3563 4.81618 16.6202L5.54764 17.0508C6.05871 17.3517 6.68957 17.3583 7.20679 17.0683L9.54578 15.7569M4.10544 8.19486L4.74313 8.82055C5.17651 9.24577 5.82828 9.35138 6.37225 9.08452M9.54578 15.7569L10.6345 17.0923C11.0716 17.6284 11.7902 17.8401 12.4454 17.6258L13.7633 17.1948C13.972 17.1265 14.1653 17.0176 14.3323 16.8743L16.6385 14.8943C17.2562 14.364 17.4071 13.4639 16.9965 12.7588L15.8928 10.8638M9.54578 15.7569L8.63905 14.8673M15.8928 10.8638L13.626 11.3087"
      stroke="#225AEA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default TrashPaper;
