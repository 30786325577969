import React from 'react';
import { useSelector } from 'react-redux';

const DatabaseStatus = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M1.45834 9.79153H3.75123C4.49139 9.79153 5.14288 9.30339 5.35079 8.59303L7.0953 2.63263C7.21262 2.23179 7.78108 2.23362 7.89582 2.63521L12.0947 17.3311C12.2101 17.7354 12.7836 17.7336 12.8966 17.3287L14.6599 11.0102C14.861 10.2898 15.5173 9.79153 16.2652 9.79153H18.5417"
        stroke={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, 0.26)"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DatabaseStatus;
