import React from 'react';
import {useTranslation} from "react-i18next";
import { Divider, Spin } from 'antd';
import ButtonComponent from "../../../Components/Button";
import Modal from '../../../Components/Modal';

const FreePlanErrorModal = (
  {
    setModal,
    visible,
    isMobile,
  }
) => {

  const { t } = useTranslation();

  return (
    <Modal handleClose={() => setModal(null)}
           title={t('Seven-day waiting period')}
           isMobile={isMobile}
           width={450}
           className="collection-options-modal collection-modal delete-tracking-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={false}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {t('The Free plan requires a seven-day waiting period before a store/product can be removed from tracking.')}
          </p>
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '16px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className={'collection-modal-btn collection-modal-btn-save wide'}
                           text={t('Close')}
                           onClick={() => setModal(null)}
          />
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

export default FreePlanErrorModal;
