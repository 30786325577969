import React from 'react';
import { useSelector } from 'react-redux';

const ShopifyStoreIcon = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.7083 16.875V13.125C12.7083 12.2045 11.9621 11.4583 11.0417 11.4583H8.95833C8.03786 11.4583 7.29167 12.2045 7.29167 13.125V16.875M6.5625 3.125V6M6.5625 6C6.5625 6.94924 5.79299 7.725 4.84375 7.725C3.89451 7.725 3.125 6.94924 3.125 6M6.5625 6C6.5625 6.94924 7.33201 7.725 8.28125 7.725C9.23049 7.725 10 6.94924 10 6M10 3.125V6M10 6C10 6.94924 10.7695 7.725 11.7187 7.725C12.668 7.725 13.4375 6.94924 13.4375 6M13.4375 3.125V6M13.4375 6C13.4375 6.94924 14.207 7.725 15.1562 7.725C16.1055 7.725 16.875 6.94924 16.875 6M3.125 4.79167V15.2083C3.125 16.1288 3.87119 16.875 4.79167 16.875H15.2083C16.1288 16.875 16.875 16.1288 16.875 15.2083V4.79167C16.875 3.87119 16.1288 3.125 15.2083 3.125H4.79167C3.87119 3.125 3.125 3.87119 3.125 4.79167Z" stroke={theme === 'light' ? "#225AEA" : "rgba(255, 255, 255, 0.36)"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ShopifyStoreIcon;
