import React from 'react';
import { useSelector } from 'react-redux';

const TopProduct = (
  {
    outline = false
  }) => {

  const theme = useSelector(state => state.nav.theme);

  return outline ?
    (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path
          d="M11.9987 5.33398V10.0007C11.9987 11.1052 12.8941 12.0007 13.9987 12.0007H17.9987C19.1033 12.0007 19.9987 11.1052 19.9987 10.0007V5.33398M18.6654 21.334H21.332M5.33203 8.00065V24.0006C5.33203 25.4734 6.52594 26.6673 7.9987 26.6673H23.9987C25.4715 26.6673 26.6654 25.4734 26.6654 24.0006V8.00065C26.6654 6.52789 25.4715 5.33398 23.9987 5.33398H7.9987C6.52594 5.33398 5.33203 6.52789 5.33203 8.00065Z"
          stroke="#E2E6F3" strokeWidth="2" strokeLinecap="round" />
      </svg>
    )
    :
    (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M7.08464 2.91602V6.66602C7.08464 7.35637 7.64428 7.91602 8.33464 7.91602H11.668C12.3583 7.91602 12.918 7.35637 12.918 6.66602V2.91602M11.668 13.7493H13.7513M2.91797 4.58268V15.416C2.91797 16.3365 3.66416 17.0827 4.58464 17.0827H15.418C16.3384 17.0827 17.0846 16.3365 17.0846 15.416V4.58268C17.0846 3.66221 16.3384 2.91602 15.418 2.91602H4.58464C3.66416 2.91602 2.91797 3.66221 2.91797 4.58268Z"
          stroke={theme === 'light' ? '#E2E6F3' : 'rgba(255, 255, 255, 0.12)'}
          strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
};

export default TopProduct;
