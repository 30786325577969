import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getAdSpotFiltersRequest: null,
  getAdSpotFiltersSuccess: ['payload'],
  getAdSpotFiltersFailure: ['payload'],

  getAdSpotPresetsRequest: ['payload'],
  getAdSpotPresetsSuccess: ['payload'],
  getAdSpotPresetsFailure: ['payload'],

  createAdSpotPresetRequest: ['payload'],
  createAdSpotPresetSuccess: ['payload'],
  createAdSpotPresetFailure: ['payload'],

  deleteAdSpotPresetRequest: ['payload'],
  deleteAdSpotPresetSuccess: ['payload'],
  deleteAdSpotPresetFailure: ['payload'],

  cancelDeleteAdSpotPresetRequest: ['payload'],
  cancelDeleteAdSpotPresetSuccess: ['payload'],
  cancelDeleteAdSpotPresetFailure: ['payload'],

  updateAdSpotPresetRequest: ['payload'],
  updateAdSpotPresetSuccess: ['payload'],
  updateAdSpotPresetFailure: ['payload'],

  getAdsRequest: ['payload'],
  getAdsSuccess: ['payload'],
  getAdsFailure: ['payload'],

  getAdsByIDRequest: ['payload'],
  getAdsByIDSuccess: ['payload'],
  getAdsByIDFailure: ['payload'],

  getSuggestionsRequest: ['payload'],
  getSuggestionsSuccess: ['payload'],
  getSuggestionsFailure: ['payload'],

  getOverviewMainInfoRequest: ['payload'],
  getOverviewMainInfoSuccess: ['payload'],
  getOverviewMainInfoFailure: ['payload'],

  getOverviewCardInfoRequest: ['payload'],
  getOverviewCardInfoSuccess: ['payload'],
  getOverviewCardInfoFailure: ['payload'],

  getOverviewChartInfoRequest: ['payload'],
  getOverviewChartInfoSuccess: ['payload'],
  getOverviewChartInfoFailure: ['payload'],

  getOverviewReportRequest: ['payload'],
  getOverviewReportSuccess: ['payload'],
  getOverviewReportFailure: ['payload'],

  getOverviewSimilarRequest: ['payload'],
  getOverviewSimilarSuccess: ['payload'],
  getOverviewSimilarFailure: ['payload'],

  getOverviewStoreProductsModalRequest: ['payload'],
  getOverviewStoreProductsModalSuccess: ['payload'],
  getOverviewStoreProductsModalFailure: ['payload'],

  getOverviewStoreProductsModalNextRequest: ['payload'],
  getOverviewStoreProductsModalNextSuccess: ['payload'],
  getOverviewStoreProductsModalNextFailure: ['payload'],

  getProductsByStoreIdAdSpotRequest: ['payload'],
  getProductsByStoreIdAdSpotSuccess: ['payload'],
  getProductsByStoreIdAdSpotFailure: ['payload'],

  getProductsCountByStoreIdAdSpotRequest: ['payload'],
  getProductsCountByStoreIdAdSpotSuccess: ['payload'],
  getProductsCountByStoreIdAdSpotFailure: ['payload'],

  getProductsFiltersByStoreIdAdSpotRequest: ['payload'],
  getProductsFiltersByStoreIdAdSpotSuccess: ['payload'],
  getProductsFiltersByStoreIdAdSpotFailure: ['payload'],

  getProductsReportAdSpotRequest: ['payload'],
  getProductsReportAdSpotSuccess: ['payload'],
  getProductsReportAdSpotFailure: ['payload'],

  getProductInfoAdSpotRequest: ['payload'],
  getProductInfoAdSpotSuccess: ['payload'],
  getProductInfoAdSpotFailure: ['payload'],

  getProductChartAdSpotRequest: ['payload'],
  getProductChartAdSpotSuccess: ['payload'],
  getProductChartAdSpotFailure: ['payload'],

  getProductReportAdSpotRequest: ['payload'],
  getProductReportAdSpotSuccess: ['payload'],
  getProductReportAdSpotFailure: ['payload'],

  getProductAdsAdSpotRequest: ['payload'],
  getProductAdsAdSpotSuccess: ['payload'],
  getProductAdsAdSpotFailure: ['payload'],

  resetProductsFiltersAdSpot: null,

  resetOverviewStoreProductsModal: null,

  resetSuggestions: null,
  resetState: null,

  resetCreateResult: null,
  resetDeleteResult: null,
  resetCreateError: null,
  setSelectedFilters: ['payload'],
  setSelectedSort: ['payload'],
  setSelectedQuickPreset: ['payload'],
  setDefaultSearch: ['payload'],
  setInnerPagesDateFilters: ['payload'],
  setInnerPagesDateCheckedList: ['payload'],
});

export const AdSpotTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  loading: false,
  filtersLoading: false,
  selectedAdvLoading: false,
  adsLoading: false,
  suggestionsLoading: false,
  errors: false,
  suggestionsErrors: false,
  createError: false,
  createResult: false,
  deleteResult: false,
  filters: {},
  selectedFilters: {},
  selectedQuickPreset: {},
  selectedSort: 'most_recent',
  defaultSearch: true,
  presets: {},
  ads: {},
  selectedAdv: {},
  suggestions: [],
  creditsLeft: null,
  innerPagesInfo: {
    dateFilters: {
      report_period: {min: null, max: null}
    },
    checkedDateFilters: null,
    overview: {
      mainInfo: {
        mainInfoLoading: true,
      },
      cardInfo: {
        cardInfoLoading: true
      },
      chartInfo: {
        chartInfoLoading: true
      },
      similarInfo: {
        similarInfoLoading: true
      },
      storeProducts: {
        storeProductsInfoLoading: true
      },
      reportLoading: false,
      reportErrors: null,
    },
    products: {
      loading: true,
      count: 0,
      countLoading: true,
      filters: {},
      filtersLoading: false,
      reportLoading: false
    },
    product: {
      productInfo: {
        productInfoLoading: true,
      },
      chartInfo: {
        chartInfoLoading: true
      },
      adsInfo: {
        adsInfoLoading: true
      },
      reportLoading: false
    }
  }
});
const resetState = (state) => state.merge({...INITIAL_STATE});
const setInnerPagesDateFilters = (state, { payload: filters }) => state.merge({ innerPagesInfo: {
    ...state?.innerPagesInfo,
    dateFilters: filters
  }});
const setInnerPagesDateCheckedList = (state, { payload: checkedList }) => state.merge({ innerPagesInfo: {
    ...state?.innerPagesInfo,
    checkedDateFilters: checkedList
  }});

const resetSuggestions = (state) =>
  state.merge({ suggestions: [] });

const resetProductsFiltersAdSpot = (state) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      products: {
        ...state?.innerPagesInfo?.products,
        results: [],
        filters: {}
      }
    }});

const getProductReportAdSpotRequest = (state) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      product: {
        ...state?.innerPagesInfo?.product,
        reportLoading: true
      }
    }});

const getProductReportAdSpotSuccess = (state, { payload: data }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      product: {
        ...state?.innerPagesInfo?.product,
        reportLoading: false
      }
    }});

const getProductReportAdSpotFailure = (state, { payload: errors }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      product: {
        ...state?.innerPagesInfo?.product,
        reportLoading: false,
        errors
      }
    }});

const getProductAdsAdSpotRequest = (state) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      product: {
        ...state?.innerPagesInfo?.product,
        adsInfo: {
          ...state?.innerPagesInfo?.product?.adsInfo,
          adsInfoLoading: true
        }
      }
    }});

const getProductAdsAdSpotSuccess = (state, { payload: data }) => {
  const {pageNumber, ...products} = data;
  if (pageNumber === 1) {
    return state.merge({innerPagesInfo: {
        ...state?.innerPagesInfo,
        product: {
          ...state?.innerPagesInfo?.product,
          adsInfo: {
            ...products,
            adsInfoLoading: false
          }
        }
      }})
  } else {
    return state.merge({innerPagesInfo: {
        ...state?.innerPagesInfo,
        product: {
          ...state?.innerPagesInfo?.product,
          adsInfo: {
            ...state?.innerPagesInfo?.product?.adsInfo,
            ...products,
            results: [...state?.innerPagesInfo?.product?.adsInfo?.results, ...products?.results],
            adsInfoLoading: false
          }
        }
      }})
  }
}

const getProductAdsAdSpotFailure = (state, { payload: errors }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      product: {
        ...state?.innerPagesInfo?.product,
        adsInfo: {
          errors,
          adsInfoLoading: false
        }
      }
    }});

const getProductChartAdSpotRequest = (state) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      product: {
        ...state?.innerPagesInfo?.product,
        chartInfo: {
          ...state?.innerPagesInfo?.product?.chartInfo,
          chartInfoLoading: true
        }
      }
    }});

const getProductChartAdSpotSuccess = (state, { payload: data }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      product: {
        ...state?.innerPagesInfo?.product,
        chartInfo: {
          ...data,
          chartInfoLoading: false
        }
      }
    }});

const getProductChartAdSpotFailure = (state, { payload: errors }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      product: {
        ...state?.innerPagesInfo?.product,
        chartInfo: {
          errors,
          chartInfoLoading: false
        }
      }
    }});

const getProductInfoAdSpotRequest = (state) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      product: {
        ...state?.innerPagesInfo?.product,
        productInfo: {
          ...state?.innerPagesInfo?.product?.productInfo,
          productInfoLoading: true
        }
      }
    }});

const getProductInfoAdSpotSuccess = (state, { payload: data }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      product: {
        ...state?.innerPagesInfo?.product,
        productInfo: {
          ...data,
          productInfoLoading: false
        }
      }
    }});

const getProductInfoAdSpotFailure = (state, { payload: errors }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      product: {
        ...state?.innerPagesInfo?.product,
        productInfo: {
          errors,
          productInfoLoading: false
        }
      }
    }});

const getProductsReportAdSpotRequest = (state) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      products: {
        ...state?.innerPagesInfo?.products,
        reportLoading: true
      }
    }});

const getProductsReportAdSpotSuccess = (state, { payload: data }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      products: {
        ...state?.innerPagesInfo?.products,
        reportLoading: false
      }
    }});

const getProductsReportAdSpotFailure = (state, { payload: errors }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      products: {
        ...state?.innerPagesInfo?.products,
        reportLoading: false,
        errors
      }
    }});

const getProductsFiltersByStoreIdAdSpotRequest = (state) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      products: {
        ...state?.innerPagesInfo?.products,
        filtersLoading: true
      }
    }});

const getProductsFiltersByStoreIdAdSpotSuccess = (state, { payload: data }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      products: {
        ...state?.innerPagesInfo?.products,
        filtersLoading: false,
        filters: data
      }
    }});

const getProductsFiltersByStoreIdAdSpotFailure = (state, { payload: errors }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      products: {
        ...state?.innerPagesInfo?.products,
        filtersLoading: false,
        errors
      }
    }});

const getProductsCountByStoreIdAdSpotRequest = (state) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      products: {
        ...state?.innerPagesInfo?.products,
        countLoading: true
      }
    }});

const getProductsCountByStoreIdAdSpotSuccess = (state, { payload: data }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      products: {
        ...state?.innerPagesInfo?.products,
        countLoading: false,
        count: data?.count
      }
    }});

const getProductsCountByStoreIdAdSpotFailure = (state, { payload: errors }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      products: {
        ...state?.innerPagesInfo?.products,
        countLoading: false,
        errors
      }
    }});

const getProductsByStoreIdAdSpotRequest = (state) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      products: {
        ...state?.innerPagesInfo?.products,
        loading: true
      }
    }});

const getProductsByStoreIdAdSpotSuccess = (state, { payload: data }) => {
  const {pageNumber, ...products} = data;
  if (pageNumber === 1) {
    return state.merge({innerPagesInfo: {
        ...state?.innerPagesInfo,
        products: {
          ...state?.innerPagesInfo?.products,
          loading: false,
          ...products
        }
      }})
  } else {
    return state.merge({innerPagesInfo: {
        ...state?.innerPagesInfo,
        products: {
          ...state?.innerPagesInfo?.products,
          loading: false,
          results: [...state?.innerPagesInfo?.products?.results, ...products?.results]
        }
      }})
  }
}

const getProductsByStoreIdAdSpotFailure = (state, { payload: errors }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      products: {
        ...state?.innerPagesInfo?.products,
        loading: false,
        errors
      }
    }});

const getOverviewSimilarRequest = (state) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        similarInfo: {
          ...state?.innerPagesInfo?.overview?.similarInfo,
          similarInfoLoading: true
        }
      }
    }});

const getOverviewSimilarSuccess = (state, { payload: data }) => {
  const {pageNumber, ...products} = data
  if (pageNumber === 1) return state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        similarInfo: {
          similarInfoLoading: false,
          ...products
        }
      }
    }})
  else return state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        similarInfo: {
          similarInfoLoading: false,
          ...state?.innerPagesInfo?.overview?.similarInfo,
          ...products,
          results: [...state?.innerPagesInfo?.overview?.similarInfo?.results, ...products?.results]
        }
      }
    }})
}

const getOverviewSimilarFailure = (state, { payload: errors }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        similarInfo: {
          similarInfoLoading: false,
          errors
        }
      }
    }});

const getOverviewReportRequest = (state) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        reportLoading: true
      }
    }});

const getOverviewReportSuccess = (state, { payload: data }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        reportLoading: false
      }
    }});

const getOverviewReportFailure = (state, { payload: errors }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        reportLoading: false,
        reportErrors: errors
      }
    }});

const getOverviewChartInfoRequest = (state) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        chartInfo: {
          ...state?.innerPagesInfo?.overview?.chartInfo,
          chartInfoLoading: true
        }
      }
    }});

const getOverviewChartInfoSuccess = (state, { payload: data }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        chartInfo: {
          chartInfoLoading: false,
          ...data
        }
      }
    }});

const getOverviewChartInfoFailure = (state, { payload: errors }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        chartInfo: {
          chartInfoLoading: false,
          errors
        }
      }
    }});

const getOverviewCardInfoRequest = (state) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        cardInfo: {
          ...state?.innerPagesInfo?.overview?.cardInfo,
          cardInfoLoading: true
        }
      }
    }});

const getOverviewCardInfoSuccess = (state, { payload: data }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        cardInfo: {
          cardInfoLoading: false,
          ...data
        }
      }
    }});

const getOverviewCardInfoFailure = (state, { payload: errors }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        cardInfo: {
          cardInfoLoading: false,
          errors
        }
      }
    }});

const getOverviewMainInfoRequest = (state) =>
  state.merge({innerPagesInfo: {
    ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        mainInfo: {
          ...state?.innerPagesInfo?.overview?.mainInfo,
          mainInfoLoading: true
        }
      }
    }});

const getOverviewMainInfoSuccess = (state, { payload: data }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        mainInfo: {
          mainInfoLoading: false,
          ...data
        }
      }
    }});

const getOverviewMainInfoFailure = (state, { payload: errors }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        mainInfo: {
          mainInfoLoading: false,
          errors
        }
      }
    }});

const getOverviewStoreProductsModalRequest = (state) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        storeProducts: {
          storeProductsInfoLoading: true
        }
      }
    }});

const getOverviewStoreProductsModalSuccess = (state, { payload: data }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        storeProducts: {
          storeProductsInfoLoading: false,
          ...data
        }
      }
    }});

const getOverviewStoreProductsModalFailure = (state, { payload: { errors } }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        storeProducts: {
          storeProductsInfoLoading: false,
          errors
        }
      }
    }});

const getOverviewStoreProductsModalNextRequest = (state) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        storeProducts: {
          ...state?.innerPagesInfo?.overview?.storeProducts,
          storeProductsInfoLoading: true
        }
      }
    }});

const getOverviewStoreProductsModalNextSuccess = (state, { payload: data }) => {
  const prev = state?.innerPagesInfo?.overview?.storeProducts?.results?.length ? state?.innerPagesInfo?.overview?.storeProducts?.results : [];
  return state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        storeProducts: {
          ...state?.innerPagesInfo?.overview?.storeProducts,
          storeProductsInfoLoading: false,
          count: data?.count,
          results: [...prev, ...data?.results]
        }
      }
    }});
}

const getOverviewStoreProductsModalNextFailure = (state, { payload: { errors } }) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        storeProducts: {
          storeProductsInfoLoading: false,
          errors
        }
      }
    }});

const resetOverviewStoreProductsModal= (state) =>
  state.merge({innerPagesInfo: {
      ...state?.innerPagesInfo,
      overview: {
        ...state?.innerPagesInfo?.overview,
        storeProducts: {
          storeProductsInfoLoading: false
        }
      }
    }});

const getSuggestionsRequest = (state) =>
  state.merge({ suggestionsLoading: true });

const getSuggestionsSuccess = (state, { payload: data }) =>
  state.merge({ suggestionsLoading: false, suggestions: data });

const getSuggestionsFailure = (state, { payload: { errors } }) =>
  state.merge({ suggestionsLoading: false, suggestionsErrors: errors })

const setDefaultSearch = (state, {payload: data}) =>
  state.merge({ defaultSearch: data })

const setSelectedFilters = (state, {payload: data}) =>
  state.merge({ selectedFilters: data })
const setSelectedQuickPreset = (state, {payload: data}) =>
  state.merge({ selectedQuickPreset: data })

const setSelectedSort = (state, {payload: data}) =>
  state.merge({ selectedSort: data })

const getAdSpotFiltersRequest = (state) =>
  state.merge({ filtersLoading: true });

const getAdSpotFiltersSuccess = (state, { payload: data }) =>
  state.merge({ filtersLoading: false, filters: data });

const getAdSpotFiltersFailure = (state, { payload: { errors } }) =>
  state.merge({ filtersLoading: false, errors })


const getAdSpotPresetsRequest = (state) =>
  state.merge({ loading: true });

const getAdSpotPresetsSuccess = (state, { payload: data }) => {
  const {credits_left, ...presets} = data
  return  state.merge({ loading: false, creditsLeft: credits_left,
    presets: {...presets}});
}

const getAdSpotPresetsFailure = (state, { payload: { errors } }) =>
  state.merge({ loading: false, errors });

const createAdSpotPresetRequest = (state) =>
  state.merge({ loading: true, createError: false, createResult: false });

const createAdSpotPresetSuccess = (state, { payload: data }) => {
  const newUserPresets = [data, ...state.presets?.users]
  return state.merge({loading: false, presets: {...state.presets, users: newUserPresets}, createError: false, createResult: true });
}
const createAdSpotPresetFailure = (state, { payload: { errors } }) =>
  state.merge({ loading: false, createError: errors, createResult: false });


const deleteAdSpotPresetRequest = (state) =>
  state.merge({ loading: true, deleteResult: false });

const deleteAdSpotPresetSuccess = (state, { payload: id }) => {
  const deletePreset = [...state.presets?.users.filter(el => el.id === id)]
  const newUserPresets = [...state.presets?.users.filter(el => el.id !== id)]
  return state.merge({loading: false, presets: {...state.presets, users: newUserPresets}, deleteResult: deletePreset[0]});
}
const deleteAdSpotPresetFailure = (state, { payload: { errors } }) =>
  state.merge({ loading: false, errors, deleteResult: false });

const cancelDeleteAdSpotPresetRequest = (state) =>
  state.merge({ loading: true });

const cancelDeleteAdSpotPresetSuccess = (state, { payload: record }) => {
  const newUserPresets = [...state.presets?.users, record]
  return state.merge({loading: false, presets: {...state.presets, users: newUserPresets}, deleteResult: false });
}
const cancelDeleteAdSpotPresetFailure = (state, { payload: { errors } }) =>
  state.merge({ loading: false, errors });

const updateAdSpotPresetRequest = (state) =>
  state.merge({ loading: true });

const updateAdSpotPresetSuccess = (state, { payload: data }) => {
  const newUserPresets = [...state.presets?.users.map(el => {
    if (el.id === data.id) return data
    else return el
  })]
  return state.merge({loading: false, presets: {...state.presets, users: newUserPresets} });
}
const updateAdSpotPresetFailure = (state, { payload: { errors } }) =>
  state.merge({ loading: false, errors });

const getAdsRequest = (state) =>
  state.merge({ adsLoading: true });

const getAdsSuccess = (state, { payload: data }) => {
  const {credits_left, isDefault, pageNumber, ...products} = data
  if (isDefault) {
    if (pageNumber === 1) {
      return state.merge({ adsLoading: false, ads: { ...products } });
    } else {
      return state.merge({ adsLoading: false, ads: {
        ...state?.ads,
          ...products,
          results: [...state?.ads?.results, ...products?.results]
      }});
    }
  } else {
    if (pageNumber === 1) {
      return state.merge({ adsLoading: false, creditsLeft: credits_left, ads: { ...products } });
    } else {
      return state.merge({ adsLoading: false, creditsLeft: credits_left,
        ads: {
          ...state?.ads,
          ...products,
          results: [...state?.ads?.results, ...products?.results]
        }});
    }
  }
}

const getAdsFailure = (state, { payload: { errors } }) =>
  state.merge({ adsLoading: false, errors, products: {} });

const getAdsByIDRequest = (state) =>
  state.merge({ selectedAdvLoading: true });

const getAdsByIDSuccess = (state, { payload: data }) => {
  const {pageNumber, credits_left, ...others} = data;
  if (pageNumber === 1) return state.merge({selectedAdvLoading: false, creditsLeft: credits_left, selectedAdv: {...state?.selectedAdv, ...others}});
  else {
    return state?.merge({
      selectedAdvLoading: false,
      creditsLeft: credits_left,
      selectedAdv: {
        ...state?.selectedAdv,
        ...others,
        results: [
          ...state?.selectedAdv?.results,
          ...others.results
        ]
      }
    })
  }
}

const getAdsByIDFailure = (state, { payload: { errors } }) =>
  state.merge({ selectedAdvLoading: false, errors, selectedAdv: {} });

const resetCreateResult = (state) =>
  state.merge({ createResult: false });

const resetCreateError = (state) =>
  state.merge({ createError: false  });

const resetDeleteResult = (state) =>
  state.merge({ deleteResult: false });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PRODUCT_INFO_AD_SPOT_REQUEST]: getProductInfoAdSpotRequest,
  [Types.GET_PRODUCT_INFO_AD_SPOT_SUCCESS]: getProductInfoAdSpotSuccess,
  [Types.GET_PRODUCT_INFO_AD_SPOT_FAILURE]: getProductInfoAdSpotFailure,

  [Types.GET_PRODUCT_CHART_AD_SPOT_REQUEST]: getProductChartAdSpotRequest,
  [Types.GET_PRODUCT_CHART_AD_SPOT_SUCCESS]: getProductChartAdSpotSuccess,
  [Types.GET_PRODUCT_CHART_AD_SPOT_FAILURE]: getProductChartAdSpotFailure,

  [Types.GET_PRODUCT_REPORT_AD_SPOT_REQUEST]: getProductReportAdSpotRequest,
  [Types.GET_PRODUCT_REPORT_AD_SPOT_SUCCESS]: getProductReportAdSpotSuccess,
  [Types.GET_PRODUCT_REPORT_AD_SPOT_FAILURE]: getProductReportAdSpotFailure,

  [Types.GET_PRODUCT_ADS_AD_SPOT_REQUEST]: getProductAdsAdSpotRequest,
  [Types.GET_PRODUCT_ADS_AD_SPOT_SUCCESS]: getProductAdsAdSpotSuccess,
  [Types.GET_PRODUCT_ADS_AD_SPOT_FAILURE]: getProductAdsAdSpotFailure,

  [Types.GET_PRODUCTS_REPORT_AD_SPOT_REQUEST]: getProductsReportAdSpotRequest,
  [Types.GET_PRODUCTS_REPORT_AD_SPOT_SUCCESS]: getProductsReportAdSpotSuccess,
  [Types.GET_PRODUCTS_REPORT_AD_SPOT_FAILURE]: getProductsReportAdSpotFailure,

  [Types.GET_PRODUCTS_FILTERS_BY_STORE_ID_AD_SPOT_REQUEST]: getProductsFiltersByStoreIdAdSpotRequest,
  [Types.GET_PRODUCTS_FILTERS_BY_STORE_ID_AD_SPOT_SUCCESS]: getProductsFiltersByStoreIdAdSpotSuccess,
  [Types.GET_PRODUCTS_FILTERS_BY_STORE_ID_AD_SPOT_FAILURE]: getProductsFiltersByStoreIdAdSpotFailure,

  [Types.GET_PRODUCTS_COUNT_BY_STORE_ID_AD_SPOT_REQUEST]: getProductsCountByStoreIdAdSpotRequest,
  [Types.GET_PRODUCTS_COUNT_BY_STORE_ID_AD_SPOT_SUCCESS]: getProductsCountByStoreIdAdSpotSuccess,
  [Types.GET_PRODUCTS_COUNT_BY_STORE_ID_AD_SPOT_FAILURE]: getProductsCountByStoreIdAdSpotFailure,

  [Types.GET_PRODUCTS_BY_STORE_ID_AD_SPOT_REQUEST]: getProductsByStoreIdAdSpotRequest,
  [Types.GET_PRODUCTS_BY_STORE_ID_AD_SPOT_SUCCESS]: getProductsByStoreIdAdSpotSuccess,
  [Types.GET_PRODUCTS_BY_STORE_ID_AD_SPOT_FAILURE]: getProductsByStoreIdAdSpotFailure,

  [Types.GET_OVERVIEW_SIMILAR_REQUEST]: getOverviewSimilarRequest,
  [Types.GET_OVERVIEW_SIMILAR_SUCCESS]: getOverviewSimilarSuccess,
  [Types.GET_OVERVIEW_SIMILAR_FAILURE]: getOverviewSimilarFailure,

  [Types.GET_OVERVIEW_REPORT_REQUEST]: getOverviewReportRequest,
  [Types.GET_OVERVIEW_REPORT_SUCCESS]: getOverviewReportSuccess,
  [Types.GET_OVERVIEW_REPORT_FAILURE]: getOverviewReportFailure,

  [Types.GET_OVERVIEW_CHART_INFO_REQUEST]: getOverviewChartInfoRequest,
  [Types.GET_OVERVIEW_CHART_INFO_SUCCESS]: getOverviewChartInfoSuccess,
  [Types.GET_OVERVIEW_CHART_INFO_FAILURE]: getOverviewChartInfoFailure,

  [Types.GET_OVERVIEW_MAIN_INFO_REQUEST]: getOverviewMainInfoRequest,
  [Types.GET_OVERVIEW_MAIN_INFO_SUCCESS]: getOverviewMainInfoSuccess,
  [Types.GET_OVERVIEW_MAIN_INFO_FAILURE]: getOverviewMainInfoFailure,

  [Types.GET_OVERVIEW_CARD_INFO_REQUEST]: getOverviewCardInfoRequest,
  [Types.GET_OVERVIEW_CARD_INFO_SUCCESS]: getOverviewCardInfoSuccess,
  [Types.GET_OVERVIEW_CARD_INFO_FAILURE]: getOverviewCardInfoFailure,

  [Types.GET_OVERVIEW_STORE_PRODUCTS_MODAL_REQUEST]: getOverviewStoreProductsModalRequest,
  [Types.GET_OVERVIEW_STORE_PRODUCTS_MODAL_SUCCESS]: getOverviewStoreProductsModalSuccess,
  [Types.GET_OVERVIEW_STORE_PRODUCTS_MODAL_FAILURE]: getOverviewStoreProductsModalFailure,

  [Types.GET_OVERVIEW_STORE_PRODUCTS_MODAL_NEXT_REQUEST]: getOverviewStoreProductsModalNextRequest,
  [Types.GET_OVERVIEW_STORE_PRODUCTS_MODAL_NEXT_SUCCESS]: getOverviewStoreProductsModalNextSuccess,
  [Types.GET_OVERVIEW_STORE_PRODUCTS_MODAL_NEXT_FAILURE]: getOverviewStoreProductsModalNextFailure,

  [Types.RESET_OVERVIEW_STORE_PRODUCTS_MODAL]: resetOverviewStoreProductsModal,

  [Types.GET_AD_SPOT_FILTERS_REQUEST]: getAdSpotFiltersRequest,
  [Types.GET_AD_SPOT_FILTERS_SUCCESS]: getAdSpotFiltersSuccess,
  [Types.GET_AD_SPOT_FILTERS_FAILURE]: getAdSpotFiltersFailure,

  [Types.GET_AD_SPOT_PRESETS_REQUEST]: getAdSpotPresetsRequest,
  [Types.GET_AD_SPOT_PRESETS_SUCCESS]: getAdSpotPresetsSuccess,
  [Types.GET_AD_SPOT_PRESETS_FAILURE]: getAdSpotPresetsFailure,

  [Types.CREATE_AD_SPOT_PRESET_REQUEST]: createAdSpotPresetRequest,
  [Types.CREATE_AD_SPOT_PRESET_SUCCESS]: createAdSpotPresetSuccess,
  [Types.CREATE_AD_SPOT_PRESET_FAILURE]: createAdSpotPresetFailure,

  [Types.DELETE_AD_SPOT_PRESET_REQUEST]: deleteAdSpotPresetRequest,
  [Types.DELETE_AD_SPOT_PRESET_SUCCESS]: deleteAdSpotPresetSuccess,
  [Types.DELETE_AD_SPOT_PRESET_FAILURE]: deleteAdSpotPresetFailure,

  [Types.CANCEL_DELETE_AD_SPOT_PRESET_REQUEST]: cancelDeleteAdSpotPresetRequest,
  [Types.CANCEL_DELETE_AD_SPOT_PRESET_SUCCESS]: cancelDeleteAdSpotPresetSuccess,
  [Types.CANCEL_DELETE_AD_SPOT_PRESET_FAILURE]: cancelDeleteAdSpotPresetFailure,

  [Types.UPDATE_AD_SPOT_PRESET_REQUEST]: updateAdSpotPresetRequest,
  [Types.UPDATE_AD_SPOT_PRESET_SUCCESS]: updateAdSpotPresetSuccess,
  [Types.UPDATE_AD_SPOT_PRESET_FAILURE]: updateAdSpotPresetFailure,

  [Types.GET_ADS_REQUEST]: getAdsRequest,
  [Types.GET_ADS_SUCCESS]: getAdsSuccess,
  [Types.GET_ADS_FAILURE]: getAdsFailure,

  [Types.GET_ADS_BY_ID_REQUEST]: getAdsByIDRequest,
  [Types.GET_ADS_BY_ID_SUCCESS]: getAdsByIDSuccess,
  [Types.GET_ADS_BY_ID_FAILURE]: getAdsByIDFailure,

  [Types.GET_SUGGESTIONS_REQUEST]: getSuggestionsRequest,
  [Types.GET_SUGGESTIONS_SUCCESS]: getSuggestionsSuccess,
  [Types.GET_SUGGESTIONS_FAILURE]: getSuggestionsFailure,

  [Types.RESET_PRODUCTS_FILTERS_AD_SPOT]: resetProductsFiltersAdSpot,

  [Types.RESET_SUGGESTIONS]: resetSuggestions,

  [Types.RESET_CREATE_RESULT]: resetCreateResult,
  [Types.RESET_DELETE_RESULT]: resetDeleteResult,
  [Types.RESET_CREATE_ERROR]: resetCreateError,

  [Types.SET_SELECTED_FILTERS]: setSelectedFilters,
  [Types.SET_SELECTED_QUICK_PRESET]: setSelectedQuickPreset,
  [Types.SET_SELECTED_SORT]: setSelectedSort,
  [Types.SET_DEFAULT_SEARCH]: setDefaultSearch,

  [Types.SET_INNER_PAGES_DATE_FILTERS]: setInnerPagesDateFilters,
  [Types.SET_INNER_PAGES_DATE_CHECKED_LIST]: setInnerPagesDateCheckedList,
  [Types.RESET_STATE]: resetState,
});
