import React from 'react';
import ButtonComponent from '../../../Components/Button';
import './UserTrackingsEmpty.less';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Icon from '../../../Icon';

const UserTrackingsEmpty = (
  {
    view
  }
) => {

  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="user-trackings-empty">
      <div className="user-trackings-empty-icons">
        <div className="user-trackings-empty-icons-left">
          {
            [1, 2, 3].map(el => (
              <span className="user-trackings-empty-icon-wrapper"
                    key={el}
                    style={{ zIndex: el }}
              >
                <Icon type={`top_${view === 'stores' ? 'store' : 'product'}`} role={'icon'} outline={false} />
              </span>
            ))
          }
        </div>
        <span className="user-trackings-empty-icon-wrapper user-trackings-empty-icon-wrapper--main">
          <Icon type={`top_${view === 'stores' ? 'store' : 'product'}`} role={'icon'} outline={true} />
        </span>
        <div className="user-trackings-empty-icons-right">
          {
            [1, 2, 3].map(el => (
              <span className="user-trackings-empty-icon-wrapper"
                    key={el + 10}
                    style={{ zIndex: 3 - el }}
              >
                <Icon type={`top_${view === 'stores' ? 'store' : 'product'}`} role={'icon'} outline={false} />
              </span>
            ))
          }
        </div>
      </div>
      <span className="user-trackings-empty-title">
        {
          t(`You don’t have any tracked ${view} yet`)
        }
      </span>
      <p className="user-trackings-empty-text">
        {
          t(
            view === 'stores' ?
              'Stores you are starting to track will appear here, to start tracking stores - enter link to the store and click “Start Tracking” or explore list of the top stores.'
              :
              'Products you start tracking will appear here. To start tracking products - paste a link to the product and click “Start Tracking” or explore list of the top products.'
          )
        }
      </p>
      <ButtonComponent className={'user-trackings-empty-btn'}
                       onClick={() => navigate(`/sales-tracker/top/${view}`)}
                       text={t(`Explore top ${view}`)}
      />
    </div>
  );
};

export default UserTrackingsEmpty;
