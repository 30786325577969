import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import openNotification, { openNotificationWithIcon } from '../../Components/Notification';
import ShopifyStoreCreators from '../ShopifyStore/reducer';
import AdLibraryCreators from './reducer';
import acc from 'accounting';
import { DefaultMsgSaga } from '../../Components/Notification/notification-message';
import TopPageHeader from '../SalesTrackerPage/pages/components/TopPageHeader';
import PageOptions from './components/PageOptions';
import QuickSearch from './components/QuickSearch';
import FiltersBlock from './components/FiltersBlock';
import LoadPresetModal from '../ShopifySearch/components/LoadPresetModal';
import LoadPresetOptionsModal from '../ShopifySearch/components/LoadPresetOptionsModal';
import DeletePresetModal from '../ShopifySearch/components/DeletePresetModal';
import RenamePresetModal from '../ShopifySearch/components/RenamePresetModal';
import SaveFilterPresetModal from '../ShopifySearch/components/SaveFilterPresetModal';
import VideoTutorialModal from '../ShopifySearch/components/VideoTutorialModal';
import AdLibraryItemActionModal from './components/AdLibraryItemActionModal';
import AdLibraryItemActiveAdsModal from './components/AdLibraryItemActiveAdsModal';
import AdLibraryItemAdSpendModal from './components/AdLibraryItemAdSpendModal';
import AdLibraryItem from './components/AdLibraryItem';
import AdLibraryItemSkeleton from './components/AdLibraryItemSkeleton';
import './styles.less';

const AdLibraryPage = (
  {
    isMobile,
    userInfo,
    shopifyLoading,
    getShopifyStores,
    shopifyStores,
    changeVisibleModalShopify,
    getFilters,
    adLibrary
  }) => {

  const {t} = useTranslation();
  const navigate = useNavigate();

  const {loading, adsLoading, filtersLoading, filters} = adLibrary;

  const skeleton = shopifyLoading || loading || adsLoading || filtersLoading;
  const credits = 500;
  const initialUrl = document?.location?.pathname + (document?.location?.search || '');
  const isYearly = userInfo?.subscriptions?.[0]?.plan?.price_type?.id !== 'monthly';

  const [isChanged, setIsChanged] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [loadedPreset, setLoadedPreset] = useState(null);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);

  const [modal, setModal] = useState(null);
  const [dataToCalc, setDataToCalc] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [disableShowFilters, setDisableShowFilters] = useState(false);
  const [inputText, setInputText] = useState('');

  useEffect(() => {
    document.title = `Ad Library - Dropship`;
    getShopifyStores();
    getFilters();
  }, []);

  useEffect(() => {
    if (inputText) {
      setShowFilters(false);
      setDisableShowFilters(true);
    } else {
      setDisableShowFilters(false)
    }
  }, [inputText])

  const loadPreset = (data) => {
    if (credits === 0 || !Boolean(userInfo?.subscriptions?.[0])) {
      openNotification({
        message: t('You have reached your search limit, upgrade to unlock unlimited searches'),
        style: { minWidth: '716px' },
        type: 'error',
      });
    } else {
      let name = data.name;
      // Object.keys(data.value).forEach(val => {
      //   if (val === 'switchFilters') setSwitchFilters(state => ({ ...state, ...data.value[val] }));
      //   if (val === 'inputFilters') setInputFilters(state => ({ ...state, ...data.value[val] }));
      //   if (val === 'minMaxFilters') setMinMaxFilters(state => ({ ...state, ...data.value[val] }));
      //   if (val === 'dropdownFilters') setDropdownFilters(state => ({ ...state, ...data.value[val] }));
      // });
      openNotificationWithIcon({
        style: { width: '400px' },
        className: 'notification notification--save',
        message: (
          <DefaultMsgSaga text={null}
                          title={t('_name_ preset was applied', {name: name})}
                          icon="notification_success"
                          iconOutline={true}
                          withTranslate={true}
                          preset={name}
          />
        ),
      });
    }
  };

  const handleOpenModalShopifyConnect = (importProductId) => {
    if (shopifyStores.length) {
      changeVisibleModalShopify({ isVisibleModal: 'import', initialUrl, importProductId });
    } else {
      changeVisibleModalShopify({ isVisibleModal: 'connect', initialUrl, importProductId: null });
    }
  };

  return (
    <div className={'product-database-page adlibrary-page'}>
      <TopPageHeader icon={'ad_library_dashboard'}
                     title={'Ad Library'}
                     subtitle={'Search our library with millions of Facebook ads'}
                     takeTourHandler={() => window.Intercom('startTour', 433090)}
                     watchTutorialHandler={() => setModal('tutorial')}
                     increaseLimitsHandler={userInfo?.subscriptions?.[0]?.plan?.plan?.name === 'premium' ? null : () => navigate('/setting/plan') }
                     skeleton={skeleton}
                     isMobile={isMobile}
                     withCredits={true}
                     isYearly={isYearly}
      />
      {
        isMobile ?
          skeleton ?
            <div className="sales-tracker-top-page-credits">
            <span className="sales-tracker-top-page-credits-title">
              <span className={'link skeleton'}
                    style={{ width: 92, height: 20 }}
              />
            </span>
              <span className="sales-tracker-top-page-credits-value">
              <span className={'current'}>
                <span className={'link skeleton'}
                      style={{ width: 62, height: 20, display: 'block' }}
                />
              </span>
              <span className={'separator'}>
                /
              </span>
              <span className={'maximum'}>
                <span className={'link skeleton'}
                      style={{ width: 62, height: 20, display: 'block' }}
                />
              </span>
            </span>
            </div>
            :
            <div className="sales-tracker-top-page-credits">
            <span className="sales-tracker-top-page-credits-title">
              {
                t('Search credits')
              }
            </span>
              <span className="sales-tracker-top-page-credits-value">
              <span className={'current'}>
                {acc.formatNumber(5000, 0, ',')}
              </span>
              <span className={'separator'}>
                /
              </span>
              <span className={'maximum'}>
                {acc.formatNumber(5000, 0, ',')}
              </span>
            </span>
            </div>
          :
          null
      }
      <PageOptions setShowFilters={setShowFilters}
                   showFilters={showFilters}
                   disableShowFilters={disableShowFilters}
                   setModal={setModal}
                   isChanged={isChanged}
                   inputText={inputText}
                   setInputText={setInputText}
                   skeleton={skeleton}
                   isMobile={isMobile}
                   sortOrder={sortOrder}
                   setSortOrder={setSortOrder}
      />
      <QuickSearch disableShowFilters={disableShowFilters}
                   isMobile={isMobile}
                   skeleton={skeleton}
      />
      <FiltersBlock showFilters={showFilters}
                    isMobile={isMobile}
                    isChanged={isChanged}
                    setIsChanged={setIsChanged}
                    setInputText={setInputText}
                    disableShowFilters={disableShowFilters}
                    filters={filters}
      />

      <div className="adlibrary-page-items-wrapper">
        {
          skeleton ?
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(el => <AdLibraryItemSkeleton key={el} />)
            :
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(el => <AdLibraryItem key={el}
                                                                         id={el}
                                                                         setDataToCalc={setDataToCalc}
                                                                         handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                                                                         setModal={setModal} />)
        }
      </div>

      <LoadPresetModal visible={modal === 'load_preset'}
                       setModal={setModal}
                       setPageSize={setPageSize}
                       setPageNumber={setPageNumber}
                       loadPreset={loadPreset}
                       setLoadedPreset={setLoadedPreset}
                       setSortOrder={setSortOrder}
                       category={'competitors'}
                       loading={false}
                       isMobile={isMobile}
                       setRecordToDelete={setRecordToDelete}
      />
      <LoadPresetOptionsModal setModal={setModal}
                              isMobile={isMobile}
                              visible={modal === 'load_preset_options'}
                              selected={recordToDelete}
      />
      <DeletePresetModal setModal={setModal}
                         visible={modal === 'delete_preset'}
                         deleteFilter={null}
                         record={recordToDelete}
                         loading={false}
                         isMobile={isMobile}
      />
      <RenamePresetModal setModal={setModal}
                         visible={modal === 'rename_preset'}
                         record={recordToDelete}
                         isMobile={isMobile}
                         modal={modal}
                         updatePreset={null}
      />
      <SaveFilterPresetModal setModal={setModal}
                             isMobile={isMobile}
                             visible={modal === 'save_preset'}
                             saveFilterPreset={null}
                             filters={{}}
                             category={'competitors'}
                             loading={false}
      />
      <VideoTutorialModal visible={modal === 'tutorial'}
                          setModal={setModal}
                          isMobile={isMobile}
                          link={'https://www.youtube.com/watch?v=h-rOBAPwptI'}
      />
      <AdLibraryItemActionModal data={dataToCalc?.title}
                                setModal={setModal}
                                visible={modal === 'ad_item_action'}
                                handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
      />
      <AdLibraryItemActiveAdsModal data={dataToCalc?.title}
                                   setModal={setModal}
                                   visible={modal === 'ad_item_active_ads'}
                                   isMobile={isMobile}
      />
      <AdLibraryItemAdSpendModal data={dataToCalc?.title}
                                 setModal={setModal}
                                 visible={modal === 'ad_item_ad_spend'}
                                 isMobile={isMobile}

      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  userInfo: state.auth.userInfo,
  shopifyLoading: state.shopifyStore.loading,
  shopifyStores: state?.shopifyStore?.results,
  adLibrary: state?.adLibrary,
});

const mapDispatchToProps = (dispatch) => ({
  getShopifyStores: () => dispatch(ShopifyStoreCreators.getShopifyStoresRequest()),
  changeVisibleModalShopify: (data) => dispatch(ShopifyStoreCreators.changeVisibleModalShopify(data)),
  getFilters: () => dispatch(AdLibraryCreators.getAdLibraryFiltersRequest())
});

export default connect(mapStateToProps, mapDispatchToProps)(AdLibraryPage);
