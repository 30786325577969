import React from 'react';
import { useSelector } from 'react-redux';

const DatabaseRadar = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M6.57407 3.09294C4.03594 4.35428 2.29166 6.97345 2.29166 10C2.29166 14.2572 5.7428 17.7084 10 17.7084C14.2572 17.7084 17.7083 14.2572 17.7083 10C17.7083 5.95216 14.5883 2.63304 10.6218 2.31641M5.71759 8.03484C5.44266 8.63297 5.28935 9.29859 5.28935 10C5.28935 12.6016 7.39838 14.7107 10 14.7107C12.6016 14.7107 14.7106 12.6016 14.7106 10C14.7106 7.60921 12.9296 5.63439 10.6218 5.33005M8.28703 10C8.28703 10.9461 9.05395 11.713 10 11.713C10.946 11.713 11.713 10.9461 11.713 10C11.713 9.27333 11.2605 8.65233 10.6218 8.40342"
        stroke={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, 0.26)"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>
  );
};

export default DatabaseRadar;
