export const plansMocked = [
  {
    "header": "Basic",
    "features": [
      {
        "header": "Quick Look",
        "details": {
          "7-day-free-trial": {
            "value": true,
            "tooltip": "Try Dropship completely for free for 7-days. No risks. Cancel anytime."
          },
          "cancel-anytime": {
            "value": true,
            "tooltip": "Cancel your trial or active subscription at anytime."
          },
          "shopify-integration": {
            "value": true,
            "tooltip": "Connect one or multiple Shopify stores to Dropship to import products."
          },
          "eligible-for-additional-seats": {
            "value": "no",
            "tooltip": "What plan is eligible to add more seats in their Dropship account. Coming soon."
          }
        }
      },
      {
        "header": "Product Database",
        "details": {
          "searches": {
            "value": "20 searches / day",
            "tooltip": "How many searches you can make in Product Database."
          },
          "view-30-day-sales-data": {
            "value": true,
            "tooltip": "View the sales revenue of the last 30 days for every product to better see if the product you're searching for is in demand or not."
          },
          "view-facebook-ads": {
            "value": true,
            "tooltip": "View current active or inactive ads for any store or product to better understand how the store owner is driving traffic to their store."
          },
          "one-click-import-to-sales-tracker": {
            "value": true,
            "tooltip": "Add a store or product to Sales Tracker, directly from Product Database, to start tracking their sales revenue every day."
          },
          "one-click-competitor-research": {
            "value": true,
            "tooltip": "View all the competitors for a product in just one click through Competitor Research, directly from Product Database."
          },
          "one-click-import-to-shopify": {
            "value": "100 imports / month",
            "tooltip": "Import products in one click to your Shopify store. This will import the product title, images, description, price, variants, and more details."
          }
        }
      },
      {
        "header": "Sales Tracker",
        "details": {
          "store-tracker": {
            "value": "10 stores",
            "tooltip": "How many stores you can track in Sales Tracker."
          },
          "product-tracker": {
            "value": "20 product listings",
            "tooltip": "How many products you can track in Sales Tracker."
          },
          "view-historical-sales-data": {
            "value": true,
            "tooltip": "View the sales revenue of any date for a product to better see if the product you're searching for is in demand or not."
          },
          "view-total-sales": {
            "value": true,
            "tooltip": "See how many total sales a store or product has made in a specific timeframe."
          },
          "view-total-unique-products-sold": {
            "value": true,
            "tooltip": "See how many total unique products a store has sold in a specific timeframe."
          },
          "view-facebook-ads": {
            "value": true,
            "tooltip": "View current active or inactive ads for any store or product to better understand how the store owner is driving traffic to their store."
          },
          "download-offline-sales-report": {
            "value": true,
            "tooltip": "Download a sales revenue report of any store or product in a sheet. This way, you can still do product research even if you do not have a wifi connection."
          },
          "top-stores": {
            "value": true,
            "tooltip": "View a list of the top performing stores. The list updates every 24 hours."
          },
          "top-products": {
            "value": false,
            "tooltip": "View a list of the top performing products. The list updates every 24 hours."
          },
          "eligible-for-additional-tracking": {
            "value": false,
            "tooltip": "What plan is eligible to track more stores or products."
          },
          "one-click-import-to-shopify": {
            "value": "100 imports / month",
            "tooltip": "Import products in one click to your Shopify store. This will import the product title, images, description, price, variants, and more details."
          }
        }
      },
      {
        "header": "Competitor Research",
        "details": {
          "searches": {
            "value": "20 searches / day",
            "tooltip": "How many searches you can make in Competitor Research."
          },
          "view-facebook-ads": {
            "value": true,
            "tooltip": "View current active or inactive ads for any store or product to better understand how the store owner is driving traffic to their store."
          },
          "one-click-import-to-sales-tracker": {
            "value": true,
            "tooltip": "Add a store or product to Sales Tracker, directly from Competitor Research, to start tracking their sales revenue every day."
          },
          "one-click-import-to-shopify": {
            "value": "100 imports / month",
            "tooltip": "Import products in one click to your Shopify store. This will import the product title, images, description, price, variants, and more details.."
          }
        }
      },
      {
        "header": "Portfolio",
        "details": {
          "products-per-week": {
            "value": "20 product / week",
            "tooltip": "How many products you will receive to your Portfolio every week."
          },
          "view-past-product-drops": {
            "value": true,
            "tooltip": "New subscriber? You will be able to view and access past drops that were released in case you missed out."
          },
          "benefits-and-drawbacks": {
            "value": true,
            "tooltip": "Access to Benefits And Drawbacks."
          },
          "product-saturation": {
            "value": true,
            "tooltip": "Access to see how saturated the product is."
          },
          "product-competitors": {
            "value": true,
            "tooltip": "Access to all competitors a product has.."
          },
          "view-facebook-ads": {
            "value": true,
                "tooltip": "View current active or inactive ads for any store or product to better understand how the store owner is driving traffic to their store."
          },
          "facebook-and-tik-tok-contents": {
            "value": true,
            "tooltip": "See current active or inactive Facebook or TikTok ads to better understand how the store owner is driving traffic to their store."
          },
          "ali-express-alibaba-suppliers": {
            "value": true,
            "tooltip": "Access to AliExpress and Alibaba suppliers that can source the product for you.."
          },
          "numbers-breakdown": {
            "value": true,
            "tooltip": "Get a complete breakdown on the numbers for the product to see how profitable it could be."
          },
          "interest-targeting": {
            "value": true,
            "tooltip": "Access to a list of interests you can use for your marketing."
          },
          "save-to-collections": {
            "value": true,
            "tooltip": "Save products you find interesting from Portfolio, directly to Collections."
          },
          "one-click-import-to-shopify": {
            "value": "100 imports / month",
            "tooltip": "Import products in one click to your Shopify store. This will import the product title, images, description, price, variants, and more details."
          }
        }
      },
      {
        "header": "Support & Training",
        "details": {
          "dropship-university": {
            "value": true,
            "tooltip": "A library of video tutorials to kickstart your e-commerce or dropshipping journey."
          },
          "a-z-dropshipping-course": {
            "value": true,
            "tooltip": "Access to a course from an 8-figure seller that goes over everything in dropshipping."
          },
          "24/7-support": {
            "value": true,
            "tooltip": "24/7 support around the clock."
          },
          "live-chat-support": {
            "value": true,
            "tooltip": "Chat with our customer support if you have any questions or come across any issues."
          },
        }
      },
    ]
  },
  {
    "header": "Standard",
    "features": [
      {
        "header": "Quick Look",
        "details": {
          "7-day-free-trial": {
            "value": true,
            "tooltip": "Try Dropship completely for free for 7-days. No risks. Cancel anytime."
          },
          "cancel-anytime": {
            "value": true,
            "tooltip": "Cancel your trial or active subscription at anytime."
          },
          "shopify-integration": {
            "value": true,
            "tooltip": "Connect one or multiple Shopify stores to Dropship to import products."
          },
          "eligible-for-additional-seats": {
            "value": "no",
            "tooltip": "What plan is eligible to add more seats in their Dropship account. Coming soon."
          }
        }
      },
      {
        "header": "Product Database",
        "details": {
          "searches": {
            "value": "unlimited",
            "tooltip": "How many searches you can make in Product Database."
          },
          "view-30-day-sales-data": {
            "value": true,
            "tooltip": "View the sales revenue of the last 30 days for every product to better see if the product you're searching for is in demand or not."
          },
          "view-facebook-ads": {
            "value": true,
            "tooltip": "View current active or inactive ads for any store or product to better understand how the store owner is driving traffic to their store."
          },
          "one-click-import-to-sales-tracker": {
            "value": true,
            "tooltip": "Add a store or product to Sales Tracker, directly from Product Database, to start tracking their sales revenue every day."
          },
          "one-click-competitor-research": {
            "value": true,
            "tooltip": "View all the competitors for a product in just one click through Competitor Research, directly from Product Database."
          },
          "one-click-import-to-shopify": {
            "value": "250 imports / month",
            "tooltip": "Import products in one click to your Shopify store. This will import the product title, images, description, price, variants, and more details."
          }
        }
      },
      {
        "header": "Sales Tracker",
        "details": {
          "store-tracker": {
            "value": "25 stores",
            "tooltip": "How many stores you can track in Sales Tracker."
          },
          "product-tracker": {
            "value": "50 product listings",
            "tooltip": "How many products you can track in Sales Tracker."
          },
          "view-historical-sales-data": {
            "value": true,
            "tooltip": "View the sales revenue of any date for a product to better see if the product you're searching for is in demand or not."
          },
          "view-total-sales": {
            "value": true,
            "tooltip": "See how many total sales a store or product has made in a specific timeframe."
          },
          "view-total-unique-products-sold": {
            "value": true,
            "tooltip": "See how many total unique products a store has sold in a specific timeframe."
          },
          "view-facebook-ads": {
            "value": true,
            "tooltip": "View current active or inactive ads for any store or product to better understand how the store owner is driving traffic to their store."
          },
          "download-offline-sales-report": {
            "value": true,
            "tooltip": "Download a sales revenue report of any store or product in a sheet. This way, you can still do product research even if you do not have a wifi connection."
          },
          "top-stores": {
            "value": true,
            "tooltip": "View a list of the top performing stores. The list updates every 24 hours."
          },
          "top-products": {
            "value": false,
            "tooltip": "View a list of the top performing products. The list updates every 24 hours."
          },
          "eligible-for-additional-tracking": {
            "value": false,
            "tooltip": "What plan is eligible to track more stores or products."
          },
          "one-click-import-to-shopify": {
            "value": "250 imports / month",
            "tooltip": "Import products in one click to your Shopify store. This will import the product title, images, description, price, variants, and more details."
          }
        }
      },
      {
        "header": "Competitor Research",
        "details": {
          "searches": {
            "value": "unlimited",
            "tooltip": "How many searches you can make in Competitor Research."
          },
          "view-facebook-ads": {
            "value": true,
            "tooltip": "View current active or inactive ads for any store or product to better understand how the store owner is driving traffic to their store."
          },
          "one-click-import-to-sales-tracker": {
            "value": true,
            "tooltip": "Add a store or product to Sales Tracker, directly from Competitor Research, to start tracking their sales revenue every day."
          },
          "one-click-import-to-shopify": {
            "value": "250 imports / month",
            "tooltip": "Import products in one click to your Shopify store. This will import the product title, images, description, price, variants, and more details."
          }
        }
      },
      {
        "header": "Portfolio",
        "details": {
          "products-per-week": {
            "value": "30 product / week",
            "tooltip": "How many products you will receive to your Portfolio every week."
          },
          "view-past-product-drops": {
            "value": true,
            "tooltip": "New subscriber? You will be able to view and access past drops that were released in case you missed out."
          },
          "benefits-and-drawbacks": {
            "value": true,
            "tooltip": "Access to Benefits And Drawbacks."
          },
          "product-saturation": {
            "value": true,
            "tooltip": "Access to see how saturated the product is."
          },
          "product-competitors": {
            "value": true,
            "tooltip": "Access to all competitors a product has."
          },
          "view-facebook-ads": {
            "value": true,
                  "tooltip": "View current active or inactive ads for any store or product to better understand how the store owner is driving traffic to their store."
          },
          "facebook-and-tik-tok-contents": {
            "value": true,
            "tooltip": "See current active or inactive Facebook or TikTok ads to better understand how the store owner is driving traffic to their store."
          },
          "ali-express-alibaba-suppliers": {
            "value": true,
            "tooltip": "Access to AliExpress and Alibaba suppliers that can source the product for you."
          },
          "numbers-breakdown": {
            "value": true,
            "tooltip": "Get a complete breakdown on the numbers for the product to see how profitable it could be."
          },
          "interest-targeting": {
            "value": true,
            "tooltip": "Access to a list of interests you can use for your marketing."
          },
          "save-to-collections": {
            "value": true,
            "tooltip": "Save products you find interesting from Portfolio, directly to Collections."
          },
          "one-click-import-to-shopify": {
            "value": "250 imports / month",
            "tooltip": "Import products in one click to your Shopify store. This will import the product title, images, description, price, variants, and more details."
          }
        }
      },
      {
        "header": "Support & Training",
        "details": {
          "dropship-university": {
            "value": true,
            "tooltip": "A library of video tutorials to kickstart your e-commerce or dropshipping journey."
          },
          "a-z-dropshipping-course": {
            "value": true,
            "tooltip": "Access to a course from an 8-figure seller that goes over everything in dropshipping."
          },
          "24/7-support": {
            "value": true,
            "tooltip": "24/7 support around the clock."
          },
          "live-chat-support": {
            "value": true,
            "tooltip": "Chat with our customer support if you have any questions or come across any issues."
          },
        }
      },
    ]
  },
  {
    "header": "Premium",
    "features": [
      {
        "header": "Quick Look",
        "details": {
          "7-day-free-trial": {
            "value": true,
            "tooltip": "Try Dropship completely for free for 7-days. No risks. Cancel anytime."
          },
          "cancel-anytime": {
            "value": true,
            "tooltip": "Cancel your trial or active subscription at anytime."
          },
          "shopify-integration": {
            "value": true,
            "tooltip": "Connect one or multiple Shopify stores to Dropship to import products."
          },
          "eligible-for-additional-seats": {
            "value": "pending",
            "tooltip": "What plan is eligible to add more seats in their Dropship account. Coming soon."
          }
        }
      },
      {
        "header": "Product Database",
        "details": {
          "searches": {
            "value": "unlimited",
            "tooltip": "How many searches you can make in Product Database."
          },
          "view-30-day-sales-data": {
            "value": true,
            "tooltip": "View the sales revenue of the last 30 days for every product to better see if the product you're searching for is in demand or not."
          },
          "view-facebook-ads": {
            "value": true,
            "tooltip": "View current active or inactive ads for any store or product to better understand how the store owner is driving traffic to their store."
          },
          "one-click-import-to-sales-tracker": {
            "value": true,
            "tooltip": "Add a store or product to Sales Tracker, directly from Product Database, to start tracking their sales revenue every day.."
          },
          "one-click-competitor-research": {
            "value": true,
            "tooltip": "View all the competitors for a product in just one click through Competitor Research, directly from Product Database."
          },
          "one-click-import-to-shopify": {
            "value": "500 imports / month",
            "tooltip": "Import products in one click to your Shopify store. This will import the product title, images, description, price, variants, and more details."
          }
        }
      },
      {
        "header": "Sales Tracker",
        "details": {
          "store-tracker": {
            "value": "50 stores",
            "tooltip": "How many stores you can track in Sales Tracker."
          },
          "product-tracker": {
            "value": "100 product listings",
            "tooltip": "How many products you can track in Sales Tracker."
          },
          "view-historical-sales-data": {
            "value": true,
            "tooltip": "View the sales revenue of any date for a product to better see if the product you're searching for is in demand or not."
          },
          "view-total-sales": {
            "value": true,
            "tooltip": "See how many total sales a store or product has made in a specific timeframe.."
          },
          "view-total-unique-products-sold": {
            "value": true,
            "tooltip": "See how many total unique products a store has sold in a specific timeframe."
          },
          "view-facebook-ads": {
            "value": true,
            "tooltip": "View current active or inactive ads for any store or product to better understand how the store owner is driving traffic to their store."
          },
          "download-offline-sales-report": {
            "value": true,
            "tooltip": "Download a sales revenue report of any store or product in a sheet. This way, you can still do product research even if you do not have a wifi connection."
          },
          "top-stores": {
            "value": true,
            "tooltip": "View a list of the top performing stores. The list updates every 24 hours."
          },
          "top-products": {
            "value": true,
            "tooltip": "View a list of the top performing products. The list updates every 24 hours."
          },
          "eligible-for-additional-tracking": {
            "value": true,
            "tooltip": "What plan is eligible to track more stores or products."
          },
          "one-click-import-to-shopify": {
            "value": "500 imports / month",
            "tooltip": "Import products in one click to your Shopify store. This will import the product title, images, description, price, variants, and more details."
          }
        }
      },
      {
        "header": "Competitor Research",
        "details": {
          "searches": {
            "value": "unlimited",
            "tooltip": "How many searches you can make in Competitor Research."
          },
          "view-facebook-ads": {
            "value": true,
            "tooltip": "View current active or inactive ads for any store or product to better understand how the store owner is driving traffic to their store."
          },
          "one-click-import-to-sales-tracker": {
            "value": true,
            "tooltip": "Add a store or product to Sales Tracker, directly from Competitor Research, to start tracking their sales revenue every day."
          },
          "one-click-import-to-shopify": {
            "value": "500 imports / month",
            "tooltip": "Import products in one click to your Shopify store. This will import the product title, images, description, price, variants, and more details."
          }
        }
      },
      {
        "header": "Portfolio",
        "details": {
          "products-per-week": {
            "value": "40 product / week",
            "tooltip": "How many products you will receive to your Portfolio every week."
          },
          "view-past-product-drops": {
            "value": true,
            "tooltip": "New subscriber? You will be able to view and access past drops that were released in case you missed out."
          },
          "benefits-and-drawbacks": {
            "value": true,
            "tooltip": "Access to Benefits And Drawbacks."
          },
          "product-saturation": {
            "value": true,
            "tooltip": "Access to see how saturated the product is."
          },
          "product-competitors": {
            "value": true,
            "tooltip": "Access to all competitors a product has."
          },
          "view-facebook-ads": {
            "value": true,
            "tooltip": "View current active or inactive ads for any store or product to better understand how the store owner is driving traffic to their store."
          },
          "facebook-and-tik-tok-contents": {
            "value": true,
            "tooltip": "See current active or inactive Facebook or TikTok ads to better understand how the store owner is driving traffic to their store."
          },
          "ali-express-alibaba-suppliers": {
            "value": true,
            "tooltip": "Access to AliExpress and Alibaba suppliers that can source the product for you."
          },
          "numbers-breakdown": {
            "value": true,
            "tooltip": "Get a complete breakdown on the numbers for the product to see how profitable it could be.."
          },
          "interest-targeting": {
            "value": true,
            "tooltip": "Access to a list of interests you can use for your marketing."
          },
          "save-to-collections": {
            "value": true,
            "tooltip": "Save products you find interesting from Portfolio, directly to Collections."
          },
          "one-click-import-to-shopify": {
            "value": "500 imports / month",
            "tooltip": "Import products in one click to your Shopify store. This will import the product title, images, description, price, variants, and more details."
          }
        }
      },
      {
        "header": "Support & Training",
        "details": {
          "dropship-university": {
            "value": true,
            "tooltip": "A library of video tutorials to kickstart your e-commerce or dropshipping journey."
          },
          "a-z-dropshipping-course": {
            "value": true,
            "tooltip": "Access to a course from an 8-figure seller that goes over everything in dropshipping."
          },
          "24/7-support": {
            "value": true,
            "tooltip": "24/7 support around the clock."
          },
          "live-chat-support": {
            "value": true,
            "tooltip": "Chat with our customer support if you have any questions or come across any issues."
          },
        }
      },
    ]
  }
]