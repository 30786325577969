import React from 'react';
import { useSelector } from 'react-redux';

const AdSpotComments = () => {
  const theme = useSelector((state) => state?.nav?.theme);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.83398 3.83333C1.83398 3.09695 2.43094 2.5 3.16732 2.5H12.834C13.5704 2.5 14.1673 3.09695 14.1673 3.83333V10.8333C14.1673 11.5697 13.5704 12.1667 12.834 12.1667H10.2513C10.096 12.1667 9.94554 12.2209 9.82595 12.32L8.42709 13.4789C8.17926 13.6842 7.82014 13.6832 7.5735 13.4764L6.1968 12.3224C6.07677 12.2218 5.92515 12.1667 5.76853 12.1667H3.16732C2.43094 12.1667 1.83398 11.5697 1.83398 10.8333V3.83333Z"
        stroke={theme === 'light' ? '#707BA0' : '#ffffff42'}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M4.29102 7.33333C4.29102 7.72453 4.60815 8.04167 4.99935 8.04167C5.39055 8.04167 5.70768 7.72454 5.70768 7.33333C5.70768 6.94213 5.39055 6.625 4.99935 6.625C4.60815 6.625 4.29102 6.94213 4.29102 7.33333ZM7.29102 7.33333C7.29102 7.72453 7.60815 8.04167 7.99935 8.04167C8.39055 8.04167 8.70768 7.72454 8.70768 7.33333C8.70768 6.94213 8.39055 6.625 7.99935 6.625C7.60815 6.625 7.29102 6.94213 7.29102 7.33333ZM10.291 7.33333C10.291 7.72454 10.6081 8.04167 10.9993 8.04167C11.3906 8.04167 11.7077 7.72453 11.7077 7.33333C11.7077 6.94213 11.3905 6.625 10.9993 6.625C10.6081 6.625 10.291 6.94213 10.291 7.33333Z"
        fill={theme === 'light' ? '#707BA0' : '#1D1D1D'}
        stroke={theme === 'light' ? '#707BA0' : '#ffffff42'}
        strokeWidth="0.75"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default AdSpotComments;
