import React from 'react';
import { Divider } from 'antd';
import utils from '../../Utils/utils';
import cls from 'classname';

const BillingSkeleton = ({ isMobile }) => {
  return (
    <div className="billing-subpage">
      <div className="billing-subpage-block">
        <div className="billing-subpage-block-title-wrapper">
        <span className="billing-subpage-block-title">
          <span className="link skeleton" style={{ width: 32, height: 20 }} />
        </span>
          <span className="link skeleton" style={{ width: 110, height: 20 }} />
        </div>
        <Divider style={{ margin: '16px 0' }} />
        <div className="billing-subpage-block-stat-wrapper vertical">
          <span className="billing-subpage-block-stat-key">
            <span className="link skeleton" style={{ width: 120, height: 20 }} />
          </span>
          <span className="billing-subpage-block-stat-value">
            <span className="link skeleton" style={{ width: 60, height: 24 }} />
          </span>
        </div>
        <div className="billing-subpage-block-stat-wrapper">
          <span className="billing-subpage-block-stat-key">
            <span className="link skeleton" style={{ width: 83, height: 20 }} />
          </span>
          <span className="billing-subpage-block-stat-value">
            <span className="link skeleton" style={{ width: isMobile ? 55 : 270, height: 20 }} />
          </span>
        </div>
        <div className="billing-subpage-block-stat-wrapper">
          <span className="billing-subpage-block-stat-key">
            <span className="link skeleton" style={{ width: 66, height: 20 }} />
          </span>
          <span className="billing-subpage-block-stat-value">
            <span className="link skeleton" style={{ width: 55, height: 20 }} />
          </span>
        </div>
        <div className="billing-subpage-block-stat-wrapper">
          <span className="billing-subpage-block-stat-key">
            <span className="link skeleton" style={{ width: 200, height: 20 }} />
          </span>
          <span className="billing-subpage-block-stat-value">
            <span className="link skeleton" style={{ width: 50, height: 20 }} />
          </span>
        </div>
        <div className="billing-subpage-block-stat-wrapper">
          <span className="billing-subpage-block-stat-key">
            <span className="link skeleton" style={{ width: 230, height: 20 }} />
          </span>
          <span className="billing-subpage-block-stat-value">
            <span className="link skeleton" style={{ width: 50, height: 20 }} />
          </span>
        </div>
        <Divider style={{ margin: '16px 0' }} />
        <div className="billing-subpage-block-stat-wrapper">
          <span className="billing-subpage-block-stat-key">
            <span className="link skeleton" style={{ width: 32, height: 20 }} />
          </span>
          <span className="billing-subpage-block-stat-value">
            <span className="link skeleton" style={{ width: 50, height: 20 }} />
          </span>
        </div>
      </div>
      <div className="billing-subpage-block">
        <div className="billing-subpage-block-title-wrapper">
        <span className="billing-subpage-block-title">
          <span className="link skeleton" style={{ width: 100, height: 20 }} />
        </span>
          <span className={'billing-subpage-block-link'}>
            <span className="link skeleton" style={{ width: 170, height: 20 }} />
          </span>
          <span className="billing-subpage-block-note">
            <span className="link skeleton" style={{ width: 300, height: 20 }} />
          </span>
        </div>
        <Divider style={{ margin: '16px 0' }} />
        <div className="billing-subpage-block-limit-wrapper">
          <div className="info">
          <span className="title">
            <span className="link skeleton" style={{ width: 90, height: 20 }} />
          </span>
            <div className="stats">
              <div className="stat">
                <span className="value">
                  <span className="link skeleton" style={{ width: 80, height: 20 }} />
                </span>
              </div>
              /
              <div className="stat">
                <span className="value">
                  <span className="link skeleton" style={{ width: 80, height: 20 }} />
                </span>
              </div>
            </div>
          </div>
          <div className="track">
            <span className="link skeleton" style={{ width: '100%', height: 8 }} />
          </div>
        </div>
        <div className="billing-subpage-block-limit-wrapper">
          <div className="info">
            <span className="title">
              <span className="link skeleton" style={{ width: 90, height: 20 }} />
            </span>
            <div className="stats">
              <div className="stat">
                <span className="value">
                  <span className="link skeleton" style={{ width: 80, height: 20 }} />
                </span>
              </div>
              /
              <div className="stat">
                <span className="value">
                  <span className="link skeleton" style={{ width: 80, height: 20 }} />
                </span>
              </div>
            </div>
          </div>
          <div className="track">
            <span className="link skeleton" style={{ width: '100%', height: 8 }} />
          </div>
        </div>
      </div>
      <div className="billing-subpage-block">
        <div className="billing-subpage-block-title-wrapper">
          <span className='billing-subpage-block-title'>
            <span className="link skeleton" style={{ width: 120, height: 20 }} />
          </span>
          <span className="billing-subpage-block-link">
            <span className="link skeleton" style={{ width: 160, height: 20 }} />
          </span>
        </div>
        <Divider style={{ margin: '16px 0' }} />
        <div className="billing-subpage-block-card-wrapper empty">
          <span className="title">
            <span className="link skeleton" style={{ width: 170, height: 20 }} />
          </span>
          <span className="text">
            <span className="link skeleton" style={{ width: 225, height: 20 }} />
          </span>
        </div>
      </div>
      <div className="billing-subpage-block">
        <div className="billing-subpage-block-title-wrapper">
          <span className="billing-subpage-block-title">
            <span className="link skeleton" style={{ width: 110, height: 20 }} />
          </span>
          <span className="billing-subpage-block-link">
            <span className="link skeleton" style={{ width: 150, height: 20 }} />
          </span>
        </div>
        <Divider style={{ margin: '16px 0' }} />
        <div className="billing-subpage-block-address-wrapper">
          {
            [1, 2, 3, 4, 5, 6, 7].map((el,) => (
              <div className="item" key={el * 2}>
                <span className="title">
                  <span className="link skeleton" style={{ width: utils.randomNum(40, 80), height: 20 }} />
                </span>
                <span className="value">
                  <span className="link skeleton" style={{ width: utils.randomNum(70, 110), height: 20 }} />
                </span>
              </div>
            ))
          }
        </div>
      </div>
      <div className="billing-subpage-block">
        <div className="billing-subpage-block-title-wrapper">
          <span className="billing-subpage-block-title">
            <span className="link skeleton" style={{ width: 120, height: 20 }} />
          </span>
          <span className="billing-subpage-block-link">
            <span className="link skeleton" style={{ width: 160, height: 20 }} />
          </span>
        </div>
        <Divider style={{ margin: '16px 0' }} />
        <div className="billing-subpage-block-address-wrapper">
          {
            [1, 2, 3, 4, 5, 6, 7, 8].map((el,) => (
              <div className="item" key={el * 3}>
                <span className="title">
                  <span className="link skeleton" style={{ width: utils.randomNum(40, 80), height: 20 }} />
                </span>
                <span className="value">
                  <span className="link skeleton" style={{ width: utils.randomNum(70, 110), height: 20 }} />
                </span>
              </div>
            ))
          }
        </div>
      </div>
      <div className="billing-subpage-block">
        <div className="billing-subpage-block-title-wrapper">
          <span className="billing-subpage-block-title">
          <span className="link skeleton" style={{ width: 120, height: 20 }} />
          </span>
        </div>
        <Divider style={{ margin: '16px 0' }} />
        <div className='billing-subpage-block-table-wrapper'>
          <div className="table-header">
            {
              [1,2,3,4,5,6].map(el => (
                <span className={cls("item", {
                  widest: el === 6,
                  wide: el === 4 || el === 5,
                })}
                      key={el * 4}
                >
                <span className="link skeleton" style={{ width: utils.randomNum(50, 80), height: 20 }} />
              </span>
              ))
            }
          </div>
          <div className="table-body">
            {
              [1,2,3,4,5].map(payment => (
                <div className="table-row"
                     key={payment * 5}
                >
                  {
                    [1,2,3,4,5,6].map(column => (
                      <span className={cls('item', {
                        widest: column === 6,
                        wide: column === 4 || column === 5,
                      })}
                            key={column + payment * 6}
                      >
                        <span className="link skeleton" style={{ width: column === 6 ? utils.randomNum(90, 120) : utils.randomNum(50, 80), height: 20 }} />
                        {
                          column === 6 ?
                            <span className="link skeleton" style={{ width: 32, height: 32, marginLeft: 'auto' }} />
                            :
                            null
                        }
                      </span>
                    ))
                  }
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingSkeleton;
