import React from 'react';
import { useSelector } from 'react-redux';

const AdSpotRepost = () => {
  const theme = useSelector((state) => state?.nav?.theme);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5068 7.63258L9.0765 2.60952C8.75634 2.31338 8.23698 2.54045 8.23698 2.97657V5.33297C8.23698 5.51706 8.08497 5.666 7.9009 5.66893C2.51072 5.75466 1.07031 7.94667 1.07031 13.4996C2.05014 11.54 2.54993 10.3804 7.90026 10.3344C8.08434 10.3328 8.23698 10.4822 8.23698 10.6663V13.0227C8.23698 13.4588 8.75634 13.6859 9.0765 13.3897L14.5068 8.36668C14.7208 8.16876 14.7208 7.83051 14.5068 7.63258Z"
        stroke={theme === 'light' ? '#707BA0' : '#ffffff42'}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AdSpotRepost;
