import React from 'react';
import { useSelector } from 'react-redux';

const LandingPage = () => {
  const theme = useSelector((state) => state?.nav?.theme);

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5013 8.95825H17.5013M3.95964 16.0416H16.043C16.9634 16.0416 17.7096 15.2954 17.7096 14.3749V5.62492C17.7096 4.70444 16.9634 3.95825 16.043 3.95825H3.95964C3.03916 3.95825 2.29297 4.70444 2.29297 5.62492V14.3749C2.29297 15.2954 3.03916 16.0416 3.95964 16.0416Z"
        stroke={theme === 'light' ? '#A1AAC8' : '#ffffff5c'}  
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M4.79167 7.00008C5.09082 7.00008 5.33333 6.75757 5.33333 6.45841C5.33333 6.15926 5.09082 5.91675 4.79167 5.91675C4.49251 5.91675 4.25 6.15926 4.25 6.45841C4.25 6.75757 4.49251 7.00008 4.79167 7.00008ZM6.875 7.00008C7.17415 7.00008 7.41667 6.75757 7.41667 6.45841C7.41667 6.15926 7.17415 5.91675 6.875 5.91675C6.57585 5.91675 6.33333 6.15926 6.33333 6.45841C6.33333 6.75757 6.57585 7.00008 6.875 7.00008ZM8.95833 7.00008C9.25749 7.00008 9.5 6.75757 9.5 6.45841C9.5 6.15926 9.25749 5.91675 8.95833 5.91675C8.65918 5.91675 8.41667 6.15926 8.41667 6.45841C8.41667 6.75757 8.65918 7.00008 8.95833 7.00008Z"
        fill={theme === 'light' ? '#A1AAC8' : '#ffffff5c'}
        stroke={theme === 'light' ? '#A1AAC8' : '#ffffff5c'}
        strokeWidth="0.25"
      />
    </svg>
  );
};

export default LandingPage;
