import React from 'react';
import { useSelector } from 'react-redux';

const TopStore = (
  {
    outline = false,
  }) => {

  const theme = useSelector(state => state.nav.theme);

  return outline ?
    (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path
          d="M28 10.6673L24.8 6.40065C24.2964 5.72917 23.506 5.33398 22.6667 5.33398H9.33333C8.49398 5.33398 7.70361 5.72917 7.2 6.40065L4 10.6673M28 10.6673V12.0007C28 13.1853 27.485 14.2497 26.6667 14.9821M28 10.6673H4M4 10.6673V12.0007C4 13.1853 4.515 14.2497 5.33333 14.9821M20 12.0007C20 14.2098 21.7909 16.0007 24 16.0007C25.0245 16.0007 25.959 15.6155 26.6667 14.9821M20 12.0007V10.6673M20 12.0007C20 14.2098 18.2091 16.0007 16 16.0007C13.7909 16.0007 12 14.2098 12 12.0007M12 12.0007C12 14.2098 10.2091 16.0007 8 16.0007C6.97553 16.0007 6.04101 15.6155 5.33333 14.9821M12 12.0007V10.6673M18.6667 26.6673L18.6667 22.6673C18.6667 21.1946 17.4728 20.0007 16 20.0007C14.5272 20.0007 13.3333 21.1946 13.3333 22.6673L13.3333 26.6673M5.33333 14.9821V24.0006C5.33333 25.4734 6.52724 26.6673 8 26.6673H24C25.4728 26.6673 26.6667 25.4734 26.6667 24.0006V14.9821"
          stroke="#E2E6F3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
    :
    (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M17.9173 6.24935L15.7789 3.54818C15.4628 3.14887 14.9814 2.91602 14.4721 2.91602H5.52916C5.01987 2.91602 4.53853 3.14887 4.22241 3.54818L2.08398 6.24935M17.9173 6.24935V7.08268C17.9173 7.8231 17.5776 8.48834 17.0377 8.9461M17.9173 6.24935H2.08398M2.08398 6.24935V7.08268C2.08398 7.8231 2.42374 8.48834 2.96361 8.9461M12.6395 7.08268C12.6395 8.46339 13.821 9.58268 15.2784 9.58268C15.9543 9.58268 16.5708 9.34197 17.0377 8.9461M12.6395 7.08268V6.24935M12.6395 7.08268C12.6395 8.46339 11.4581 9.58268 10.0007 9.58268C8.54323 9.58268 7.36176 8.46339 7.36176 7.08268M7.36176 7.08268C7.36176 8.46339 6.18029 9.58268 4.72287 9.58268C4.04701 9.58268 3.43048 9.34197 2.96361 8.9461M7.36176 7.08268V6.24935M2.96361 8.9461V15.416C2.96361 16.3365 3.70981 17.0827 4.63028 17.0827H7.91732M17.0377 8.9461V15.416C17.0377 16.3365 16.2915 17.0827 15.371 17.0827H12.084M7.91732 17.0827V14.9993C7.91732 13.8488 8.85006 12.916 10.0007 12.916C11.1512 12.916 12.084 13.8488 12.084 14.9993V17.0827M7.91732 17.0827H12.084"
          stroke={theme === 'light' ? '#E2E6F3' : 'rgba(255, 255, 255, 0.12)'}
          strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
    ;
};

export default TopStore;
