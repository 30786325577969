import React from 'react';
import cls from 'classname';
import { useSelector } from 'react-redux';

const DatabaseDomain = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M2.49999 8.9585H17.5M3.95832 16.0418H16.0417C16.9621 16.0418 17.7083 15.2956 17.7083 14.3752V5.62516C17.7083 4.70469 16.9621 3.9585 16.0417 3.9585H3.95832C3.03785 3.9585 2.29166 4.70469 2.29166 5.62516V14.3752C2.29166 15.2956 3.03785 16.0418 3.95832 16.0418Z"
        stroke={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, 0.26)"}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round" />
      <path
        d="M4.79167 6.99984C5.09082 6.99984 5.33333 6.75732 5.33333 6.45817C5.33333 6.15902 5.09082 5.9165 4.79167 5.9165C4.49251 5.9165 4.25 6.15902 4.25 6.45817C4.25 6.75732 4.49251 6.99984 4.79167 6.99984ZM6.875 6.99984C7.17415 6.99984 7.41667 6.75732 7.41667 6.45817C7.41667 6.15902 7.17415 5.9165 6.875 5.9165C6.57585 5.9165 6.33333 6.15902 6.33333 6.45817C6.33333 6.75732 6.57585 6.99984 6.875 6.99984ZM8.95833 6.99984C9.25749 6.99984 9.5 6.75732 9.5 6.45817C9.5 6.15902 9.25749 5.9165 8.95833 5.9165C8.65918 5.9165 8.41667 6.15902 8.41667 6.45817C8.41667 6.75732 8.65918 6.99984 8.95833 6.99984Z"
        stroke={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, 0.26)"}
        fill={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, 0.26)"}
        strokeWidth="0.25"
      />
    </svg>
  )
}
export default DatabaseDomain;
