import React from 'react';
import { useSelector } from 'react-redux';

const DatabaseAction = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.99996 5.62484V7.2915M9.99996 17.7082C7.12348 17.7082 4.79163 15.3763 4.79163 12.4998V7.49984C4.79163 4.62335 7.12348 2.2915 9.99996 2.2915C12.8764 2.2915 15.2083 4.62335 15.2083 7.49984V12.4998C15.2083 15.3763 12.8764 17.7082 9.99996 17.7082Z"
        stroke={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, 0.26)"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DatabaseAction;
