import React from 'react';

const AdSpotChartComments = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3745 11.875H16.043C16.9634 11.875 17.7096 11.1288 17.7096 10.2083V4.79167C17.7096 3.87119 16.9634 3.125 16.043 3.125H7.5013C6.58083 3.125 5.83464 3.87119 5.83464 4.79167V6.45833M12.7096 6.45833H3.95964C3.03916 6.45833 2.29297 7.20453 2.29297 8.125V13.5417C2.29297 14.4621 3.03916 15.2083 3.95964 15.2083H5.0013V17.2917L8.7513 15.2083H12.7096C13.6301 15.2083 14.3763 14.4621 14.3763 13.5417V8.125C14.3763 7.20453 13.6301 6.45833 12.7096 6.45833Z"
        stroke="#225AEA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AdSpotChartComments;
