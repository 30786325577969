import React from 'react';

const Medal = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.45898 12.4997V17.9731C6.45898 18.4313 6.93558 18.7337 7.35011 18.5387L9.73453 17.4166C9.90308 17.3373 10.0982 17.3373 10.2668 17.4166L12.6512 18.5387C13.0657 18.7337 13.5423 18.4313 13.5423 17.9731V12.4997M16.0423 7.49967C16.0423 10.8364 13.3374 13.5413 10.0007 13.5413C6.66393 13.5413 3.95898 10.8364 3.95898 7.49967C3.95898 4.16295 6.66393 1.45801 10.0007 1.45801C13.3374 1.45801 16.0423 4.16295 16.0423 7.49967Z"
        stroke="#707BA0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Medal;
