import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Creators from '../reducer';
import cls from 'classname';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Modal, Spin, Tooltip } from 'antd';
import DatabaseProductsPagination from '../../ProductDatabasePage/components/DatabaseProductsPagination';
import SalesTrackerTopPageTable from './components/SalesTrackerTopPageTable';
import SalesTrackerTopPageSkeleton from './components/SalesTrackerTopPageSkeleton';
import PaginationSkeleton from './components/PaginationSkeleton';
import Icon from '../../../Icon';
import ReactPlayer from 'react-player';
import DropdownBarFilter from './components/DropdownBarFilter';
import FilterTag from './components/FilterTag';
import DropdownList from './components/DropdownList';
import DropdownCalendar from './components/DropdownCalendar';
import DeleteTrackingModal from '../components/DeleteTrackingModal';
import CanNotRemoveTracking from '../../ProductDatabasePage/components/CanNotRemoveTracking';
import StoreProductsModal from '../components/StoreProductsModal';
import SearchWithTags from './components/SearchWithTags';
import PriceHistory from '../../ProductDatabasePage/components/PriceHistory';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import QuickSearch from '../../AdSpotPage/components/QuickSearch';
import { openNotificationWithIcon } from '../../../Components/Notification/index';
import { DefaultMsgSaga } from '../../../Components/Notification/notification-message';
import './SalesTrackerTopPage.less';
import OldDropDownCalendar from '../../../Shares/OldDropDownCalendar/OldDropDownCalendar';

dayjs.extend(utc);

const periodFilters = [
  {title: 'Last 7 Days', filter: 'week'},
  {title: 'Last 30 Days', filter: 'month'},
];

const SalesTrackerTopPage = (
  {
    isMobile,
    limits,
    loading,
    spinner,
    filtersLoading,
    presetsLoading,
    getTopStoresFilters,
    getTopProductsFilters,
    getTopStoresPresets,
    getTopProductsPresets,
    getTopStores,
    getTopProducts,
    getTopStoresCount,
    getTopProductsCount,
    countLoading,
    countStores,
    countProducts,
    storesFilters,
    productsFilters,
    storesPresets = [],
    productsPresets = [],
    topStores,
    topProducts,
    userInfo,
    getStoreProductsModal,
    getStoreProductsModalNext,
    storeProductsModalTableDataLoading,
    storeProductsModalTableData,
    resetStoreProductsModal,
    fetching,
    connectTopStore,
    deleteTopStore,
    connectTopProduct,
    deleteTopProduct,
    storesCount,
    productsCount,
  }
) => {

  const {t} = useTranslation();
  const location = useLocation();
  const view = location.pathname.split('/')[3] || 'Stores';

  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;
  const isBasicPlan = userInfo?.subscriptions?.[0]?.plan?.plan?.name?.toLowerCase() === 'basic';
  const isStandardPlan = userInfo?.subscriptions?.[0]?.plan?.plan?.name?.toLowerCase() === 'standard';

  const filterOptions = view === 'stores' ? [
    {title: 'Revenue', id: 'revenue',},
    {title: 'Sales', id: 'sales', isStore: true},
    {title: 'Products', id: 'products_count'},
    {title: 'Language', id: 'languages'},
    {title: 'Creation Date', id: 'created_at',}
  ] : [
    {title: 'Price', id: 'price',},
    {title: 'Revenue', id: 'revenue',},
    {title: 'Sales', id: 'sales',},
    {title: 'Language', id: 'languages',},
    {title: 'Creation Date', id: 'created_at',}
  ]

  const [activePeriod, setActivePeriod] = useState({title: 'Last 30 Days', filter: 'month'});


  const initialValue = () => {
    let currentFilters = view === 'stores' ? storesFilters : productsFilters;
    let res = {}
    for (let filter in currentFilters) {
      if (filter !== 'languages' && filter !== 'created_at') {
        res[filter] = {min: currentFilters?.[filter]?.[activePeriod?.filter]?.min, max: currentFilters?.[filter]?.[activePeriod?.filter]?.max}
      } else {
        if (filter === 'languages') {
          res[filter] = null;
        } else {
          res[filter] = {min: null, max: null}
        }
      }
    }
    return res
  }

  const [value, setValue] = useState(initialValue())
  const [visibleVideo, setVisibleVideo] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [loadedPreset, setLoadedPreset] = useState(null);
  const [clickedPreset, setClickedPreset] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToPriceHistory, setRecordToPriceHistory] = useState(null);
  const [storeProductsToShow, setStoreProductsToShow] = useState(null);
  const [showTags, setShowTags] = useState(false);
  const [newSearch, setNewSearch] = useState(true);
  const [timestamp, setTimestamp] = useState(Math.floor((new Date().getTime())/3600000));
  const [firstLoading, setFirstLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState(false);

  const modalBlocksWidth = {
    deleteTracking: 512,
    canNotRemoveTracking: 512,
    storeProducts: 1120,
    priceHistory: 1040,
  };

  const modalBlocks = {
    deleteTracking: <DeleteTrackingModal setModal={setModal}
                                         record={recordToDelete}
                                         setVisible={setVisible}
                                         deleteStore={deleteTopStore}
                                         deleteProduct={deleteTopProduct}
                                         view={view}
                                         isTopPage={true}
    />,
    canNotRemoveTracking: <CanNotRemoveTracking/>,
    storeProducts: <StoreProductsModal getStoreProductsModal={getStoreProductsModal}
                                       getStoreProductsModalNext={getStoreProductsModalNext}
                                       storeProductsModalTableDataLoading={storeProductsModalTableDataLoading}
                                       storeProductsModalTableData={storeProductsModalTableData}
                                       storeId={storeProductsToShow}
                                       resetStoreProductsModal={resetStoreProductsModal}
                                       minDate={null}
                                       maxDate={null}
                                       isMobile={isMobile}
    />,
    priceHistory: <PriceHistory product={recordToPriceHistory} />
  }

  const viewName = () => {
    const firstLetter = view.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = view.slice(1)
    return firstLetterCap + remainingLetters
  }

  const searchSubmit = (withEmptySearch=false) => {
    if (loading || spinner || fetching) return
    setLoadedPreset(null);
    if (pageNumber === 1) applyFilters(false, withEmptySearch);
    else setPageNumber(1)
  }

  const checkIfChanged = (withState = true) => {
    let currentFilters = view === 'stores' ? storesFilters : productsFilters;
    if (Object.keys(value).some(el => {
      if (el !== 'languages' && el !== 'created_at') {
        return (value?.[el]?.min !== currentFilters?.[el]?.[activePeriod?.filter]?.min && value?.[el]?.min !== null)
          || (value?.[el]?.max !== currentFilters?.[el]?.[activePeriod?.filter]?.max && value?.[el]?.max !== null)
      } else {
        if (el === 'languages') {
          return value?.[el] !== null;
        } else {
          return value?.[el]?.min !== null || value?.[el]?.max !== null
        }
      }
    }) || searchValue) {
      if (withState) setShowTags(true);
      return true;
    } else {
      if (withState) setShowTags(false);
      return false;
    }
  }

  const resetFilters = (isInit=false) => {
    setLoadedPreset(null);
    setSearchValue('');
    setPageSize(50);
    setPageNumber(1);
    if (isInit) setActivePeriod({title: 'Last 30 Days', filter: 'month'});
    setValue(initialValue());
    setNewSearch(true);
    setTimestamp(Math.floor((new Date().getTime())/3600000));
    setFirstLoading(true);
  }

  const applyFilters = (newSearch = false, withEmptySearch=false) => {
    let data = {
      "page": pageNumber,
      "page_size": pageSize,
      "timestamp": timestamp,
      "new_search": newSearch,
      "filters": {
        "period": activePeriod?.filter,
        "languages": value?.languages,
        "domain": withEmptySearch ? null : searchValue?.length ? searchValue : null,
        "sales": {
          "min": value?.sales?.min,
          "max": view === 'stores' ? storesFilters?.sales?.[activePeriod?.filter]?.max === value?.sales?.max ? null : value?.sales?.max : productsFilters?.sales?.[activePeriod?.filter]?.max === value?.sales?.max ? null : value?.sales?.max,
        },
        "revenue": {
          "min": value?.revenue?.min,
          "max": view === 'stores' ? storesFilters?.revenue?.[activePeriod?.filter]?.max === value?.revenue?.max ? null : value?.revenue?.max : productsFilters?.revenue?.[activePeriod?.filter]?.max === value?.revenue?.max ? null : value?.revenue?.max,
        },
        "created_at": {
          "min": value?.created_at?.min,
          "max": value?.created_at?.max,
        }
      }
    }
    if (view === 'stores') {
      data = {...data, filters: {...data?.filters, "products": {
            "min": value?.products_count?.min,
            "max": value?.products_count?.max === storesFilters?.products_count?.[activePeriod?.filter]?.max ? null : value?.products_count?.max,
      }}}
      getTopStores({...data})
    } else {
      data = {...data, filters: {...data?.filters, "price": {
            "min": value?.price?.min,
            "max": value?.price?.max === productsFilters?.price?.[activePeriod?.filter]?.max ? null : value?.price?.max,
          }}}
      getTopProducts({...data})
    }
  }

  const handleCount = (key={}) => {
    let data = {
      "page": pageNumber,
      "page_size": pageSize,
      "new_search": newSearch,
      "filters": {
        "period": activePeriod?.filter,
        "languages": value?.languages,
        "domain": searchValue?.length ? searchValue : null,
        "sales": {
          "min": value?.sales?.min,
          "max": view === 'stores' ? storesFilters?.sales?.[activePeriod?.filter]?.max === value?.sales?.max ? null : value?.sales?.max : productsFilters?.sales?.[activePeriod?.filter]?.max === value?.sales?.max ? null : value?.sales?.max,
        },
        "revenue": {
          "min": value?.revenue?.min,
          "max": view === 'stores' ? storesFilters?.revenue?.[activePeriod?.filter]?.max === value?.revenue?.max ? null : value?.revenue?.max : productsFilters?.revenue?.[activePeriod?.filter]?.max === value?.revenue?.max ? null : value?.revenue?.max,
        },
        "created_at": {
          "min": value?.created_at?.min,
          "max": value?.created_at?.max,
        }
      }
    }
    if (view === 'stores') {
      data = {...data, filters: {...data?.filters, "products": {
            "min": value?.products_count?.min,
            "max": value?.products_count?.max === storesFilters?.products_count?.[activePeriod?.filter]?.max ? null : value?.products_count?.max,
          }}}
      data.filters[key?.id].min = key?.value?.min;
      data.filters[key?.id].max = key?.value?.max;
      getTopStoresCount({...data})
    } else {
      data = {...data, filters: {...data?.filters, "price": {
            "min": value?.price?.min,
            "max": value?.price?.max === productsFilters?.price?.[activePeriod?.filter]?.max ? null : value?.price?.max,
          }}}
      data.filters[key?.id].min = key?.value?.min;
      data.filters[key?.id].max = key?.value?.max;
      getTopProductsCount({...data})
    }
  };

  const loadPreset = (data) => {
    let name = data.name;
    let {domain, period, created_at, languages, revenue, sales, ...last} = data?.value?.filters;
    let val = {
      created_at,
      revenue,
      sales,
    };

    if (view === 'stores') {
      val = {
        ...val,
        languages: languages ? storesFilters?.languages.filter(el => languages.includes(el?.value)).map(el => el?.code) : null,
        products_count: {
          'min': last?.products?.min,
          'max': last?.products?.max,
        }
      }
    } else {
      val = {
        ...val,
        languages: languages ? productsFilters?.languages.filter(el => languages.includes(el?.value)).map(el => el?.code) : null,
        price: {
          'min': last?.price?.min,
          'max': last?.price?.max,
        }
      }
    }
    setSearchValue(domain === null ? '' : domain);
    setActivePeriod(periodFilters.filter(el => el.filter === period)?.[0]);
    setValue({...val});

    openNotificationWithIcon({
      style: { minWidth: '716px' },
      className: 'notification notification--save',
      message: (
        <DefaultMsgSaga text={
          <span className="preset-notification-block">
            preset_load_name_
          </span>
        }
                        icon="notification_success"
                        iconOutline={true}
                        withTranslate={true}
                        preset={name}
        />
      ),
    });
  };

  const setLoadedPresetHandler = data => {
    setClickedPreset(true);
    setLoadedPreset(data);
  };

  useEffect(() => {
    document.title = `Top ${viewName()} - Dropship`;
    resetFilters(true);
    if (!firstLoading) {
      setFirstLoading(true)
    }
    if (view === 'stores') {
      getTopStoresPresets();
      if (!Object.keys(storesFilters).length || dayjs().utc().endOf('day').diff(dayjs().utc(), 'seconds') < 3) getTopStoresFilters();
    } else {
      getTopProductsPresets();
      if (!Object.keys(productsFilters).length || dayjs().utc().endOf('day').diff(dayjs().utc(), 'seconds') < 3) getTopProductsFilters();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [view])

  useEffect(() => {
    checkIfChanged()
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [value])

  useEffect(() => {
    if (!loadedPreset) resetFilters();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [activePeriod?.title])

  useEffect(() => {
    if (loadedPreset && clickedPreset) {
      applyFilters(true);
      setClickedPreset(false);
    } else {
      if (!firstLoading && Object.keys(view === 'stores' ? storesFilters : productsFilters).length && !loading) {
        if (newSearch) setNewSearch(false);
        else {
          if (loadedPreset) {
            applyFilters(true);
            setLoadedPreset(null);
          } else applyFilters(false);
        }
      }
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [value, pageSize, pageNumber])

  useEffect(() => {
    if (firstLoading) {
      applyFilters(true);
      setFirstLoading(false);
      setNewSearch(false)
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [newSearch, firstLoading])

  return (
    <Spin spinning={(spinner || fetching) && !(loading || filtersLoading || presetsLoading)}>
      <div className={cls('product-database-page sales-tracker-top-page')}>
        {
          loading || filtersLoading || presetsLoading ?
            <SalesTrackerTopPageSkeleton isMobile={isMobile} />
            :
            <>
              <div className="sales-tracker-top-page-header">
                <div className="title-wrapper">
                  <div className="title-wrapper-icon">
                    <Icon type={`top_${view}_icon`} role={'icon'} />
                  </div>
                  <div className="sales-tracker-top-page-subheader">
                    <h3 className="sales-tracker-top-page-title">
                      {t(`Top Performing ${viewName()}`)}
                    </h3>
                    <h4 className="sales-tracker-top-page-subtitle">
                      {t(`This list of ${view} will update every 24 hours`)}
                    </h4>
                  </div>
                </div>
                <div style={{display: 'flex', alignItems: "center"}}>
                  <div className={'sales-tracker-top-page-watch-tutorial'}
                       onClick={() => setVisibleVideo(true)}
                  >
                    <Icon type={'watch_tutorial'} role={'icon'} width={24} height={24}/>
                    <span>{t('Watch Tutorial')}</span>
                  </div>
                  <div className={'sales-tracker-top-page-limits-wrapper'}>
                    <div className="sales-tracker-top-page-limits">
                      {t('My Trackings')}
                      <Divider type={'vertical'} style={{ margin: '0 20px' }} />
                      {limits?.stores?.used}/{limits?.stores?.allowed} {t('Stores')}
                      <Divider type={'vertical'} style={{ margin: '0 6px' }} />
                      {limits?.products?.used}/{limits?.products?.allowed} {t('Products')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-filter-wrapper">
                <Tooltip placement="top"
                         title={isTrial ? t('Feature disabled during Trial') : null}
                         destroyTooltipOnHide={true}
                >
                  <div className="main-filter-search">
                    <SearchWithTags searchValue={searchValue}
                                    search={search}
                                    setSearch={setSearch}
                                    setSearchValue={setSearchValue}
                                    searchSubmit={searchSubmit}
                                    disabled={isTrial || isBasicPlan || view === 'products' ? isStandardPlan : false}
                                    disabledBtn={loading || filtersLoading || spinner || presetsLoading}
                                    placeholder={`Search for a ${view === 'stores' ? 'store' : 'product'}...`}
                    />
                  </div>
                </Tooltip>
                <Divider style={{margin: '24px auto'}} type={'horizontal'} />
                <div className="main-filters-wrapper">
                  {
                    view === 'stores' ?
                      <div className="main-filter-dropdown-wrapper">
                        <DropdownBarFilter key={'Revenue'}
                                           title={t('Revenue')}
                                           tooltip={'Store revenue generated based on the period you select either 7 Days, 30 Days or 90 Days.'}
                                           position={'bottomLeft'}
                                           id={'revenue'}
                                           isStore={view === 'stores'}
                                           min={storesFilters?.revenue?.[activePeriod?.filter]?.min}
                                           max={storesFilters?.revenue?.[activePeriod?.filter]?.max}
                                           value={value}
                                           setValue={setValue}
                                           disabled={isTrial || isBasicPlan}
                                           countLoading={countLoading}
                                           count={countStores}
                                           handleCount={handleCount}
                        />
                        <DropdownBarFilter key={'Sales'}
                                           title={t('Sales')}
                                           tooltip={'Number of sales the store generated based on the period you select either 7 Days, 30 Days or 90 Days.'}
                                           position={'bottom'}
                                           id={'sales'}
                                           isStore={view === 'stores'}
                                           min={storesFilters?.sales?.[activePeriod?.filter]?.min}
                                           max={storesFilters?.sales?.[activePeriod?.filter]?.max}
                                           value={value}
                                           setValue={setValue}
                                           disabled={isTrial || isBasicPlan}
                                           countLoading={countLoading}
                                           count={countStores}
                                           handleCount={handleCount}
                        />
                        <DropdownBarFilter key={'Products'}
                                           title={t('Products')}
                                           tooltip={'Amount of product listings in the store.'}
                                           position={'bottomRight'}
                                           id={'products_count'}
                                           isStore={view === 'stores'}
                                           min={storesFilters?.products_count?.[activePeriod?.filter]?.min}
                                           max={storesFilters?.products_count?.[activePeriod?.filter]?.max}
                                           value={value}
                                           setValue={setValue}
                                           disabled={isTrial || isBasicPlan}
                                           countLoading={countLoading}
                                           count={countStores}
                                           handleCount={handleCount}
                        />
                        <DropdownList title={t('Language')}
                                      id={'languages'}
                                      value={value}
                                      setValue={setValue}
                                      data={storesFilters?.languages || []}
                                      disabled={isTrial || isBasicPlan}

                        />

                        <OldDropDownCalendar title={t('Creation Date')}
                                          id={'created_at'}
                                          value={value}
                                          setValue={setValue}
                                          disabled={isTrial || isBasicPlan}
                                          countLoading={countLoading}
                                          count={countStores}
                                          handleCount={handleCount}
                                          position={'bottomRight'}
                        />

                      </div>
                      :
                      <div className="main-filter-dropdown-wrapper">
                        <DropdownBarFilter key={'Price'}
                                           title={t('Price')}
                                           tooltip={'The price products are sold for in USD.'}
                                           position={'bottomLeft'}
                                           id={'price'}
                                           isStore={view === 'stores'}
                                           min={productsFilters?.price?.[activePeriod?.filter]?.min}
                                           max={productsFilters?.price?.[activePeriod?.filter]?.max}
                                           value={value}
                                           setValue={setValue}
                                           disabled={isTrial || isBasicPlan || isStandardPlan}
                                           isStandard={isStandardPlan}
                                           countLoading={countLoading}
                                           count={countProducts}
                                           handleCount={handleCount}
                        />
                        <DropdownBarFilter key={'Revenue'}
                                           title={t('Revenue')}
                                           tooltip={'Product listing revenue generated based on the period you select either 7 Days, 30 Days or 90 Days.'}
                                           position={'bottomRight'}
                                           id={'revenue'}
                                           isStore={view === 'stores'}
                                           min={productsFilters?.revenue?.[activePeriod?.filter]?.min}
                                           max={productsFilters?.revenue?.[activePeriod?.filter]?.max}
                                           value={value}
                                           setValue={setValue}
                                           disabled={isTrial || isBasicPlan || isStandardPlan}
                                           isStandard={isStandardPlan}
                                           countLoading={countLoading}
                                           count={countProducts}
                                           handleCount={handleCount}
                        />
                        <DropdownBarFilter key={'Sales'}
                                           title={t('Sales')}
                                           tooltip={'Number of sales a product listing generated based on the period you select either 7 Days, 30 Days or 90 Days.'}
                                           position={'bottom'}
                                           id={'sales'}
                                           isStore={view === 'stores'}
                                           min={productsFilters?.sales?.[activePeriod?.filter]?.min}
                                           max={productsFilters?.sales?.[activePeriod?.filter]?.max}
                                           value={value}
                                           setValue={setValue}
                                           disabled={isTrial || isBasicPlan || isStandardPlan}
                                           isStandard={isStandardPlan}
                                           countLoading={countLoading}
                                           count={countProducts}
                                           handleCount={handleCount}
                        />
                        <DropdownList title={t('Language')}
                                      id={'languages'}
                                      value={value}
                                      setValue={setValue}
                                      data={productsFilters?.languages || []}
                                      disabled={isTrial || isBasicPlan || isStandardPlan}
                                      isStandard={isStandardPlan}

                        />

                        <OldDropDownCalendar title={t('Creation Date')}
                                          id={'created_at'}
                                          value={value}
                                          setValue={setValue}
                                          disabled={isTrial || isBasicPlan || isStandardPlan}
                                          isStandard={isStandardPlan}
                                          countLoading={countLoading}
                                          count={countProducts}
                                          handleCount={handleCount}
                                          position={'bottomRight'}
                        />
                      </div>
                  }
                  <Tooltip placement="top"
                           title={isTrial ? 'Feature disabled during Trial': null}
                           destroyTooltipOnHide={true}
                  >
                  <div className="period-filter-wrapper">
                    <div className={cls("period-filter-active", {
                      "period-filter-active-disabled": isTrial || isBasicPlan || (view === 'products' && isStandardPlan)
                    })}
                         style={{left: isMobile ? `calc((100% / 2) * ${periodFilters.map(elem => elem?.title).indexOf(activePeriod?.title)})` :  `${(periodFilters.map(elem => elem?.title).indexOf(activePeriod?.title) * 184)}px`}} />
                    {periodFilters.map(el => (
                      <div key={el?.title} className={cls("period-filter", {
                        active: el?.title === activePeriod?.title,
                        'disabled': isTrial || isBasicPlan || (view === 'products' && isStandardPlan)
                      })}
                           onClick={() => {
                             if (el?.title === activePeriod?.title || isTrial || isBasicPlan || (view === 'products' && isStandardPlan)) return
                             else {
                               setLoadedPreset(null);
                               setActivePeriod(el);
                             }
                           }}
                      >
                        {t(el?.title)}
                      </div>
                    ))}
                  </div>
                  </Tooltip>
                </div>
                {
                  (view === 'stores' ? [...storesPresets] : [...productsPresets]).length ?
                    <Divider style={{ margin: '24px auto' }} type={'horizontal'} />
                    :
                    null
                }
                {
                  (view === 'stores' ? [...storesPresets] : [...productsPresets]).length ?
                  <div style={{width:'100%'}}>
                    <QuickSearch loadPreset={loadPreset}
                                 setLoadedPreset={setLoadedPresetHandler}
                                 loadedPreset={loadedPreset}
                                 skeleton={false}
                                 isMobile={isMobile}
                                 data={(view === 'stores' ? storesPresets : productsPresets) || []}
                                 reset={resetFilters}
                                 disabledReset={!Boolean(checkIfChanged(false) || loadedPreset)}
                                 
                    />
                    </div>
                    :
                    null
                }
              </div>
            </>
        }
        {
          showTags && !(loading || filtersLoading || presetsLoading) ?
            (
              <div className={'sales-tracker-top-page-tags'}>
                {
                  Object.keys(value)?.filter(key => {
                    let currentFilters = view === 'stores' ? storesFilters : productsFilters;
                    if (key !== 'languages' && key !== 'created_at') {
                      return (value?.[key]?.min !== currentFilters?.[key]?.[activePeriod?.filter]?.min && value?.[key]?.min !== null)
                        || (value?.[key]?.max !== currentFilters?.[key]?.[activePeriod?.filter]?.max && value?.[key]?.max !== null)
                    } else {
                      if (key === 'languages') {
                        return value?.[key] !== null;
                      } else {
                        return value?.[key]?.min !== null || value?.[key]?.max !== null
                      }
                    }
                  })
                    ?.map(el => {
                      let def = filterOptions.filter(elem => elem?.id === el)?.[0];
                      let data = {};
                      let currentFilters = view === 'stores' ? storesFilters : productsFilters;
                      if (el !== 'languages' && el !== 'created_at') {
                        data = {id: el, title: def?.title, value: value?.[el], min: currentFilters?.[el]?.[activePeriod?.filter]?.min, max: currentFilters?.[el]?.[activePeriod?.filter]?.max};
                      } else {
                        data = {id: el, title: def?.title, value: value?.[el], min: null, max: null};
                      }
                      return (
                        <FilterTag key={el}
                                   languages={view === 'stores' ? (storesFilters?.languages?.length ? [...storesFilters.languages] : []) : (productsFilters?.languages?.length ? [...productsFilters.languages] : [])}
                                   data={data}
                                   setValue={setValue}
                        />
                      )
                    })
                }
              </div>
            )
            :
            null
        }
        <div className={cls('product-database-table-wrapper sales-tracker-top-table-wrapper')}>
          {
            loading || filtersLoading || presetsLoading ?
              <PaginationSkeleton />
              :
              (view === 'stores' ? topStores?.length : topProducts?.length) ?
              <DatabaseProductsPagination
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageNumber={pageNumber}
                isTopStores={true}
                isTrial={isTrial}
                setPageNumber={setPageNumber}
                totalProducts={view === 'stores' ? storesCount || 0 : productsCount || 0}
                disabled={isTrial || isBasicPlan || (view === 'products' && isStandardPlan)}
                isTopProducts={view === 'products' && isStandardPlan}
              />
                :
                null
          }
          <SalesTrackerTopPageTable view={view}
                                    loading={loading || filtersLoading || presetsLoading}
                                    period={activePeriod?.filter}
                                    data={view === 'stores' ? topStores || [] : topProducts || []}
                                    setModal={setModal}
                                    setVisible={setVisible}
                                    setRecordToPriceHistory={setRecordToPriceHistory}
                                    isTrial={isTrial}
                                    setRecordToDelete={setRecordToDelete}
                                    setStoreProductsToShow={setStoreProductsToShow}
                                    connectStore={connectTopStore}
                                    connectProduct={connectTopProduct}
                                    spinner={spinner}
                                    isBasicPlan={isBasicPlan}
                                    isMobile={isMobile}
          />
        </div>
        <Modal
          className="change-modal custom-modal video-modal"
          getContainer={() => document.getElementById('global-wrap')}
          {...(isMobile ? {transitionName: ''} : null)}
          open={visibleVideo}
          centered={!isMobile}
          closeIcon={
            <Icon role="icon" type="close_modal" color="#225aea" opacity={1}/>
          }
          footer={null}
          closable="true"
          onCancel={() => setVisibleVideo(false)}
          destroyOnClose
        >
          <ReactPlayer controls={true}
                       width={'100%'}
                       height={'100%'}
                       playing={true}
                       url={view === 'stores' ? 'https://www.youtube.com/watch?v=nqwt8lpe9cY' : 'https://www.youtube.com/watch?v=vZqzFerxSps'}
          />
        </Modal>
        <Modal
          className={cls("change-modal custom-modal", {
            'store-products-opened': modal === 'storeProducts'
          })}
          getContainer={() => document.getElementById('global-wrap')}
          {...(isMobile ? {transitionName: ''} : null)}
          open={visible}
          centered={!isMobile}
          closeIcon={
            <Icon role="icon" type="close_modal" color="#707BA0" opacity={1}/>
          }
          width={modalBlocksWidth[modal]}
          footer={null}
          closable="true"
          onCancel={() => setVisible(false)}
          destroyOnClose
        >
          <Spin size="large" spinning={fetching}>
            {modalBlocks[modal]}
          </Spin>
        </Modal>
      </div>
    </Spin>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  userInfo: state.auth.userInfo,
  limits: state.salesTracker.stores?.limits,
  loading: state.salesTracker.topPage?.loading,
  spinner: state.salesTracker.topPage?.spinner,
  countLoading: state.salesTracker.topPage?.countLoading,
  countStores: state.salesTracker.topPage?.topStores?.count,
  countProducts: state.salesTracker.topPage?.topProducts?.count,
  fetching: state.salesTracker.loading,
  filtersLoading: state.salesTracker.topPage?.filtersLoading,
  presetsLoading: state.salesTracker.topPage?.presetsLoading,
  storesFilters: state?.salesTracker?.topPage?.topStores?.filters,
  productsFilters: state?.salesTracker?.topPage?.topProducts?.filters,
  storesPresets: state?.salesTracker?.topPage?.topStores?.presets?.quick_search,
  productsPresets: state?.salesTracker?.topPage?.topProducts?.presets?.quick_search,
  topStores: state?.salesTracker?.topPage?.topStores?.stores?.results,
  topProducts: state?.salesTracker?.topPage?.topProducts?.products?.results,
  storeProductsModalTableDataLoading: state.salesTracker.storeProductsModalLoading,
  storeProductsModalTableData: state.salesTracker.storeProductsModalTableData,
  storesCount: state.salesTracker.topPage?.topStores?.stores?.total,
  productsCount: state.salesTracker.topPage?.topProducts?.products?.total,
});

const mapDispatchToProps = (dispatch) => ({
  getTopStoresFilters: () => dispatch(Creators.getTopStoresFiltersRequest()),
  getTopProductsFilters: () => dispatch(Creators.getTopProductsFiltersRequest()),
  getTopStoresPresets: () => dispatch(Creators.getTopStoresPresetsRequest()),
  getTopProductsPresets: () => dispatch(Creators.getTopProductsPresetsRequest()),
  getTopStores: (data) => dispatch(Creators.getTopStoresRequest(data)),
  getTopProducts: (data) => dispatch(Creators.getTopProductsRequest(data)),
  getTopStoresCount: (data) => dispatch(Creators.getTopStoresCountRequest(data)),
  getTopProductsCount: (data) => dispatch(Creators.getTopProductsCountRequest(data)),
  getStoreProductsModal: (data) => dispatch(Creators.getStoreProductsModalRequest(data)),
  getStoreProductsModalNext: (data) => dispatch(Creators.getStoreProductsModalNextRequest(data)),
  resetStoreProductsModal: () => dispatch(Creators.resetStoreProductsModal()),
  connectTopStore: (data) => dispatch(Creators.createTrackingTopStoreRequest(data)),
  deleteTopStore: (data) => dispatch(Creators.deleteTrackingTopStoreByIDRequest(data)),
  connectTopProduct: (data) => dispatch(Creators.createTrackingTopProductRequest(data)),
  deleteTopProduct: (data) => dispatch(Creators.deleteTrackingTopProductByIDRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesTrackerTopPage);
