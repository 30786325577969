import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Input } from 'antd';
import utils from '../../Utils/utils';
import cls from 'classname';
import Icon from '../../Icon';
import ButtonComponent from '../Button';
import InputComponent from '../Input';

function SignUpEmailForm({ error, ...props }) {

  const [focusedField, setFocusedField] = useState('');
  const [fieldNotification, setFieldNotification] = useState(null);

  const [errorText, setErrorText] = useState(null);

  const [isVisibleBtn, setIsVisibleBtn] = useState(false);
  const [isBackError, setIsBackError] = useState(false);

  const [firstLoading, setFirstLoading] = useState(true);

  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (firstLoading) {
      setTimeout(() => setFirstLoading(false), 300);
    }
  }, [])

  useEffect(() => {
    if (error) {
      if (typeof error === 'string') {
        if (error.length > 100) {
          setErrorText(t('Server response error'));
        } else {
          setErrorText(error);
        }
      } else {
        setErrorText(Object.values(error)[0]);
      }
      setIsBackError(true);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [!!error]);

  const onFinish = values => {
    const data = { ...values };
    delete data.accept;
    delete data.confirm;
    props.onSubmit(values);
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields) setErrorText(errorFields[0]['errors']);
  };

  function handleClearError(e, field) {
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ]);
    let newError = null;
    let errorList = form.getFieldsError();

    errorList.forEach(field => {
      if (field.errors.length && !newError) newError = field.errors[0];
    });
    setIsBackError(false);
    setErrorText(newError);
  }

  function handleChange(e) {
    const { name, value } = e.target;
    if (firstLoading) return;

    if (utils.validateEmail(name === 'email' ? value : form.getFieldValue('email'))
      && form.getFieldValue('password')?.length >= 6
      && form.getFieldValue('first_name') && form.getFieldValue('last_name')) {
      setIsVisibleBtn(true);
    } else {
      setIsVisibleBtn(false);
    }
    if (e.target.name === 'password') {
      if (form.getFieldValue(e.target?.name) && focusedField === e.target?.name) {
        if (form.getFieldValue(e.target?.name)?.length
          && form.getFieldValue(e.target?.name)?.length < 6) {
          setFieldNotification({
            [e.target?.name]: {
              color: '#D71313',
              text: 'Passwords should be a minimum of 6 characters and include upper and lower-case letters, numbers, and special characters',
              border: 'rgb(237, 149, 149)'
            }
          });
        } else if (form.getFieldValue(e.target?.name)?.length
          && form.getFieldValue(e.target?.name)?.length >= 6
          && /[0-9]/g.test(form.getFieldValue(e.target?.name))
          && /[a-zA-Z]/g.test(form.getFieldValue(e.target?.name))
          && !/[!-\/:-@[-`{-~]/g.test(form.getFieldValue(e.target?.name))
        ) {
          setFieldNotification({
            [e.target?.name]: {
              color: '#D71313',
              text: 'Passwords should be a minimum of 6 characters and include upper and lower-case letters, numbers, and special characters',
              border: 'rgb(237, 149, 149)'
            }
          });
        } else if (form.getFieldValue(e.target?.name)?.length
          && form.getFieldValue(e.target?.name)?.length >= 6
          && /[0-9]/g.test(form.getFieldValue(e.target?.name))
          && /[a-zA-Z]/g.test(form.getFieldValue(e.target?.name))
          && /[!-\/:-@[-`{-~]/g.test(form.getFieldValue(e.target?.name))
        ) {
          setFieldNotification({
            [e.target?.name]: {
              color: '#16895A',
              text: 'Your password is strong and fully compliant with the requirements.',
              border: ''
            }
          });
        }
      } else if (!form.getFieldValue(e.target?.name)?.length && focusedField === e.target?.name) {
        setFieldNotification({
          [e.target?.name]: {
            color: '#434C69',
            text: 'Passwords should be a minimum of 6 characters and include upper and lower-case letters, numbers, and special characters',
            border: '',
          }
        });
      }
    } else if (e.target.name === 'email') {
      let mailRegex =   /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (mailRegex.test(form.getFieldValue(e.target?.name)) && focusedField === e.target?.name) {
        setFieldNotification({
          [e.target?.name]: {
            color: '',
            text: '',
            border: ''
          }
        });
      } else setFieldNotification({
        [e.target?.name]: {
          color: '#D71313',
          text: 'Enter valid email address',
          border: 'rgb(237, 149, 149)'
        }
      });
    } else {
      if (form.getFieldValue(e.target?.name)?.length && focusedField === e.target?.name) {
        setFieldNotification({
          [e.target?.name]: {
            color: '',
            text: '',
            border: ''
          }
        });
      } else {
        setFieldNotification({
          [e.target?.name]: {
            color: '#D71313',
            text: `Enter ${e.target?.name === 'first_name' ? 'first' : 'last'} name`,
            border: 'rgb(237, 149, 149)'
          }
        });
      }
    }
  }

  function fieldFocusHandler(e) {
    setFocusedField(e.target?.name);
    if (e.target?.name ==='password') {
      if (e.target.value) {
        if (e.target.value?.length
          && e.target.value?.length < 6) {
          setFieldNotification({
            [e.target?.name]: {
              color: '#D71313',
              text: 'Passwords should be a minimum of 6 characters and include upper and lower-case letters, numbers, and special characters',
              border: 'rgb(237, 149, 149)'
            }
          });
        } else if (e.target.value?.length
          && e.target.value?.length >= 6
          && /[0-9]/g.test(e.target.value)
          && /[a-zA-Z]/g.test(e.target?.value)
          && !/[!-\/:-@[-`{-~]/g.test(e.target.value)
        ) {
          setFieldNotification({
            [e.target?.name]: {
              color: '#D71313',
              text: 'Passwords should be a minimum of 6 characters and include upper and lower-case letters, numbers, and special characters',
              border: 'rgb(237, 149, 149)'
            }
          });
        } else if (e.target.value?.length
          && e.target.value?.length >= 6
          && /[0-9]/g.test(e.target.value)
          && /[a-zA-Z]/g.test(e.target?.value)
          && /[!-\/:-@[-`{-~]/g.test(e.target.value)
        ) {
          setFieldNotification({
            [e.target?.name]: {
              color: '#16895A',
              text: 'Your password is strong and fully compliant with the requirements.',
              border: ''
            }
          });
        }
      } else if (!e.target.value?.length) {
        setFieldNotification({
          [e.target?.name]: {
            color: '#434C69',
            text: 'Passwords should be a minimum of 6 characters and include upper and lower-case letters, numbers, and special characters',
            border: ''
          }
        });
      }
    } else if (e.target.name === 'email') {
      let mailRegex =   /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (mailRegex.test(e.target.value)) {
        setFieldNotification({
          [e.target?.name]: {
            color: '',
            text: '',
            border: ''
          }
        });
      } else setFieldNotification({
        [e.target?.name]: {
          color: '#D71313',
          text: 'Enter valid email address',
          border: 'rgb(237, 149, 149)'
        }
      });
    } else {
      if (e.target.value?.length) {
        setFieldNotification({
          [e.target?.name]: {
            color: '',
            text: '',
            border: ''
          }
        });
      } else {
        setFieldNotification({
          [e.target?.name]: {
            color: '#D71313',
            text: `Enter ${e.target?.name === 'first_name' ? 'first' : 'last'} name`,
            border: 'rgb(237, 149, 149)'
          }
        });
      }
    }
  }

  function fieldBlurHandler() {
    setFocusedField('');
    setFieldNotification(null);
  }

  return (
    <Form form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateTrigger="onSubmit"
          className="login-form sign-up-new-form"
          layout="vertical"
          requiredMark={false}
    >

      <Form.Item noStyle shouldUpdate={true}>
        {() => <div className={cls('error-text', { 'error-text_empty': !errorText })}>
          {
            isBackError
              ? errorText.includes('social') ?
                <span>{errorText}</span>
                : (<span>{errorText + ` ${t('Kindly try another one or')} `} <Link
                  to="/login">{t('Sign In')}</Link></span>)
              : <span>{errorText}</span>
          }

        </div>}
      </Form.Item>

      <Row gutter={16}>
        <Col span={12} style={{ paddingRight: 6 }}>
          <Form.Item name="first_name"
                     rules={[
                       { required: true, message: 'Kindly insert your full name' },
                       {
                         type: 'string',
                         message: 'You can only use letters to insert your name',
                         pattern: /^[a-zA-Z ]+$/,
                         transform(value) {
                           if (value) return value.trim();
                         },
                       },
                     ]}
          >
            <InputComponent placeholder={t('First name')}
                            name="first_name"
                            style={fieldNotification?.first_name?.border ? {borderColor: fieldNotification?.first_name?.border} : {}}
                            onFocus={fieldFocusHandler}
                            onBlur={fieldBlurHandler}
                            onChange={e => {
                              handleChange(e);
                              handleClearError(e, 'first_name');
                            }}
            />
          </Form.Item>
        </Col>
        <Col span={12} style={{ paddingLeft: 6 }}>
          <Form.Item name="last_name"
                     rules={[
                       { required: true, message: 'Kindly insert your full name' },
                       {
                         type: 'string',
                         message: 'You can only use letters to insert your name',
                         pattern: /^[a-zA-Z ]+$/,
                         transform(value) {
                           if (value) return value.trim();
                         },
                       },
                     ]}
          >
            <InputComponent placeholder={t('Last name')}
                            name="last_name"
                            style={fieldNotification?.last_name?.border ? {borderColor: fieldNotification?.last_name?.border} : {}}
                            onFocus={fieldFocusHandler}
                            onBlur={fieldBlurHandler}
                            onChange={e => {
                              handleChange(e);
                              handleClearError(e, 'last_name');
                            }}
            />
          </Form.Item>
        </Col>
      </Row>
      {
        fieldNotification?.first_name?.text || fieldNotification?.last_name?.text ?
          <span style={{color: fieldNotification?.first_name?.color || fieldNotification?.last_name?.color}}
                className='field-helper'
          >
            {fieldNotification?.first_name?.text || fieldNotification?.last_name?.text}
          </span>
          :
          null
      }
      <Form.Item name="email"
                 validateStatus={isBackError ? 'error' : null}
                 rules={[
                   { required: true, message: 'Please insert your Email' },
                   { type: 'email', message: 'The email you entered is incorrect' },
                 ]}
      >
        <InputComponent placeholder={'Email address'}
                        name="email"
                        type="email"
                        style={fieldNotification?.email?.border ? {borderColor: fieldNotification?.email?.border} : {}}
                        onFocus={fieldFocusHandler}
                        onBlur={fieldBlurHandler}
                        onChange={e => {
                          handleChange(e);
                          handleClearError(e, 'email');
                        }}
        />
      </Form.Item>
      {
        fieldNotification?.email?.text ?
          <span style={{color: fieldNotification?.email?.color}}
                className='field-helper'>
            {fieldNotification?.email?.text}
          </span>
          :
          null
      }
      <Form.Item name="password"
                 rules={[{ required: true, message: 'Please insert your password' }]}
                 className="login-form-password"
      >
        <Input.Password size="large"
                        name="password"
                        placeholder={'Password with 6+ characters'}
                        autoComplete="current-password"
                        visibilityToggle
                        prefix={null}
                        style={fieldNotification?.password?.border ? {borderColor: fieldNotification?.password?.border} : {}}
                        onFocus={fieldFocusHandler}
                        // onBlur={fieldBlurHandler}
                        onChange={e => {
                          handleChange(e);
                          handleClearError(e, 'password');
                        }}
                        iconRender={visible => (
                          visible
                            ? <Icon type="eye" color={'#C5CCE3'} />
                            : <Icon type="eye_invisible" color={'#C5CCE3'} />
                        )
                        }
        />
      </Form.Item>
      {
        fieldNotification?.password?.text ?
          <span style={{color: fieldNotification?.password?.color}}
                className='field-helper'>
            {fieldNotification?.password?.text}
          </span>
          :
          null
      }
      <ButtonComponent htmlType="submit"
                       className="login-form-submit"
                       disabled={!isVisibleBtn}
                       style={{ width: '100%' }}
      >
        Create Account
      </ButtonComponent>
    </Form>
  );
}

export default SignUpEmailForm;
