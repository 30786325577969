import React from 'react';
import cls from 'classname';
import Icon from '../../../Icon';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import ProductView from '../../ProductsPage/components/product-view';
import './PageOptions.less';

const { Search } = Input;

const PageOptions = (
  {
    displayFormat,
    setDisplayFormat,
    view,
    setView,
    setInputText,
    inputText,
    skeleton,
    disabled,
  }
) => {

  const {t} = useTranslation();

  const handleSearch = (e) => {
    setInputText(e.target.value.trim());
  }

  const handleSubmitSearch = () => {

  };

  if (skeleton) return (
    <div className={'page-options-wrapper'}>
      <div className="search-options">
        <div className="search">
          <span className={'link skeleton'}
                style={{ width: 400, height: 40 }}
          />
          <span className={'link skeleton'}
                style={{ width: 140, height: 40 }}
          />
        </div>
      </div>
      <div className={'view-options'}>
        <span className={'link skeleton'}
              style={{ width: 160, height: 40 }}
        />
        <span className={'link skeleton'}
              style={{ width: 70, height: 40 }}
        />
      </div>
    </div>
  );

  return (
    <div className={'page-options-wrapper'}>
      <div className="search-options">
        <div className="search">
          <Search placeholder={t('Insert Shopify store URL...')}
                  className={'search-input'}
                  onChange={(e) => {
                    e.persist();
                    handleSearch(e);
                  }}
                  defaultValue={inputText && inputText}
                  {...(inputText ? { value: inputText } : null)}
                  prefix={<Icon type="search_icon"
                                role="icon"
                  />}
          />
          <div className={cls('search-submit', {
            'disabled': !inputText || disabled,
          })}
               onClick={handleSubmitSearch}
          >
            <Icon type="gps" />
            {t('Start tracking')}
          </div>
        </div>
      </div>
      <div className={'view-options'}>
        <div className={"product-view page-view"}
             onClick={() => {
               setDisplayFormat('details');
               setView(view === 'stores' ? 'products' : 'stores');
             }}
        >
          <div className={cls('product-view-btn', {
            'product-view-btn--active': view === 'stores',
          })}
          >
            {t('Stores')}
          </div>
          <div className={cls('product-view-btn', {
            'product-view-btn--active': view === 'products',
          })}
          >
            {t('Products')}
          </div>
        </div>
        <ProductView view={displayFormat}
                     setView={setDisplayFormat}
        />
      </div>
    </div>
  );
};

export default PageOptions;
