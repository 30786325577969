import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import utils from '../../../Utils/utils';
import dayjs from 'dayjs';
import cls from 'classname';
import Icon from '../../../Icon';
import { Divider, Dropdown } from 'antd';
import './AdLibraryItem.less';

const data = {
  "id": "870138871946950",
  "domain_fld": "facebook.com",
  "thumbnail": [
    "https://adsavermedia.s3.amazonaws.com/46a58a84fea50ede8e8ef75075432ab9.jpg"
  ],
  "advertiser": {
    "id": "100068525354301",
    "name": "360 Boutique",
    "icon": "https://adsavermedia.s3.amazonaws.com/27b189e7472ac9a1dbd15cf0bef846b1.jpg",
    "total_ads": 422,
    "is_verified": true,
    "url": "https://www.facebook.com/100068525354301/"
  },
  "min_calendar_date": "2024-02-01",
  "call_to_action": "Send Message",
  "call_to_action_url": "https://www.facebook.com/messages/t/355801197916763",
  "creative": "image",
  "video_preview": [],
  "video_length": [],
  "ad_text": "𝗡𝗘𝗪 𝗔𝗥𝗥𝗜𝗩𝗔𝗟 | 𝗡𝗘𝗪 𝗖𝗔𝗥𝗗𝗜𝗚𝗔𝗡\n\n🍂 Thời tiết thu se lạnh là lúc mà những chiếc cardigan thời trang trở thành món đồ được săn đón rất nhiều từ các chàng trai hiện đại. Với chất liệu Interlock mịn, chi tiết bo tay và thân áo cùng điểm nhấn graphic thêu trước ngực giúp bạn trở nên thật phong cách và có gu thời trang.\n\n👍🏻 Sản phẩm đã có tại hệ thống cửa hàng, ghé 360® ngay để lựa cho mình chiếc áo cardigan đầu tiên của mùa Thu nhé❗️\n#360Boutique #Thời_trang_nam #thoitrangnam360 #thoitrangnam #cardigan #CARTK501\n------------------------\n360® - BE YOURSELF\n- Hotline: 1900 886 803 - 0973 285 886\n- Website: https://360.com.vn/\nHỆ THỐNG CỬA HÀNG:\nTP. HÀ NỘI:\n▪️ 18 Chùa Bộc - Tel: 0981 388 360\n▪️ 242 Thái Hà - Tel: 0989 765 738\n▪️ 63 Đại Cồ Việt - Tel: 0868 952 938\n▪️ 296-298 Nguyễn Trãi (gần ĐH Hà Nội) - Tel: 0972 668 938\n▪️ 24 Trần Phú, Hà Đông - Tel: 0866 604 738\n▪️ 20 Dương Quảng Hàm - Tel: 0981 840 186\n▪️ 11 Dương Quảng Hàm - Tel: 0961 829 615\n▪️ 346 Cầu Giấy - Tel: 0388 315 738\n▪️ 116 Hồ Tùng Mậu - Tel: 0976 214 038\n▪️ 29 Phố Nhổn - Tel: 0987 841 738\nTỉnh BẮC NINH:\n▪️ 214 Trần Phú, Từ Sơn - Tel: 0344 089 838\nTP. HẢI PHÒNG:\n▪️ 272 Tô Hiệu, Q. Lê Chân - Tel: 0971 624 938\nOPEN DAILY: 8h30 - 22h00\nMọi ý kiến đóng góp, phản hồi về thái độ phục vụ và chất lượng sản phẩm xin liên hệ: 0986 688 930",
  "domain": "",
  "domain_url": "",
  "ad_headline": "360 Boutique",
  "ad_description": "Cửa hàng quần áo nam",
  "post_url": "https://www.facebook.com/100068525354301/posts/870138871946950",
  "platform": "Facebook",
  "landing_page_url": [
    "https://www.facebook.com/messages/t/355801197916763"
  ],
  "internal_shop_id": null,
  "created": "2024-10-04 03:33:00",
  "first_seen_date": "2024-11-15 04:17:40",
  "last_seen_date": "2024-11-15 00:00:00",
  "status": false,
  "updated_status": null,
  "last_active": "2024-11-15 00:00:00"
}

const AdLibraryItem = (
  {
    id,
    setModal,
    setDataToCalc,
    handleOpenModalShopifyConnect
  }) => {

  const {t} = useTranslation();
  const isExtended = id % 2 === 0 || id % 3 === 0;
  const isMobile = useSelector(state => state?.nav?.isMobile);

  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (value) => setVisible(value);

  const getDiff = (date) => {
    let today = dayjs();
    let yearDiff = today.diff(dayjs(date), 'years');
    let monthDiff = today.diff(dayjs(date), 'months');
    let dayDiff = today.diff(dayjs(date), 'days');
    return yearDiff ?
      `${yearDiff}y ago`
      :
      monthDiff ?
        `${monthDiff}m ago`
        :
        dayDiff ?
          `${dayDiff}d ago`
          :
          ''
  }

  return (
    <div className={cls("adLibrary-item", {
      extended: isExtended,
    })}
    >
      <div className="adLibrary-item-header">
        <div className="adLibrary-item-header-info-wrapper">
          <div className='adLibrary-item-header-logo-wrapper'>
            <img src={data?.advertiser?.icon}
                 alt=""
                 className={'adLibrary-item-header-logo'}
                 onError={utils.addDefaultSrc}
            />
          </div>
          <div className="adLibrary-item-header-title-wrapper">
            <span className="adLibrary-item-header-title">
              {
                `${data?.advertiser?.name} #${id}`
              }
              <Icon role={'icon'} type={'ad_spot_checkmark'}/>
            </span>
            <span className="adLibrary-item-header-status">
              <div className="adLibrary-item-header-status-circle" />
              {
                t('Currently active')
              }
            </span>
          </div>
        </div>
        {
          isMobile ?
            <span className="adLibrary-item-header-action"
                  onClick={() => {
                    setModal('ad_item_action');
                    setDataToCalc({title: `${data?.advertiser?.name} #${id}`});
                  }}
            >
              <Icon type={'three_dots'} role={'icon'} />
            </span>
            :
            <Dropdown destroyPopupOnHide={true}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onOpenChange={handleVisibleChange}
                      placement="bottomRight"
                      dropdownRender={() => (
                        <div className="adLibrary-item-header-action-dropdown">
                          <div className="adLibrary-item-header-action-dropdown-option">
                            <Icon role={'icon'} type={'search_facebook'} isDark={false} />
                            <span>
                              {t('View Facebook Ad Library')}
                            </span>
                          </div>
                          <div className="adLibrary-item-header-action-dropdown-option"
                               onClick={() => {
                                 setVisible(false);
                                 handleOpenModalShopifyConnect(`storeId_productId`);
                               }}
                          >
                            <Icon type={'shopify_btn_icon'} role={'icon'} isLogo={true} />
                            <span>
                              {t('Import to Shopify store')}
                            </span>
                          </div>
                          <div className="adLibrary-item-header-action-dropdown-option">
                            <Icon role={'icon'} type={'similar_web'} isDark={false} />
                            <span>
                              {t('View with Similarweb')}
                            </span>
                          </div>
                        </div>
                      )}
                      trigger={['click']}
                      open={visible}
            >
              <span className="adLibrary-item-header-action">
                <Icon type={'three_dots'} role={'icon'} />
              </span>
            </Dropdown>
        }
      </div>
      <div className="adLibrary-item-stats">
        <span className="adLibrary-item-stat">
          1 ads with same creative
        </span>
        {/*<span className="adLibrary-item-stat">*/}
        {/*  1 adsets*/}
        {/*</span>*/}
        {/*<Divider style={{margin: '0 8px'}} type={'vertical'} />*/}
        {/*<span className="adLibrary-item-stat">*/}
        {/*  1 inactive ads*/}
        {/*</span>*/}
        {/*<Divider style={{margin: '0 8px'}} type={'vertical'} />*/}
        {/*<span className="adLibrary-item-stat"*/}
        {/*      onClick={() => {*/}
        {/*        setModal('ad_item_active_ads');*/}
        {/*        setDataToCalc({title: `${data?.advertiser?.name} #${id}`});*/}
        {/*}}*/}
        {/* >*/}
        {/*   1 active ads*/}
        {/*</span>*/}
      </div>
      <p className="adLibrary-item-description">
        {
          data?.ad_description
        }
      </p>
      {
        isExtended ?
          <div className="adLibrary-item-target-wrapper">
            <div className="adLibrary-item-target">
              <span className="adLibrary-item-target-title">
                {t('Region')}
              </span>
              <span className="adLibrary-item-target-value">
                EU
              </span>
            </div>
            <div className="adLibrary-item-target">
              <span className="adLibrary-item-target-title">
                {t('Spent')}
              </span>
              <span className="adLibrary-item-target-value">
                {
                  `$${utils.convertNumber(1213.5, 1)} - $${utils.convertNumber(121233.5, 1)}`
                }
              </span>
            </div>
            <div className="adLibrary-item-target">
              <span className="adLibrary-item-target-title">
                {t('Reach')}
              </span>
              <span className="adLibrary-item-target-value">
                {
                  `${utils.convertNumber(12113.2, 1)}`
                }
              </span>
            </div>
          </div>
          :
          null
      }
      {/*{*/}
      {/*  isExtended ?*/}
      {/*    <div className="adLibrary-item-insights">*/}
      {/*      <span onClick={() => {*/}
      {/*        setModal('ad_item_ad_spend');*/}
      {/*        setDataToCalc({title: `${data?.advertiser?.name} #${id}`});*/}
      {/*      }}>*/}
      {/*        {*/}
      {/*          t('View ad insights')*/}
      {/*        }*/}
      {/*      </span>*/}
      {/*    </div>*/}
      {/*    :*/}
      {/*    null*/}
      {/*}*/}
      <div className="adLibrary-item-image-wrapper">
        <img src={data?.thumbnail}
             alt=""
             className={'adLibrary-item-image'}
             onError={utils.addDefaultSrc}
        />
      </div>
      <div className="adLibrary-item-cta-wrapper">
        <div className="adLibrary-item-cta-text-wrapper">
          <span className="adLibrary-item-cta-title"
                onClick={() => window.open(data?.advertiser?.url, '_blank')}
          >
            {data?.ad_headline}
          </span>
          <span className="adLibrary-item-cta-text">
            {data?.ad_text}
          </span>
        </div>
        <div className="adLibrary-item-cta-btn"
             onClick={() => window.open(data?.call_to_action_url, '_blank')}
        >
          {data?.call_to_action}
        </div>
      </div>
      <div className="adLibrary-item-info-wrapper">
        {
          isExtended ?
            <div className="adLibrary-item-info">
              <span className="adLibrary-item-info-title">
                {t('Countries')}
              </span>
              <div className="adLibrary-item-info-value">
                All
              </div>
            </div>
            :
            null
        }
        {
          isExtended ?
            <div className="adLibrary-item-info">
              <span className="adLibrary-item-info-title">
                {t('Gender')}
              </span>
              <div className="adLibrary-item-info-value">
                Any
              </div>
            </div>
            :
            null
        }
        {
          isExtended ?
            <div className="adLibrary-item-info">
              <span className="adLibrary-item-info-title">
                {t('Ages')}
              </span>
              <div className="adLibrary-item-info-value">
                18-65+
              </div>
            </div>
            :
            null
        }
        <div className="adLibrary-item-info">
          <span className="adLibrary-item-info-title">
            {t('Creation date')}
          </span>
          <div className="adLibrary-item-info-value">
            {dayjs(data?.created).format('D.MM.YYYY')}
            <span className="adLibrary-item-info-value-gray">
              {getDiff(data?.created)}
            </span>
          </div>
        </div>
        <div className="adLibrary-item-info">
          <span className="adLibrary-item-info-title">
            {t('Last seen')}
          </span>
          <div className="adLibrary-item-info-value">
            {dayjs(data?.last_seen_date).format('D.MM.YYYY')}
            <span className='adLibrary-item-info-value-gray'>
              {getDiff(data?.last_seen_date)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdLibraryItem;
