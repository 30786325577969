import React from 'react';

const UniversityPageCourseWrapperSkeleton = ({isMobile}) => {
  return (
    <div className="university-page-course-wrapper">
      <span className='link skeleton' style={{width: isMobile ? 'calc(100vw - 32px)' : 370, height: isMobile ? '162' : 208,  borderRadius: 24, marginLeft: isMobile ? 16 : 32}}/>
      <div className="university-page-course-info">
        <span className='link skeleton' style={{width: 200, height: 20}}/>
        <span className='link skeleton' style={{width: 312, height: 32, marginTop: 12}}/>
        <span className='link skeleton' style={{width: '100%', height: 20, marginTop: 8}}/>
        <span className='link skeleton' style={{width: '65%', height: 20, marginTop: 4}}/>
        <span className='link skeleton' style={{width: isMobile ? '100%' : 148, height: 40, marginTop: 24}}/>
      </div>
    </div>
  );
};

export default UniversityPageCourseWrapperSkeleton;
