import React from 'react';
import {Divider} from "antd";

const SalesTrackerTopPageSkeleton = ({isMobile = false}) => {

  const options = [1,2,3,4,5];

  return (
    <div>
      <div className="sales-tracker-top-page-header">
        <div className="title-wrapper">
          <span className={'link skeleton'} style={{width: 40, height: 40}}/>
          <div className="sales-tracker-top-page-subheader">
            <span className={'link skeleton'} style={{width: 240, height: 32}}/>
            <span className={'link skeleton'} style={{width: 338, height: 24}}/>
          </div>
        </div>
        <div className={'sales-tracker-top-page-limits-wrapper'}>
          <span className={'link skeleton'} style={{width: 169, height: 40}}/>
          <span className={'link skeleton'} style={{width: 389, height: 40}}/>
        </div>
      </div>
      <div className="main-filter-wrapper">
        <span className={'link skeleton'} style={{ width: '100%', height: 40 }} />
        {
          isMobile ?
            <span className={'link skeleton'} style={{ width: '100%', height: 40, marginTop: 16 }} />
            :
            null
        }
        <Divider style={{ margin: '24px auto' }} type={'horizontal'} />
        <div className="main-filters-wrapper">
          <div className="main-filter-dropdown-wrapper">
            {options.map(el => <span key={el} className={'link skeleton'}
                                     style={{ width: isMobile ? '100%' : 110, height: 34 }} />)}
          </div>
          <div className="period-filter-wrapper">
            {isMobile ? (
              <>
                <span className={'link skeleton'} style={{ width: '50%', height: 40 }} />
                <span className={'link skeleton'} style={{ width: '50%', height: 40 }} />
              </>
            ) : (
              <span className={'link skeleton'} style={{ width: 369, height: 40 }} />
            )}
          </div>
        </div>
        <Divider style={{ margin: '24px auto' }} type={'horizontal'} />
        <div className={'fadspot-page-quick-search-wrapper'}
             style={{height: 88}}
        >
      <span className={'fadspot-page-quick-search-title'}>
        <span className={'link skeleton'} style={{ width: 92, height: 20 }} />
      </span>
          <div className="fadspot-page-quick-search-items" style={{ flexDirection: "row" }}>
            {
              [1, 2, 3].map(el => (
                <span key={el} className={'link skeleton'}
                      style={{ width: isMobile ? '100%' : 101 + el * 10, height: 32 }} />
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesTrackerTopPageSkeleton;
