import React from 'react';

export default function TableArrow() {
  return (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.33203 3.9999L3.52729 1.80464C3.78764 1.54429 4.20975 1.54429 4.4701 1.80464L6.66536 3.9999M6.66536 7.9999L4.4701 10.1952C4.20975 10.4555 3.78764 10.4555 3.52729 10.1952L1.33203 7.9999" stroke="#434C69" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}



