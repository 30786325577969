import React from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';
import "./SwitchBlockSettings.less"

const SwitchBlockSettings = ({ isYearlySubscription, onChangeSwitch, t }) => {
  return (
    <div
      className={'switch-block-settings'}
      onClick={() => onChangeSwitch(!isYearlySubscription)}
    >
      <span
        className={cls('value', {
          active: !isYearlySubscription,
        })}
      >
        {t('Monthly')}
      </span>
      <span
        className={cls('value', {
          active: isYearlySubscription,
        })}
      >
        {t('Yearly')}
        <span className="value-tag">{t('Save 40%')}</span>
      </span>
    </div>
  );
};

SwitchBlockSettings.propTypes = {
  isYearlySubscription: PropTypes.bool.isRequired,
  onChangeSwitch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default SwitchBlockSettings;
