import { useLocation } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router';
import '../../SalesTrackerPage/components/MainBlockNew.less';
import MainBlockStat from '../../SalesTrackerPage/components/MainBlockStat';
import dayjs from 'dayjs';
import acc from 'accounting';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Icon';
import './BlockStatistics.less';

const BlockStatistics = ({
  isShopify,
  data,
  setVisible,
  setModal,
  isMobile,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { search } = useLocation();

  const statBlocks = [
    {
      title: 'Store creation date',
      value: data?.store_created_at
        ? dayjs(data?.store_created_at).format('MMM DD, YYYY')
        : t('No data'),
    },
    {
      title: `Advertiser ${data?.ads_count === 1 ? 'ad' : 'ads'} found`,
      value: acc.formatNumber(data?.ads_count, 0, ','),
      onClick: () => {
        navigate(`/ad-spot/${id}/all_ads/${search}`);
      },
    },
    { title: 'Provider', value: data?.provider },
    {
      title: `Store ${
        data?.store_products_count === 1 ? 'product' : 'products'
      }`,
      value: acc.formatNumber(data?.store_products_count, 0, ','),
      ...(data?.store_products_count && {
        onClick: () => {
          setVisible(true);
          setModal('storeProducts');
        },
      }),
    },
    data?.store_apps?.length && {
      title: `Store ${data?.store_apps?.length === 1 ? 'app' : 'apps'}`,
      value: data?.store_apps.length,
      onClick: () => {
        setVisible(true);
        setModal('storeApps');
      },
    },
  ];

  return (
    <>
      {isShopify ? (
        <div className="main-stats">
          <div className="main-stats-blocks-wrapper">
            {data?.domain ? (
              <div className="main-block-stat-wrapper">
                <h4 className="main-block-stat-title">{t('Domain')}</h4>
                <div className="main-block-stat-value">
                  <a
                    className={
                      'report-main-block_link chart-block_link domain-link'
                    }
                    target={'_blank'}
                    href={data?.domain ? `https://${data?.domain}` : '#'}
                    rel="noopener noreferrer"
                  >
                    <span>{data?.domain}</span>
                    <Icon role={'icon'} type={'ad_spot_redirect'} />
                  </a>
                </div>
              </div>
            ) : null}
            {statBlocks.map((el) => (
              <MainBlockStat
                key={el?.title}
                {...el}
                t={t}
                isMobile={isMobile}
              />
            ))}
            {data?.social_channels?.length > 0 && (
              <div className="main-block-stat-wrapper">
                <div className="channels-wrapper">
                  <h4 className="main-block-stat-title">
                    {data?.social_channels?.length === 1
                      ? t('Social')
                      : t('Socials')}
                  </h4>
                  <div className="main-block-stat-value">
                    <div className="main-block-stat-value-socials">
                      {data?.social_channels?.length > 0
                        ? data?.social_channels?.map((el) => (
                            <a
                              key={el?.platform}
                              href={el?.link ? el?.link : '#'}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="link with-img"
                            >
                              <Icon
                                role={'button'}
                                width={15}
                                height={15}
                                type={`search_${el?.platform}`}
                              />
                            </a>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default BlockStatistics;
