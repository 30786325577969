import React from 'react';
import {Divider} from "antd";

const values = {
  first: {
    1: {
      first: 56,
      second: 82
    },
    2: {
      first: 115,
      second: 100
    },
    3: {
      first: 115,
      second: 100
    }
  },
  second: {
    1: {
      first: 175,
      second: 154
    },
    2: {
      first: 216,
      second: 195
    },
    3: {
      first: 230,
      second: 195
    }
  }
}

const PlanCardOnboardingSkeleton = ({ index }) => {
  return (
    <div className={'plan-card-onboarding'} style={{position: "relative", width: '100%'}}>

      <div  className={'link skeleton'} style={{width: 48, height: 48}}/>
      <div style={{display: "flex", flexDirection: "column", marginTop: 14}}>
        <span className="link skeleton" style={{width: values.second[index].first, height: 16}} />
        <span className="link skeleton" style={{width: values.second[index].second, height: 16, marginTop: 4}} />
      </div>
      <div  className={'link skeleton'} style={{width: '100%', height: 32, marginTop: 20}}/>


      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: '100%', marginTop: 20}}>
        <span className="link skeleton" style={{width: '100%', height: 32}} />
      </div>

      <div className={'skeleton-plan-card-info'}  style={{width: '100%', marginTop: 20}}>
        <div style={{display: "flex", alignItems: "center", gap:8}}>
          <span className={'link skeleton'} style={{width: 20, height: 20}} />
          <span className={'link skeleton'} style={{width: 180, height: 20, marginRight: 4}} />
        </div>
      </div>

      <div className={'skeleton-plan-card-info'}  style={{width: '100%', marginTop: 20}}>
        <div style={{display: "flex", alignItems: "center", gap:8}}>
          <span className={'link skeleton'} style={{width: 20, height: 20}} />
          <span className={'link skeleton'} style={{width: 180, height: 20, marginRight: 4}} />
        </div>
      </div>

      <div className={'skeleton-plan-card-info'}  style={{width: '100%', marginTop: 20}}>
        <div style={{display: "flex", alignItems: "center", gap:8}}>
          <span className={'link skeleton'} style={{width: 20, height: 20}} />
          <span className={'link skeleton'} style={{width: 180, height: 20, marginRight: 4}} />
        </div>
      </div>

      <div className={'skeleton-plan-card-info'}  style={{width: '100%', marginTop: 20}}>
        <div style={{display: "flex", alignItems: "center", gap:8}}>
          <span className={'link skeleton'} style={{width: 20, height: 20}} />
          <span className={'link skeleton'} style={{width: 180, height: 20, marginRight: 4}} />
        </div>
      </div>

      <div className={'skeleton-plan-card-info'}  style={{width: '100%', marginTop: 20}}>
        <div style={{display: "flex", alignItems: "center", gap:8}}>
          <span className={'link skeleton'} style={{width: 20, height: 20}} />
          <span className={'link skeleton'} style={{width: 180, height: 20, marginRight: 4}} />
        </div>
      </div>

      <div className={'skeleton-plan-card-info'}  style={{width: '100%', marginTop: 20}}>
        <div style={{display: "flex", alignItems: "center", gap:8}}>
          <span className={'link skeleton'} style={{width: 20, height: 20}} />
          <span className={'link skeleton'} style={{width: 180, height: 20, marginRight: 4}} />
        </div>
      </div>

      <div className={'skeleton-plan-card-info'}  style={{width: '100%', marginTop: 20}}>
        <div style={{display: "flex", alignItems: "center", gap:8}}>
          <span className={'link skeleton'} style={{width: 20, height: 20}} />
          <span className={'link skeleton'} style={{width: 180, height: 20, marginRight: 4}} />
        </div>
      </div>

      <div className={'skeleton-plan-card-info'}  style={{width: '100%', marginTop: 20}}>
        <div style={{display: "flex", alignItems: "center", gap:8}}>
          <span className={'link skeleton'} style={{width: 20, height: 20}} />
          <span className={'link skeleton'} style={{width: 180, height: 20, marginRight: 4}} />
        </div>
      </div>

      <div className={'skeleton-plan-card-info'}  style={{width: '100%', marginTop: 20}}>
        <div style={{display: "flex", alignItems: "center", gap:8}}>
          <span className={'link skeleton'} style={{width: 20, height: 20}} />
          <span className={'link skeleton'} style={{width: 180, height: 20, marginRight: 4}} />
        </div>
      </div>

      <div className={'skeleton-plan-card-info'}  style={{width: '100%', marginTop: 20}}>
        <div style={{display: "flex", alignItems: "center", gap:8}}>
          <span className={'link skeleton'} style={{width: 20, height: 20}} />
          <span className={'link skeleton'} style={{width: 180, height: 20, marginRight: 4}} />
        </div>
      </div>

      <div className={'skeleton-plan-card-info'}  style={{width: '100%', marginTop: 20}}>
        <div style={{display: "flex", alignItems: "center", gap:8}}>
          <span className={'link skeleton'} style={{width: 20, height: 20}} />
          <span className={'link skeleton'} style={{width: 180, height: 20, marginRight: 4}} />
        </div>
      </div>

      <div className={'skeleton-plan-card-info'}  style={{width: '100%', marginTop: 20}}>
        <div style={{display: "flex", alignItems: "center", gap:8}}>
          <span className={'link skeleton'} style={{width: 20, height: 20}} />
          <span className={'link skeleton'} style={{width: 180, height: 20, marginRight: 4}} />
        </div>
      </div>
    </div>
  );
};

export default PlanCardOnboardingSkeleton;
