import React, { useEffect, useState, useRef } from 'react';
import Icon from '../../../Icon';
import { useTranslation } from 'react-i18next';
import utils, { dateOptions } from '../../../Utils/utils';
import Creators from '../reducer';
import ShopifyCreators from '../../ShopifyStore/reducer';
import acc from 'accounting';
import dayjs from 'dayjs';
import cls from 'classname';
import { connect, useDispatch } from 'react-redux';
import AutoDSCreators from '../../AutoDS/reducer';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import Image from '../../../Components/Image';
import Images from '../../../Images';
import { Divider, Spin, Dropdown, Tooltip, Modal } from 'antd';
import ButtonComponent from '../../../Components/Button';
import ChartBlock from '../../SalesTrackerPage/components/ChartBlock';
import Chart from '../../../Components/Charts/Chart';
import AdItem from '../components/AdItem';
import MainBlockSkeleton from '../../SalesTrackerPage/components/MainBlockSkeleton';
import MainBlockAdSpot from '../components/MainBlockAdSpot';
import IconWithText from '../../../Components/Text';
import CascadingDropdown from '../components/CascadingDropdown';
import ChartInfoSkeleton from '../components/ChartInfoSkeleton';

const RadialChart = ({ data }) => {
  const { t } = useTranslation();

  let percentage = {};
  let dataToBuild = [];
  let result = [];
  const { total, ...keys } = data;

  const calcDegrees = (key) => {
    return (percentage[key] = Math.floor(((data[key] || 0) / total) * 360));
  };

  const calcSectorsSkew = (key, percentage) => {
    if (percentage[key] > 90) {
      let count = Math.floor(percentage[key] / 90);
      for (let i = 1; i <= count; i++) {
        dataToBuild.push({
          key: key,
          skew: 0,
        });
      }
      dataToBuild.push({
        key: key,
        skew: 90 - (percentage[key] - 90 * count) - 2,
      });
    } else {
      dataToBuild.push({
        key: key,
        skew: 90 - percentage[key] - 2,
      });
    }
  };

  const calcSectorsRotation = (data) => {
    for (let i = 0; i < data?.length; i++) {
      if (i === 0) {
        result.push({
          ...data[i],
          rotation: -180,
        });
      } else {
        result.push({
          ...data[i],
          rotation: result[i - 1]?.rotation + (90 - result[i - 1]?.skew) - 1,
        });
      }
    }
  };

  const buildSectors = () => {
    for (let key in keys) {
      calcDegrees(key);
    }
    for (let key in keys) {
      calcSectorsSkew(key, percentage);
    }
    calcSectorsRotation(dataToBuild);
    return result.map((el, index) => (
      <div
        key={index}
        className={`sector ${el?.key}`}
        style={{ transform: `rotate(${el?.rotation}deg) skew(${el?.skew}deg)` }}
      />
    ));
  };

  return (
    <div className="chart">
      {buildSectors()}
      <div className="total-info">
        <h4>{t('Total ad types')}</h4>
        <span>{data.total}</span>
      </div>
    </div>
  );
};

const AdItemProductsDetailed = ({
  isMobile,
  theme,
  userInfo,
  productInfo,
  getProductInfoAdSpot,
  getProductChartAdSpot,
  getProductReportAdSpot,
  getProductAdsAdSpot,
  innerPagesInfo,
  setInnerPagesDateFilters,
  setInnerPagesDateCheckedList,
  shopifyStores,
  changeVisibleModalShopify,
  creditsLeft,
  getOverviewCardInfo,
  overviewInfo,
  autoDS,
  setRecordToPriceHistory,
  setAdLandingPagesLinks,
  setVisibleModal,
  setModal,
  sortOrder,
  setSortOrder,
}) => {
  const { t } = useTranslation();
  const { productId } = useParams();
  const { search } = useLocation();

  let params = new URLSearchParams(search);

  const tableRef = useRef(null);

  const dispatch = useDispatch();

  const initialUrl =
    document?.location?.pathname + (document?.location?.search || '');
  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;

  const [historicalDataView, setHistoricalDataView] = useState(true);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [firstLoading, setFirstLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isLineChartEngagement, setIsLineChartEngagement] = useState(true);
  const [isLineChartCreationDate, setIsLineChartCreationDate] = useState(true);

  const minDate = innerPagesInfo?.dateFilters?.report_period?.min;
  const maxDate = innerPagesInfo?.dateFilters?.report_period?.max;  

  const handleOpenModalShopifyConnect = (importProductId) => {
    if (shopifyStores.length) {
      changeVisibleModalShopify({
        isVisibleModal: 'import',
        initialUrl,
        importProductId,
      });
    } else {
      changeVisibleModalShopify({
        isVisibleModal: 'connect',
        initialUrl,
        importProductId: null,
      });
    }
  };

  const handleOpenModalAutoDSConnect = (importProductId) => {
    if (autoDS?.email) {
      if (autoDS?.stores?.length)
        dispatch(
          AutoDSCreators.changeVisibleModalAuto({
            isVisibleModal: 'import',
            importProductId,
          }),
        );
      else
        dispatch(
          AutoDSCreators.changeVisibleModalAuto({
            isVisibleModal: 'import_unavailable',
            importProductId: null,
          }),
        );
    } else
      dispatch(
        AutoDSCreators.changeVisibleModalAuto({
          isVisibleModal: 'connect',
          importProductId,
        }),
      );
  };

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if (
      currentTopScroll >= maxTopScroll * 0.95 &&
      !productInfo?.adsInfo?.adsInfoLoading &&
      productInfo?.adsInfo?.results?.length < +productInfo?.adsInfo?.count
    ) {
      setPageNumber((prev) => prev + 1);
      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  useEffect(() => {
    if (firstLoading) setTimeout(() => setFirstLoading(false), 1000);
    getOverviewCardInfo({
      ad_id: params.get('ad_id'),
    });
  }, [search]);

  useEffect(() => {
    let min =
      dayjs().diff(dayjs(overviewInfo?.cardInfo?.min_calendar_date), 'days') <
      30
        ? dayjs(overviewInfo?.cardInfo?.min_calendar_date).format('YYYY-MM-DD')
        : dayjs().subtract(30, 'days').format('YYYY-MM-DD');
    let max = dayjs().format('YYYY-MM-DD');
    setInnerPagesDateFilters({ report_period: { min, max } });
    setInnerPagesDateCheckedList([
      { id: 4, name: 'Last 30 days', value: 'Last 30 days', days: 30 },
    ]);
  }, [overviewInfo?.cardInfo?.min_calendar_date]);

  useEffect(() => {
    if (minDate && maxDate) {
      getProductInfoAdSpot({
        internal_shop_id: params.get('internal_shop_id'),
        shopify_product_id: productId,
        date__gte: minDate,
        date__lte: maxDate,
      });
    }
  }, [minDate, maxDate]);

  useEffect(() => {
    getProductChartAdSpot({
      internal_shop_id: params.get('internal_shop_id'),
      shopify_product_id: productId,
      date__gte: minDate,
      date__lte: maxDate,
      historical_data_view: historicalDataView,
    });
  }, [minDate, maxDate, historicalDataView]);

  useEffect(() => {
    getProductAdsAdSpot({
      internal_shop_id: params.get('internal_shop_id'),
      shopify_product_id: productId,
      page_number: pageNumber,
      page_size: pageSize,
      sort: sortOrder,
    });
  }, [pageNumber, pageSize, sortOrder]);

  useEffect(() => {
    const tableContent = tableRef.current;
    if (
      tableContent &&
      productInfo?.adsInfo?.results?.length < +productInfo?.adsInfo?.count
    ) {
      tableContent.addEventListener('scroll', onScrollHandlerFunc);
    }
    return () => {
      if (tableContent && !firstLoading) {
        tableContent.removeEventListener('scroll', onScrollHandlerFunc);
      }
    };
  }, [
    productInfo?.adsInfo?.results?.length,
    productInfo?.adsInfo?.count,
    tableRef?.current,
  ]);

  return (
    <div className={'fadspot-page-products-detailed'}>
      {firstLoading || productInfo?.productInfo?.productInfoLoading ? (
        <div className="fadspot-page-product-info-wrapper">
          <div className="fadspot-page-product-info">
            <div className="main">
              <div className="title">
                <div className="product-info-header">
                  <span
                    className="link skeleton"
                    style={{
                      width: isMobile ? 32 : 36,
                      height: isMobile ? 32 : 36,
                      marginRight: 12,
                    }}
                  />
                  <span
                    className="link skeleton"
                    style={{ width: isMobile ? 190 : 332, height: 24 }}
                  />
                </div>
                <div className="product-info-buttons">
                  <span
                    className="link skeleton"
                    style={{ width: isMobile ? '100%' : 170, height: 40 }}
                  />
                  <span
                    className="link skeleton"
                    style={{ width: isMobile ? '100%' : 67, height: 40 }}
                  />
                </div>
              </div>
              <div className="stats">
                <div className="stat">
                  <span
                    className="link skeleton"
                    style={{ width: 34, height: 20 }}
                  />
                  <span
                    className="link skeleton"
                    style={{ width: 160, height: 24 }}
                  />
                </div>
                <div className="stat">
                  <span
                    className="link skeleton"
                    style={{ width: 85, height: 20 }}
                  />
                  <span
                    className="link skeleton"
                    style={{ width: 55, height: 24 }}
                  />
                </div>
                <div className="stat">
                  <span
                    className="link skeleton"
                    style={{ width: 63, height: 20 }}
                  />
                  <span
                    className="link skeleton"
                    style={{ width: 11, height: 24 }}
                  />
                </div>
                <div className="stat">
                  <span
                    className="link skeleton"
                    style={{ width: 93, height: 20 }}
                  />
                  <span
                    className="link skeleton"
                    style={{ width: 97, height: 24 }}
                  />
                </div>
                <div className="stat">
                  <span
                    className="link skeleton"
                    style={{ width: 93, height: 20 }}
                  />
                  <span
                    className="link skeleton"
                    style={{ width: 97, height: 24 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="fadspot-page-product-info-wrapper">
          <div className="fadspot-page-product-info">
            <div className="main">
              <div className="title">
                <div className="product-info-header">
                  <div className="fadspot-page-product-image">
                    {productInfo?.productInfo?.main_image ? (
                      <img
                        src={utils.changeImageSize(
                          productInfo?.productInfo?.main_image,
                          496,
                        )}
                        alt=""
                        onError={utils.addDefaultSrc}
                      />
                    ) : (
                      <Image src={Images.defaultImg} small={false} />
                    )}
                  </div>
                  <h3>{productInfo?.productInfo?.title}</h3>
                </div>
                <div className="product-info-buttons">
                  <ButtonComponent>
                    <span
                      onClick={() => {
                        window.open(
                          productInfo?.productInfo?.landing_page_url?.[0],
                          '_blank',
                        );
                      }}
                    >
                      <Icon type="landing_page" role="icon" />
                      {t('View landing page')}
                    </span>
                  </ButtonComponent>
                  {isMobile ? (
                    <>
                      <ButtonComponent
                        className="product-info-btn-import"
                        disabled={false}
                        onClick={() => {
                          setVisible(true);
                        }}
                      >
                        {t('Import')}
                      </ButtonComponent>
                      <Modal
                        onCancel={() => setVisible(false)}
                        title={null}
                        width={300}
                        className="change-modal custom-modal adspot-product-import"
                        getContainer={() => document.getElementById('global-wrap')}
                        open={visible}
                        destroyOnClose
                        footer={null}
                        closeIcon={
                        <Icon role="icon" type="close_modal" color="#707BA0" opacity={1} />
                        }
                      >
                        <div className="product-import-modal">
                          <span className="product-import-modal-header">
                            <span className="product-import-modal-title">{t('Product actions')}</span>
                            <span className="product-import-modal-description">{t('You are able to import product or start tracking it from here.')}</span>
                          </span>
                          <ul
                            className={
                              'filter-preset-action-dropdown database-action-dropdown'
                            }
                            style={{ width: 'unset' }}
                          >
                            <li
                              className="action-dropdown_item no-hover"
                              onClick={() => {
                                setVisible(false);
                                handleOpenModalShopifyConnect(
                                  `${productInfo?.productInfo?.internal_shop_id}_${productInfo?.productInfo?.id}`,
                                );
                              }}
                            >
                              <IconWithText
                                size={24}
                                icon={() => (
                                  <Icon
                                    type={'shopify_btn_icon'}
                                    role={'icon'}
                                    isLogo={true}
                                  />
                                )}
                                text={t('Import to Shopify')}
                              />
                            </li>
                            <li
                              className="action-dropdown_item"
                              onClick={() => {
                                setVisible(false);
                                handleOpenModalAutoDSConnect({
                                  source: 'shopify',
                                  product_id: `${productInfo?.productInfo?.store?.id}_${productInfo?.productInfo?.id}`,
                                });
                              }}
                            >
                              <IconWithText
                                icon={() => <Icon type={'auto_ds'} role={'icon'} />}
                                text={t('Import to AutoDS')}
                              />
                            </li>
                          </ul>
                        </div>
                      </Modal>
                    </>) : (
                    <Dropdown
                      open={visible}
                      onOpenChange={setVisible}
                      dropdownRender={() => (
                        <ul
                          className={
                            'filter-preset-action-dropdown database-action-dropdown'
                          }
                          style={{ width: 'unset' }}
                        >
                          <li
                            className="action-dropdown_item no-hover"
                            onClick={() => {
                              setVisible(false);
                              handleOpenModalShopifyConnect(
                                `${productInfo?.productInfo?.internal_shop_id}_${productInfo?.productInfo?.id}`,
                              );
                            }}
                          >
                            <IconWithText
                              size={24}
                              icon={() => (
                                <Icon
                                  type={'shopify_btn_icon'}
                                  role={'icon'}
                                  isLogo={true}
                                />
                              )}
                              text={t('Import to Shopify')}
                            />
                          </li>
                          <li
                            className="action-dropdown_item"
                            onClick={() => {
                              setVisible(false);
                              handleOpenModalAutoDSConnect({
                                source: 'shopify',
                                product_id: `${productInfo?.productInfo?.store?.id}_${productInfo?.productInfo?.id}`,
                              });
                            }}
                          >
                            <IconWithText
                              icon={() => <Icon type={'auto_ds'} role={'icon'} />}
                              text={t('Import to AutoDS')}
                            />
                          </li>
                        </ul>
                      )}
                      destroyPopupOnHide={true}
                      trigger={['click']}
                      placement="bottomRight"
                      getPopupContainer={(trigger) => trigger.parentNode}
                      overlayClassName={'filter-preset-cell-menu-wrapper'}
                    >
                      <ButtonComponent
                        className="product-info-btn-import"
                        disabled={false}
                        onClick={() => null}
                      >
                        {t('Import')}
                      </ButtonComponent>
                    </Dropdown>
                  )}
                </div>
              </div>
              <div className="stats">
                <div className="stat price">
                  <h4>{t('Price')}</h4>
                  <span
                    onClick={() => {
                      setRecordToPriceHistory(productInfo?.productInfo);
                      setVisibleModal(true);
                      setModal('priceHistory');
                    }}
                  >
                    $
                    {acc.formatNumber(
                      productInfo?.productInfo?.usd_price,
                      '2',
                      ',',
                      '.',
                    )}
                    {productInfo?.productInfo?.currency !== 'USD'
                      ? ` (${acc.formatNumber(
                          productInfo?.productInfo?.original_price,
                          '2',
                          ',',
                          '.',
                        )} ${productInfo?.productInfo?.currency || 'GBP'})`
                      : null}
                  </span>
                </div>
                <div className="stat">
                  <h4>{t('Engagement')}</h4>
                  {isMobile ? (
                    <span>
                      <span
                        className={'clickable'}
                        onClick={(e) => {
                          e.stopPropagation();
                          document
                            .getElementById('additional-info-block-chart')
                            .scrollIntoView({
                              behavior: 'smooth',
                            });
                        }}
                      >
                        {t('View')}
                      </span>
                      <Divider type={'vertical'} className="stat-divider" />
                      {acc.formatNumber(
                        productInfo?.productInfo?.engagement_count,
                        '0',
                        ',',
                        '.',
                      )}
                    </span>
                  ) : (
                    <span>
                      {acc.formatNumber(
                        productInfo?.productInfo?.engagement_count,
                        '0',
                        ',',
                        '.',
                      )}
                      <Divider type={'vertical'} className="stat-divider" />
                      <span
                        className={'clickable'}
                        onClick={(e) => {
                          e.stopPropagation();
                          document
                            .getElementById('additional-info-block-chart')
                            .scrollIntoView({
                              behavior: 'smooth',
                            });
                        }}
                      >
                        {t('View')}
                      </span>
                    </span>
                  )}
                </div>
                <div className="stat">
                  <h4>{t('Ad count')}</h4>
                  {isMobile ? (
                    <span>
                      <span
                        className={'clickable'}
                        onClick={(e) => {
                          e.stopPropagation();
                          document
                            .getElementById('fadspot-page-items-wrapper')
                            .scrollIntoView({ behavior: 'smooth' });
                        }}
                      >
                        {t('View')}
                      </span>
                      <Divider type={'vertical'} className="stat-divider" />
                      {acc.formatNumber(
                        productInfo?.productInfo?.ad_count,
                        '0',
                        ',',
                        '.',
                      )}
                    </span>
                  ) : (
                    <span>
                      {acc.formatNumber(
                        productInfo?.productInfo?.ad_count,
                        '0',
                        ',',
                        '.',
                      )}
                      <Divider type={'vertical'} className="stat-divider" />
                      <span
                        className={'clickable'}
                        onClick={(e) => {
                          e.stopPropagation();
                          document
                            .getElementById('fadspot-page-items-wrapper')
                            .scrollIntoView({ behavior: 'smooth' });
                        }}
                      >
                        {t('View')}
                      </span>
                    </span>
                  )}
                </div>
                <div className="stat">
                  <h4>{t('Creation date')}</h4>
                  <span>
                    {dayjs(productInfo?.productInfo?.created_at).format(
                      'MMM DD, YYYY',
                    )}
                  </span>
                </div>
                <div className="stat">
                  <h4>{t('Socials')}</h4>
                  <div className="stat-quick-search">
                    {productInfo?.productInfo?.quick_search?.length
                      ? productInfo?.productInfo?.quick_search.map(
                          (el, index) => {
                            return (
                              <a
                                key={el.platform}
                                href={el?.link || '#'}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link with-img"
                              >
                                <Icon
                                  width={20}
                                  height={20}
                                  role="button"
                                  type={`search_${el.platform}`}
                                  theme={theme}
                                  className={`${el.platform}-icon`}
                                  titleText={() => (
                                    <>
                                      {t('Search on')} {' '}
                                      <span
                                        style={{ textTransform: 'capitalize' }}
                                      >
                                        {el.platform}
                                      </span>
                                    </>
                                  )}
                                  tooltipProps={{
                                    placement: 'bottom',
                                    destroyTooltipOnHide: true,
                                    trigger: 'hover',
                                    overlayClassName: cls(
                                      'details-tooltip',
                                      `${el.name}-tooltip`,
                                    ),
                                    getPopupContainer: (trigger) =>
                                      trigger.parentNode,
                                  }}
                                />
                              </a>
                            );
                          },
                        )
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {firstLoading || productInfo?.chartInfo?.chartInfoLoading ? (
        <MainBlockSkeleton isMobile={isMobile} />
      ) : (
        <MainBlockAdSpot
          data={{
            internal_shop_id: params.get('internal_shop_id'),
            shopify_product_id: productId,
            ad_headline: productInfo?.productInfo?.title,
            timeZone: productInfo?.productInfo?.store_timezone,
            timeInfo: overviewInfo?.cardInfo?.min_calendar_date,
            dates: { min: minDate, max: maxDate },
          }}
          downloadReport={getProductReportAdSpot}
          dateOptions={dateOptions}
          fileLoading={productInfo?.reportLoading}
          isProduct={true}
          isMobile={isMobile}
        />
      )}

      {productInfo?.chartInfo?.chartInfoLoading ? (
        <ChartInfoSkeleton isMobile={isMobile} />
      ) : (
        <ChartBlock
          type={'ad_spot'}
          subTitle={`${dayjs(minDate).format('MMM DD, YYYY')} - ${dayjs(
            maxDate,
          ).format('MMM DD, YYYY')}`}
          totalCount={productInfo?.chartInfo?.stats?.total_engagements || 0}
          link={null}
          adsInfo={true}
          historicalDataViewToggleText={[t('Daily'), t('Cumulative')]}
          historicalDataView={historicalDataView}
          setHistoricalDataView={setHistoricalDataView}
          adsQuantity={productInfo?.chartInfo?.stats?.total_ads}
          id={'additional-info-block-chart'}
          isMobile={isMobile}
          isLineChart={isLineChartEngagement}
          setIsLineChart={setIsLineChartEngagement}
        >
          <Chart
            data={
              productInfo?.chartInfo?.charts?.engagements?.length
                ? [...productInfo?.chartInfo?.charts?.engagements]
                : []
            }
            height={isMobile ? null : '350px'}
            type={'ad_spot'}
            animation={false}
            isMobile={isMobile}
            externalTooltip={false}
            chartType={!isLineChartEngagement && 'bar'}
          />
          <Divider style={{ margin: '16px auto' }} type={'horizontal'} />
          <div className="fadspot-page-overview-chart-stats">
            <div className="fadspot-page-overview-chart-stat">
              <div className="fadspot-page-overview-chart-stat-wrapper">
                <div className="fadspot-page-overview-chart-stat-icon">
                  <Icon
                    type={'ad_spot_chart_like'}
                    role={'icon'}
                    width={20}
                    height={20}
                  />
                </div>
                <div className="fadspot-page-overview-chart-stat-info">
                  <p>
                    {t('Reactions')}
                    <Icon
                      type={'attention_outline'}
                      role="button"
                      width={16}
                      height={16}
                      tooltipProps={{
                        trigger: 'hover',
                        placement: isMobile ? 'bottom' : 'right',
                        overlayClassName: 'subscription-tooltip scheduled',
                        getPopupContainer: (trigger) => trigger.parentNode,
                      }}
                      titleText={t(
                        'We calculate Reactions based on the sum of reactions the ad receives, such as Like, Love, Haha, Wow, Sad, and Angry.',
                      )}
                    />
                  </p>
                </div>
              </div>
              <span>
                {acc.formatNumber(
                  productInfo?.chartInfo?.stats?.total_reactions_count,
                  0,
                  ',',
                  '',
                )}
              </span>
            </div>
            <div className="fadspot-page-overview-chart-stat">
              <div className="fadspot-page-overview-chart-stat-wrapper">
                <div className="fadspot-page-overview-chart-stat-icon">
                  <Icon
                    type={'ad_spot_chart_comments'}
                    role={'icon'}
                    width={20}
                    height={20}
                  />
                </div>
                <div className="fadspot-page-overview-chart-stat-info">
                  <p>
                    {t('Comments')}
                  </p>
                </div>
              </div>
              <span>
                {acc.formatNumber(
                  productInfo?.chartInfo?.stats?.comments_count,
                  0,
                  ',',
                  '',
                )}
              </span>
            </div>
            <div className="fadspot-page-overview-chart-stat">
              <div className="fadspot-page-overview-chart-stat-wrapper">
                <div className="fadspot-page-overview-chart-stat-icon">
                  <Icon
                    type={'ad_spot_chart_shares'}
                    role={'icon'}
                    width={20}
                    height={20}
                  />
                </div>
                <div className="fadspot-page-overview-chart-stat-info">
                  <p>
                    {t('Shares')}
                  </p>
                </div>
              </div>
              <span>
                {acc.formatNumber(
                  productInfo?.chartInfo?.stats?.shares_count,
                  0,
                  ',',
                  '',
                )}
              </span>
            </div>
          </div>
        </ChartBlock>
      )}

      {productInfo?.chartInfo?.chartInfoLoading ? (
        <div className="fadspot-page-product-additional-info">
          <div className="additional-info-block">
            <div className="landing-pages ad-types">
              {isMobile ? <Divider type={'horizontal'} /> : null}
              <span
                className="link skeleton"
                style={{ width: 80, height: 20 }}
              />
              <div className="ad-types-info">
                <span
                  className="link skeleton"
                  style={{
                    width: isMobile ? 136 : 165,
                    height: isMobile ? 136 : 165,
                    borderRadius: '50%',
                  }}
                />
                <div className="stats">
                  <div className="stat">
                    <div className="title">
                      <div>
                        <span
                          className="link skeleton"
                          style={{ width: 84, height: 20 }}
                        />
                      </div>
                      <div>
                        <span
                          className="link skeleton"
                          style={{ width: 20, height: 20 }}
                        />
                        <Divider
                          style={{ margin: '0 8px' }}
                          type={'vertical'}
                        />
                        <span
                          className="link skeleton"
                          style={{ width: 40, height: 20 }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="stat">
                    <div className="title">
                      <div>
                        <span
                          className="link skeleton"
                          style={{ width: 84, height: 20 }}
                        />
                      </div>
                      <div>
                        <span
                          className="link skeleton"
                          style={{ width: 20, height: 20 }}
                        />
                        <Divider
                          style={{ margin: '0 8px' }}
                          type={'vertical'}
                        />
                        <span
                          className="link skeleton"
                          style={{ width: 40, height: 20 }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="stat">
                    <div className="title">
                      <div>
                        <span
                          className="link skeleton"
                          style={{ width: 104, height: 20 }}
                        />
                      </div>
                      <div>
                        <span
                          className="link skeleton"
                          style={{ width: 20, height: 20 }}
                        />
                        <Divider
                          style={{ margin: '0 8px' }}
                          type={'vertical'}
                        />
                        <span
                          className="link skeleton"
                          style={{ width: 40, height: 20 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="additional-info-block">
            <div className="landing-pages">
              {isMobile ? <Divider type={'horizontal'} /> : null}
              <span
                className="link skeleton"
                style={{ width: 131, height: 24, marginTop: 8 }}
              />
              <span
                className="link skeleton"
                style={{ width: 50, height: 50, marginTop: 8 }}
              />
              <span
                className="link skeleton"
                style={{ width: '100%', height: 24, marginTop: 8 }}
              />
              <span
                className="link skeleton"
                style={{ width: '100%', height: 24, marginTop: 8 }}
              />
              <span
                className="link skeleton"
                style={{ width: '100%', height: 24, marginTop: 8 }}
              />
              <span
                className="link skeleton"
                style={{ width: '100%', height: 24, marginTop: 8 }}
              />
              <span
                className="link skeleton"
                style={{ width: '100%', height: 24, marginTop: 8 }}
              />

              <span
                className="link skeleton"
                style={{ width: '100%', height: 24, marginTop: 8 }}
              />
              <span
                className="link skeleton"
                style={{ width: '100%', height: 24, marginTop: 8 }}
              />
            </div>
          </div>
          {/* <div className="additional-info-block"> */}
          {/*  <div className="landing-pages ad-status">*/}
          {/*    <span className="link skeleton" style={{width: 131, height: 24}}/>*/}
          {/*    <Divider type={'horizontal'} style={{margin: '16px auto'}} />*/}
          {/*    <div className="info-stats">*/}
          {/*      <div className="info-stat-wrapper">*/}
          {/*        <span className="link skeleton" style={{width: 8, height: 84}}/>*/}
          {/*        <div className={'stats'}>*/}
          {/*          <span className="link skeleton" style={{width: 71, height: 20}}/>*/}
          {/*          <span className="link skeleton" style={{width: 124, height: 40}}/>*/}
          {/*          <div className="types">*/}
          {/*            <div className="type">*/}
          {/*              <span className="link skeleton" style={{width: 20, height: 20, marginRight: 2}}/>*/}
          {/*              <span className="link skeleton" style={{width: 24, height: 20}}/>*/}
          {/*            </div>*/}
          {/*            <div className="type">*/}
          {/*              <span className="link skeleton" style={{width: 20, height: 20, marginRight: 2}}/>*/}
          {/*              <span className="link skeleton" style={{width: 24, height: 20}}/>*/}
          {/*            </div>*/}
          {/*            <div className="type">*/}
          {/*              <span className="link skeleton" style={{width: 20, height: 20, marginRight: 2}}/>*/}
          {/*              <span className="link skeleton" style={{width: 24, height: 20}}/>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="info-stat-wrapper">*/}
          {/*        <span className="link skeleton" style={{width: 8, height: 84}}/>*/}
          {/*        <div className={'stats'}>*/}
          {/*          <span className="link skeleton" style={{width: 71, height: 20}}/>*/}
          {/*          <span className="link skeleton" style={{width: 124, height: 40}}/>*/}
          {/*          <div className="types">*/}
          {/*            <div className="type">*/}
          {/*              <span className="link skeleton" style={{width: 20, height: 20, marginRight: 2}}/>*/}
          {/*              <span className="link skeleton" style={{width: 24, height: 20}}/>*/}
          {/*            </div>*/}
          {/*            <div className="type">*/}
          {/*              <span className="link skeleton" style={{width: 20, height: 20, marginRight: 2}}/>*/}
          {/*              <span className="link skeleton" style={{width: 24, height: 20}}/>*/}
          {/*            </div>*/}
          {/*            <div className="type">*/}
          {/*              <span className="link skeleton" style={{width: 20, height: 20, marginRight: 2}}/>*/}
          {/*              <span className="link skeleton" style={{width: 24, height: 20}}/>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <span className="link skeleton" style={{width: '100%', height: 72, marginTop: 16}}/>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      ) : (
        <div className="fadspot-page-product-additional-info">
          <div className="additional-info-block">
            <div className="landing-pages ad-types">
              {isMobile ? (
                <Divider type={'horizontal'} style={{ marginTop: 8 }} />
              ) : null}
              <h4>{t('Ad types')}</h4>
              <div className="ad-types-info">
                <RadialChart
                  data={{
                    total: productInfo?.chartInfo?.stats?.total_ads,
                    video: productInfo?.chartInfo?.ad_type?.video,
                    photo: productInfo?.chartInfo?.ad_type?.image,
                    carousel: productInfo?.chartInfo?.ad_type?.carousel,
                  }}
                />
                <div className="stats">
                  <div className="stat">
                    <div className="title">
                      <div>
                        <div className="title-video-block" />
                        <Icon type={'ad_spot_video'} role={'icon'} />
                        <span>{t('Video')}</span>
                      </div>
                      <div className="title-block-wrapper-count">
                        <span className="title-block-count">
                          {acc.formatNumber(
                            productInfo?.chartInfo?.ad_type?.video,
                            0,
                            ',',
                          )}
                        </span>
                        <Divider
                          style={{ margin: '0 8px' }}
                          type={'vertical'}
                          className="title-block-wrapper-divider"
                        />
                        <span className="title-block-count">
                          {acc.formatNumber(
                            (productInfo?.chartInfo?.ad_type?.video /
                              productInfo?.chartInfo?.stats?.total_ads) *
                              100,
                            0,
                            '',
                            '',
                          )}
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="stat">
                    <div className="title">
                      <div>
                        <div className="title-photo-block" />
                        <Icon type={'ad_spot_photo'} role={'icon'} />
                        <span>{t('Photo')}</span>
                      </div>
                      <div className="title-block-wrapper-count">
                        <span className="title-block-count">
                          {acc.formatNumber(
                            productInfo?.chartInfo?.ad_type?.image,
                            0,
                            ',',
                          )}
                        </span>
                        <Divider
                          style={{ margin: '0 8px' }}
                          type={'vertical'}
                          className="title-block-wrapper-divider"
                        />
                        <span className="title-block-count">
                          {acc.formatNumber(
                            (productInfo?.chartInfo?.ad_type?.image /
                              productInfo?.chartInfo?.stats?.total_ads) *
                              100,
                            0,
                            '',
                            '',
                          )}
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="stat">
                    <div className="title">
                      <div>
                        <div className="title-carousel-block" />
                        <Icon type={'ad_spot_carousel'} role={'icon'} />
                        <span>{t('Carousel')}</span>
                      </div>
                      <div className="title-block-wrapper-count">
                        <span className="title-block-count">
                          {acc.formatNumber(
                            productInfo?.chartInfo?.ad_type?.carousel,
                            0,
                            ',',
                          )}
                        </span>
                        <Divider
                          style={{ margin: '0 8px' }}
                          type={'vertical'}
                          className="title-block-wrapper-divider"
                        />
                        <span className="title-block-count">
                          {acc.formatNumber(
                            (productInfo?.chartInfo?.ad_type?.carousel /
                              productInfo?.chartInfo?.stats?.total_ads) *
                              100,
                            0,
                            '',
                            '',
                          )}
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isMobile ? (
                <Divider type={'horizontal'} style={{ margin: '24px 0 0' }} />
              ) : null}
            </div>
          </div>
          <div className="additional-info-block">
            <div className="landing-pages">
              <div className="landing-pages-header">
                <h3>
                  {productInfo?.chartInfo?.ad_landing_page?.total === 1
                    ? t('Ad Landing Page')
                    : t('Ad Landing Pages')}
                </h3>
                {!(
                  [...productInfo?.chartInfo?.ad_landing_page?.results]
                    .length <= 7
                ) ? (
                  <span
                    onClick={() => {
                      setAdLandingPagesLinks([
                        ...productInfo?.chartInfo?.ad_landing_page?.results,
                      ]);
                      setVisibleModal(true);
                      setModal('adLandingPages');
                    }}
                  >
                    {t('View all')}
                    <Icon role={'icon'} type={'arrow_slider_next'} />
                  </span>
                ) : null}
              </div>
              <span className="landing-pages-count">
                {acc.formatNumber(
                  productInfo?.chartInfo?.ad_landing_page?.total,
                  0,
                  ',',
                )}
              </span>
              <div className="links">
                {productInfo?.chartInfo?.ad_landing_page?.results?.length
                  ? [...productInfo?.chartInfo?.ad_landing_page?.results].map(
                      (el) => (
                        <div className="link" key={el?.id}>
                          <Icon type={'ad_spot_link'} role={'icon'} />
                          <div>
                            <Tooltip
                              placement="top"
                              title={el?.landing_page_url}
                              destroyTooltipOnHide={true}
                              getPopupContainer={(triggerNode) =>
                                triggerNode?.parentNode
                              }
                            >
                              <a
                                className={'ad-landing-pages-link'}
                                href={el?.landing_page_url}
                                target={'_blank'}
                              >
                                {isMobile
                                  ? el?.landing_page_url &&
                                    el.landing_page_url.length >= 31
                                    ? `${el.landing_page_url.slice(0, 31)}...`
                                    : el?.landing_page_url
                                  : el?.landing_page_url}
                              </a>
                              <div>
                                <span>
                                  {acc.formatNumber(el?.count, 0, ',')}
                                </span>
                                <Divider
                                  style={{ margin: '0 8px' }}
                                  className="landing-pages-divider"
                                  type={'vertical'}
                                />
                                <span>
                                  {acc.formatNumber(el?.percent, 0, ',')}%
                                </span>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      ),
                    )
                  : null}
              </div>
            </div>
          </div>
          {/*<div className="additional-info-block">*/}
          {/*  <div className="landing-pages ad-status">*/}
          {/*    <h4>*/}
          {/*      {t('Ad Status')}*/}
          {/*    </h4>*/}
          {/*    <Divider type={'horizontal'} style={{margin: '8px auto 16px'}} />*/}
          {/*    <div className="info-stats">*/}
          {/*      <div className="info-stat-wrapper">*/}
          {/*        <div className="bar active" />*/}
          {/*        <div className={'stats'}>*/}
          {/*      <span className="title">*/}
          {/*        {t('Active Ads')}*/}
          {/*      </span>*/}
          {/*          <span className="value">*/}
          {/*            {*/}
          {/*              acc.formatNumber(productInfo?.chartInfo?.ad_status?.active?.total, 0, ',')*/}
          {/*            }*/}
          {/*            <span>({*/}
          {/*              acc.formatNumber(productInfo?.chartInfo?.ad_status?.active?.percent, 0, ',')*/}
          {/*            }%)</span>*/}
          {/*          </span>*/}
          {/*          <div className="types">*/}
          {/*            <div className="type">*/}
          {/*              <Icon type={'ad_spot_video'} role={'icon'}/>*/}
          {/*              <span>{acc.formatNumber(productInfo?.chartInfo?.ad_status?.active?.types?.video, 0, ',', '')}</span>*/}
          {/*            </div>*/}
          {/*            <div className="type">*/}
          {/*              <Icon type={'ad_spot_photo'} role={'icon'}/>*/}
          {/*              <span>{acc.formatNumber(productInfo?.chartInfo?.ad_status?.active?.types?.image, 0, ',', '')}</span>*/}
          {/*            </div>*/}
          {/*            <div className="type">*/}
          {/*              <Icon type={'ad_spot_carousel'} role={'icon'}/>*/}
          {/*              <span>{acc.formatNumber(productInfo?.chartInfo?.ad_status?.active?.types?.carousel, 0, ',', '')}</span>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="info-stat-wrapper">*/}
          {/*        <div className="bar" />*/}
          {/*        <div className={'stats'}>*/}
          {/*      <span className="title">*/}
          {/*        {t('Inactive Ads')}*/}
          {/*      </span>*/}
          {/*          <span className="value">*/}
          {/*            {*/}
          {/*              acc.formatNumber(productInfo?.chartInfo?.ad_status?.inactive?.total, 0, ',')*/}
          {/*            }*/}
          {/*            <span>({*/}
          {/*              acc.formatNumber(productInfo?.chartInfo?.ad_status?.inactive?.percent, 0, ',')*/}
          {/*            }%)</span>*/}
          {/*          </span>*/}
          {/*          <div className="types">*/}
          {/*            <div className="type">*/}
          {/*              <Icon type={'ad_spot_video'} role={'icon'}/>*/}
          {/*              <span>{acc.formatNumber(productInfo?.chartInfo?.ad_status?.inactive?.types?.video, 0, ',', '')}</span>*/}
          {/*            </div>*/}
          {/*            <div className="type">*/}
          {/*              <Icon type={'ad_spot_photo'} role={'icon'}/>*/}
          {/*              <span>{acc.formatNumber(productInfo?.chartInfo?.ad_status?.inactive?.types?.image, 0, ',', '')}</span>*/}
          {/*            </div>*/}
          {/*            <div className="type">*/}
          {/*              <Icon type={'ad_spot_carousel'} role={'icon'}/>*/}
          {/*              <span>{acc.formatNumber(productInfo?.chartInfo?.ad_status?.inactive?.types?.carousel, 0, ',', '')}</span>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className="diagram">*/}
          {/*      <div className="active" style={{width: `${productInfo?.chartInfo?.ad_status?.active?.percent}%`}} />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      )}

      {productInfo?.chartInfo?.chartInfoLoading ? (
        <div className={'chart-block'}>
          {isMobile ? <Divider type={'horizontal'} /> : null}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span
                className="link skeleton"
                style={{ width: 122, height: 24, marginBottom: 8 }}
              />
            </div>
            {isMobile ? null : (
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <span
                  className="link skeleton"
                  style={{ width: 80, height: 40 }}
                />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', marginBottom: 8 }}>
            <span
              className="link skeleton"
              style={{ width: isMobile ? '100%' : 60, height: 40 }}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <span
              className="link skeleton"
              style={{ width: '100%', height: 350 }}
            />
          </div>
        </div>
      ) : (
        <div className="additional-info-block wide">
          {isMobile ? (
            <div style={{ margin: '0px 16px' }}>
              <Divider type={'horizontal'} />
            </div>
          ) : null}
          <ChartBlock
            type={'ad_spot_date'}
            // subTitle={`${dayjs(minDate).format('MMM DD, YYYY')} - ${dayjs(
            //   maxDate,
            // ).format('MMM DD, YYYY')}`}
            subTitle={null}
            totalCount={productInfo?.chartInfo?.stats?.total_ads}
            link={null}
            isMobile={isMobile}
            isLineChart={isLineChartCreationDate}
            setIsLineChart={setIsLineChartCreationDate}
          >
            <Chart
              data={
                productInfo?.chartInfo?.charts?.creation_date?.length
                  ? [...productInfo?.chartInfo?.charts?.creation_date]
                  : []
              }
              type={'ad_spot_date'}
              animation={false}
              isMobile={isMobile}
              height={isMobile ? null : '350px'}
              chartType={!isLineChartCreationDate && 'bar'}
            />
          </ChartBlock>
        </div>
      )}
      {isMobile ? (
        <div style={{ margin: '0px 16px' }}>
          <Divider type={'horizontal'} />
        </div>
      ) : null}

      {productInfo?.chartInfo?.chartInfoLoading ||
      productInfo?.productInfo?.productInfoLoading ||
      firstLoading ? (
        <div
          style={{
            display: 'flex',
            padding: isMobile ? '0px 12px' : '0px 32px',
            justifyContent: 'space-between',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          <span
            className="link skeleton"
            style={{ width: 80, height: 40, marginBottom: 8 }}
          />
          <span
            className="link skeleton"
            style={{ width: isMobile ? '100%' : 190, height: 40 }}
          />
        </div>
      ) : (
        <div
          className="fadspot-page-pagination-wrapper"
          style={{
            display: productInfo?.adsInfo?.adsInfoLoading ? 'none' : 'flex',
          }}
        >
          <div className="fadspot-page-pagination-header" ref={tableRef} id={'fadspot-page-items-wrapper'}>
            <span className="fadspot-page-pagination-title">{t('Ads')}</span>
          </div>
          <CascadingDropdown
            value={sortOrder}
            setValue={setSortOrder}
            isMobile={isMobile}
            isTrial={isTrial}
            disabled={creditsLeft === 0}
            setModal={setModal}
            setVisible={setVisibleModal}
          />
        </div>
      )}

      {productInfo?.chartInfo?.chartInfoLoading ||
      productInfo?.productInfo?.productInfoLoading ||
      firstLoading ? (
        <div className="fadspot-page-items-wrapper">
          {[1, 2, 3, 4, 5, 6].map((el) => (
            <span
              key={el}
              className="fadspot-page-item-wrapper link skeleton"
            />
          ))}
        </div>
      ) : (
        <Spin spinning={productInfo?.adsInfo?.adsInfoLoading}>
          <div
            className="fadspot-page-items-wrapper"
          >
            {productInfo?.adsInfo?.results?.length
              ? [...productInfo?.adsInfo?.results]?.map((el, index) => (
                  <AdItem
                    key={el?.id + index}
                    data={{ ...el }}
                    isInnerPage={false}
                    isAlive={el?.status}
                  />
                ))
              : null}
          </div>
        </Spin>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state?.nav?.isMobile,
  userInfo: state.auth.userInfo,
  theme: state?.nav?.theme,
  productInfo: state?.adSpot?.innerPagesInfo?.product,
  innerPagesInfo: state?.adSpot?.innerPagesInfo,
  shopifyStores: state?.shopifyStore?.results,
  autoDS: state?.autoDS,
  creditsLeft: state.adSpot.creditsLeft,
  overviewInfo: state?.adSpot?.innerPagesInfo?.overview,
  sortOrder: state.adSpot.selectedSort,
});

const mapDispatchToProps = (dispatch) => ({
  setSortOrder: (data) => dispatch(Creators.setSelectedSort(data)),
  getProductInfoAdSpot: (data) =>
    dispatch(Creators.getProductInfoAdSpotRequest(data)),
  getOverviewCardInfo: (data) =>
    dispatch(Creators.getOverviewCardInfoRequest(data)),
  getProductChartAdSpot: (data) =>
    dispatch(Creators.getProductChartAdSpotRequest(data)),
  getProductReportAdSpot: (data) =>
    dispatch(Creators.getProductReportAdSpotRequest(data)),
  getProductAdsAdSpot: (data) =>
    dispatch(Creators.getProductAdsAdSpotRequest(data)),
  setInnerPagesDateFilters: (data) =>
    dispatch(Creators.setInnerPagesDateFilters(data)),
  setInnerPagesDateCheckedList: (data) =>
    dispatch(Creators.setInnerPagesDateCheckedList(data)),
  changeVisibleModalShopify: (data) =>
    dispatch(ShopifyCreators.changeVisibleModalShopify(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdItemProductsDetailed);
