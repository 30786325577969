import React from 'react';

const Refresh = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M4.22987 4.66667C5.15708 3.64454 6.51742 3 8 3C10.7614 3 13 5.23858 13 8C13 8.2121 12.9868 8.42088 12.9613 8.62563C12.9271 8.89965 13.1216 9.14949 13.3956 9.18366C13.6696 9.21783 13.9194 9.02339 13.9536 8.74937C13.9842 8.50367 14 8.25356 14 8C14 4.68629 11.3137 2 8 2C6.3194 2 4.77027 2.69162 3.66667 3.80748V2.5C3.66667 2.22386 3.44281 2 3.16667 2C2.89052 2 2.66667 2.22386 2.66667 2.5V4.66667C2.66667 5.21895 3.11438 5.66667 3.66667 5.66667H5.83333C6.10948 5.66667 6.33333 5.44281 6.33333 5.16667C6.33333 4.89052 6.10948 4.66667 5.83333 4.66667H4.22987Z"
        fill="#A1AAC8" />
      <path
        d="M3.03871 7.37437C3.07288 7.10035 2.87844 6.85051 2.60442 6.81634C2.3304 6.78217 2.08056 6.97661 2.04639 7.25063C2.01576 7.49633 2 7.74644 2 8C2 11.3137 4.68629 14 8 14C9.68461 14 11.2371 13.3051 12.3412 12.1845V13.5C12.3412 13.7761 12.5651 14 12.8412 14C13.1174 14 13.3412 13.7761 13.3412 13.5V11.3333C13.3412 10.781 12.8935 10.3333 12.3412 10.3333H10.1746C9.89842 10.3333 9.67456 10.5572 9.67456 10.8333C9.67456 11.1095 9.89842 11.3333 10.1746 11.3333H11.7701C10.8429 12.3555 9.48258 13 8 13C5.23858 13 3 10.7614 3 8C3 7.7879 3.01318 7.57912 3.03871 7.37437Z"
        fill="#A1AAC8" />
    </svg>
  );
};

export default Refresh;
