import React from 'react';
import { useSelector } from 'react-redux';
import cls from 'classname';

const AdLibrary = ({ className, outline = true }) => {

  const theme = useSelector(state => state?.nav?.theme);

  return outline ? (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
         className={cls(className, 'icon icon-adlibrary icon-adlibrary-outline')}
    >
      <path d="M5.62496 16.0417V5.625C5.62496 5.16476 5.25186 4.79167 4.79163 4.79167H3.12496C2.66472 4.79167 2.29163 5.16476 2.29163 5.625V16.0417C2.29163 16.5019 2.66472 16.875 3.12496 16.875H4.79163C5.25186 16.875 5.62496 16.5019 5.62496 16.0417ZM5.62496 16.0417V3.95833C5.62496 3.4981 5.99806 3.125 6.45829 3.125H10.2083C10.6685 3.125 11.0416 3.4981 11.0416 3.95833V16.0417C11.0416 16.5019 10.6685 16.875 10.2083 16.875H6.45829C5.99806 16.875 5.62496 16.5019 5.62496 16.0417ZM5.62496 6.5625H11.0416M5.62496 13.4375H11.0416M15.3833 16.901L17.3956 16.3618C17.8402 16.2427 18.104 15.7857 17.9849 15.3412L15.5045 6.08437C15.3854 5.63981 14.9285 5.37599 14.4839 5.49511L12.4716 6.03432C12.027 6.15344 11.7632 6.61038 11.8823 7.05494L14.3627 16.3117C14.4818 16.7563 14.9387 17.0201 15.3833 16.901Z"
            stroke={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, .26"}
            strokeWidth="1.5"
            strokeLinecap="square"
            strokeLinejoin="round"
      />
    </svg>
    )
    :
    (
      <svg xmlns="http://www.w3.org/2000/svg"
           width="20"
           height="20"
           viewBox="0 0 20 20"
           fill="none"
           className={cls(className, 'icon icon-adlibrary icon-adlibrary-bold')}
      >
        <path d="M5.62496 16.0417V5.625C5.62496 5.16476 5.25186 4.79167 4.79163 4.79167H3.12496C2.66472 4.79167 2.29163 5.16476 2.29163 5.625V16.0417C2.29163 16.5019 2.66472 16.875 3.12496 16.875H4.79163C5.25186 16.875 5.62496 16.5019 5.62496 16.0417ZM5.62496 16.0417V3.95833C5.62496 3.4981 5.99806 3.125 6.45829 3.125H10.2083C10.6685 3.125 11.0416 3.4981 11.0416 3.95833V16.0417C11.0416 16.5019 10.6685 16.875 10.2083 16.875H6.45829C5.99806 16.875 5.62496 16.5019 5.62496 16.0417ZM5.62496 6.5625H11.0416M5.62496 13.4375H11.0416M15.3833 16.901L17.3956 16.3618C17.8402 16.2427 18.104 15.7857 17.9849 15.3412L15.5045 6.08437C15.3854 5.63981 14.9285 5.37599 14.4839 5.49511L12.4716 6.03432C12.027 6.15344 11.7632 6.61038 11.8823 7.05494L14.3627 16.3117C14.4818 16.7563 14.9387 17.0201 15.3833 16.901Z"
              stroke={theme === 'light' ? '#225AEA' : 'white'}
              strokeWidth="1.5"
              strokeLinecap="square"
              strokeLinejoin="round"
        />
      </svg>
    )

};

export default AdLibrary;
