import React, { useEffect, useState } from 'react';
import Modal from '../../../Components/Modal';
import InputComponent from '../../../Components/Input';
import { Divider } from 'antd';
import ButtonComponent from '../../../Components/Button';
import { useTranslation } from 'react-i18next';

const RenamePresetModal = (
  {
    modal,
    isMobile,
    record,
    visible,
    updatePreset,
    setModal,
    modalRename = 'rename_preset',
    modalLoad = 'load_preset'
  }) => {

  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    modal === modalRename && setInputValue(record?.name);
  }, [modal])

  return (
    <Modal handleOk={null}
           handleClose={() => {
             setModal(null);
           }}
           title={`Rename preset`}
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal"
           open={visible}
           destroyOnClose
    >
      <div className={'collection-modal-content'}>
        <p className={'collection-modal-text'}>
          {
            t('Rename your collection to make it easier to distinguish from others in the list')
          }
        </p>
        <InputComponent value={inputValue}
                        placeholder={t('Enter name for preset...')}
                        onChange={(e) => setInputValue(e.target.value)}
                        className="input--collections-modal"
        />
      </div>
      <Divider type={'horizontal'}
               style={{ margin: '16px 0' }}
      />
      <div className={'collection-modal-btn-wrapper'}>
        <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                         onClick={() => setModal(modalLoad)}
                         text={t('Cancel')}
        />
        <ButtonComponent className="collection-modal-btn collection-modal-btn-save"
                         disabled={!Boolean(inputValue) || inputValue.trim() === record?.name}
                         onClick={() => {
                           updatePreset({record, name: inputValue.trim()});
                           setModal(modalLoad);
                           setInputValue('');
                         }}
                         text={t('Rename')}
        />
      </div>
      {
        isMobile ?
          null
          :
          <div style={{ height: 16 }} />
      }
    </Modal>
  );
};

export default RenamePresetModal;
