import React from 'react';

const TopStoresIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4ZM2 4C2 2.89543 2.89543 2 4 2V4C4 4.55228 3.55228 5 3 5C2.44772 5 2 4.55228 2 4ZM6 4V2H8V4C8 4.55228 7.55228 5 7 5C6.44772 5 6 4.55228 6 4ZM10 4V2H12V4C12 4.55228 11.5523 5 11 5C10.4477 5 10 4.55228 10 4ZM14 4V2C15.1046 2 16 2.89543 16 4C16 4.55228 15.5523 5 15 5C14.4477 5 14 4.55228 14 4ZM7 14C7 12.8954 7.89543 12 9 12C10.1046 12 11 12.8954 11 14V16H13V14C13 11.7909 11.2091 10 9 10C6.79086 10 5 11.7909 5 14V16H7V14Z" fill="#F9F9FC" />
    </svg>
  );
};

export default TopStoresIcon;
