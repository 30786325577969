import React from 'react';
import Icon from '../../../Icon';

const AdLibraryItemSkeleton = () => {
  return (
    <div className={"adLibrary-item"}>
      <div className="adLibrary-item-header">
        <div className="adLibrary-item-header-info-wrapper">
          <div className='adLibrary-item-header-logo-wrapper'>
            <span className={'link skeleton'}
                  style={{ width: 36, height: 36, borderRadius: '50%' }}
            />
          </div>
          <div className="adLibrary-item-header-title-wrapper">
            <span className="adLibrary-item-header-title">
              <span className={'link skeleton'}
                    style={{ width: 59, height: 20 }}
              />
              <span className={'link skeleton'}
                    style={{ width: 16, height: 16 }}
              />
            </span>
            <span className="adLibrary-item-header-status">
              <span className={'link skeleton'}
                    style={{ width: 6, height: 6, borderRadius: '50%' }}
              />
              <span className={'link skeleton'}
                    style={{ width: 87, height: 16 }}
              />
            </span>
          </div>
        </div>
        <span className="adLibrary-item-header-action">
          <Icon type={'three_dots'} role={'icon'} />
        </span>
      </div>
      <div className="adLibrary-item-stats">
        <span className="adLibrary-item-stat">
          <span className={'link skeleton'}
                style={{ width: 82, height: 16 }}
          />
        </span>
      </div>
      <p className="adLibrary-item-description">
        <span className={'link skeleton'}
              style={{ width: '100%', height: 40 }}
        />
      </p>
      <div className="adLibrary-item-image-wrapper">
        <span className={'link skeleton'}
              style={{ width: '100%', height: '100%' }}
        />
      </div>
      <div className="adLibrary-item-cta-wrapper">
        <div className="adLibrary-item-cta-text-wrapper">
          <span className="adLibrary-item-cta-title">
            <span className={'link skeleton'}
                  style={{ width: 74, height: 16 }}
            />
          </span>
          <span className="adLibrary-item-cta-text">
            <span className={'link skeleton'}
                  style={{ width: 200, height: 40 }}
            />
          </span>
        </div>
        <span className={'link skeleton'}
              style={{ width: 89, height: 36 }}
        />
      </div>
      <div className="adLibrary-item-info-wrapper">
        <div className="adLibrary-item-info">
          <span className={'link skeleton'}
                style={{ width: 100, height: 20 }}
          />
          <div className="adLibrary-item-info-value">
            <span className={'link skeleton'}
                  style={{ width: 80, height: 20 }}
            />
          </div>
        </div>
        <div className="adLibrary-item-info">
          <span className={'link skeleton'}
                style={{ width: 70, height: 20 }}
          />
          <div className="adLibrary-item-info-value">
            <span className={'link skeleton'}
                  style={{ width: 87, height: 20 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdLibraryItemSkeleton;
