import React from 'react';
import { useSelector } from 'react-redux';

const ProductInsights = () => {

  const theme = useSelector(state => state.nav.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
         fill="none">
      <path
        d="M3.12564 3.125H16.8757M3.12564 3.125V12.7083C3.12564 13.6288 3.87184 14.375 4.79231 14.375H15.209C16.1295 14.375 16.8757 13.6288 16.8757 12.7083V3.125M3.12564 3.125H19.1673H16.8757M3.12564 3.125H0.833984M11.6673 16.875L10.2788 14.7922M8.33398 16.875L9.72251 14.7922M10.0006 6.45833V11.0417M6.87564 9.79167V11.0417M13.1256 8.125V11.0417"
        stroke={theme === 'light' ? '#434C69' : 'rgba(255, 255, 255, 0.361)'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProductInsights;
