import React from "react";
import Icon from "../../../Icon";
import { useTranslation } from "react-i18next";

const AdSpotEngagementNotice = ({ loading, isMobile }) => {
  const { t } = useTranslation();
  
  return (
    <>
      {
        loading ?
        <div style={{ display: "flex" }}>
          <span className="link skeleton" style={{ width: '100%', height: isMobile ? 120 : 70 }} />
        </div>
          :
          <div className="fadspot-page-overview-banner">
            <Icon type={'warning_triangle'} role={'icon'} width={24} height={24} color={'#707ba0'} />
            <div>
              <h3>
                {t('Facebook post links may not be present in every ad')}
              </h3>
              <p>
                {t('Ad Engagement chart relies on ads with post links; more ads may exist')}
              </p>
            </div>
          </div>
      }
    </>
  )
};

export default AdSpotEngagementNotice;