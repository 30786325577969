import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import cls from 'classname';
import skeleton from '../../../Components/Skeleton';

const options = [
  {
    key: 'weekly_winners',
    name: 'Weekly winners',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M6.45837 12.4999V17.9734C6.45837 18.4315 6.93497 18.734 7.3495 18.5389L9.73392 17.4168C9.90247 17.3375 10.0976 17.3375 10.2662 17.4168L12.6506 18.5389C13.0651 18.734 13.5417 18.4315 13.5417 17.9734V12.4999M16.0417 7.49992C16.0417 10.8366 13.3368 13.5416 10 13.5416C6.66332 13.5416 3.95837 10.8366 3.95837 7.49992C3.95837 4.1632 6.66332 1.45825 10 1.45825C13.3368 1.45825 16.0417 4.1632 16.0417 7.49992Z" stroke="#707BA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg>,
    value: {},
    tooltip: 'Placeholder 1'
  },
  {
    key: 'best_performers',
    name: 'Best performers',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M10.0032 15.0001V12.5001M15.2083 3.95841H15.7083C16.8129 3.95841 17.7083 4.85385 17.7083 5.95842V6.04175C17.7083 7.42246 16.589 8.54175 15.2083 8.54175M4.79163 3.95841H4.29163C3.18706 3.95841 2.29163 4.85385 2.29163 5.95842V6.04175C2.29163 7.42246 3.41091 8.54175 4.79163 8.54175M9.79163 12.7084H10.2083C12.9697 12.7084 15.2083 10.4698 15.2083 7.70841V4.29175C15.2083 3.18718 14.3129 2.29175 13.2083 2.29175H6.79163C5.68706 2.29175 4.79163 3.18718 4.79163 4.29175V7.70841C4.79163 10.4698 7.0302 12.7084 9.79163 12.7084ZM7.08329 17.7084H12.9166C13.722 17.7084 14.375 17.0555 14.375 16.2501V16.2501C14.375 15.4447 13.722 14.7917 12.9166 14.7917H7.08329C6.27788 14.7917 5.62496 15.4447 5.62496 16.2501V16.2501C5.62496 17.0555 6.27788 17.7084 7.08329 17.7084Z" stroke="#707BA0" strokeWidth="1.5" strokeLinejoin="round" /></svg>,
    value: {},
  },
  {
    key: 'dropshipping_ads',
    name: 'Dropshipping ads',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M12.7084 5.62508V3.95841C12.7084 3.03794 11.9622 2.29175 11.0417 2.29175H5.62504C4.70457 2.29175 3.95837 3.03794 3.95837 3.95841V12.7084C3.95837 13.6289 4.70457 14.3751 5.62504 14.3751H7.29171M11.6667 9.79175V11.6667M11.6667 11.6667V13.5417M11.6667 11.6667H9.79171M11.6667 11.6667H13.5417M14.375 17.7084H8.95837C8.0379 17.7084 7.29171 16.9622 7.29171 16.0417V7.29175C7.29171 6.37127 8.0379 5.62508 8.95837 5.62508H14.375C15.2955 5.62508 16.0417 6.37127 16.0417 7.29175V16.0417C16.0417 16.9622 15.2955 17.7084 14.375 17.7084Z" stroke="#707BA0" strokeWidth="1.5" strokeLinecap="round" /></svg>,
    value: {},
    tooltip: 'Placeholder 3'
  },
];

const QuickSearch = (
  {
    disableShowFilters,
    isMobile,
    skeleton
  }) => {

  const { t } = useTranslation();

  if (skeleton) return (
    <div className="adlibrary-page-quick-search">
      <div className="adlibrary-page-quick-search-presets">
        {
          [1,2,3].map(el => (
            <span className={'adlibrary-page-quick-search-preset'}
                  key={el}
            >
              <span className={'link skeleton'}
                    style={{ width: 20, height: 20, display: 'block' }}
              />
              <span className={'link skeleton'}
                    style={{ width: 100, height: 20, display: 'block' }}
              />
            </span>
          ))
        }
      </div>
    </div>
  );

  return (
    <div className="adlibrary-page-quick-search">
      {
        isMobile ?
          null
          :
          <span className="adlibrary-page-quick-search-title">
            {t('Quick search')}
          </span>
      }
      <div className="adlibrary-page-quick-search-presets">
        {
          options.map(el => (
            el?.tooltip && !disableShowFilters ?
              <Tooltip placement="top"
                       title={el?.tooltip}
                       destroyTooltipOnHide={true}
                       key={el?.key}
                       overlayClassName={'subscription-tooltip scheduled'}
                       trigger={'hover'}
                       getPopupContainer={(trigger) => trigger.parentNode}
              >
                <span className={cls('adlibrary-page-quick-search-preset', {
                  disabled: disableShowFilters,
                })}
                      key={el?.key}
                >
                  {el?.icon}
                  {el?.name}
                </span>
              </Tooltip>
              :
              <span className={cls('adlibrary-page-quick-search-preset', {
                disabled: disableShowFilters,
              })}
                    key={el?.key}
              >
                {el?.icon}
                {el?.name}
            </span>
          ))
        }
      </div>
    </div>
  );
};

export default QuickSearch;
