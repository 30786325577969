import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal, Spin } from 'antd';
import AdItem from '../components/AdItem';
import AdItemPageSkeleton from '../components/AdItemPageSkeleton';
import Icon from '../../../Icon';
import { OverviewAdvertiserWrapper } from '../components/OverviewAdvertiser';
import openNotification from '../../../Components/Notification/index';
import CascadingDropdown from '../components/CascadingDropdown';
import './AdItemAllAdsPage.less';
import cls from 'classname';
import CascadingModal from '../components/CascadingModal';

const AdItemAllAdsPage = ({
  isMobile,
  userInfo,
  getAds,
  selectedAdv,
  loading,
  getOverviewMainInfo,
  mainInfo,
  creditsLeft,
  sortOrder,
  setSortOrder
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  let params = new URLSearchParams(search);
  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;
  const tableRef = useRef(null);
  const carouselRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [firstLoading, setFirstLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [modal, setModal] = useState(null);
  const SLIDE_PAGE_SIZE = useMemo(() => isMobile ? 1 : 4, [isMobile]);

  const modalBlocksWidth = {
    sortOrderModal: 450,
  };

  const modalBlocks = {
    sortOrderModal: <CascadingModal value={sortOrder} setValue={setSortOrder} setVisible={setVisible} />
  }

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if (
      currentTopScroll >= maxTopScroll * 0.95 &&
      !loading &&
      selectedAdv?.results?.length < +selectedAdv?.count
    ) {
      setPageNumber((prev) => prev + 1);
      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  useEffect(() => {
    getOverviewMainInfo({
      same: search.includes('same='),
      id: params.get('same'),
      ad_id: params.get('ad_id'),
      domain: params.get('domain'),
      ...(params.get('internal_shop_id') && {
        internal_shop_id: params.get('internal_shop_id'),
      }),
    });
  }, [search]);

  useEffect(() => {
    if (!loading)
      getAds({
        same: search.includes('same='),
        id: params.get('same'),
        domain: params.get('domain'),
        page_number: pageNumber,
        page_size: pageSize,
        sort: sortOrder,
      });
  }, [pageNumber, pageSize, search]);

  useEffect(() => {
    if (firstLoading) setFirstLoading(false);
    else {
      if (pageNumber === 1) {
        getAds({
          same: search.includes('same='),
          id: params.get('same'),
          domain: params.get('domain'),
          page_number: pageNumber,
          page_size: pageSize,
          sort: sortOrder,
        });
      } else setPageNumber(1);
    }
  }, [sortOrder]);

  useEffect(() => {
    const tableContent = tableRef.current;
    if (tableContent && selectedAdv?.results?.length < +selectedAdv?.count) {
      tableContent.addEventListener('scroll', onScrollHandlerFunc);
    }
    return () => {
      if (tableContent && !firstLoading) {
        tableContent.removeEventListener('scroll', onScrollHandlerFunc);
      }
    };
  }, [selectedAdv?.results?.length, selectedAdv?.count, tableRef?.current]);

  useEffect(() => {
    if (creditsLeft === 0 && !isMobile && !firstLoading && !loading) {
      openNotification({
        message: t('You have reached your search limit, upgrade to unlock unlimited searches'),
        style: { minWidth: '716px' },
        type: 'error',
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [creditsLeft]);

  if ((loading || mainInfo?.mainInfoLoading) && pageNumber === 1) return <AdItemPageSkeleton isMobile={isMobile} />

  return (
    <div className="fadspot-page-all-ads">
      {!Boolean(mainInfo?.related_advertisers) ||
      mainInfo?.related_advertisers?.length <= 1 ? null : (
        <div className="fadspot-page-all-ads-wrapper">
            <div className="fadspot-page-all-ads-header">
              { isMobile ? null : (
                <span>
                  {mainInfo?.related_advertisers?.length === 1
                    ? t('Same domain advertiser')
                    : t('Same domain advertisers')}
                </span>
              ) }
              { isMobile ? null : (
                <div className="adspot-page-all-ads-buttons">
                  <div className={cls("adspot-page-all-ads-buttons-btn", {
                    "disabled": currentSlide === 0
                  })} onClick={() => carouselRef.current.prev()}>
                    <Icon width={20} height={20} role="icon" type="arrow_slider_prev"/>
                  </div>
                  
                  <div className={cls("adspot-page-all-ads-buttons-btn", {
                    "disabled": currentSlide === Math.ceil(mainInfo?.related_advertisers?.length / SLIDE_PAGE_SIZE) - 1 || mainInfo?.related_advertisers?.length < SLIDE_PAGE_SIZE
                  })} onClick={() => carouselRef.current.next()}>
                    <Icon width={20} height={20} role="icon" type="arrow_slider_next"/>
                  </div>
                </div>
              ) }
            </div>
            <OverviewAdvertiserWrapper
              data={mainInfo?.related_advertisers || []}
              pageSize={SLIDE_PAGE_SIZE}
              carouselRef={carouselRef}
              afterChange={setCurrentSlide}
            />
          </div>
      )}
      {(selectedAdv?.results?.length && !loading) || loading ? (
        <div className="fadspot-page-pagination-wrapper">
          <div className="fadspot-page-pagination-header">
            <span className="fadspot-page-pagination-title">{t('Ads')}</span>
            <span className="fadspot-page-pagination-count">{mainInfo.ads_count}</span>
          </div>
          <CascadingDropdown
            value={sortOrder}
            setValue={setSortOrder}
            isMobile={isMobile}
            disabled={creditsLeft === 0}
            isTrial={isTrial}
            setModal={setModal}
            setVisible={setVisible}
          />
        </div>
      ) : null}

      <Spin spinning={loading}>
        {(selectedAdv?.results?.length && !loading) || loading ? (
          <div className="fadspot-page-items-wrapper" ref={tableRef}>
            {selectedAdv?.results?.map((el) => (
              <AdItem
                key={el?.id}
                data={{ ...el }}
                isInnerPage={false}
                isMobile={isMobile}
                isAlive={el?.status}
              />
            ))}
          </div>
        ) : null}
      </Spin>

      <Modal
        className="change-modal custom-modal"
        {...(isMobile ? { transitionName: '' } : null)}
        getContainer={() => document.getElementById('global-wrap')}
        open={visible}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#707BA0" opacity={1}/>
        }
        width={modalBlocksWidth[modal]}
        footer={null}
        closable="true"
        onCancel={() => setVisible(false)}
        destroyOnClose
      >
        {modalBlocks[modal]}
      </Modal>
    </div>
  );
};

export default AdItemAllAdsPage;
