import React, { useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import Icon from '../../Icon';
import cls from 'classname';
import ButtonComponent from '../Button';
import InputComponent from '../Input';

const LoginForm = (
  {
    rememberMe,
    setRemember,
    onSubmit,
    error,
    otpRequired
  }) => {

  const mailRegex =   /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


  const [errorText, setErrorText] = useState(null);
  const [isBackError, setIsBackError] = useState(false);

  const [focusedField, setFocusedField] = useState('');
  const [fieldNotification, setFieldNotification] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (error) {
      setIsBackError(true);
      if (typeof error === 'string') {
        setErrorText(error);
      } else {
        setErrorText(Object.values(error)[0]);
      }
    } else {
      if (setErrorText && isBackError)
        setIsBackError(false);
      setErrorText(null);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [error]);

  const onFinish = values => {
    if (rememberMe) setRemember(values);
    onSubmit(values);
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields) {
      setErrorText(errorFields[0]['errors']);
    }
  };

  function handleClearError(e, field) {
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ]);
    setErrorText(null);
    setIsBackError(false);
  }

  function onBlurHandler(e) {
    const target = e.target;

    setTimeout(() => {
      let error = form.getFieldError(target.name);
      if (error.length) {
        setErrorText(error[0]);
      }
    }, 10);
    setFocusedField('');
    setFieldNotification(null);
  }

  function onFocusHandler(e) {
    setFocusedField(e.target?.name);
    if (e.target.name === 'email') {
      if (mailRegex.test(e.target.value)) {
        setFieldNotification({
          [e.target?.name]: {
            color: '',
            text: '',
            border: ''
          }
        });
      } else setFieldNotification({
        [e.target?.name]: {
          color: '#D71313',
          text: 'Please enter a valid email address.',
          border: 'rgb(237, 149, 149)'
        }
      });
    } else {
      if (e.target.value.length >=6 ) {
        setFieldNotification({
          [e.target?.name]: {
            color: '',
            text: '',
            border: ''
          }
        });
      } else {
        setFieldNotification({
          [e.target?.name]: {
            color: '#D71313',
            text: 'Password is incorrect.',
            border: 'rgb(237, 149, 149)'
          }
        });
      }
    }
  }

  function handleChange(e) {
    if (e.target.name === 'email') {
      if (mailRegex.test(form.getFieldValue(e.target?.name)) && focusedField === e.target?.name) {
        setFieldNotification({
          [e.target?.name]: {
            color: '',
            text: '',
            border: ''
          }
        });
      } else setFieldNotification({
        [e.target?.name]: {
          color: '#D71313',
          text: 'Please enter a valid email address.',
          border: 'rgb(237, 149, 149)'
        }
      });
    } else {
      if (e.target.value.length >= 6) {
        setFieldNotification({
          [e.target?.name]: {
            color: '',
            text: '',
            border: ''
          }
        });
      } else {
        setFieldNotification({
          [e.target?.name]: {
            color: '#D71313',
            text: 'Password is incorrect.',
            border: 'rgb(237, 149, 149)'
          }
        });
      }
    }
  }

  return (
    <Form onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateTrigger="onSubmit"
          className="login-form"
          layout="vertical"
          form={form}
          requiredMark={false}
    >
      <Form.Item noStyle shouldUpdate={true}>
        {() =>
          <div className={cls('error-text', { 'error-text_empty': !errorText })}>
            <span>{errorText}</span>
          </div>}
      </Form.Item>

      <Form.Item
        name="email"
        initialValue={rememberMe?.email}
        validateStatus={errorText || isBackError ? 'error' : null}
        rules={[
          { type: 'email', message: 'The email or password you entered is incorrect.' },
          { required: true, message: 'The email or password you entered is incorrect.' },
        ]}
      >
        <InputComponent
          placeholder={'Email Address'}
          name="email"
          type="email"
          prefix={null}
          style={fieldNotification?.email?.border ? {borderColor: fieldNotification?.email?.border} : {}}
          onChange={e => {
            handleChange(e);
            handleClearError(e, 'email');
          }}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
        />
      </Form.Item>
      {
        fieldNotification?.email?.text ?
          <span style={{color: fieldNotification?.email?.color}}
                className='field-helper'>
            {fieldNotification?.email?.text}
          </span>
          :
          null
      }
      <Form.Item
        name="password"
        initialValue={rememberMe?.password}
        validateStatus={errorText || isBackError ? 'error' : null}
        rules={[{ required: true, message: 'The email or password you entered is incorrect.' }]}
        className="login-form-password"
      >
        <Input.Password
          size="large"
          name="password"
          placeholder={'Password'}
          autoComplete="current-password"
          visibilityToggle
          prefix={null}
          style={fieldNotification?.email?.border ? {borderColor: fieldNotification?.email?.border} : {}}
          onChange={e => {
            handleChange(e);
            handleClearError(e, 'password');
          }}
          onFocus={onFocusHandler}
          // onBlur={onBlurHandler}
          iconRender={visible => (
            visible
              ? <Icon type="eye" color={'#C5CCE3'} />
              : <Icon type="eye_invisible" color={'#C5CCE3'} />
          )
          }
        />
      </Form.Item>
      {
        fieldNotification?.password?.text ?
          <span style={{color: fieldNotification?.password?.color}}
                className='field-helper'>
            {fieldNotification?.password?.text}
          </span>
          :
          null
      }

      {
        otpRequired ?
          <Form.Item
            name="otp_pass"
            initialValue={''}
            validateStatus={errorText || isBackError ? 'error' : null}
            rules={[{ required: true, message: 'OTP is required' }]}
          >
            <InputComponent
              placeholder={'6-digit Code'}
              name="otp_pass"
              type="number"
              prefix={null}
              onChange={e => {
                handleClearError(e, 'otp');
              }}
            />
          </Form.Item>
          :
          null
      }

      <ButtonComponent type="primary"
                       htmlType="submit"
                       className="login-form-submit"
                       style={{ width: '100%' }}
                       disabled={fieldNotification?.email?.text ||
                         fieldNotification?.password?.text ||
                         form.getFieldValue('password')?.length < 6 ||
                         // !mailRegex.test(form.getFieldValue('email')) ||
                         errorText}
      >
        Sign In
      </ButtonComponent>
    </Form>
  );
}

export default LoginForm;
