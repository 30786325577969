import React from 'react';
import { useTranslation } from 'react-i18next';
import './AdLandingPageModal.less';
import Icon from '../../../Icon';
import { Divider, Tooltip } from 'antd';
import acc from 'accounting';

const AdLandingPageModal = ({ links, isMobile }) => {
  const { t } = useTranslation();

  return (
    <div className="ad-landing-page">
      <div className="ad-landing-page-header">
        <span className="ad-landing-page-header-sup">
          {t('Ad landing pages')}
        </span>
        <span className="ad-landing-page-header-sub">
          {t('A list of all the landing pages that we were able to find.')}
        </span>
        <div className="ad-landing-page-header-bar">
          <span>{t('Landing page link')}</span>
          <span>
            <span>{t('Amount')}</span>
            <Icon role={'icon'} type={'arrow_double_vertical'} />
          </span>
        </div>
      </div>
      <div className="ad-landing-page-list">
        {links?.map((link) => (
          <div
            className="ad-landing-page-list-item"
            key={link?.landing_page_url}
          >
            <span className="link">
              <Icon
                type={'ad_spot_link'}
                role={'icon'}
                width={20}
                height={20}
              />
              <Tooltip
                placement="top"
                title={link?.landing_page_url}
                destroyTooltipOnHide={true}
                getPopupContainer={(triggerNode) => triggerNode?.parentNode}
              >
                <a
                  href={link?.landing_page_url}
                  target="_blank"
                  className="url"
                >
                  {isMobile
                    ? link?.landing_page_url.length > 30
                      ? `${link.landing_page_url.slice(0, 30)}...`
                      : link.landing_page_url
                    : link?.landing_page_url.length > 36
                    ? `${link.landing_page_url.slice(0, 36)}...`
                    : link.landing_page_url}
                </a>
              </Tooltip>
            </span>
            <span className="amount">
              <span>{acc.formatNumber(link?.count, 0, ',')}</span>
              <Divider type={'vertical'} className="divider" />
              <span>{acc.formatNumber(link?.percent, 0, ',')}%</span>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdLandingPageModal;
