import React from 'react';
import Icon from '../../../../Icon';
import Modal from '../../../../Components/Modal';
import { useTranslation } from 'react-i18next';
import cls from 'classname';

const HeaderModal = (
  {
    setModal,
    isMobile,
    visible,
    takeTourHandler,
    watchTutorialHandler,
    increaseLimitsHandler,
    title,
    text,
    isTrial = false
  }) => {

  const { t } = useTranslation();

  return (
    <Modal handleClose={() => setModal(null)}
           title={title ? t(title) : null}
           isMobile={isMobile}
           width={450}
           className="change-modal custom-modal collection-modal header-modal"
           open={visible}
           destroyOnClose
    >
      <div className={'custom-modal-content'}>
        {
          text ?
            <p className={'collection-modal-text'}>
              {
                t(text)
              }
            </p>
            :
            null
        }
        <div className={'sales-tracker-top-page-header-dropdown'}>
          {
            takeTourHandler ?
              <div className="sales-tracker-top-page-header-dropdown-option tour"
                   onClick={() => {
                     setModal(null);
                     takeTourHandler();
                   }}
              >
                <Icon type="take_tour" role="icon" />
                <span>
                  {t('Take tour')}
                </span>
              </div>
              :
              null
          }
          {
            watchTutorialHandler ?
              <div className="sales-tracker-top-page-header-dropdown-option tutorial"
                   onClick={() => {
                     setModal(null);
                     watchTutorialHandler();
                   }}
              >
                <Icon type={'watch_tutorial'}
                      role={'icon'}
                />
                <span>
                  {t('Watch tutorial')}
                </span>
              </div>
              :
              null
          }
          {
            increaseLimitsHandler ?
              <div className={cls('sales-tracker-top-page-header-dropdown-option tutorial', {
                disabled: isTrial
              })}
                   onClick={() => {
                     if (isTrial) return;
                     setModal(null);
                     increaseLimitsHandler();
                   }}
              >
                <Icon type={'speedometer'}
                      role={'icon'}
                />
                <span>
                  {t('Increase limits')}
                </span>
              </div>
              :
              null
          }
        </div>
      </div>
    </Modal>
  );
};

export default HeaderModal;
