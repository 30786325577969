import React, { useState } from 'react';
import cls from 'classname';
import Icon from '../../../Icon';
import SearchWithTags from '../../SalesTrackerPage/pages/components/SearchWithTags';
import CascadingDropdown from './CascadingDropdown';
import PresetsDropdown from './PresetsDropdown';
import { useTranslation } from 'react-i18next';

const FiltersPanel = ({
  visibleFilters,
  setVisibleFilters,
  isMobile,
  setModal,
  setVisible,
  changedFilters,
  sortOrder,
  setSortOrder,
  isTrial,
  skeleton,
  disabled,
  applyFilters,
  setInputFilters,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const searchSubmit = (withEmptySearch = false) => {
    setInputFilters((prev) => ({
      ...prev,
      global_search: searchValue,
    }));
    applyFilters();
  };

  if (skeleton) {
    return (
      <div className="fadspot-page-products-filters">
        <div className="fadspot-page-products-filters-search" style={{ width: "100%" }}>
          <div
            className="main-filter-search"
            style={{ display: 'flex', gap: 12 }}
          >
            <span
              className={'link skeleton'}
              style={{ width: isMobile ? "75%" : 400, height: 40 }}
            />
            <span
              className={'link skeleton'}
              style={{ width: isMobile ? "25%" : 70, height: 40 }}
            />
            {!isMobile && (<span
              className={'link skeleton'}
              style={{ width: isMobile ? 0 : 100, height: 40 }}
            />)}
          </div>
          <div className="search-buttons-wrapper" style={{ gap: 12 }}>
            <span
              className={'link skeleton'}
              style={{ width: isMobile ? "50%" : 110, height: 40 }}
              />
            <span
              className={'link skeleton'}
              style={{ width: isMobile ? "50%" : 190, height: 40 }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fadspot-page-products-filters">
      <div className="fadspot-page-products-filters-search">
        <div className="main-filter-search">
          <div className="fadspot-page-products-search filters-panel product-cards-page-search">
            <SearchWithTags
              searchValue={searchValue}
              search={search}
              setSearch={setSearch}
              setSearchValue={setSearchValue}
              disabled={disabled || visibleFilters}
              disabledBtn={disabled}
              searchSubmit={searchSubmit}
              placeholder={`Search for ads...`}
            />
          </div>
        </div>
        <div className="fadspot-page-products-filters-search-buttons">
          <div
            onClick={() => !disabled && setVisibleFilters((prev) => !prev)}
            className={cls('cascading-wrapper fadspot-page-filter-btn', {
              "active": visibleFilters,
              "disabled": disabled,
            })}
          >
            <span
              className={cls(
                'fadspot-page-filters-preset-btn cascading-dropdown-value',
                {
                  "disabled": disabled,
                },
              )}
            >
              <Icon role={' icon'} type={'filter_icon'} />
              {t('Filters')}
            </span>
          </div>
          {isMobile && (
            <CascadingDropdown
              value={sortOrder}
              setValue={setSortOrder}
              isMobile={isMobile}
              isTrial={isTrial}
              disabled={disabled}
              setModal={setModal}
              setVisible={setVisible}
            />
          )}
        </div>
      </div>
      <div className="search-buttons-wrapper">
        <PresetsDropdown
          isMobile={isMobile}
          setModal={setModal}
          setVisible={setVisible}
          changedFilters={changedFilters}
          disabled={disabled}
        />
        {!isMobile && (
          <CascadingDropdown
            value={sortOrder}
            setValue={setSortOrder}
            isMobile={isMobile}
            isTrial={isTrial}
            disabled={disabled}
            setModal={setModal}
            setVisible={setVisible}
          />
        )}
      </div>
    </div>
  );
};

export default FiltersPanel;
