import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Tooltip } from 'antd';
import { plansMocked } from './MockingPlanData';
import './ComparePlansComponent.less';
import ArrowSelectDown from '../../../Icon/img/ArrowSelectDown';

const ComparePlansComponent = ({ isMobile }) => {
  const [maxKeyWidth, setMaxKeyWidth] = useState(0);
  const [expandedKeys, setExpandedKeys] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const maxKeyLength = Math.max(
      ...plansMocked[0].features.flatMap((feature) =>
        Object.keys(feature.details).map((key) => key.length)
      )
    );

    setMaxKeyWidth(maxKeyLength * 10);
  }, []);


  const toggleAccordion = (key) => {
    setExpandedKeys((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const renderValue = (value, header) => {
    if (value === true || value.value === true) {
      return (
        <div className="compare-plans-item-value">
          {isMobile && <div className="compare-plans-item-plan">{header}</div>}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M2.29199 12.5781L7.50033 16.875L17.7087 3.125"
              stroke="#16895A"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      );
    }
    if (value === false || value === 'no' || value.value === false || value.value === 'no') {
      return <div className="compare-plans-item-value">
        {isMobile && <div className="compare-plans-item-plan">{header}</div>}
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.832 10.8337H4.16536C3.94435 10.8337 3.73239 10.7459 3.57611 10.5896C3.41983 10.4333 3.33203 10.2213 3.33203 10.0003C3.33203 9.77931 3.41983 9.56735 3.57611 9.41107C3.73239 9.25479 3.94435 9.16699 4.16536 9.16699H15.832C16.053 9.16699 16.265 9.25479 16.4213 9.41107C16.5776 9.56735 16.6654 9.77931 16.6654 10.0003C16.6654 10.2213 16.5776 10.4333 16.4213 10.5896C16.265 10.7459 16.053 10.8337 15.832 10.8337Z" fill="#707BA0" />
        </svg>
      </div>;
    }
    if (value === 'pending' || value.value === 'pending') {
      return (
        <div className="compare-plans-item-value">
          {isMobile && <div className="compare-plans-item-plan">{header}</div>}
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.0013 3.33366C6.3194 3.33366 3.33464 6.31843 3.33464 10.0003C3.33464 13.6822 6.3194 16.667 10.0013 16.667C13.6832 16.667 16.668 13.6822 16.668 10.0003C16.668 6.31843 13.6832 3.33366 10.0013 3.33366ZM1.66797 10.0003C1.66797 5.39795 5.39893 1.66699 10.0013 1.66699C14.6037 1.66699 18.3346 5.39795 18.3346 10.0003C18.3346 14.6027 14.6037 18.3337 10.0013 18.3337C5.39893 18.3337 1.66797 14.6027 1.66797 10.0003Z" fill="#707BA0" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.40077 10.001C5.17065 10.001 4.98228 10.1882 5.00133 10.4175C5.21452 12.984 7.37961 15.001 10.0008 15.001C12.7622 15.001 15.0008 12.7624 15.0008 10.001C15.0008 7.37981 12.9838 5.21454 10.4173 5.00133C10.188 4.98228 10.0008 5.17065 10.0008 5.40077V10.001H5.40077Z" fill="#707BA0" />
          </svg>
        </div>
      )
    }
    return <div className="compare-plans-item-value">
      {isMobile && <div className="compare-plans-item-plan">{header}</div>}
      <div className='compare-plans-item-extra-value'>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          style={{ marginRight: 4 }}
        >
          <path
            d="M2.29199 12.5781L7.50033 16.875L17.7087 3.125"
            stroke="#16895A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {t(value.value || value)}
      </div>
    </div>;
  };


  return (
    <>
      {!isMobile ?
       <div className="compare-plans-wrapper">
        {plansMocked[0].features.map((feature,index) => (
          <div key={`${feature.header}-${index}`} style={{ marginBottom: '20px' }}>
            <div className="compare-plans-main-header">{t(feature.header)}</div>
            {Object.entries(feature.details).map(([key],index) => (
              <div className="compare-plans-main-items-wrapper" key={`${key}-${index}`}>
                <div
                  className="compare-plans-items-header"
                  style={{ width: `${maxKeyWidth}px` }}
                >
                  <Tooltip placement="right"
                    title={feature.details[key].tooltip}
                    destroyTooltipOnHide={true}
                    key={key + 1}>
                    {t(key)}
                  </Tooltip>
                </div>
                <div className="compare-plans-items-wrapper">
                  {plansMocked.map((plan, index) => (
                    <div className="compare-plans-item-wrapper"  key={`${key}-${index}`}>
                      {renderValue(
                        plan.features.find((f) => f.header === feature.header)
                          ?.details[key]
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div> :
        <div className="compare-plans-wrapper">
          {plansMocked[0].features.map((feature, index) => (
            <div key={`${feature.header}-${index}`} className="compare-plans-section">
              <div className="compare-plans-main-header">{t(feature.header)}</div>
              {Object.entries(feature.details).map(([key], index) => (
                <div className="compare-plans-main-items-wrapper" key={`${key}-${index}`}>

                  <div
                    className={`compare-plans-items-header ${expandedKeys[key] ? 'expanded' : ''}`}
                    style={{ width: '100%' }}
                    onClick={() => toggleAccordion(key)}
                  >
                    <Tooltip placement="top"
                      title={feature.details[key].tooltip}
                      destroyTooltipOnHide={true}
                      key={key + 1}
                    >
                      {t(key)}
                    </Tooltip>
                    <ArrowSelectDown />
                  </div>
                  {expandedKeys[key] && (
                    <div className={`compare-plans-items-content ${expandedKeys[key] ? 'expanded' : ''}`}>
                      {plansMocked.map((plan,index) => (
                        <div className="compare-plans-item-wrapper"  key={`${plan.name}-${index}`}>
                          {renderValue(
                            plan.features.find((f) => f.header === feature.header)?.details[key],
                            plan.header
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      }
    </>
  );
};

export default ComparePlansComponent;
