import React from 'react';
import { useSelector } from 'react-redux';

const VideoSimple = () => {
  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.95829 13.125V8.95833L11.6666 11.0417L8.95829 13.125Z" fill={theme === 'light' ? '#151E3A' : '#F2F3F8'}/>
      <path d="M3.95829 3.125H16.0416M9.58329 11.0417H10.2083M9.58329 11.0417V10.8333M9.58329 11.0417V11.25M8.95829 8.95833V13.125L11.6666 11.0417L8.95829 8.95833ZM3.95829 16.875H16.0416C16.9621 16.875 17.7083 16.1288 17.7083 15.2083V6.875C17.7083 5.95453 16.9621 5.20833 16.0416 5.20833H3.95829C3.03782 5.20833 2.29163 5.95453 2.29163 6.875V15.2083C2.29163 16.1288 3.03782 16.875 3.95829 16.875Z" stroke={theme === 'light' ? '#151E3A' : '#F2F3F8'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default VideoSimple;
