import React from 'react';

const Energy = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.16602 6.66742C3.16602 3.72157 5.55409 1.3335 8.49994 1.3335C11.4458 1.3335 13.8339 3.72157 13.8339 6.66742C13.8339 8.39087 13.0159 9.9235 11.7491 10.8978C11.5645 11.0398 11.3702 11.1701 11.1674 11.2874V11.3335H5.83248V11.2874C5.62971 11.1701 5.43542 11.0398 5.25075 10.8978C3.98395 9.9235 3.16602 8.39087 3.16602 6.66742Z"
        fill="#1448CB"
      />
      <path
        d="M5.83248 12.3335V12.666C5.83248 14.1392 7.02674 15.3335 8.49994 15.3335C9.97313 15.3335 11.1674 14.1392 11.1674 12.666V12.3335H5.83248Z"
        fill="#1448CB"
      />
    </svg>
  );
};

export default Energy;
