import React from 'react'

const Dashboard = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_dd_4690_714)">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.7414 3.95572C14.7284 3.1269 13.2716 3.1269 12.2586 3.95572L6.0086 9.06936C5.37022 9.59167 5 10.3729 5 11.1977V20.25C5 21.7688 6.23122 23 7.75 23H20.25C21.7688 23 23 21.7688 23 20.25V11.1977C23 10.3729 22.6298 9.59167 21.9914 9.06936L15.7414 3.95572ZM9.75 17.5C9.33579 17.5 9 17.8358 9 18.25C9 18.6642 9.33579 19 9.75 19H18.25C18.6642 19 19 18.6642 19 18.25C19 17.8358 18.6642 17.5 18.25 17.5H9.75Z" fill="#F9F9FC" />
    </g>
    <defs>
      <filter id="filter0_dd_4690_714" x="-2.26667" y="-1.2" width="34.6667" height="35.7333" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="4.26667" />
        <feGaussianBlur stdDeviation="2.13333" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4690_714" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="1.06667" dy="2.13333" />
        <feGaussianBlur stdDeviation="2.66667" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_4690_714" result="effect2_dropShadow_4690_714" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4690_714" result="shape" />
      </filter>
    </defs>
  </svg>
)

export default Dashboard
