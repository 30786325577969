import React from 'react';

const AdSpotChartShares = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1316 9.54097L11.3437 3.26215C10.9435 2.89197 10.2943 3.17581 10.2943 3.72096V6.66645C10.2943 6.89657 10.1043 7.08275 9.87417 7.0864C3.13645 7.19356 1.33594 9.93358 1.33594 16.8748C2.56072 14.4252 3.18546 12.9757 9.87337 12.9182C10.1035 12.9163 10.2943 13.103 10.2943 13.3331V16.2786C10.2943 16.8238 10.9435 17.1076 11.3437 16.7374L18.1316 10.4586C18.3991 10.2112 18.3991 9.78838 18.1316 9.54097Z"
        stroke="#225AEA"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AdSpotChartShares;
