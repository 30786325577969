import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import OnboardingCreators from '../../Redux/OnboardingRedux';
import SettingCreators from '../../Containers/SettingPage/reducer';
import { Spin } from 'antd';
import PlanCard from './PlanCard';
import SwitchBlockSettings from './SwitchBlockSettings';
import cls from 'classname';

const AvailablePlans = (
  {
    plansList = [],
    onBoarding,
    chargeLoading,
    userLoading,
    onChangeSwitch,
    handleSelectPlan,
    setModal,
    fullHeight
  },
) => {

  const {
    isYearlySubscription,
    isRestoredCancel,
    cancelSubFetching,
    cancelTrialFetching,
    stripeLoading,
  } = onBoarding;


  const { t } = useTranslation();

  return (
    <Spin spinning={
      (isRestoredCancel && cancelSubFetching && cancelTrialFetching) ||
      stripeLoading || chargeLoading || userLoading}
    >
      <div className="plan-subpage-block-custom">
       {fullHeight && <div className="plan-subpage-block-custom-title-wrapper">
          <span className="plan-subpage-block-custom-title">
            {t('Available plans')}
          </span>
          <SwitchBlockSettings
            isYearlySubscription={isYearlySubscription}
            onChangeSwitch={onChangeSwitch}
            t={t}
          />
        </div>}
        <div className={cls('plan-subpage-block-custom-plans',{  'smallHeight': !fullHeight})}>
          {
            plansList?.filter(el => el?.status)
              ?.map((el, index) => (
                <PlanCard key={index}
                  plan={el}
                  onSelectPlan={handleSelectPlan}
                  setModal={setModal}
                  fullHeight={fullHeight}
                />
              ))
          }
        </div>
      </div>
    </Spin>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  onBoarding: state?.onBoarding,
  chargeLoading: state.setting.chargeLoading,
  chargeLink: state.setting.chargeLink,
  userLoading: state.users.fetching,
  plansList: state?.plan?.plans,
});

const mapDispatchToProps = (dispatch) => ({
  setSwitchPlan: (plan) => dispatch(OnboardingCreators.setSwitchPlan(plan)),
  createStripeSession: (value) => dispatch(OnboardingCreators.createStripeSessionRequest(value)),
  getRecharge: (data) => dispatch(SettingCreators.getRechargeRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AvailablePlans);
