import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import Modal from '../../../Components/Modal';
import Icon from '../../../Icon';

const AdLibraryItemActionModal = (
  {
    visible,
    data,
    setModal,
    handleOpenModalShopifyConnect
  }
) => {

  const { t } = useTranslation();

  return (
    <Modal handleClose={() => setModal(null)}
           title={t('Ad actions')}
           isMobile={true}
           width={450}
           className="collection-options-modal collection-modal product-actions-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={false}>
        <div className={'collection-modal-content'}>
          <div className="adLibrary-item-header-action-dropdown">
            <div className="adLibrary-item-header-action-dropdown-option">
              <Icon role={'icon'} type={'search_facebook'} isDark={false} />
              <span>
                {t('View Facebook Ad Library')}
              </span>
            </div>
            <div className="adLibrary-item-header-action-dropdown-option"
                 onClick={() => {
                   setModal(null);
                   handleOpenModalShopifyConnect(`storeId_productId`);
                 }}
            >
              <Icon type={'shopify_btn_icon'} role={'icon'} isLogo={true} />
              <span>
                {t('Import to Shopify store')}
              </span>
            </div>
            <div className="adLibrary-item-header-action-dropdown-option">
              <Icon role={'icon'} type={'similar_web'} isDark={false} />
              <span>
                {t('View with Similarweb')}
              </span>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default AdLibraryItemActionModal;
