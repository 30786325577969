import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import { Row, Col, Divider, Switch, Collapse } from 'antd';
import ProductDetailsCard from '../../ProductDetailsPage/components/ProductDetailsCard';
import ButtonComponent from '../../../Components/Button';
import Icon from '../../../Icon';
import { useDispatch, useSelector } from 'react-redux';
import FiltersBlockSkeleton from './FiltersBlockSkeleton';
import { ArrowSelectDown } from '../../../Icon/img';
import InputProductFilter from '../../ProductDatabasePage/components/InputProductFilter';
import MinMaxInput from '../../ProductDatabasePage/components/MinMaxInput';
import DropdownDateBlock from '../../ProductDatabasePage/components/DropdownDateBlock';
import DropdownBlockTest from '../../ProductDatabasePage/components/DropdownBlockTest';
import Creators from '../../ProductDatabasePage/reducer';
import dayjs from 'dayjs';
import './FiltersBlock.less';

const { Panel } = Collapse;

const minMaxInputsData = [
  {
    field: 'price',
    iconType: 'database_price',
    text: 'Price',
    tooltip: 'The price products are sold for in USD',
  }, {
    field: 'products',
    iconType: 'database_store_products',
    text: 'Store products',
    tooltip: 'Amount of product listings in the store',
  }, {
    field: 'images',
    iconType: 'database_product_images',
    text: 'Product images',
    tooltip: 'Amount of images the product listing has',
  }, {
    field: 'variants',
    iconType: 'database_variants',
    text: 'Variants',
    tooltip: 'Amount of variants the product listing has',
  }];

const switchFilterOptions = [
  { text: 'Exclude Unavailable Products', key: 'exclude_unavailable', disabled: false },
];

const keyWords = ['Title', 'Description', 'Domain'];

const dateOptions = [
  { id: 0, name: 'Last 7 days' },
  { id: 1, name: 'Last 30 days' },
  { id: 2, name: 'Last 90 days' },
  { id: 3, name: 'Last 6 months' },
  { id: 4, name: 'Last 12 months' },
];

const FiltersBlock = (
  {
    isAdmin,
    setLoadedPreset,
    loadedPreset,
    switchFilters,
    setSwitchFilters,
    inputFilters,
    setInputFilters,
    minMaxFilters,
    setMinMaxFilters,
    dropdownFilters,
    setDropdownFilters,
    skeleton,
    isMobile,
    getProducts,
    productsAdd,
    showTable,
    setShowTable,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    sortOrder,
    setSortOrder,
    resetFilters,
    isChanged,
    setIsChanged,
    setEnabled,
    disabledSearch,
    currentScroll,
    productsLoading,
    showFilters,
    disableShowFilters
  }) => {

  const currencyOptions = useSelector(store => store.competitors.filters.store_currency) || [];
  const domainOptions = useSelector(store => store.competitors.filters.domain_tld) || [];
  const languageOptions = useSelector(store => store.competitors.filters.store_language) || [];
  const competitor = useSelector(store => store.productDatabase.competitor);
  const dispatch = useDispatch();

  const [showAdvanced, setShowAdvanced] = useState(false);
  const [visibleModal, setVisibleModal] = useState(null);

  //first loading to avoid search on render
  const firstLoading = useRef(true);
  const { t } = useTranslation();

  //if filters were changed
  const checkIfChanged = () => {
    if (
      Object.keys(switchFilters).some((key) => {
        if (key === 'exclude_unavailable') return switchFilters[key] !== true;
        return switchFilters[key] !== false;
      }) ||
      Object.keys(inputFilters).some((key) => inputFilters[key].include !== null || inputFilters[key].exclude !== null) ||
      Object.keys(minMaxFilters).some((key) => minMaxFilters[key].min !== null || minMaxFilters[key].max !== null) ||
      Object.keys(dropdownFilters).some((key) => {
        if (key === 'store_created_at' || key === 'product_created_at') {
          return dropdownFilters[key].min !== null || dropdownFilters[key].max !== null || dropdownFilters[key].id !== null;
        } else return dropdownFilters[key] !== 'All';
      }) || disableShowFilters
    ) {
      if (!isChanged) setIsChanged(true);
    } else if (isChanged) setIsChanged(false);
  };

  //function to parse preset period dynamically
  const parseDates = key => {
    let quantity = dropdownFilters?.[key]?.id?.name?.split(' ')[1];
    let value = dropdownFilters?.[key]?.id?.name?.split(' ')[2];
    let max = new Date(dayjs().endOf('day')).toISOString().split('T')[0];
    let min = new Date(dayjs().endOf('day').subtract(quantity, value)).toISOString().split('T')[0];
    return { min: min, max: max };
  };

  //competitors research search api call
  const applyFilters = (new_search = false) => {
    if (disableShowFilters || (disabledSearch && !isAdmin) || !Object.keys(inputFilters).some((key) => inputFilters[key].include !== null || inputFilters[key].exclude !== null)) return;

    getProducts({
      productsAdd,
      page: pageNumber,
      page_size: pageSize,
      ordering: [sortOrder],
      new_search: new_search,
      filters: {
        ...switchFilters,
        ...inputFilters,
        ...minMaxFilters,
        product_created_at: dropdownFilters?.product_created_at?.id ? parseDates('product_created_at') : {
          min: dropdownFilters?.product_created_at?.min,
          max: dropdownFilters?.product_created_at?.max,
        },
        store_created_at: dropdownFilters?.store_created_at?.id ? parseDates('store_created_at') : {
          min: dropdownFilters?.store_created_at?.min,
          max: dropdownFilters?.store_created_at?.max,
        },
        language: dropdownFilters.language === 'All' ? null : dropdownFilters.language,
        currency: dropdownFilters.currency === 'All' ? null : dropdownFilters.currency,
        domain_tld: dropdownFilters.domain_tld === 'All' ? null : dropdownFilters.domain_tld,
      },
    });
    //hide banner -> show table
    setShowTable(true);
    setEnabled(true);
    currentScroll.current = null;
  };

  //search when pagination/loaded preset changed except 1st render
  useEffect(() => {
    if (firstLoading.current) firstLoading.current = false;
    else {
      applyFilters();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pageNumber, pageSize, sortOrder]);

  useEffect(() => {
    if (firstLoading.current) firstLoading.current = false;
    else {
      if (Boolean(loadedPreset)) {
        applyFilters(true);
        setLoadedPreset(null);
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [loadedPreset]);

  //check if changed to enable/disable search btn
  useEffect(() => {
    checkIfChanged();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [switchFilters, minMaxFilters, inputFilters, dropdownFilters, disableShowFilters]);

  useEffect(() => {
    if (competitor) {
      setInputFilters(prev => ({
        ...prev,
        title: {
          include: competitor, exclude: null,
        },
      }));
    }
  }, [competitor]);

  const competitorsPage = document.querySelector('.competitors-page');

  useEffect(() => {
    if (competitorsPage) {
      if (!showTable) {
        if (showAdvanced) competitorsPage.style.height = 'fit-content';
        else competitorsPage.style.height = 'unset';
      } else competitorsPage.style.height = 'unset';
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [showAdvanced, showTable]);

  //reset competitor selected in product database
  const setCompetitor = value => dispatch(Creators.setCompetitorResearch(value));

  //switch filters hanndler
  const onChangeSwitch = (value, name) => {
    setSwitchFilters(state => ({ ...state, [name]: value }));
  };

  //tags input include/exclude handler
  const onChangeInputFilter = (e, field, type) => {
    e.persist();
    setInputFilters(state => ({
      ...state,
      [field]: {
        ...state[field],
        [type]: state[field][type] === null ? e.target.value.split(',')[0].trim() + ',' : state[field][type] + ',' + e.target.value.split(',')[0].trim(),
      },
    }));
  };

  const onPasteInputFilter = (e, field, type) => {
    e.persist();
    let val = e.clipboardData.getData('text').split(',');
    for (let i = 0; i < val.length; i++) {
      setInputFilters(state => ({
        ...state,
        [field]: {
          ...state[field],
          [type]: state[field][type] === null ? val?.[i].trim() + ',' : [...new Set([...state[field][type].split(','), val?.[i].trim()])].join(','),
        },
      }));
    }
  };

  const onChangeMinMaxFilter = useCallback((e, field, type) => {
    e.persist();
    const parsedValue = e.target.value.replace(/\$\s?|(,*)/g, '');
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(parsedValue) && reg.test(parsedValue)) || parsedValue === '') {
      setMinMaxFilters(state => ({
        ...state,
        [field]: { ...state[field], [type]: e.target.value.trim().length ? +parsedValue : null },
      }));
    }
  }, []);

  const handleSearch = (new_search = false) => {
    if (pageSize === 50 && pageNumber === 1) applyFilters(new_search);
    else {
      setPageSize(50);
      setPageNumber(1);
      setSortOrder(null);
    }
  };

  if (skeleton) return <FiltersBlockSkeleton isMobile={isMobile} />;

  return (
    <div className={cls('competitors-filter-wrapper', {
      active: showFilters,
    })}>
      <ProductDetailsCard header={null}
                          skeleton={skeleton}
                          withLine={!isMobile}
                          headerClassName={'filters-header'}
                          bodyClassName={'filters-body'}
      >
        <span className={'filters-title'}>
          <span>
            {
              t('Filters by keywords')
            }
          </span>
          <Icon type={'attention_outline'}
                role="button"
                width={16}
                height={16}
                tooltipProps={{
                  trigger: 'hover',
                  placement: 'right',
                  overlayClassName: 'filters-title-tooltip',
                  getPopupContainer: (trigger) => trigger.parentNode,
                }}
                titleText={t('Filters by keywords')}
          />
        </span>
        <Row className="product-main-block_wrapper">
          <Col xs={24} md={12} style={{ paddingRight: isMobile ? 0 : 12, paddingLeft: 0 }}>
            <div className="input-filter-wrapper">
              {
                keyWords.map(el =>
                  <InputProductFilter key={'include_' + el.toLowerCase()}
                                      el={el}
                                      onChangeInputFilter={onChangeInputFilter}
                                      onPasteInputFilter={onPasteInputFilter}
                                      inputFilters={inputFilters}
                                      setInputFilters={setInputFilters}
                                      type={'include'}
                                      setCompetitor={setCompetitor}
                  />,
                )
              }
            </div>
          </Col>
          <Col xs={24} md={12}
               style={{ paddingRight: 0, paddingLeft: isMobile ? 0 : 12, marginTop: isMobile ? 12 : 0 }}
          >
            <div className="input-filter-wrapper">
              {
                keyWords.map(el =>
                  <InputProductFilter key={'exclude_' + el.toLowerCase()}
                                      el={el}
                                      onChangeInputFilter={onChangeInputFilter}
                                      onPasteInputFilter={onPasteInputFilter}
                                      inputFilters={inputFilters}
                                      setInputFilters={setInputFilters}
                                      type={'exclude'}
                                      setCompetitor={setCompetitor}
                  />,
                )}
            </div>
          </Col>
          <Collapse collapsible={'header'} ghost={true}>
            <Panel key={1}
                   showArrow={false}
                   header={
                     <div className="advanced-filters-toggle-wrapper"
                          onClick={() => setShowAdvanced(!showAdvanced)}
                     >
                       <div className="advanced-filters-toggle-divider" />
                       <span className={'advanced-filters-toggle'}>
                         {t('Advanced Filters')}
                         <ArrowSelectDown />
                       </span>
                       <div className="advanced-filters-toggle-divider" />
                     </div>
                   }
            >
              <div className={cls('advanced-options-wrapper')}>
                <div className={'advanced-min-max'}>
                  <Col xs={24}
                       md={24}
                       style={{ maxWidth: isMobile ? '100%' : '50%', paddingRight: isMobile ? 0 : 24, paddingLeft: 0 }}
                       className={'custom-competitor-filter-block'}
                  >
                    <span className={'filters-title'}>
                      <span>
                        {
                          t('Filters')
                        }
                      </span>
                      <Icon type={'attention_outline'}
                            role="button"
                            width={16}
                            height={16}
                            tooltipProps={{
                              trigger: 'hover',
                              placement: 'right',
                              overlayClassName: 'filters-title-tooltip',
                              getPopupContainer: (trigger) => trigger.parentNode,
                            }}
                            titleText={t('Filters')}
                      />
                    </span>
                    {
                      minMaxInputsData.map((el, index) => (
                          <MinMaxInput data={minMaxFilters}
                                       field={el.field}
                                       iconType={el.iconType}
                                       text={el.text}
                                       key={el.iconType + index}
                                       tooltip={el.tooltip}
                                       onChange={onChangeMinMaxFilter}
                          />
                        ),
                      )
                    }
                  </Col>
                  <Col xs={24}
                       md={24}
                       style={{ maxWidth: isMobile ? '100%' : '50%', paddingRight: 0, paddingLeft: isMobile ? 0 : 24, marginTop: isMobile ? 49 : 0 }}
                  >
                    <span className={'filters-title'}>
                      <span>
                        {
                          t('Additional')
                        }
                      </span>
                    </span>
                    <div className={'selects-wrapper'}>
                      <DropdownDateBlock label={'Product creation date'}
                                         id={'product_created_at'}
                                         iconType={'database_calendar'}
                                         data={dateOptions}
                                         dropdownFilters={dropdownFilters}
                                         setDropdownFilters={setDropdownFilters}
                                         hideTimeZone={true}
                                         visibleModal={visibleModal}
                                         setVisibleModal={setVisibleModal}
                                         isMobile={isMobile}
                      />
                      <DropdownDateBlock label={'Store creation date'}
                                         id={'store_created_at'}
                                         iconType={'database_calendar'}
                                         data={dateOptions}
                                         dropdownFilters={dropdownFilters}
                                         setDropdownFilters={setDropdownFilters}
                                         hideTimeZone={true}
                                         visibleModal={visibleModal}
                                         setVisibleModal={setVisibleModal}
                                         isMobile={isMobile}
                      />
                      <DropdownBlockTest label={'Store language'}
                                         id={'language'}
                                         dropdownFilters={dropdownFilters}
                                         setDropdownFilters={setDropdownFilters}
                                         iconType={'database_globe'}
                                         searchPlaceholder={'Search for store language...'}
                                         data={languageOptions}
                                         visibleModal={visibleModal}
                                         setVisibleModal={setVisibleModal}
                                         isMobile={isMobile}
                      />
                      <DropdownBlockTest label={'Store currency'}
                                         id={'currency'}
                                         dropdownFilters={dropdownFilters}
                                         setDropdownFilters={setDropdownFilters}
                                         iconType={'database_currency'}
                                         searchPlaceholder={'Search for currency...'}
                                         data={currencyOptions}
                                         visibleModal={visibleModal}
                                         setVisibleModal={setVisibleModal}
                                         isMobile={isMobile}
                      />
                      <DropdownBlockTest label={'Domain TLD'}
                                         id={'domain_tld'}
                                         dropdownFilters={dropdownFilters}
                                         setDropdownFilters={setDropdownFilters}
                                         iconType={'database_domain'}
                                         searchPlaceholder={'Search for domain LTD...'}
                                         data={domainOptions}
                                         visibleModal={visibleModal}
                                         setVisibleModal={setVisibleModal}
                                         isMobile={isMobile}
                      />
                    </div>
                  </Col>
                </div>
                {
                  isMobile ?
                    null
                    :
                    <Divider style={{
                      margin: isMobile ? '12px auto' : '24px auto',
                      minWidth: 'unset',
                      width: isMobile ? '100%' : 'calc(100% - 48px)',
                    }}
                    />
                }
              </div>
            </Panel>
          </Collapse>
          <Col xs={24} md={24}
               style={{
                 paddingLeft: isMobile ? 0 : 24,
                 paddingRight: isMobile ? 0 : 24
               }}
          >
            <div className="switch-wrapper">
              <div className="switch-options-wrapper">
                {
                  switchFilterOptions.map(el =>
                    <div key={el.key + 1} className="switch-option">
                      <Switch checked={switchFilters[el.key]}
                              name={el.key}
                              key={el.key}
                              onChange={(state) => onChangeSwitch(state, el.key)}
                              disabled={el.disabled}
                      />
                      <span onClick={() => onChangeSwitch(!switchFilters[el.key], el.key)}
                            style={{ cursor: 'pointer', pointerEvents: el.disabled ? 'none' : 'unset' }}
                      >
                        {t(el.text)}
                      </span>
                    </div>,
                  )
                }
              </div>
              <div className="switch-button-wrapper">
                <ButtonComponent className={'switch-button-reset'}
                                 text={t('Reset Filter')}
                                 onClick={resetFilters}
                />
                <ButtonComponent className={'switch-button-apply'}
                                 text={t('Search')}
                                 onClick={() => handleSearch(true)}
                                 disabled={(disabledSearch && !isAdmin) || !Object.keys(inputFilters)
                                     .some((key) => inputFilters[key].include !== null || inputFilters[key].exclude !== null)
                                   || productsLoading
                                 }
                />
              </div>
            </div>
          </Col>
        </Row>
      </ProductDetailsCard>
    </div>
  );
};

export default FiltersBlock;
