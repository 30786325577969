import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthActions from './LoginPage/reducer';
import { Layout, Spin, message, Card, Statistic } from 'antd';
import Images from '../Images';
import utils from '../Utils/utils';
import cls from 'classname';
import ButtonComponent from '../Components/Button';

const { Countdown } = Statistic;

const VerifyCodePage = (props) =>{

  const { error, fetching, resetError } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [resendFetching, setResendFetching] = useState(false);
  const [deadline, setDeadline] = useState(null);

  const [errorText, setErrorText] = useState(null);

  useEffect(() => {
    document.title = 'Verify Email - Dropship';
  }, []);

  useEffect(() => {
    if (error) {
      if (error.message) {
        setErrorText(Object.values(error.message)[0]);
      } else if (typeof error === 'string') {
        setErrorText(error);
      } else {
        for (let i in error) {
          message.error(`${i}: ${error[i]}`, 5);
        }
      }
      resetError();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [error]);

  function handleResendEmail() {
    if (state) {
      setDeadline(Date.now() + 1000 * 30);
      setResendFetching(true);
      props.checkPassword(state);
    } else {
      navigate('/restore-password');
    }
  }

  function onFinish() {
    setResendFetching(false);
    setDeadline(null);
  }

  return (
    <Layout>
      <Spin size="large" spinning={fetching}>
        <div className="login-content">
          <img src={Images.logo} alt="" className={'login-logo'} />
          <div className="login-card-wrapper">
            <Card className="login-card">
              <h2 className="login-card-title left">
                Verify Email
              </h2>
              <h3 className="login-card-subtitle left">
                We’ve sent you a letter to your email <br /> <span>{state}</span>
              </h3>
              <div className={cls('error-text', { 'error-text_empty': !errorText })}>
                <span>{errorText}</span>
              </div>
              <div className="resend-code" style={{ justifyContent: 'space-between' }}>
                {
                  resendFetching ?
                    <div className="resend-timer">
                      <Countdown title=""
                                 value={deadline}
                                 onFinish={onFinish}
                                 format="mm:ss"
                                 prefix={<span> You will be able to resend code </span>}
                                 suffix={null}
                      />
                    </div>
                    :
                    <span className="text"
                          onClick={handleResendEmail}
                    >
                      Didn't receive a letter? Resend
                    </span>
                }
              </div>
              <div className="verify-btn-block">
                <ButtonComponent className="gmail"
                                 href={`https://mail.${utils.checkMailDomain(state).link}.com`} target="_blank"
                                 rel="noopener noreferrer"
                >
                  <img className="btn-icon icon-space" src={Images[utils.checkMailDomain(state).type]} alt="" />
                  Go To {utils.checkMailDomain(state).name}
                </ButtonComponent>
              </div>
            </Card>
          </div>
          <div className="remember-password">
            Made mistake in email? <Link to="/restore-password">Change Email</Link>
          </div>
        </div>
      </Spin>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    isDesktop: state.nav.isDesktop,
    isMobile: state.nav.isMobile,
    error: state.auth.error,
    fetching: state.auth.fetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkPassword: (email) => dispatch(AuthActions.checkPasswordRequest(email)),
  resetError: () => dispatch(AuthActions.authErrorReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCodePage);
