import React from 'react';
import { useSelector } from 'react-redux';

const AdSpotCalendar = () => {
  const theme = useSelector((state) => state?.nav?.theme);

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        fill={theme === 'light' ? '#A1AAC8' : '#ffffff5c'}
        d="M7.08333 2.29199C7.08333 1.94681 6.80351 1.66699 6.45833 1.66699C6.11316 1.66699 5.83333 1.94681 5.83333 2.29199V3.33366H4.79167C3.52601 3.33366 2.5 4.35967 2.5 5.62533V15.2087C2.5 16.4743 3.52601 17.5003 4.79167 17.5003H9.375C9.72018 17.5003 10 17.2205 10 16.8753C10 16.5301 9.72018 16.2503 9.375 16.2503H4.79167C4.21637 16.2503 3.75 15.784 3.75 15.2087V8.33366H16.25V10.2087C16.25 10.5538 16.5298 10.8337 16.875 10.8337C17.2202 10.8337 17.5 10.5538 17.5 10.2087V5.62533C17.5 4.35967 16.474 3.33366 15.2083 3.33366H14.1667V2.29199C14.1667 1.94681 13.8868 1.66699 13.5417 1.66699C13.1965 1.66699 12.9167 1.94681 12.9167 2.29199V3.33366H7.08333V2.29199Z" 
      />
      <path 
        fill={theme === 'light' ? '#A1AAC8' : '#ffffff5c'} 
        d="M18.1993 13.5121C18.4129 13.241 18.3663 12.848 18.0951 12.6344C17.824 12.4208 17.431 12.4674 17.2174 12.7385L14.0451 16.765L12.737 15.4368C12.4948 15.1908 12.099 15.1878 11.8531 15.43C11.6072 15.6722 11.6042 16.0679 11.8464 16.3139L13.6519 18.1472C13.7778 18.275 13.9526 18.3426 14.1317 18.3327C14.3109 18.3228 14.4771 18.2364 14.5882 18.0955L18.1993 13.5121Z" 
      />
    </svg>

  );
};

export default AdSpotCalendar;
