import React from 'react';
import { useSelector } from 'react-redux';

const Sort = () => {
  const theme = useSelector((state) => state?.nav?.theme);

  return (
    <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00133 3.9585V16.0418M5.00133 16.0418L2.5 13.5418M5.00133 16.0418L7.5 13.5418M9.79167 5.62516H16.875M13.125 14.3752H16.875M11.4583 10.0002H16.875"
        stroke={theme === 'light' ? '#707BA0' : '#ffffff5c'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Sort;
