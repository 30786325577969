import React, { useState } from 'react';
import {useSelector} from "react-redux";
import cls from "classname";

const AttentionOutline = () => {

  const isDisabledTransition = useSelector(state => state.nav.disabledTransition);
  const theme = useSelector(state => state.nav.theme);
  const [hovered, setHovered] = useState(false);

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="16"
         height="16"
         viewBox="0 0 16 16"
         fill="none"
         className={cls('attention-outline-icon', {
           'disabled-transition': isDisabledTransition,
         })}
         onMouseEnter={() => setHovered(true)}
         onMouseLeave={() => setHovered(false)}
    >
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335ZM6.66732 6.66683H8.66732V11.3335H7.33398V8.00016H6.66732V6.66683ZM7.33398 6.00016V4.66683H8.66732V6.00016H7.33398Z"
            fill={hovered ? '#225AEA' : theme == 'light' ? "#C5CCE3" : 'rgba(255, 255, 255, 0.36)'}
      />
    </svg>
  )
};

export default AttentionOutline;
