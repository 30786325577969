import React from 'react';
import { useSelector } from 'react-redux';

const Speedometer = () => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.99999 10.8333L7.29166 8.125M4.31571 16.04C1.54121 13.0155 1.6191 8.31299 4.54938 5.38272C7.55967 2.37243 12.4403 2.37243 15.4506 5.38272C18.3809 8.31299 18.4588 13.0155 15.6843 16.04"
        stroke={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, .26"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>
  );
};

export default Speedometer;
