import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import ButtonComponent from "./Button";
import InputComponent from "./Input";
import { Divider } from 'antd';
import './DiscountCard.less'

const DiscountCard = (
  {
    plan,
    period,
    onSubmit,
    error,
    clearError,
    discount,
    clearDiscount,
    disabled,
    style,
    isMobile,
    isSt=false,
    divider = false,
    ...props
  }) => {

  const [code, setCode] = useState(null)

  useEffect(() => {
    clearDiscount();
    clearError();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [])

  const {t} = useTranslation()

  function handleClearShowError() {
    if (error) clearError()
  }

  function handleClearDiscount() {
    if (discount) clearDiscount()
  }

  function handleChange(event) {
    let target = event.target
    handleClearShowError()
    handleClearDiscount()
    setCode(target.value)
  }

  function onFocusHandler() {
    handleClearShowError()
    handleClearDiscount()
  }

  function handleSubmit() {
    if (props.fetching) return
    if (discount) {
      clearDiscount()
      setCode('')
    } else {
      onSubmit(code)
    }
  }

  return (
    <div className='discount-card'
         style={style ? {...style} : {}}
    >
      <div className={`input-block${code ? ' active' : ''}${error || discount? ' with-result' : ''}`}>
        <div className={'discount-wrap'}>
          <InputComponent className={`input${error ? ' error' : ''}${discount ? ' active' : ''}`}
                          name={'discount_code'}
                          value={code}
                          placeholder={t('Discount code')}
                          onChange={handleChange}
                          onPressEnter={handleSubmit}
                          onFocus={onFocusHandler}
                          disabled={disabled}
                          prefix={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M2.29297 9.30115V3.95817C2.29297 3.0377 3.03916 2.2915 3.95964 2.2915H9.30261C9.74464 2.2915 10.1686 2.4671 10.4811 2.77966L17.1561 9.45466C17.807 10.1055 17.807 11.1608 17.1561 11.8117L11.8131 17.1547C11.1623 17.8055 10.107 17.8055 9.45612 17.1547L2.78112 10.4797C2.46856 10.1671 2.29297 9.74317 2.29297 9.30115Z"
                                  stroke="#A1AAC8"
                                  strokeWidth="1.5"
                                  strokeLinejoin="round"
                            />
                            <path d="M6.8763 6.24984C6.8763 6.59502 6.59648 6.87484 6.2513 6.87484C5.90612 6.87484 5.6263 6.59502 5.6263 6.24984C5.6263 5.90466 5.90612 5.62484 6.2513 5.62484C6.59648 5.62484 6.8763 5.90466 6.8763 6.24984Z"
                                  stroke="#A1AAC8"
                                  strokeWidth="1.5"
                                  strokeLinejoin="round"
                            /></svg>}
                          suffix={!code ?
                            null
                            :
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 width="20"
                                 height="20"
                                 viewBox="0 0 20 20"
                                 fill="none"
                                 onClick={() => setCode('')}
                            >
                              <path d="M6.45703 6.4585L13.5404 13.5418M13.5404 6.4585L6.45703 13.5418" stroke="#A1AAC8"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                              />
                            </svg>}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                           disabled={!code || disabled}
                           onClick={handleSubmit}
                           text={t('Apply')}
          />
        </div>
      </div>
      {
        error?.message?.detail ?
          <span className='error'>
              {
                error?.message?.detail
              }
            </span>
          :
          null
      }
     <Divider style={{ margin: '16px 0' }} />
    </div>
  )
}

export default DiscountCard
