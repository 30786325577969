import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../Components/Modal';
import cls from 'classname';
import { Divider } from 'antd';
import ButtonComponent from '../../../Components/Button';

const options = [
  {
    key: 'active_ads',
    text: 'Active ads'
  },
  {
    key: 'eu_reach',
    text: 'EU reach'
  },
  {
    key: 'adsets',
    text: 'Adsets'
  },
  {
    key: 'ad_spend',
    text: 'Ad spend'
  },
  {
    key: 'last_seen_date',
    text: 'Last seen date'
  },
  {
    key: 'creation_date',
    text: 'Creation Date'
  },
];

const SortModal = (
  {
    order,
    setSort,
    setModal,
    visible,
    isMobile
  }
) => {

  const { t } = useTranslation();

  const [ordering, setOrdering] = useState('');
  const [key, setKey] = useState('');

  useEffect(() => {
    if (!visible) {
      setKey('');
      setOrdering('');
    } else {
      setOrdering(order?.includes('-') ? '-' : '');
      setKey(order?.includes('-') ? order?.split('-')?.[1] : order);
    }
  }, [visible])

  return (
    <Modal handleClose={() => setModal(null)}
           title={t('Sorting')}
           isMobile={isMobile}
           width={450}
           className="collection-options-modal collection-modal adlibrary-sort-modal"
           open={visible}
           destroyOnClose
    >
      <div className={'custom-modal-content'}>
        <p className={'collection-modal-text'}>
          {
            t(`Select an option to sort your ads list.`)
          }
        </p>
        <div className={'sales-tracker-top-page-header-dropdown sort'}>
          <span className="sort-header">
            {t('Sort results from')}
          </span>
          <div className="sort-header-options">
            <span className={cls("sort-header-option", {
              active: ordering === '-'
            })}
                  onClick={() => {
                    setOrdering('-');
                  }}
            >
              {t('High to low')}
            </span>
            <span className={cls('sort-header-option', {
              active: ordering === '',
            })}
                  onClick={() => {
                    setOrdering('');
                  }}
            >
              {t('Low to high')}
            </span>
          </div>
          <Divider style={{ margin: '16px 16px 8px', width: 'calc(100% - 32px)', minWidth: 'calc(100% - 32px)' }} />
          <div className="sort-options">
            {
              options.map(el => (
                <span className={cls("sort-option", {
                  active: key?.includes(el?.key)
                })}
                      key={el?.key}
                      onClick={() => {
                        setKey(el?.key);
                      }}
                >
                  {el?.text}
                </span>
              ))
            }
          </div>
          <Divider style={{ margin: '12px 0 16px' }} />
          <div className={'collection-modal-btn-wrapper'}>
            <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                             onClick={() => {
                               setModal(null);
                               setSort(null)
                             }}
                             text={t('Clear')}
            />
            <ButtonComponent className="collection-modal-btn collection-modal-btn-save"
                             onClick={() => {
                               setModal(null);
                               setSort(`${ordering}${key}`);
                             }}
                             text={t('Confirm')}
            />
          </div>
          <div style={{ height: 16 }} />
        </div>
      </div>
    </Modal>
  );
};

export default SortModal;
