import React, { useEffect, useRef } from 'react';
import cls from 'classname';
import acc from 'accounting';
import { useTranslation } from 'react-i18next';
import { Spin, Tooltip } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import EmptyState from './empty-state';
import SaveToCollection from '../SaveToCollections/save-to-collections';
import Icon from '../../Icon';
import utils from '../../Utils/utils';
import './styles-overview.less';

const ProductsComponent = (
  {
    products = [],
    loading = false,
    searchText,
    getNextList,
    next,
    addToCollectionFunc,
    isAdmin,
    breadcrumbs,
    isAdminEdit,
    onOpenUpgradePlan,
    isMobile,
    setInputText,
    isCollections = false
  }) => {

  let navigate = useNavigate();
  const productsRef = useRef();
  const { t } = useTranslation();
  const breadCrumbs = breadcrumbs?.length ? [...breadcrumbs] : null;
  const path =
    isAdmin && !isAdminEdit ?
      '/admin/portfolio'
      :
      breadCrumbs?.filter((el) => !!el.link)[0].link || '/portfolio';

  // GET NEXT PRODUCT HANDLER (INFINITY SCROLL)
  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight - 10;
    const currentTopScroll = event.target.scrollTop;
    if (currentTopScroll >= maxTopScroll && !loading && !!next) {
      getNextList();

      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  // ADD EVENT LISTENER
  useEffect(() => {
    const productsContent = productsRef.current;

    if (productsContent) {
      productsContent.addEventListener('scroll', onScrollHandlerFunc);
    }

    return () => {
      if (productsContent) {
        productsContent.removeEventListener('scroll', onScrollHandlerFunc);
      }
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [next]);

  // SCROLL TO TOP WHEN INPUT TEXT
  useEffect(() => {
    scrollToTop();
  }, [searchText]);

  // SCROLL TO TOP
  const scrollToTop = () => {
    const productsContent = productsRef.current;

    if (productsContent) {
      productsContent.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const lockedContent = (
    <div className={cls('block-locked block-locked_content')}>
      <div className="block-locked-info-wrap">
        <Icon role="icon" type="single_locked_block_grey" />
        <div className="block-locked-info-wrap block-locked-info-wrap_text">
          <div className="block-locked-title">{t('Locked Content')}</div>
          <div>
            <span className="block-locked-text-clickable"  onClick={() => onOpenUpgradePlan()}>{t('Upgrade') + ' '}</span>
            <span className="block-locked-text">{t('your plan to unlock')}</span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={'products-overview'}>
      {
        products?.length > 0 ?
          <Spin spinning={loading}>
            <div className={cls('products-overview_wrapper', {
              collections: isCollections
            })}
                 ref={productsRef}
            >
              {
                products.map((value) => (
                  <div className="product"
                       key={`product-${value.product_id}`}
                  >
                    {
                      value['is_locked'] ?
                        (
                          <div className="product_image">
                            {lockedContent}
                          </div>)
                        :
                        (
                          <Link to={`${path}/product/${value.product_id}`}
                                className="product_image"
                                state={value}
                          >
                            {value.image && <img src={value?.image?.url} alt="images" onError={utils.addDefaultSrc} />}
                          </Link>
                        )
                    }
                    <div className="product_wrap"
                         id="product_wrap"
                    >
                      {
                        value['is_locked'] ?
                          (
                            <span className="product_name">
                              {value.title}
                            </span>
                          )
                          :
                          (
                            <Tooltip overlayClassName="product_name-tooltip details-tooltip"
                                     title={value.title}
                                     trigger={isMobile ? 'click' : 'hover'}
                                     destroyTooltipOnHide={true}
                                     getPopupContainer={() =>
                                       document.getElementById('product_wrap')
                                     }
                            >
                              <Link to={`${path}/product/${value.product_id}`}
                                    className="product_name"
                              >
                                {value.title}
                              </Link>
                            </Tooltip>
                          )
                      }
                    </div>
                    <div className="product-detail_price">
                      ${acc.formatNumber(value.price, 2, ' ', ',')}
                    </div>
                    <div className="product_details">
                      <div className="product-detail">
                        <div className="product-detail_title">
                          {t('Cost')}
                        </div>
                        <div className="product-detail_value">
                          {value.cost ? value?.cost?.replaceAll('.', ',') : 0}
                        </div>
                      </div>
                      <div className="product-detail">
                        <div className="product-detail_title">
                          {t('P/C Ratio')}
                        </div>
                        <div className="product-detail_value">
                          {acc.formatNumber(value.p_c_ratio, 2, ' ', '.')}X
                        </div>
                      </div>
                      <div className="product-detail">
                        <div className="product-detail_title">
                          {t('BECPA')}
                        </div>
                        <div className="product-detail_value">
                          ${acc.formatNumber(value.BECPA, 2, ' ', ',')}
                        </div>
                      </div>
                      <div className="product-detail">
                        <div className="product-detail_title">
                          {t('POT Profit')}
                        </div>
                        <div className="product-detail_value">
                          ≈${acc.formatNumber(value.pot_profit, 0, ',', ',')}
                        </div>
                      </div>
                    </div>
                    <div className={'product-btn-wrapper'}>
                      {
                        isAdmin ?
                          null
                          :
                          <div className={'product_view-details'}>
                            <SaveToCollection isDisabled={value['is_locked']}
                                              saveFunc={(isSaved) =>
                                                addToCollectionFunc(
                                                  value.product_id,
                                                  isSaved,
                                                  value.title,
                                                )
                                              }
                                              isSave={value.saved}
                            />
                          </div>
                      }
                      {
                        value['is_locked'] ?
                          (
                            <div className={'product_view-details wide'}
                                 onClick={() => onOpenUpgradePlan(value['is_locked'])}
                            >
                              {t('Upgrade Plan')}
                            </div>)
                          :
                          (
                            <Link to={`${path}/product/${value.product_id}`}
                                  state={{ ...value }}
                                  className="product_view-details wide"
                            >
                              {t(isAdminEdit ? 'Edit' : 'Product insights')}
                            </Link>)
                      }
                    </div>
                  </div>
                ))}
            </div>
          </Spin>
          :
          <EmptyState setInputText={(value) => searchText?.length ? setInputText(value) : navigate(path)}
                      btnText={searchText?.length ? 'Clear search' : 'Go back'}
                      isCollections={isCollections}
          />
      }
    </div>
  );
};

export default React.memo(ProductsComponent, (prev, next) => {
  return _.isEqual(prev.products, next?.products) && prev?.loading === next?.loading
});
