import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import Images from '../Images';
import ButtonComponent from "../Components/Button";
import LoginHeader from "../Components/LoginHeader/LoginHeader";
import TitleAnimationColor from "../Components/TitleAnimationColor";
import UserCreators from './User/reducer';
import ShopifyStoreCreators from './ShopifyStore/reducer';

const CheckoutSuccessPage = () => {

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector(store => store?.auth?.userInfo);
  const settingFetching = useSelector(store => store?.users?.fetching);
  const email = userInfo?.email;
  const uid = userInfo?.chargebee_customer_id;

  const getUserInfo =  () => dispatch(UserCreators.userRequest({isCheckout: true}));
  const openModalShopifyConnect = initialUrl => dispatch(ShopifyStoreCreators.changeVisibleModalShopify({ isVisibleModal: 'connect', initialUrl }));


  const addPlanToGTM = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'subscription_success',
        'gtm.newUrl': window.location.href,
        'plan_type': userInfo?.subscriptions[0]?.plan?.name,
        'user_email': userInfo?.email,
      });
  }

  useEffect(() => {
    document.title = 'Success - Dropship';
    window.fpr("referral",{email: email, uid: uid});
    if (localStorage.getItem('completely_finished')) navigate('/dashboard');
    addPlanToGTM();
    let head = document.querySelector('head');
    let script = document.createElement('script')
    script.text = "!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');twq('config','oepmf');"
    head.appendChild(script)
    return () => {
      head.removeChild(script);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  return (
    <Layout>
      <div className="login-row">
        <LoginHeader withLogout={false} />
        <Spin spinning={settingFetching}>
          <div className={'login-content success success_first-time'}>
          <div className='content-title animation'>
            <TitleAnimationColor>
              <h2>
                {
                  userInfo?.first_name ?
                    `Welcome, ${userInfo?.first_name}!`
                    :
                    'Welcome!'
                }
              </h2>
            </TitleAnimationColor>
            <div>
              {
                window?.location?.search.includes('state=succeeded') ?
                  'Your store is on its way! We will keep you updated by email. Your Dropship account is waiting for you.'
                  :
                  'Your Dropship account has been successfully created. Good luck with your journey!'
              }
            </div>
          </div>

          <div className="page-content">
            <div dangerouslySetInnerHTML={{__html:
                `
                  <video src="${Images.videoSignUpSuccess}"
                          loop
                          muted
                          autoplay
                          playsinline
                 />
                  `
            }} />

            <ButtonComponent type="primary"
                             className="btn-primary btn-primary_animation small"
                             disabled={settingFetching}
                             onClick={() => {
                               getUserInfo();
                               openModalShopifyConnect();
                             }}
            >
              Go To Dashboard
            </ButtonComponent>
          </div>
        </div>
        </Spin>
      </div>
    </Layout>
  );
}

export default CheckoutSuccessPage;
