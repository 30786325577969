import React, {useInsertionEffect, useRef, useState} from 'react';
import cls from "classname";
import {useTranslation} from "react-i18next";
import {connect, useDispatch, useSelector} from "react-redux";
import Creators from "../reducer";
import ShopifyCreators from "../../ShopifyStore/reducer";
import {dateOptions, langMapISO} from "../../../Utils/utils";
import dayjs from "dayjs";
import AutoDSCreators from "../../AutoDS/reducer";
import {useLocation} from "react-router-dom";
import SearchWithTags from "../../SalesTrackerPage/pages/components/SearchWithTags";
import DropdownBarFilter from "../../SalesTrackerPage/pages/components/DropdownBarFilter";
import OldDropDownCalendar from "../../../Shares/OldDropDownCalendar/OldDropDownCalendar";
import AdItemProductCard from "./AdItemProductCard";
import AdItemProductsGeneralSkeleton from "../components/AdItemProductsGeneralSkeleton";
import {Divider, Spin} from "antd";
import MainBlockAdSpot from "../components/MainBlockAdSpot";
import Icon from "../../../Icon";
import _ from 'lodash';
import acc from "accounting";


const categories = (isMobile) => ([
  {
    key: 'all',
    title: isMobile ? 'All' : 'All products',
  },
  {
    key: 'newest',
    title: 'Newest products'
  },
  {
    key: 'most_ads',
    title: 'Most ads'
  },
]);

const AdItemProductsGeneral = (
  {
    isMobile,
    id,
    innerPagesInfo,
    setInnerPagesDateFilters,
    setInnerPagesDateCheckedList,
    overviewInfo,
    getOverviewMainInfo,
    getOverviewCardInfo,
    setView,
    getProductsByStoreIdAdSpot,
    loading,
    products,
    count,
    countLoading,
    filters,
    filtersLoading,
    getProductsCountByStoreIdAdSpot,
    getProductsFiltersByStoreIdAdSpot,
    resetFilters,
    reportLoading,
    getProductsReportAdSpot,
    changeVisibleModalShopify,
    shopifyStores,
    autoDS,
    reset,
    setRecordToPriceHistory,
    setVisibleModal,
    setModal,
    visibleModal,
  }
) => {

  const {t} = useTranslation();
  const tableRef = useRef(null);
  const {search: searchParams} = useLocation();
  const dispatch = useDispatch();
  let params = new URLSearchParams(searchParams);
  const initialUrl = document?.location?.pathname + (document?.location?.search || '');
  const lang = useSelector(store => store?.auth?.userInfo?.language) || 'en';

  const minDate = innerPagesInfo?.dateFilters?.report_period?.min;
  const maxDate = innerPagesInfo?.dateFilters?.report_period?.max;
  const minDateInitial = dayjs().diff(dayjs(overviewInfo?.cardInfo?.min_calendar_date), 'days') < 30 ?
            dayjs(overviewInfo?.cardInfo?.min_calendar_date).format('YYYY-MM-DD')
            :
            dayjs().subtract(30, 'days').format('YYYY-MM-DD');
  const maxDateInitial = dayjs().format('YYYY-MM-DD');
  
  const [pageNumber, setPageNumber] = useState(1);
  const [sort, setSort] = useState('most_ads');
  const [value, setValue] = useState({
    price: {min: null, max: null},
    ads: {min: null, max: null},
    created_at: {min: null, max: null},
  });
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [firstLoading, setFirstLoading] = useState(true);

  const initialValue = (data) => {
    let res = {};
    for (let filter in data) {
      res[filter] = {
        min: data[filter]?.min,
        max: data[filter]?.max,
      }
    }
    return res;
  }

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if ((currentTopScroll >= maxTopScroll*0.95) && !loading && products?.length < +count) {
      setPageNumber(prev => prev + 1);
      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  const handleOpenModalShopifyConnect = (importProductId) => {
    if (shopifyStores.length) {
      changeVisibleModalShopify({ isVisibleModal: 'import', initialUrl, importProductId });
    } else {
      changeVisibleModalShopify({ isVisibleModal: 'connect', initialUrl, importProductId: null });
    }
  };

  const handleOpenModalAutoDSConnect = (importProductId) => {
    if (autoDS?.email) {
      if (autoDS?.stores?.length) dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'import', importProductId }));
      else dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'import_unavailable', importProductId: null }));
    } else dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'connect', importProductId }));
  }

  const applyFilters = (newSearch=false, withEmptySearch=false) => {
    let data = {
      date__gte: minDate,
      date__lte: maxDate,
      price__gte: value?.price?.min,
      price__lte: value?.price?.max,
      ads__gte: value?.ads?.min,
      ads__lte: value?.ads?.max,
      created_at__gte: value?.created_at?.min,
      created_at__lte: value?.created_at?.max,
      search: withEmptySearch ? null : searchValue?.length ? searchValue : null,
      sort: sort,
      page_number: newSearch ? 1 : pageNumber,
      internal_shop_id: params.get('internal_shop_id')
    }

    getProductsByStoreIdAdSpot(data);
  }

  const handleCount = (key) => {
    let data = {
      date__gte: minDate,
      date__lte: maxDate,
      price: {min: value?.price?.min, max: value?.price?.max},
      ads: {min: value?.ads?.min, max: value?.ads?.max},
      created_at: {min: value?.created_at?.min, max: value?.created_at?.max},
      search: searchValue,
      sort: sort,
      internal_shop_id: params.get('internal_shop_id')
    };

    data[key.id].min = key?.value?.min;
    data[key.id].max = key?.value?.max;

    getProductsCountByStoreIdAdSpot(data);
  }

  const searchSubmit = (withEmptySearch=false) => {
    applyFilters(true, withEmptySearch);
  }

  const valueSubmit = (value) => {
    setPageNumber(1);
    setValue(value)
  }

  const clearEngagementCalendar = () => {
    let min = dayjs().diff(dayjs(overviewInfo?.cardInfo?.min_calendar_date), 'days') < 30 ?
    dayjs(overviewInfo?.cardInfo?.min_calendar_date).format('YYYY-MM-DD')
    :
    dayjs().subtract(30, 'days').format('YYYY-MM-DD');
    let max = dayjs().format('YYYY-MM-DD');
    setInnerPagesDateFilters({report_period: {min, max}});
    setInnerPagesDateCheckedList([{id: 4, name: 'Last 30 days', value: 'Last 30 days', days: 30}]);
    applyFilters(true, true);
  };

  useInsertionEffect(() => {
    reset();
    getOverviewMainInfo({
      ad_id: params.get('ad_id'),
      domain: params.get('domain'),
      ...(params.get('internal_shop_id') && {internal_shop_id: params.get('internal_shop_id')})
    });
    getOverviewCardInfo({
        ad_id: params.get('ad_id'),
      });

    return () => {
      resetFilters();
    }
  }, [searchParams]);

  useInsertionEffect(() => {
    let min = dayjs().diff(dayjs(overviewInfo?.cardInfo?.min_calendar_date), 'days') < 30 ?
      dayjs(overviewInfo?.cardInfo?.min_calendar_date).format('YYYY-MM-DD')
      :
      dayjs().subtract(30, 'days').format('YYYY-MM-DD');
    let max = dayjs().format('YYYY-MM-DD');
    setInnerPagesDateFilters({report_period: {min, max}});
    setInnerPagesDateCheckedList([{id: 4, name: 'Last 30 days', value: 'Last 30 days', days: 30}]);
  }, [overviewInfo?.cardInfo?.min_calendar_date]);

  useInsertionEffect(() => {
    if (minDate && maxDate && overviewInfo?.cardInfo?.min_calendar_date && !filtersLoading) {
      getProductsFiltersByStoreIdAdSpot({
        date__gte: minDate,
        date__lte: maxDate,
        sort: sort,
        internal_shop_id: params.get('internal_shop_id')
      });
      setSearchValue('');
      setPageNumber(1);
      if (!loading && !firstLoading) {
        applyFilters(true, true);
      }
    }
  }, [minDate, maxDate, sort])

  useInsertionEffect(() => {
    if (filters !== null && Object.keys(filters || {}).length) {
      setValue({...filters});
    }
  }, [JSON.stringify(filters)]);


  useInsertionEffect(() => {
    setPageNumber(1);
  }, [JSON.stringify(value)]);

  useInsertionEffect(() => {
    if (!filtersLoading && Object.keys(filters || {}).length) {
      if (firstLoading && minDate && maxDate) {
        applyFilters(true)
        setFirstLoading(false);
      } else {
        if (!loading) {
          applyFilters();
        }
      }
    }
  }, [pageNumber, JSON.stringify(value)]);

  useInsertionEffect(() => {
    const tableContent = tableRef.current;
    if (tableContent && products?.length < +count) {
      tableContent.addEventListener('scroll', onScrollHandlerFunc);
    }
    return () => {
      if (tableContent && !firstLoading) {
        tableContent.removeEventListener('scroll', onScrollHandlerFunc);
      }
    }
  }, [products?.length, count, tableRef?.current, loading])

  if (filtersLoading || (loading && pageNumber === 1)) return <AdItemProductsGeneralSkeleton isMobile={isMobile} />

  return (
    <div className={'fadspot-page-products-general'}>
      <div className={'fadspot-page-products-general-header-title'}>
        <span className="fadspot-page-products-general-header-title-text">{t('Products')}</span>
        <span className="fadspot-page-products-general-header-title-count">{products?.length}</span>
      </div>

      <div className="fadspot-page-products-filters-wrapper">
        <div className="fadspot-page-products-search product-cards-page-search">
          <SearchWithTags searchValue={searchValue}
                          search={search}
                          setSearch={setSearch}
                          setSearchValue={setSearchValue}
                          searchSubmit={searchSubmit}
                          disabled={false}
                          disabledBtn={loading || filtersLoading}
                          placeholder={t('Search for products...')}
          />
        </div>
        <div className="fadspot-page-products-categories">
          {
            categories(isMobile).map(el => (
              <div key={el} className={cls('fadspot-page-products-category', {
                'active': sort === el?.key
              })}
                  onClick={() => sort !== el?.key && setSort(el?.key)}
              >
                {t(el?.title)}
              </div>
            ))
          }
        </div>
      </div>
        <div className="product-cards-page-dropdown-wrapper fadspot-page-products-dropdown-wrapper">
          <DropdownBarFilter key={'Price'}
                title={t('Price')}
                tooltip={'The price products are sold for in USD.'}
                position={'bottomRight'}
                id={'price'}
                isStore={false}
                min={filters?.price?.min || 0}
                max={filters?.price?.max || 100}
                value={value}
                setValue={valueSubmit}
                disabled={false}
                isStandard={false}
                countLoading={countLoading}
                count={count || 0}
                handleCount={handleCount}
                isMobile={isMobile}
          />
          <DropdownBarFilter key={'ads'}
                            title={t('Ad count')}
                            tooltip={'The price products are sold for in USD.'}
                            position={'bottomRight'}
                            id={'ads'}
                            isStore={false}
                            min={filters?.ads?.min || 0}
                            max={filters?.ads?.max || 100}
                            value={value}
                            setValue={valueSubmit}
                            disabled={false}
                            isStandard={false}
                            countLoading={countLoading}
                            count={count || 0}
                            handleCount={handleCount}
                            suffix={t('count')}
                            isMobile={isMobile}
          />
          <OldDropDownCalendar title={t('Creation date')}
                            id={'created_at'}
                            value={value}
                            setValue={valueSubmit}
                            disabled={false}
                            isStandard={false}
                            countLoading={countLoading}
                            count={count || 0}
                            handleCount={handleCount}
                            adspot={true}
          />
          <MainBlockAdSpot
            data={{
              id: overviewInfo?.mainInfo?.advertiser?.id,
              ad_headline: overviewInfo?.mainInfo?.advertiser?.name,
              timeZone: overviewInfo?.mainInfo?.store_timezone,
              timeInfo: overviewInfo?.cardInfo?.min_calendar_date,
              dates: {min: minDate, max: maxDate},
            }}
            downloadReport={getProductsReportAdSpot}
            dateOptions={dateOptions}
            fileLoading={reportLoading}
            sort={sort}
            withTitle
            isMobile={isMobile}
          />
        </div>
        <div className="fadspot-page-products-bottom-content">
          <span className="fadspot-page-products-bottom-content-item clear-all" onClick={() => {
            clearEngagementCalendar();
            setValue({...filters});
          }}>
            <Icon role={'icon'} type={'clear'} />{t('Clear all')}
          </span>

          {minDateInitial === minDate && maxDateInitial === maxDate ? null : (
            <>
            <Divider type={'vertical'} className="fadspot-page-products-bottom-content-divider"/>
            <span className="fadspot-page-products-bottom-content-item engagement-date">
              <span>{t('Engagement calendar')}:</span>
              {dayjs(minDate)
                    .locale(langMapISO[lang])
                    .format('MMM DD, YYYY')}
              {' - '}
              {dayjs(maxDate)
                    .locale(langMapISO[lang])
                    .format('MMM DD, YYYY')}
              <span
                  className="close"
                  onClick={() => {
                    clearEngagementCalendar();
                  }}
              >
                <Icon type={'close'} role={'icon'} />
              </span>
            </span>
            </>
          )}

          {filters?.price?.max === value?.price?.max ? null : (
            <>
              <Divider type={'vertical'} className="fadspot-page-products-bottom-content-divider"/>
              <span className="fadspot-page-products-bottom-content-item product-price">
                <span>{t('Price')}:</span>
                {value?.price?.min
                  ? acc.formatNumber(value?.price?.min, '0', ',', '.')
                  : acc.formatNumber(filters?.price?.min, '0', ',', '.')}{' '}
                -{' '}
                {value?.price?.max
                  ? acc.formatNumber(value?.price?.max, '0', ',', '.')
                  : acc.formatNumber(filters?.price?.max, '0', ',', '.')}
                <span
                  className="close"
                  onClick={() => {
                    setValue({
                      ...value,
                      price: {
                        min: filters?.price?.min,
                        max: filters?.price?.max,
                      },
                    });
                  }}
                >
                  <Icon type={'close'} role={'icon'} />
                </span>
              </span>
            </>
          )}

          {filters?.ads?.max === value?.ads?.max ? null : (
            <>
              <Divider type={'vertical'} className="fadspot-page-products-bottom-content-divider"/>
              <span className="fadspot-page-products-bottom-content-item">
                <span>{t('Ad count')}:</span>
                {value?.ads?.min
                  ? acc.formatNumber(value?.ads?.min, '0', ',', '.')
                  : acc.formatNumber(filters?.ads?.min, '0', ',', '.')}{' '}
                -{' '}
                {value?.ads?.max
                  ? acc.formatNumber(value?.ads?.max, '0', ',', '.')
                  : acc.formatNumber(filters?.ads?.max, '0', ',', '.')}
                <span
                  className="close"
                  onClick={() => {
                    setValue({
                      ...value,
                      ads: {
                        min: filters?.ads?.min,
                        max: filters?.ads?.max,
                      },
                    });
                  }}
                >
                  <Icon type={'close'} role={'icon'} />
                </span>
              </span>
            </>
          )}

          {filters?.created_at?.max === value?.created_at?.max ? null : (
          <span className="fadspot-page-products-bottom-content-item create-date">
            <span>{t('Creation date')}:</span>
            {value?.created_at?.min
              ? dayjs(value?.created_at?.min)
                  .locale(langMapISO[lang])
                  .format('MMM DD, YYYY')
              : dayjs(filters?.created_at?.min)
                  .locale(langMapISO[lang])
                  .format('MMM DD, YYYY')}{' '}
            -{' '}
            {value?.created_at?.max
              ? dayjs(value?.created_at?.max)
                  .locale(langMapISO[lang])
                  .format('MMM DD, YYYY')
              : dayjs(filters?.created_at?.max)
                  .locale(langMapISO[lang])
                  .format('MMM DD, YYYY')}
            <span
              className="close"
              onClick={() => {
                setValue({
                  ...value,
                  created_at: {
                    min: filters?.created_at?.min,
                    max: filters?.created_at?.max,
                  },
                });
              }}
            >
              <Icon type={'close'} role={'icon'} />
            </span>
          </span>
          )}
        </div>
      <Spin spinning={loading}>
        <div className="fadspot-page-products-cards-wrapper product-cards-page-items-wrapper"
             ref={tableRef}
        >
          {
            products?.length ?
              products?.map((el, index) => <AdItemProductCard id={index}
                                                              search={searchParams}
                                                              setModal={setModal}
                                                              setVisibleModal={setVisibleModal}
                                                              setRecordToPriceHistory={setRecordToPriceHistory}
                                                              data={{...el}}
                                                              active={sort}
                                                              setView={setView}
                                                              storeId={id}
                                                              handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                                                              handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
            />)
              :
              <div className={'sales-tracker-top-table-body-empty'}>
                <div className="empty-image_icon">
                  <Icon width={96} height={96} role="icon" type="empty_warning" />
                </div>
                <div className={'sales-tracker-top-table-body-empty-text'}>
                  <p>
                    {t('No products found')}
                  </p>
                  <p>
                    {t('Kindly change your search query and try again.')}
                  </p>
                </div>
              </div>
          }
        </div>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state?.nav?.isMobile,
  userInfo: state.auth.userInfo,
  products: state?.adSpot?.innerPagesInfo?.products?.results,
  loading: state?.adSpot?.innerPagesInfo?.products?.loading,
  count: state?.adSpot?.innerPagesInfo?.products?.count,
  countLoading: state?.adSpot?.innerPagesInfo?.products?.countLoading,
  filters: state?.adSpot?.innerPagesInfo?.products?.filters,
  filtersLoading: state?.adSpot?.innerPagesInfo?.products?.filtersLoading,
  reportLoading: state?.adSpot?.innerPagesInfo?.products?.reportLoading,
  overviewInfo: state?.adSpot?.innerPagesInfo?.overview,
  innerPagesInfo: state?.adSpot?.innerPagesInfo,
  shopifyStores: state?.shopifyStore?.results,
  autoDS: state?.autoDS,
});

const mapDispatchToProps = (dispatch) => ({
  reset: () => dispatch(Creators.resetState()),
  getProductsByStoreIdAdSpot: (data) => dispatch(Creators.getProductsByStoreIdAdSpotRequest(data)),
  getProductsCountByStoreIdAdSpot: (data) => dispatch(Creators.getProductsCountByStoreIdAdSpotRequest(data)),
  getProductsFiltersByStoreIdAdSpot: (data) => dispatch(Creators.getProductsFiltersByStoreIdAdSpotRequest(data)),
  getProductsReportAdSpot: (data) => dispatch(Creators.getProductsReportAdSpotRequest(data)),
  resetFilters: () => dispatch(Creators.resetProductsFiltersAdSpot()),
  getOverviewMainInfo: (data) => dispatch(Creators.getOverviewMainInfoRequest(data)),
  getOverviewCardInfo: (data) => dispatch(Creators.getOverviewCardInfoRequest(data)),
  setInnerPagesDateFilters: (data) => dispatch(Creators.setInnerPagesDateFilters(data)),
  setInnerPagesDateCheckedList: (data) => dispatch(Creators.setInnerPagesDateCheckedList(data)),
  changeVisibleModalShopify: (data) => dispatch(ShopifyCreators.changeVisibleModalShopify(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdItemProductsGeneral);
