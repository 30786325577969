import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getAdLibraryFiltersRequest: null,
  getAdLibraryFiltersSuccess: ['payload'],
  getAdLibraryFiltersFailure: ['payload'],

  getAdLibraryPresetsRequest: null,
  getAdLibraryPresetsSuccess: ['payload'],
  getAdLibraryPresetsFailure: ['payload'],

  createAdLibraryPresetRequest: ['payload'],
  createAdLibraryPresetSuccess: ['payload'],
  createAdLibraryPresetFailure: ['payload'],

  deleteAdLibraryPresetRequest: ['payload'],
  deleteAdLibraryPresetSuccess: ['payload'],
  deleteAdLibraryPresetFailure: ['payload'],

  updateAdLibraryPresetRequest: ['payload'],
  updateAdLibraryPresetSuccess: ['payload'],
  updateAdLibraryPresetFailure: ['payload'],

  getAdLibraryAdsRequest: ['payload'],
  getAdLibraryAdsSuccess: ['payload'],
  getAdLibraryAdsFailure: ['payload'],
});

export const AdLibraryTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  loading: false,
  adsLoading: false,
  filtersLoading: false,
  errors: null,
  filters: {},
  presets: {},
  creditsLeft: null,
  ads: {},
});

const getAdLibraryFiltersRequest = (state) =>
  state.merge({ filtersLoading: true });

const getAdLibraryFiltersSuccess = (state, { payload: data }) =>
  state.merge({ filtersLoading: false, filters: data });

const getAdLibraryFiltersFailure = (state, { payload: { errors } }) =>
  state.merge({ filtersLoading: false, errors })

const getAdLibraryPresetsRequest = (state) =>
  state.merge({ loading: true });

const getAdLibraryPresetsSuccess = (state, { payload: data }) => {
  const {credits_left, ...presets} = data
  return  state.merge({ loading: false, creditsLeft: credits_left,
    presets: {...presets}});
}

const getAdLibraryPresetsFailure = (state, { payload: { errors } }) =>
  state.merge({ loading: false, errors });

const createAdLibraryPresetRequest = (state) =>
  state.merge({ loading: true });

const createAdLibraryPresetSuccess = (state, { payload: data }) => {
  const newUserPresets = [data, ...state.presets?.users]
  return state.merge({loading: false, presets: {...state.presets, users: newUserPresets} });
}
const createAdLibraryPresetFailure = (state, { payload: { errors } }) =>
  state.merge({ loading: false, errors, });

const deleteAdLibraryPresetRequest = (state) =>
  state.merge({ loading: true });

const deleteAdLibraryPresetSuccess = (state, { payload: id }) => {
  const newUserPresets = [...state.presets?.users.filter(el => el.id !== id)]
  return state.merge({loading: false, presets: {...state.presets, users: newUserPresets}});
}
const deleteAdLibraryPresetFailure = (state, { payload: { errors } }) =>
  state.merge({ loading: false, errors });

const updateAdLibraryPresetRequest = (state) =>
  state.merge({ loading: true });

const updateAdLibraryPresetSuccess = (state, { payload: data }) => {
  const newUserPresets = [...state.presets?.users.map(el => {
    if (el.id === data.id) return data
    else return el
  })]
  return state.merge({loading: false, presets: {...state.presets, users: newUserPresets} });
}

const updateAdLibraryPresetFailure = (state, { payload: { errors } }) =>
  state.merge({ loading: false, errors });

const getAdLibraryAdsRequest = (state) =>
  state.merge({ adsLoading: true });

const getAdLibraryAdsSuccess = (state, { payload: data }) =>{
  const {attempts_left, productsAdd, ...rest} = data;
  return productsAdd && rest?.results?.length ?
    state.merge({ adsLoading: false, ads: { ...rest, results: [...state?.ads?.results, ...rest?.results] }, attemptsLeft: attempts_left })
    :
    state.merge({ adsLoading: false, ads: { ...rest }, attemptsLeft: attempts_left });
}

const getAdLibraryAdsFailure = (state, { payload: { errors } }) =>
  state.merge({ adsLoading: false, errors });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_AD_LIBRARY_FILTERS_REQUEST]: getAdLibraryFiltersRequest,
  [Types.GET_AD_LIBRARY_FILTERS_SUCCESS]: getAdLibraryFiltersSuccess,
  [Types.GET_AD_LIBRARY_FILTERS_FAILURE]: getAdLibraryFiltersFailure,

  [Types.GET_AD_LIBRARY_PRESETS_REQUEST]: getAdLibraryPresetsRequest,
  [Types.GET_AD_LIBRARY_PRESETS_SUCCESS]: getAdLibraryPresetsSuccess,
  [Types.GET_AD_LIBRARY_PRESETS_FAILURE]: getAdLibraryPresetsFailure,

  [Types.CREATE_AD_LIBRARY_PRESET_REQUEST]: createAdLibraryPresetRequest,
  [Types.CREATE_AD_LIBRARY_PRESET_SUCCESS]: createAdLibraryPresetSuccess,
  [Types.CREATE_AD_LIBRARY_PRESET_FAILURE]: createAdLibraryPresetFailure,

  [Types.DELETE_AD_LIBRARY_PRESET_REQUEST]: deleteAdLibraryPresetRequest,
  [Types.DELETE_AD_LIBRARY_PRESET_SUCCESS]: deleteAdLibraryPresetSuccess,
  [Types.DELETE_AD_LIBRARY_PRESET_FAILURE]: deleteAdLibraryPresetFailure,

  [Types.UPDATE_AD_LIBRARY_PRESET_REQUEST]: updateAdLibraryPresetRequest,
  [Types.UPDATE_AD_LIBRARY_PRESET_SUCCESS]: updateAdLibraryPresetSuccess,
  [Types.UPDATE_AD_LIBRARY_PRESET_FAILURE]: updateAdLibraryPresetFailure,

  [Types.GET_AD_LIBRARY_ADS_REQUEST]: getAdLibraryAdsRequest,
  [Types.GET_AD_LIBRARY_ADS_SUCCESS]: getAdLibraryAdsSuccess,
  [Types.GET_AD_LIBRARY_ADS_FAILURE]: getAdLibraryAdsFailure,
});
