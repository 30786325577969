import React, {useEffect} from 'react';
import cls from 'classname';
import acc from 'accounting';
import Icon from '../../../Icon';
import utils from '../../../Utils/utils';
import { columnTitles } from '../../../Components/titleSortFilters'
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import {Dropdown} from "antd";
import IconWithText from "../../../Components/Text";
import Creators from "../../AutoDS/reducer";
import {useDispatch, useSelector} from "react-redux";

dayjs.extend(utc)

export const getColumns = (
  {
    sortOrder,
    handleToggleTracking,
    t,
    report,
    setVisible,
    setModal,
    setRecordToCalc,
    setRecordToPriceHistory,
    deleteProduct,
    handleExpandRow,
    handleOpenModalShopifyConnect,
    handleOpenModalAutoDSConnect,
    isTrial,
    isMobile=false,
    visibleDropdown,
    setVisibleDropdown,
  }) => {

  const DropdownRender = ({record, handleOpenModalShopifyConnect, handleOpenModalAutoDSConnect, t, visibleDropdown, setVisibleDropdown}) => {

    const dispatch = useDispatch();
    const autoStores = useSelector(store => store?.autoDS?.stores) || [];

    const handleInnerVisibleChange = (value) => {
      setVisibleDropdown({[record?.id]: value});
    };

    useEffect(() => {
      if (visibleDropdown?.[record?.id] && !autoStores?.length) {
        dispatch(Creators.getAutoStoreRequest());
      }
    }, [visibleDropdown]);

    return (
      <Dropdown
        open={visibleDropdown?.[record?.id]}
        onOpenChange={handleInnerVisibleChange}
        dropdownRender={() => (
          <ul className={'filter-preset-action-dropdown database-action-dropdown'} style={{width: 'unset'}}>
            <li className="action-dropdown_item no-hover"
                onClick={() => {
                  setVisibleDropdown(null);
                  handleOpenModalShopifyConnect(`${record?.store?.internal_shop_id}_${record?.id}`)
                }}>
              <IconWithText
                size={24}
                icon={() => <Icon type={'shopify_btn_icon'} role={'icon'} isLogo={true} /> }
                text={t('Import Product to Shopify')}
              />
            </li>
            <li className="action-dropdown_item"
                onClick={() => {
                  setVisibleDropdown(null);
                  handleOpenModalAutoDSConnect({
                    'source': 'shopify',
                    'product_id': `${record?.store?.internal_shop_id}_${record?.id}`
                  })
                }}>
              <IconWithText
                icon={() => <Icon type={'auto_ds'} role={'icon'} /> }
                text={t('Import Product to AutoDS')}
              />
            </li>
          </ul>
        )}
        destroyPopupOnHide={true}
        trigger={['click']}
        placement="bottomRight"
        getPopupContainer={() => document.getElementById('product-database-table-body')}
        overlayClassName={'filter-preset-cell-menu-wrapper'}
      >
      <span className={'product-actions-options'}>
        <Icon type="three_dots" role="button" width={24} height={24}/>
      </span>
      </Dropdown>
    )
  }

  if(report === 'sales'){
    return (
      [
        /*Table.EXPAND_COLUMN,*/
        {
          title: () => t('Product'),
          dataIndex: 'title',
          key: 'name',
          width: 400,
          fixed: isMobile ? false : 'left',
          sorter: false,
          render: (text, record) => (
            <div className={'product-database-table-cell'}
                 onClick={(e) => {
                   e.persist();
                   if (!e.target.classList.contains('product-url')) window.open(`https://${record?.store?.original_domain}/products/${record?.handle}`,
                     '_blank')
                 }}
            >
                <div className={'product-database-table-image'}
                >
                  <img src={utils.changeImageSize(record?.main_image, 124)}
                       alt=""
                       onError={utils.addDefaultSrc}
                       width="62" height="62"
                  />
                </div>
                <div className="product-image-quantity">
                  <Icon type={'database_product_images'}
                        role={'icon'}
                        color={'#707ba0'}
                        width={16}
                        height={16}
                  />
                  <span>{record.images}</span>
                </div>
              <div className={'product-info-wrapper'} style={{flex: 1}}>
                <p className={'product-name'}>{record.title}</p>
                <p>
                    <span className={'product-url'} style={{color: '#225AEA', cursor: 'pointer' }}
                          onClick={(e) => {
                            setVisible(true);
                            setModal('breakdown');
                            setRecordToCalc(record);
                          }}>
                      {`$${record?.usd_price ? record?.usd_price : 0}`}

                      <span style={{paddingLeft: 4, color: '#707ba0'}}>
                        {record?.store?.currency !== 'USD' && `(${record?.original_price ? record?.original_price : 0} ${record?.store?.currency})`}
                      </span>
                    </span>
                  <span className={'product-variants'}>{record.variants} {t(record.variants === 1 ? 'Variant' : 'Variants')}</span>
                </p>
              </div>
            </div>
          ),
        },
        {
          title: '',
          fixed: isMobile ? false : 'left',
          dataIndex: 'quick_search',
          key: 'suppliers',
          width: 136,
          sorter: false,
          render: (text, record) => (
            <div className={'product-database-table-cell'}>
              <div className="product-suppliers">
                {record.quick_search.map(el => {
                  if (el?.platform === 'aliexpress') return (
                    <a
                      key={el.platform}
                      href={el.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link with-img"
                    >
                      <Icon role="button"
                            type={`search_${el.platform}`}
                            className={`${el.platform}-icon`}
                            width={el.platform === 'alibaba' ? 24 : 16}
                            height={el.platform === 'alibaba' ? 24 : 16}
                            titleText={() => <>Search on <span style={{ textTransform: 'capitalize' }}>{el.platform}</span></>}
                            tooltipProps={{
                              placement: 'bottom',
                              trigger: 'hover',
                              destroyTooltipOnHide: true,
                              overlayClassName: cls('details-tooltip', `${el.platform}-tooltip`),
                              getPopupContainer: () => document.getElementById('product-database-table-body'),
                            }}
                      />
                    </a>
                  )
                })}
                <div onClick={() => {
                  handleOpenModalAutoDSConnect({
                    'source': 'shopify',
                    'product_id': `${record?.store?.id}_${record?.id}`
                  })
                }}>
                  <Icon role="button"
                        type={'auto_ds'}
                        isTooltip={true}
                        width={21}
                        height={20}
                        titleText={() => <>{t('Import Product to AutoDS')}</>}
                        tooltipProps={{
                          placement: 'bottom',
                          trigger: 'hover',
                          destroyTooltipOnHide: true,
                          overlayClassName: cls('details-tooltip', `aliexpress-tooltip`),
                          getPopupContainer: () => document.getElementById('product-database-table-body'),
                        }}
                  />
                </div>
              </div>
            </div>
          ),
        },
        {
          title: '',
          key: 'price_history',
          width: 64,
          sorter: false,
          render: (text, record) => (
            <div className={'product-database-table-cell product-database-table-cell_clickable'}
                 onClick={e => {
                   setVisible(true);
                   setModal('priceHistory');
                   setRecordToPriceHistory(record);
                 }}
            >
              <div className="product-price-history">
                <Icon role={'icon'} type={'chart_icon'} />
              </div>
            </div>
          ),
        },
        {
          title: () => {
            let result = t('Sales')
            return columnTitles({
              sortOrder,
              title: result,
              key: 'sales',
            })
          },
          dataIndex: 'sales',
          key: 'sales',
          width: 150,
          sorter: true,
          render: (text, record) => (
            <div className={'product-database-table-cell product-database-table-cell_clickable'}
                 onClick={()=> handleExpandRow(true, record)}
            >
              <div className="product-monthly-sales">
                {acc.format(record.sales, 0, ',', '.')}
              </div>
            </div>
          ),
        },
        {
          title: () => {
            let result = t('Revenue')
            return columnTitles({
              sortOrder,
              title: result,
              key: 'revenue',
            })
          },
          dataIndex: 'revenue',
          key: 'revenue',
          width: 150,
          sorter: true,
          render: (text, record) => (
            <div className={'product-database-table-cell product-database-table-cell_clickable'}
                 onClick={()=> handleExpandRow(true, record)}
            >
              <div className="product-monthly-revenue">
                ${acc.format(record.revenue, 2, ',', '.')}
              </div>
            </div>
          ),
        },
        {
          title: () => {
            let result = t('Returns')
            return columnTitles({
              sortOrder,
              title: result,
              key: 'returns',
            })
          },
          dataIndex: 'returns',
          key: 'returns',
          width: 170,
          sorter: true,
          render: (text, record) => (
            <div className={'product-database-table-cell'}>
              <div className="product-monthly-revenue">
                {acc.format(record.returns, 0, ',', '.')}
              </div>
            </div>
          ),
        },
        {
          title: () => {
            let result = t('Returns Loss')
            return columnTitles({
              sortOrder,
              title: result,
              key: 'returns_revenue',
            })
          },
          dataIndex: 'returns_revenue',
          key: 'returns_revenue',
          width: 170,
          sorter: true,
          render: (text, record) => (
            <div className={'product-database-table-cell'}>
              <div className="product-monthly-revenue">
                {
                  record.returns_revenue > 0 ?
                    `$${acc.format(record.returns_revenue, 2, ',', '.')}`
                    :
                    record.returns_revenue === 0 ?
                      `$${acc.format(Math.abs(record.returns_revenue), 2, ',', '.')}`
                      :
                      `-$${acc.format(Math.abs(record.returns_revenue), 2, ',', '.')}`
                }
              </div>
            </div>
          ),
        },
        {
          title: () => {
            let result = t('Product creation date')
            return columnTitles({
              sortOrder,
              title: result,
              key: 'created_at',
            })
          },
          dataIndex: 'created_at',
          key: 'created_at',
          width: 216,
          sorter: true,
          render: (text, record) => (
            <div className={'product-database-table-cell product-database-table-cell_clickable'}
                 onClick={()=> handleExpandRow(true, record)}
            >
              <div className="product-creation-date">
                {
                  record.created_at ?  dayjs(record.created_at, 'YYYY-MM-DDTHH:mm:ssZ').utc().format('MMM DD, YYYY') : 'No Data'
                }
              </div>
            </div>
          ),
        },
        {
          title: '',
          key: 'actions',
          width: isMobile ? 112 : 162,
          sorter: false,
          fixed: 'right',
          render: (text, record) => (
            <div className={'product-database-table-cell cell-actions cell-actions_tracker'}>
              <div className="product-actions" style={{height: 71}}>
                <DropdownRender record={record}
                                handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                                handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                                t={t}
                                visibleDropdown={visibleDropdown}
                                setVisibleDropdown={setVisibleDropdown}
                />
                <span className={cls('product-actions-options-btn', {
                  'product-actions-options-btn--tracked': record?.is_tracked
                })}
                      onClick={() => {
                        if (!record['is_tracked']) {
                          handleToggleTracking({
                            "custom_domain": record?.store?.custom_domain,
                            "handle": record?.handle,
                            "product_id": record?.id,
                            "title": record?.title,
                            "images": record?.images,
                            "main_image": record?.main_image,
                            "variants": record?.variants,
                            "created_at": record?.created_at,
                            "original_price": record?.original_price,
                            "original_price_min": record?.original_price_min,
                            "original_price_max": record?.original_price_max,
                            "shopify_shop_id": record?.store?.shopify_shop_id,
                            "internal_shop_id": record?.store?.internal_shop_id,
                            "original_domain": record?.store?.original_domain
                          })
                        } else {
                          if (isTrial) {
                            setModal('canNotRemoveTracking');
                            setVisible(true);
                          } else {
                            deleteProduct({...record})
                          }
                        }
                      }}
                >
                  {
                    isMobile ?
                      null
                      :
                    record?.is_tracked ?
                      'Stop'
                      :
                      'Track'
                  }
                  <Icon type="gps" role="icon" width={24} height={24} outline={!(record?.is_tracked)}/>
                </span>
              </div>
            </div>
          ),
        },
      ]
    )
  } else {
    let baseColumn = [
      {
        title: () => t('setting_billing.Date'),
        dataIndex: 'date',
        key: 'date',
        width: 170,
        sorter: false,
        render: (text, record) => (
          <div className={'product-database-table-cell'}>
            <div className="product-creation-date">
              {
                record.date ?  dayjs(record.date).format('MMM DD, YYYY') : 'No Data'
              }
            </div>
          </div>
        ),
      },
      {
        title: () => {
          let result = t('Sales')
          return columnTitles({
            sortOrder,
            title: result,
            key: 'monthly_sales',
          })
        },
        dataIndex: 'sales',
        key: 'monthly_sales',
        width: 170,
        sorter: true,
        render: (text, record) => (
          <div className={'product-database-table-cell'}>
            <div className="product-monthly-sales">
              {acc.format(record.sales, 0, ',', '.')}
            </div>
          </div>
        ),
      },
      {
        title: () => {
          let result = t('Revenue')
          return columnTitles({
            sortOrder,
            title: result,
            key: 'revenue',
          })
        },
        dataIndex: 'revenue',
        key: 'revenue',
        width: 170,
        sorter: true,
        render: (text, record) => (
          <div className={'product-database-table-cell'}>
            <div className="product-monthly-revenue">
              ${acc.format(record.revenue, 2, ',', '.')}
            </div>
          </div>
        ),
      },
    ]
    if(report === 'product'){
      return [
        ...baseColumn,
        {
          title: () => {
            let result = t('Returns')
            return columnTitles({
              sortOrder,
              title: result,
              key: 'returns_sales',
            })
          },
          dataIndex: 'returns_sales',
          key: 'returns_sales',
          width: 170,
          sorter: true,
          render: (text, record) => (
            <div className={'product-database-table-cell'}>
              <div className="product-monthly-revenue">
                {acc.format(record.returns_sales, 0, ',', '.')}
              </div>
            </div>
          ),
        },
        {
          title: () => {
            let result = t('Returns Loss')
            return columnTitles({
              sortOrder,
              title: result,
              key: 'returns_revenue',
            })
          },
          dataIndex: 'returns_revenue',
          key: 'returns_revenue',
          width: 170,
          sorter: true,
          render: (text, record) => (
            <div className={'product-database-table-cell'}>
              <div className="product-monthly-revenue">
                {
                  record.returns_revenue > 0 ?
                    `$${acc.format(record.returns_revenue, 2, ',', '.')}`
                    :
                    record.returns_revenue === 0 ?
                      `$${acc.format(Math.abs(record.returns_revenue), 2, ',', '.')}`
                      :
                      `-$${acc.format(Math.abs(record.returns_revenue), 2, ',', '.')}`
                }
              </div>
            </div>
          ),
        },
      ]
    } else {
      return [
        ...baseColumn,
        {
          title: () => {
            let result = isMobile ? 'Unique Sold' : t('Unique Products Sold')
            return columnTitles({
              sortOrder,
              title: result,
              key: 'unique',
            })
          },
          dataIndex: 'unique',
          key: 'unique',
          width: 170,
          sorter: true,
          render: (text, record) => (
            <div className={'product-database-table-cell'}>
              <div className="product-monthly-revenue">
                {acc.format(record.unique, 0, ',', '.')}
              </div>
            </div>
          ),
        },
        {
          title: () => {
            let result = t('Returns')
            return columnTitles({
              sortOrder,
              title: result,
              key: 'returns_sales',
            })
          },
          dataIndex: 'returns_sales',
          key: 'returns_sales',
          width: 170,
          sorter: true,
          render: (text, record) => (
            <div className={'product-database-table-cell'}>
              <div className="product-monthly-revenue">
                {acc.format(record.returns_sales, 0, ',', '.')}
              </div>
            </div>
          ),
        },
        {
          title: () => {
            let result = t('Returns Loss')
            return columnTitles({
              sortOrder,
              title: result,
              key: 'returns_revenue',
            })
          },
          dataIndex: 'returns_revenue',
          key: 'returns_revenue',
          width: 170,
          sorter: true,
          render: (text, record) => (
            <div className={'product-database-table-cell'}>
              <div className="product-monthly-revenue">
                {
                  record.returns_revenue > 0 ?
                    `$${acc.format(record.returns_revenue, 2, ',', '.')}`
                    :
                    record.returns_revenue === 0 ?
                      `$${acc.format(Math.abs(record.returns_revenue), 2, ',', '.')}`
                      :
                      `-$${acc.format(Math.abs(record.returns_revenue), 2, ',', '.')}`
                }
              </div>
            </div>
          ),
        },
        {
          title: () => {
            let result = t('Unique Returned')
            return columnTitles({
              sortOrder,
              title: result,
              key: 'returns_unique',
            })
          },
          dataIndex: 'returns_unique',
          key: 'returns_unique',
          width: 170,
          sorter: true,
          render: (text, record) => (
            <div className={'product-database-table-cell'}>
              <div className="product-monthly-revenue">
                {acc.format(record.returns_unique, 0, ',', '.')}
              </div>
            </div>
          ),
        },
      ]
    }
  }
};
