import React from 'react';
import { useSelector } from 'react-redux';

const AdSpotFilterDomain = () => {
  const theme = useSelector((state) => state?.nav?.theme);

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={theme === 'light' ? '#A1AAC8' : '#ffffff5c'}
        d="M2.49935 8.95801H17.4993M3.95768 16.0413H16.041C16.9615 16.0413 17.7077 15.2951 17.7077 14.3747V5.62467C17.7077 4.7042 16.9615 3.95801 16.041 3.95801H3.95768C3.03721 3.95801 2.29102 4.7042 2.29102 5.62467V14.3747C2.29102 15.2951 3.03721 16.0413 3.95768 16.0413Z"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M4.79167 7.00033C5.09082 7.00033 5.33333 6.75781 5.33333 6.45866C5.33333 6.1595 5.09082 5.91699 4.79167 5.91699C4.49251 5.91699 4.25 6.1595 4.25 6.45866C4.25 6.75781 4.49251 7.00033 4.79167 7.00033ZM6.875 7.00033C7.17415 7.00033 7.41667 6.75781 7.41667 6.45866C7.41667 6.1595 7.17415 5.91699 6.875 5.91699C6.57585 5.91699 6.33333 6.1595 6.33333 6.45866C6.33333 6.75781 6.57585 7.00033 6.875 7.00033ZM8.95833 7.00033C9.25749 7.00033 9.5 6.75781 9.5 6.45866C9.5 6.1595 9.25749 5.91699 8.95833 5.91699C8.65918 5.91699 8.41667 6.1595 8.41667 6.45866C8.41667 6.75781 8.65918 7.00033 8.95833 7.00033Z"
        fill={theme === 'light' ? '#A1AAC8' : '#ffffff5c'}
        stroke={theme === 'light' ? '#A1AAC8' : '#ffffff5c'}
        strokeWidth="0.25"
      />
    </svg>
  );
};

export default AdSpotFilterDomain;
