import React from 'react';
import { useSelector } from 'react-redux';

const ArrowNext = (
  {
    width = 20,
    height = 20,
    className = '',
  }) => {

  const theme = useSelector((state) => state.nav.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none" className={className}>
      <path d="M8.33398 13.3327L11.0781 10.5886C11.4035 10.2632 11.4035 9.73553 11.0781 9.41009L8.33398 6.66602"
            stroke={theme === 'light' ? "#707BA0" : "rgba(255, 255, 255, 0.361)"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowNext;
