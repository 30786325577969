import React from 'react';

const UserTrackingsSkeleton = (
  {
    isMobile
  }
) => {
  return (
    <div className={'sales-tracker-data-wrapper'}>
      <div className={'products-database-table-wrapper sales-tracker-stores-table-wrapper'}>
        <div className="product-database-table-body user-trackings-table">
          <div className="skeleton-table-header">
            {
              [
                {
                  width: '20%',
                  contentWidth: 35
                },
                {
                  width: '7%',
                  contentWidth: 48
                },
                {
                  width: '15%',
                  contentWidth: 75
                },
                {
                  width: '15%',
                  contentWidth: 102
                },
                {
                  width: '14%',
                  contentWidth: 91
                },
                {
                  width: '14%',
                  contentWidth: 91
                },
                {
                  width: '14%',
                  contentWidth: 63
                },
              ].map((el, i) => (
                <div key={i}
                     style={{ width: el?.width, display: 'flex', alignItems: 'center', justifyContent: i === 0 ? 'center' : 'flex-start', gap: 4 }}
                >
                  <span className={'link skeleton'}
                        style={{ width: el?.contentWidth, height: 20 }}
                  />
                  <span className={'link skeleton'}
                        style={{ width: 16, height: 16 }}
                  />
                </div>
              ))
            }
          </div>
          {
            [1,2,3,4,5,6,7].map((el, i) => (
              <div key={i + 10}
                   className={'skeleton-table-row'}
              >
                <div style={{ width: '3%' }}>
                  <span className={'link skeleton'}
                        style={{ width: 20, height: 20 }}
                  />
                </div>
                <div style={{ width: '17%', gap: 8 }}>
                  <span className={'link skeleton'}
                        style={{ width: 40, height: 40 }}
                  />
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    <span className={'link skeleton'}
                          style={{ width: 71, height: 20 }}
                    />
                    <span className={'link skeleton'}
                          style={{ width: 125, height: 20 }}
                    />
                  </div>
                </div>
                <div style={{ width: '7%' }}>
                  <span className={'link skeleton'}
                        style={{ width: 16, height: 16, borderRadius: '50%' }}
                  />
                </div>
                <div style={{ width: '15%', flexDirection: 'column', gap: 4, alignItems: 'flex-start' }}>
                  <span className={'link skeleton'}
                        style={{ width: 88, height: 20 }}
                  />
                  <span className={'link skeleton'}
                        style={{ width: 47, height: 20 }}
                  />
                </div>
                <div style={{ width: '15%', flexDirection: 'column', gap: 4, alignItems: 'flex-start' }}>
                  <span className={'link skeleton'}
                        style={{ width: 88, height: 20 }}
                  />
                  <span className={'link skeleton'}
                        style={{ width: 47, height: 20 }}
                  />
                </div>
                <div style={{ width: '14%', flexDirection: 'column', gap: 4, alignItems: 'flex-start' }}>
                  <span className={'link skeleton'}
                        style={{ width: 88, height: 20 }}
                  />
                  <span className={'link skeleton'}
                        style={{ width: 47, height: 20 }}
                  />
                </div>
                <div style={{ width: '14%', flexDirection: 'column', gap: 4, alignItems: 'flex-start' }}>
                  <span className={'link skeleton'}
                        style={{ width: 88, height: 20 }}
                  />
                  <span className={'link skeleton'}
                        style={{ width: 47, height: 20 }}
                  />
                </div>
                <div style={{ width: '10%', flexDirection: 'column', gap: 4, alignItems: 'flex-start' }}>
                  <span className={'link skeleton'}
                        style={{ width: 88, height: 20 }}
                  />
                  <span className={'link skeleton'}
                        style={{ width: 47, height: 20 }}
                  />
                </div>
                <div style={{ width: '4%' }}>
                  <span className="link skeleton"
                        style={{ width: 20, height: 5 }}
                  />
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default UserTrackingsSkeleton;
