import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactPlayer from "react-player";
import { Modal, Row, Divider } from 'antd';
import BreadcrumbsActions from '../Breadcrumbs/actions';
import DashboardWelcome from '../../Components/DashboardWelcome';
import Creators from './reducer';
import ShopifyCreators from '../ShopifyStore/reducer';
import AutoDSCreators from '../AutoDS/reducer';
import { changeGreetingMessage } from '../../Utils/utils';
import UniversityPageCourseWrapper from "../UniversityPage/components/UniversityPageCourseWrapper";
import cls from 'classname';
import DashboardPageBlock from "./components/DashboardPageBlock";
import Icon from "../../Icon";
import ButtonComponent from "../../Components/Button";
import './styles.less';
import ConfirmEmail from './components/ConfirmEmail'

export const DashboardPage = (
  {
    category,
    plan,
    fetching,
    fetchingProducts,
    error,
    dashboardInfo,
    userInfo,
    isMobile,
    theme,
    nav,
    breadcrumbs,
    clearBreadCrumbs,
    addBreadCrumb,
    isAdmin,
    changeVisibleModalShopify,
    getShopifyStore,
    getAutoStore,
    connectAutoStore,
    shopifyStore,
    ...props
  }) => {
  /* latest, highest, average */
  const { showConfirm } = nav;
  const { isVisibleModal } = shopifyStore;

  const [firsLoading, setFirsLoading] = useState(true);
  const [greetingMessage, setGreetingMessage] = useState('Welcome');
  const [video, setVideo] = useState('');
  const [visible, setVisible] = useState(false);

  const pageBlocks = !userInfo?.need_block_pages ?
    [
      {
        title: 'Ad Spot',
        text: 'Search our library with millions of Facebook ads',
        video: 'https://www.youtube.com/watch?v=OftbjjbL0tc',
        icon: 'ad_spot_dashboard',
        link: '/ad-spot',
        setVideo: setVideo,
        setVisible: setVisible,
        iconColor: 'orange',
        tag: 'new',
        disabled: true
      },
      {
        title: 'Product Database',
        text: 'Access millions of products with sales revenue data',
        video: 'https://www.youtube.com/watch?v=OftbjjbL0tc',
        icon: 'product_database_dashboard',
        link: '/product-database',
        setVideo: setVideo,
        setVisible: setVisible,
        iconColor: 'green'
      },
      {
        title: 'Sales Tracker',
        text: 'Track the sales revenue of stores and products',
        video: 'https://www.youtube.com/watch?v=ViFABtDOpDw',
        icon: 'sales_tracker_dashboard',
        link: '/sales-tracker/stores',
        setVideo: setVideo,
        setVisible: setVisible,
        iconColor: 'red'
      },
      {
        title: 'Competitor Research',
        text: 'Find all your competitors with just one click',
        video: 'https://www.youtube.com/watch?v=h-rOBAPwptI',
        icon: 'competitors_dashboard',
        link: '/competitor-research',
        setVideo: setVideo,
        setVisible: setVisible,
        iconColor: 'yellow'
      },
      {
        title: 'Portfolio',
        text: 'Receive winning products every week on Monday',
        video: 'https://www.youtube.com/watch?v=SaL0W9Lq1To',
        icon: 'portfolio_dashboard',
        link: '/portfolio',
        setVideo: setVideo,
        setVisible: setVisible,
        iconColor: 'purple'
      },
    ]
    :
    [
      {
        title: 'Portfolio',
        text: 'Receive winning products every week on Monday',
        video: 'https://www.youtube.com/watch?v=SaL0W9Lq1To',
        icon: 'portfolio_dashboard',
        link: '/portfolio',
        setVideo: setVideo,
        setVisible: setVisible,
        iconColor: 'purple'
      },
    ]

  const { t } = useTranslation();
  const navigate = useNavigate();

  const isDisabled = !Boolean(userInfo?.subscriptions?.[0]) && !isAdmin && userInfo?.onboarding_finished

  const skeleton = fetching || firsLoading;

  // BREADCRUMBS
  useEffect(() => {
    document.title = 'Dashboard - Dropship';
    if (window?.location?.search?.includes('connect_store')) {
      changeVisibleModalShopify({ isVisibleModal: 'connect', initialUrl: window?.location?.pathname, importProductId: null });
      navigate('/dashboard', { replace: true });
    }
    if (window?.location?.search?.includes('import_product')) {
      getShopifyStore();
      let link = window?.location?.search?.split('=')?.[1];
      changeVisibleModalShopify({ isVisibleModal: 'import', initialUrl: window?.location?.pathname, importProductId: link });
      navigate('/dashboard', { replace: true });
    }
    if (window?.location?.search.includes('code')) {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      connectAutoStore(code);
    }
    getAutoStore();
    clearBreadCrumbs();
    addBreadCrumb({ name: 'Dashboard', link: '/dashboard' });
    return () => {
      changeVisibleModalShopify({ isVisibleModal: null, initialUrl: null, importProductId: null });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    changeGreetingMessage(greetingMessage, setGreetingMessage);
    props.getDashboardInfo({});
    if (firsLoading) setTimeout(() => setFirsLoading(false), 300);
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (!Boolean(isVisibleModal) && !firsLoading) props.getDashboardInfo({});
  }, [isVisibleModal]);

  function handleNavigate(path, value) {
    navigate(path, { ...value });
  }

  function handleOpenModalShopify(step) {
    props.setNichesStep(step);
    props.openModalShopifyConnect(document?.location?.pathname + (document?.location?.search || ''));
  }

  return (
    <div className={'dashboard-page'}>
      <DashboardWelcome
        userInfo={userInfo}
        isDisabled={isDisabled}
        handleNavigate={handleNavigate}
        isMobile={isMobile}
        isDemo={userInfo?.need_block_pages}
        skeleton={skeleton}
        greetings={`${t(greetingMessage)}${userInfo?.first_name ? `, ${userInfo?.first_name}` : ''}!`}
        stInfo={dashboardInfo?.limits}
        showConfirm={showConfirm}
      />

      {
        showConfirm && !isMobile ?
          <ConfirmEmail />
          :
          null
      }

      <Divider type={'horizontal'} style={{ margin: '0 0' }} />

      {!userInfo?.need_block_pages ?
        <Row gutter={[{ xs: 0, sm: 0, md: 16 }, 0]}>
          <UniversityPageCourseWrapper skeleton={skeleton}
            isMobile={isMobile}
            info={dashboardInfo?.course}
            dashboard={true}
            isComingSoon={false}
          />
        </Row>
        :
        null
      }

      <div className="dashboard-page-blocks-wrapper">
        <h3 className="dashboard-page-blocks-title">
          {
            skeleton ?
              <span className='link skeleton' style={{ width: 145, height: 24 }} />
              :
              t('Browse our tools')
          }
        </h3>
        <h4 className="dashboard-page-blocks-subtitle">
          {
            skeleton ?
              <span className='link skeleton' style={{ width: 263, height: 20 }} />
              :
              t('A step-by-step guide to using our tools')
          }
        </h4>
        <div className="dashboard-page-blocks">
          {pageBlocks.map(el => (
            <DashboardPageBlock key={el.video + el?.icon} {...el} skeleton={skeleton} />
          ))}
        </div>
      </div>
      <div className='dashboard-page-shopify-wrapper'>
        <h3 className="dashboard-page-blocks-title">
          {
            skeleton ?
              <span className='link skeleton' style={{ width: 145, height: 24 }} />
              :
              t('Hot propositions')
          }
        </h3>
        <h4 className="dashboard-page-blocks-subtitle">
          {
            skeleton ?
              <span className='link skeleton' style={{ width: 263, height: 20 }} />
              :
              t('Get extra offers from Dropship and our partners')
          }
        </h4>
        <div className={cls('dashboard-page-shopify', {
          dark: theme === 'dark'
        })}>
          <div className="dashboard-page-shopify-icon">
            {
              skeleton ?
                <span className='link skeleton' style={{ width: 40, height: 40 }} />
                :
                <Icon type={'shopify_dashboard'} role={'icon'} />
            }
          </div>
          <div className="dashboard-page-block-text dashboard-page-shopify-text">
            <h4 className='dashboard-page-block-title dashboard-page-shopify-title'>
              {
                skeleton ?
                  <span className='link skeleton' style={{ width: 184, height: 24 }} />
                  :
                  'Shopify x Dropship.io'
              }
            </h4>
            <h5 className="dashboard-page-block-subtitle">
              {
                skeleton ?
                  <span className='link skeleton skeleton-shopify-subtitle' style={{ width: 370, height: 20 }} />
                  :
                  t('Generate a custom built AI Shopify store in just 30 seconds.')
              }
            </h5>
          </div>
          {
            skeleton ?
              <div className="dashboard-page-block-shopify-btn-wrapper">
                <span className="link skeleton skeleton-shopify-button" style={{ width: 139, height: 40 }} />
                <span className="link skeleton skeleton-shopify-button" style={{ width: 116, height: 40 }} />
              </div>
              :
              <div className="dashboard-page-block-shopify-btn-wrapper">
                {
                  dashboardInfo?.ai_stores_count > 0 ?
                    <ButtonComponent className='dashboard-page-block-button dashboard-page-shopify-button dashboard-page-shopify-button'
                      onClick={() => {
                        handleOpenModalShopify(0);
                      }}
                      text={null}
                    >
                      {
                        t('My Ai Stores')
                      }
                      <span className={'badge'}>
                        {
                          dashboardInfo?.ai_stores_count
                        }
                      </span>
                    </ButtonComponent>
                    :
                    null
                }
                <ButtonComponent className='dashboard-page-block-button-second'
                  onClick={() => {
                    handleOpenModalShopify(2);
                  }}
                  text={t('Create Store')}
                />
              </div>
          }
        </div>
      </div>
      <Modal
        className="change-modal custom-modal video-modal"
        getContainer={() => document.getElementById('global-wrap')}
        {...(isMobile ? { transitionName: '' } : null)}
        open={visible}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#225aea" opacity={1} />
        }
        footer={null}
        closable="true"
        onCancel={() => setVisible(false)}
        destroyOnClose
      >
        <ReactPlayer controls={true}
          width={'100%'}
          height={'100%'}
          playing={true}
          url={video}
        />
      </Modal>
    </div>
  );
};

DashboardPage.defaultProps = {
  dashboardInfo: {
    previous: null,
    next: null,
    results: [],
    timers: [],
    latest_drop: {},
    total_drops: 0,
    total_products: 0,
  },
};

DashboardPage.propTypes = {
  dashboardInfo: PropTypes.shape({
    previous: PropTypes.string,
    next: PropTypes.string,
    results: PropTypes.array,
    timers: PropTypes.array,
    latest_drop: PropTypes.object,
    total_drops: PropTypes.number,
    total_products: PropTypes.number,
  }),
  fetching: PropTypes.bool,
  fetchingProducts: PropTypes.bool,
  isMobile: PropTypes.bool,
  theme: PropTypes.string,
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  isAdmin: state.auth.isAdmin,
  fetching: state.dashboard.fetching,
  fetchingProducts: state.dashboard.fetchingProducts,
  error: state.dashboard.errors,
  dashboardInfo: state.dashboard.result,
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  breadcrumbs: state.breadcrumbs.totalBreadcrumbs,
  shopifyStore: state.shopifyStore,
  nav: state.nav,
});

const mapDispatchToProps = (dispatch) => ({
  getShopifyStore: () => dispatch(ShopifyCreators.getShopifyStoresRequest()),
  setNichesStep: (data) => dispatch(ShopifyCreators.setNichesStep(data)),
  openModalShopifyConnect: initialUrl => dispatch(ShopifyCreators.changeVisibleModalShopify({ isVisibleModal: 'connect', initialUrl })),
  getDashboardInfo: (params) =>
    dispatch(Creators.getDashboardInfoRequest(params)),
  getNewDashboardInfo: (params) =>
    dispatch(Creators.getNewDashboardInfoRequest(params)),
  getNextDashboardInfo: (params) =>
    dispatch(Creators.getNextDashboardInfoRequest(params)),
  addBreadCrumb: (params) => dispatch(BreadcrumbsActions.addBreadCrumb(params)),
  clearBreadCrumbs: () => dispatch(BreadcrumbsActions.clearBreadCrumbs()),
  changeVisibleModalShopify: (data) => dispatch(ShopifyCreators.changeVisibleModalShopify(data)),
  getAutoStore: () => dispatch(AutoDSCreators.getAutoStoreRequest()),
  connectAutoStore: (data) => dispatch(AutoDSCreators.connectAutoStoreRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
