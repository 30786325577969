import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import { Spin, Divider, notification } from 'antd';
import Modal from '../../Components/Modal';
import DiscountCard from '../../Components/DiscountCard';
import { openNotificationWithIcon } from '../../Components/Notification/index.js';
import { DefaultMsg } from '../../Components/Notification/notification-message.js';
import PlanCardUpcomingNew from '../../Components/PlanCardUpcomingNew';
import PaymentOptionCard from '../../Components/PaymentOptionCard';
import ChangeCreditCardForm from '../../Components/BillingSubPage/ChangeCreditCardForm';
import Icon from '../../Icon';
import actions from './actions';
import OnboardingActions from '../../Redux/OnboardingRedux';
import ErrorsActions from '../../Redux/ErrorsRedux';
import { footerModal } from './modal-footer.js';
import utils from '../../Utils/utils';
import dayjs from 'dayjs';
import './styles.less';

const textButtonModal = {
  select: {
    ok: ['Continue', 'Slide To Subscribe'],
    cancel: ['Continue Browsing', 'Back'],
  },
  switch: {
    ok: ['Continue', 'Next Step'],
    cancel: ['Cancel', 'Back'],
  },
  delete: {
    ok: ['Cancel downgrade', 'Cancel downgrade'],
    cancel: ['Back to Settings', 'Back to Settings'],
  },
  undoCancel: {
    ok: ['Cancel', 'Cancel'],
    cancel: ['Back', 'Back'],
  },
  cancel: {
    ok: ['Cancel', 'Cancel'],
    cancel: ['Back', 'Back'],
  },
  'add-card': false
};

export const ModalManageSubscriptions = (
  {
    category,
    plan,
    onBoarding,
    userInfo,
    isMobile,
    theme,
    isShowCartModal,
    userFetching,
    visible,
    setVisible,
    modalType,
    setModalType,
    getCountries,
    setChargebeeCardFetching,
    defaultUserCountry,
    withoutSelectPlan,
    ...props
  }) => {
  const [confirm, setConfirm] = useState(false);
  const [upgradeConfirm, setUpgradeConfirm] = useState(false);

  /*select, switch, delete, undoCancel*/
  const [modalTitle, setModalTitle] = useState('');

  /*upgrade or downgrade*/
  const [switchType, setSwitchType] = useState(null);

  /*now or scheduled*/
  const [paymentType, setPaymentType] = useState('now');

  const trialUsed = useSelector(store => store.auth.userInfo.trial_used);
  const paymentId = useSelector(store => store.auth.userInfo?.subscriptions?.[0]?.payment_status?.id);
  const { t } = useTranslation();

  const {
    saveSubscriptionFetching,
    updateSubscriptionFetching,
    deleteSubscriptionFetching,
    undoDeleteSubscriptionFetching,
    saveSubscriptionResult,
    undoCancelSubResult,
    undoCancelSubFetching,
    updateSubscriptionResult,
    deleteSubscriptionResult,
    undoDeleteSubscriptionResult,
    selectedPlan,
    switchPlan,
    countries,
    isYearlySubscription,
  } = onBoarding;

  const plansList = plan.plans || [];
  const priceType = isYearlySubscription ? 'yearly' : 'monthly'
  const isCurrentSubscription = !!userInfo?.subscriptions?.length
  const currentSubscription = userInfo?.subscriptions?.[0];

  useEffect(() => {
    if (!category?.categories) props.getCategory();
    if (!plan?.plans) props.getPlan();
    props.setSwitchPlan({ 2: null });
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (visible && withoutSelectPlan) {
      if (modalType === 'switch' && Object.values(onBoarding?.switchPlan).length) {
        const switchCategoryId = +Object.keys(onBoarding?.switchPlan)[0];
        const subscription = userInfo.subscriptions.filter((subscription) => subscription.category.id === switchCategoryId)[0];
        if (!confirm) setConfirm(true);
        if (!subscription?.plan?.price) {
          if (!subscription && !upgradeConfirm) setUpgradeConfirm(true)
          setSwitchType('upgrade')
          setPaymentType('now')
        }
      }
      if ((modalType === 'delete' || modalType === 'undoCancel') && confirm) setConfirm(false)
    }
    setPaymentType('now')
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [visible, modalType])

  useEffect(() => {
    changeModalTitle();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [modalType, confirm, upgradeConfirm, switchType]);

  useEffect(() => {
    if (
      saveSubscriptionResult ||
      updateSubscriptionResult ||
      deleteSubscriptionResult ||
      undoCancelSubResult
    ) {
      let notificationInfo = null;

      if (modalType === 'select') {
        let subscribedPlans = saveSubscriptionResult
          .map((subscription) => subscription?.category?.title)
          .join(', ');
        notificationInfo = {
          message: t('You are now subscribed to _plan_!', {
            plan: subscribedPlans,
          }),
        };
      } else if (modalType === 'switch' || modalType === 'add-card') {
        if (switchType === 'upgrade' && paymentType === 'now') {
          notificationInfo = {
            message: t('Your plan has been successfully upgraded'),
          };
        } else {
          notificationInfo = {
            message: t('Your plan change has been successfully scheduled'),
            type: 'addSchedule',
          };
        }
      } else if (modalType === 'undoCancel') {
        notificationInfo = {
          message: t(' Your plan change has been successfully canceled'),
          type: 'deleteSchedule',
        }
              {/*<span*/}
              {/*  className={'mark-text clickable'}*/}
              {/*  onClick={() => {*/}
              {/*    notification.destroy();*/}
              {/*    props.cancelSub(currentSubscription?.id);*/}
              {/*    props.restoreCancel(true);*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {t('Undo Action')}*/}
              {/*</span>*/}
              

        
      } else if (modalType === 'delete') {
        let deletedSubscriptionId = deleteSubscriptionResult.subscription.id;
        let oldSchedule = deleteSubscriptionResult.old_schedule;
        const key = `open${Date.now()}`;
        notificationInfo = {
          message:t('Your plan change has been successfully canceled'),
          type: 'deleteSchedule',
          key: key,
        };
      }else{
        notificationInfo = {
          message:t('Your plan change has been successfully scheduled'),
          type: 'editLimits',
          key: `open${Date.now()}`,
        };
      }
      notification.destroy();
      openNotificationWithIcon({
        key: `open${Date.now()}`,
        style: { width: '400px' },
        className: 'notification notification_delete',
        message: (
          <DefaultMsg
            text={notificationInfo?.message || ''}
            icon={'payment_added'}
          />
        ),
      });
      props.getSubscriptionsList('active');
      setVisible(false);
      if (!withoutSelectPlan) setConfirm(false);
      setSwitchType(null);
      setPaymentType(null);
      setUpgradeConfirm(false);
      props.changeSubscriptionReset();
    }

    if (undoDeleteSubscriptionResult) {
      notification.destroy();
      openNotificationWithIcon({
        key: `open${Date.now()}`,
        style: { width: '400px' },
        className: 'notification notification_delete',
        message: (
          <DefaultMsg
            text={'Schedule restored!'}
            icon={'payment_added'}
          />
        ),
      });
      props.changeSubscriptionReset();
      props.getSubscriptionsList('active');
    }

    if (onBoarding?.chargebeeCardFetching) setChargebeeCardFetching(false)
    clearDiscount();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [!!saveSubscriptionResult, !!updateSubscriptionResult, !!deleteSubscriptionResult, !!undoDeleteSubscriptionResult, !!undoCancelSubResult]);

  const handleDeleteSelectPlan = (categoryId) => {
    const newSelectedPlans = { ...selectedPlan };
    const newUndoPlan = { [categoryId]: { ...selectedPlan[categoryId] } };
    delete newSelectedPlans[categoryId];
    props.setSelectedPlan(newSelectedPlans);

    if (!Object.keys(newSelectedPlans).length && visible) {
      setVisible(false);
      if (isShowCartModal) props.closeCartModal();
    }

    const key = `open${Date.now()}`;

    const handleUndoDeleteSelectPlan = () => {
      props.setSelectedPlan({ ...newSelectedPlans, ...newUndoPlan });
      notification.close(key);
    };
    notification.destroy();
    openNotificationWithIcon({
      
      key: `open${Date.now()}`,
      style: { width: '400px' },
      className: 'notification notification_delete',
      message: (
        <DefaultMsg
          text={'Item has been removed from cart!'}
          icon={'payment_added'}
          title={t('Item has been removed from cart!')}
        />
      ),
    })
  };

  function handleUndoDelete(data, notificationKey) {
    if (undoDeleteSubscriptionFetching) return;
    props.undoDeleteSubscription(data);
    notification.close(notificationKey);
  }

  function changeSubscription(data) {
    if (isCurrentSubscription) {
      props.updateSubscription(data);
    } else {
      props.saveSubscription({
        ...data,
        'activate_trial': paymentId === 6 ? false : !trialUsed,
      });
    }
  }

  const handleSwitchPlan = (categoryId, plan) => {
    if (!plan && switchPlan?.[categoryId]?.id !== plan?.id) return;
    props.setSwitchPlan({ [categoryId]: plan });
  };

  const handleClose = () => {
    setVisible(false);
    clearDiscount();
    if (isShowCartModal) props.closeCartModal();
    if (modalType === 'add-card') {
      setConfirm(false);
      setUpgradeConfirm(false);
      setSwitchType(null);
      setPaymentType(null);
      setModalType('switch');
    }
    if (modalType === 'select' && confirm) setConfirm(false);
    if (modalType === 'cancel') setModalType('switch');
    if (modalType === 'switch' && !!Object.keys(switchPlan).length) {
      props.setSwitchPlan({ 2: null });
      if (!withoutSelectPlan) setConfirm(false);
      setSwitchType(null);
      setPaymentType(null);
      setUpgradeConfirm(false);
    }
  };

  const handleCancel = () => {
    if (modalType === 'add-card') {
      setModalType('switch');
      clearDiscount();
    } else if (upgradeConfirm) {
      setUpgradeConfirm(false);
    } else if (confirm) {
      if (withoutSelectPlan) {
        handleClose();
      } else {
        setConfirm(false);
      }
      setSwitchType(null);
      setPaymentType(null);
    } else {
      handleClose();
    }
  };

  const handleOk = () => {
    if (confirm) {
      if (modalType === 'select') {
        props.saveSubscription({
          plan: { ...selectedPlan },
          'activate_trial': false,
          'discount_code': onBoarding?.discountResult?.id || null,
        });
      } else if (modalType === 'switch') {
        if (switchType === 'upgrade' && !upgradeConfirm) {
          setUpgradeConfirm(true);
        } else {
          let [categoryId, plan] = Object.entries(switchPlan)[0]
          let categoryIdWithPeriod = category?.categoriesMap?.[categoryId]?.prices?.[priceType]?.id || 2;

          if (userInfo?.card || (
            onBoarding?.discountResult?.duration_type === "forever" &&
            onBoarding?.discountResult?.type === "percent" &&
            +onBoarding?.discountResult?.value === 100
          )) {
            let data = {
              plan: { [categoryIdWithPeriod]: plan?.prices?.[priceType] },
              'discount_code': onBoarding?.discountResult?.id || null,
              force: switchType === 'upgrade' && paymentType === 'now',
            }
            changeSubscription(data)
          } else {
            setModalType('add-card');
          }
        }
      }
    } if (modalType === 'undoCancel') {
      props.undoCancelSub(userInfo?.subscriptions?.[0]?.id);
    } else {
      const switchCategoryId = +Object.keys(onBoarding?.switchPlan)[0];
      const subscription = userInfo.subscriptions.filter(
        (subscription) => subscription.category.id === switchCategoryId,
      )[0];
      if (modalType === 'delete') {
        props.deleteSubscription(subscription.id);
      } else {
        setConfirm(true);
        if (!subscription?.plan?.price) {
          setSwitchType('upgrade')
          setPaymentType('now')
          setUpgradeConfirm(true)
        }
      }
    }
  };

  function handleCheckDiscount(code) {
    props.checkDiscountCode(code);
  }

  function handleClearError() {
    if (onBoarding.discountError) props.checkDiscountCodeReset();
  }

  function handleClearDiscount() {
    if (onBoarding.discountResult) props.checkDiscountCodeReset();
  }

  function clearDiscount() {
    if (onBoarding.discountResult || onBoarding.discountError) props.checkDiscountCodeReset();
  }

  const updatePlanWithCard = (data) => {
    let [categoryId, plan] = Object.entries(switchPlan)[0]
    let categoryIdWithPeriod = category?.categoriesMap?.[categoryId]?.prices?.[priceType]?.id || 2;
    let fullData = {
      plan: { [categoryIdWithPeriod]: plan?.prices?.[priceType] },
      force: switchType === 'upgrade' && paymentType === 'now',
      'discount_code': onBoarding?.discountResult?.id || null,
      payment: {
        intent_id: data.intent_id,
        type: 'card',
      }
    }
    changeSubscription(fullData);
  }

  const modalContentAdd = () => {
    const result = Object.keys(selectedPlan)?.map((categoryId) => (
      <PlanCardUpcomingNew
        key={categoryId}
        category={category?.categoriesMap?.[categoryId]}
        plan={selectedPlan?.[categoryId]}
        maxProducts={plan.maxProducts}
        onDeletePlan={handleDeleteSelectPlan}
        isVisibleBtn={!confirm}
      />
    ));

    const renderText = (
      <p key="text" style={{ margin: isMobile ? '24px 0 0' : '16px 0 0' }}>
        {t(
          confirm
            ? 'Are you sure you want to begin your subscription?'
            : 'Do you want to continue browsing or checkout?',
        )}
      </p>
    );

    return [result, renderText];
  };

  const modalContentSwitch = () => {
    const switchCategoryId = +Object.keys(onBoarding?.switchPlan)[0];
    const switchPlan = Object.values(onBoarding?.switchPlan)[0];

    const subscription = userInfo.subscriptions.filter(
      (subscription) => subscription.category.id === switchCategoryId,
    )[0];

    const downgradePlan =
      confirm && (
        switchPlan?.name === subscription?.plan?.plan?.name
          ? utils.multiplyPriceType[priceType] < utils.multiplyPriceType[subscription?.plan?.price_type?.id]
          : switchPlan?.amount_products_per_week < subscription?.plan?.plan?.amount_products_per_week
      );

    if (confirm) {
      if (downgradePlan && switchType !== 'downgrade') {
        setSwitchType('downgrade');
      }
      if (!downgradePlan && switchPlan?.name && switchType !== 'upgrade') {
        setSwitchType('upgrade');
      }
    }

    let subscriptionInfo = {
      date: dayjs(subscription?.expire_date || subscription?.next_payment_date).format('MMM DD, YYYY'),
    };

    let text = t(
      'Your plan downgrade will be scheduled until the end of your current billing period _date_',
      { ...subscriptionInfo },
    );

    let marginText = isMobile ? '0 0 24px' : '0 0 16px';
    let renderText = !confirm ? (
      <p key="text" style={{ margin: marginText }}>
        {t('Select the plan you wish to switch to')}
      </p>
    ) : downgradePlan ? (
      <p key="text" style={{ margin: marginText }}>
        {text}
      </p>
    ) : null;

    let result = plansList
      .filter((el) => (confirm ? el.id === switchPlan?.id : true))
      .map((otherPlan) => {
        const matchPlan = subscription?.plan?.plan?.name === otherPlan.name && subscription?.plan?.price_type?.id === priceType;
        return (
          <PlanCardUpcomingNew
            switchType={switchType}
            upgradeConfirm={upgradeConfirm}
            paymentType={paymentType}
            key={otherPlan?.id}
            category={category?.categoriesMap?.[switchCategoryId]}
            plan={otherPlan}
            maxProducts={plan.maxProducts}
            switchPlan={switchPlan}
            onSwitchPlan={handleSwitchPlan}
            onDeleteSubscription={() => setModalType('cancel')}
            isVisibleBtn={!confirm}
            isDisabled={matchPlan}
            customClass={matchPlan && otherPlan?.prices?.[priceType]?.price ? 'with-red-btn' : ''}
            typeBtn={matchPlan && otherPlan?.prices?.[priceType]?.price ? false : 'radio'}
            discount={onBoarding.discountResult}
            priceType={priceType}
          />
        );
      });

    let discount = (
      (confirm && switchPlan?.prices?.[priceType]?.price)
        ? <DiscountCard
          key={'discount'}
          onSubmit={handleCheckDiscount}
          error={onBoarding.discountError}
          clearError={handleClearError}
          discount={onBoarding.discountResult}
          clearDiscount={handleClearDiscount}
          fetching={onBoarding.discountFetching}
          style={{padding: 0, marginTop: 16}}
          isMobile={isMobile}
          priceType={priceType}
          divider
        />
        : null
    )

    if (switchType && !upgradeConfirm) {
      result = (
        <div key={'switch-subscription-wrap'}
          className='switch-subscription-wrap'>
          <PlanCardUpcomingNew
            key={subscription?.plan?.name}
            category={category?.categoriesMap?.[switchCategoryId]}
            plan={subscription?.plan}
            maxProducts={plan.maxProducts}
            switchPlan={switchPlan}
            onSwitchPlan={handleSwitchPlan}
            onDeleteSubscription={() => setModalType('cancel')}
            isVisibleBtn={!confirm}
            discount={onBoarding.discountResult}
            isCurrentPlan={true}
          />
          <span className="final-confirmation-icon">
            <svg xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M14.5833 17.7085L16.6414 15.6504C16.8855 15.4064 16.8855 15.0106 16.6414 14.7666L14.5833 12.7085M5.41667 2.29183L3.35861 4.34989C3.11453 4.59396 3.11453 4.98969 3.35861 5.23377L5.41667 7.29183M4.375 4.79183H15.2083C16.1288 4.79183 16.875 5.53802 16.875 6.4585V8.9585M3.125 11.4585V13.5418C3.125 14.4623 3.87119 15.2085 4.79167 15.2085H15.625"
                stroke="#F2F3F8"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          {
            result[0]
          }
        </div>
      )
    }

    if (switchType === 'upgrade') {
      if (upgradeConfirm) {
        let marginText = isMobile ? '0 0 24px' : '0 0 16px';

        text =
          paymentType === 'now'
            ? t(isCurrentSubscription ? 'Are you sure you want to upgrade your subscription?' : 'Are you sure you want to begin your subscription?')
            : t(
              'Are you sure you want to schedule your subscription upgrade? You will be billed on _date_',
              {
                date: dayjs(subscription?.expire_date || subscription?.next_payment_date).format('DD/MM/YYYY'),
              },
            );

        renderText = (
          <p key="text" style={{ margin: marginText }}>
            {text}
          </p>
        );
      }
      let subTitle = !upgradeConfirm && subscription?.plan?.price ? (
        <p key={'sub-title'} className={'modal-sub-title'}>
          {t('Payment Options')}
        </p>
      ) : null;

      let paymentOptionsCard = !upgradeConfirm && subscription?.plan?.price
        ? [
          {
            type: 'now',
            title: t('Pay Now'),
            text: t('You will be billed immediately after checkout'),
            onCheck: setPaymentType,
            checkedPaymentType: paymentType,
          },
          {
            type: 'schedule',
            title: t('Schedule Payment'),
            text: t('You will be billed at the end of the billing period'),
            onCheck: setPaymentType,
            checkedPaymentType: paymentType,
          },
        ].map((payment) => (
          <PaymentOptionCard key={payment.type} {...payment} />
        ))
        : null;

      discount = upgradeConfirm ? discount : null

      return [renderText, result, subTitle, paymentOptionsCard, discount];
    } else {
      return [
        renderText,
        <div key={'switch-subscription-wrap'}
          className='switch-subscription-wrap'>
          {result}
        </div>,
        discount];
    }
  };

  const modalContentDelete = () => {
    const switchCategoryId = +Object.keys(onBoarding?.switchPlan)[0];

    const subscription = userInfo.subscriptions.filter(
      (subscription) => subscription.category.id === switchCategoryId,
    )[0];
    return (
      <>
        <p key="text" style={{ margin: 0 }}>
          {t(
            'Are you sure you want to cancel the scheduling for this subscription? Your plan downgrade was scheduled until the end of your current billing period _date_',
            {
              date: dayjs(subscription?.expire_date || subscription?.next_payment_date).format('DD/MM/YYYY'),
            },
          )}
        </p>
        <Divider style={{ margin: '24px 0 0' }} />
      </>
    );
  };

  const modalContentCancel = () => {
    const switchCategoryId = +Object.keys(onBoarding?.switchPlan)[0];

    const subscription = userInfo.subscriptions.filter(
      (subscription) => subscription.category.id === switchCategoryId,
    )[0];

    return (
      <>
        <p key="text" style={{ margin: '0 0 16px' }}>
          {t(
            'Are you sure you want to cancel your subscription?',
          )}
        </p>

        <PlanCardUpcomingNew
          category={subscription?.category}
          plan={subscription?.plan}
          maxProducts={plan.maxProducts}
          switchPlan={subscription?.plan}
          isVisibleBtn={false}
          isDisabled={false}
          isCurrentPlan={true}
        />
      </>
    );
  };

  const modalContentAddCard = () => {
    const switchPlan = Object.values(onBoarding?.switchPlan)[0];
    const price = switchPlan?.prices?.[priceType]?.price * utils.multiplyPriceType[priceType]

    let discountCash = 0
    if (onBoarding?.discountResult) {
      discountCash = onBoarding?.discountResult.type === 'value' ? onBoarding?.discountResult.value : (price * 100 / 100) * onBoarding?.discountResult.value
    }

    return (
      <ChangeCreditCardForm
        customClass={'add-card'}
        paymentMethod={userInfo?.card}
        billingInfo={{ country: defaultUserCountry }}
        onCancel={handleCancel}
        onSubmit={updatePlanWithCard}
        getCountries={getCountries}
        countries={countries}
        isMobile={isMobile}
        theme={theme}
        setChargebeeCardFetching={setChargebeeCardFetching}
        errorSave={props.errorSave}
        intent={{
          result: onBoarding?.intentResult,
          error: onBoarding?.intentError,
          createIntent: props.createIntent,
          clearIntent: props.clearIntent,
          amount: price ? (price * 100 - discountCash) : null,
        }}
      />
    )
  }

  const changeModalTitle = () => {
    let result = '';

    if (modalType === 'select') {
      result = !confirm ? 'Added To Cart!' : 'Confirmation';
    } else if (modalType === 'undoCancel') {
      result = 'Are you sure?';
    } else if (modalType === 'delete') {
      result = "Cancel plan changes"
    } else if (modalType === 'cancel') {
      result = 'Confirmation';
    } else if (modalType === 'add-card') {
      result = 'Add Credit Card';
    } else {
      if (switchType) {
        result = !upgradeConfirm
          ? `${utils.toUpperLatter(switchType)} Plan`
          : 'Confirmation';
      } else {
        result = 'Switch Plan';
      }
    }
    setModalTitle(result);
  };

  const changeModalContent = () => {
    if (!visible) return null;
    let result = null;

    switch (modalType) {
      case 'switch':
        result = modalContentSwitch();
        break;
      case 'select':
        result = modalContentAdd();
        break;
      case 'delete':
      case 'undoCancel':
        result = modalContentDelete();
        break;
      case 'cancel':
        result = modalContentCancel();
        break;
      case 'add-card':
        result = modalContentAddCard();
        break;
      default:
        result = null;
        break;
    }
    return result;
  };

  return (
    <Modal
      className={cls('upcoming-drop', {
        full: modalType === 'select',
        small: modalType === 'delete' || modalType === 'undoCancel',
        'small-top': modalType !== 'select' && (!confirm || upgradeConfirm || switchType === 'downgrade'),
        'without-footer': modalType === 'add-card',
        'upgrade-plan': switchType === 'upgrade',
        'downgrade-plan': switchType === 'downgrade',
      })}
      title={t(modalTitle)}
      open={visible}
      width={modalType === 'add-card' ? 550 : (modalType === 'switch' && !switchType && !withoutSelectPlan ? 720 : 450)}
      handleOk={handleOk}
      handleClose={handleClose}
      isMobile={isMobile}
      destroyOnClose={true}
      footer={footerModal({
        isMobile: isMobile,
        handleCancel: handleCancel,
        textBtnCancel:
          textButtonModal[modalType] && modalType &&
          t(textButtonModal[modalType]['cancel'][Number(confirm)]),
        handleOk: handleOk,
        textBtnOk:
          textButtonModal[modalType] && modalType && t(textButtonModal[modalType]['ok'][Number(confirm)]),
        isDisabledBtnOk:
          (modalType === 'switch' && !Object.values(switchPlan)[0]) ||
          (switchType === 'upgrade' && !paymentType) ||
          saveSubscriptionFetching ||
          updateSubscriptionFetching ||
          deleteSubscriptionFetching ||
          props.cancelSubscriptionFetching ||
          onBoarding?.chargebeeCardFetching ||
          undoCancelSubFetching,
        deleteType: modalType === 'delete' || modalType === 'cancel' || switchType === 'downgrade' || modalType === 'undoCancel',
        upgradeConfirm: upgradeConfirm,
        bigBtn:
          (modalType === 'switch' && switchType === 'upgrade')
            ? upgradeConfirm
            : (modalType === 'cancel' || (modalType === 'select' && confirm) || (modalType === 'switch' && switchType === 'downgrade')),
        textSwipeBtn:
          modalType === 'cancel'
            ? t('Slide To Cancel')
            : (modalType === 'switch'
              ? (switchType === 'upgrade' ? t('Slide To Upgrade') : t('Slide To Downgrade'))
              : t('Slide To Subscribe')
            ),
        textBtnCancelNew: t(isCurrentSubscription ? 'Keep My Plan' : 'Cancel'),
        handleClose: handleClose,
        textBigBtn:
          modalType === 'cancel'
            ? t('Cancel')
            : (modalType === 'switch'
              ? (switchType === 'upgrade' ? t(isCurrentSubscription ? 'Upgrade Plan' : 'Subscribe') : t('Downgrade Plan'))
              : t('Subscribe')
            ),
      })}
    >
      <Spin
        spinning={
          saveSubscriptionFetching ||
          updateSubscriptionFetching ||
          deleteSubscriptionFetching ||
          props.cancelSubscriptionFetching ||
          onBoarding?.chargebeeCardFetching ||
          undoCancelSubFetching
        }
      >
        {changeModalContent()}
      </Spin>
    </Modal>
  );
};

ModalManageSubscriptions.defaultProps = {
  category: {
    categories: [],
    categoriesMap: {},
  },
};

ModalManageSubscriptions.propTypes = {
  category: PropTypes.shape({
    categories: PropTypes.array,
    categoriesMap: PropTypes.object,
    categoriesFetching: PropTypes.bool,
  }).isRequired,
  plan: PropTypes.shape({
    plans: PropTypes.array,
    plansMap: PropTypes.object,
    maxProducts: PropTypes.number,
  }).isRequired,
  isMobile: PropTypes.bool,
  theme: PropTypes.string,
  isShowCartModal: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  userFetching: state.users.fetching,
  userInfo: state.auth.userInfo,
  onBoarding: state.onBoarding,
  category: state.category,
  plan: state.plan,
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  isShowCartModal: state.nav.isShowCartModal,
  defaultUserCountry: state.setting.userCountry,
});

const mapDispatchToProps = (dispatch) => ({
  getCategory: () => dispatch(actions.getCategory()),
  getPlan: () => dispatch(actions.getPlan()),
  getCountries: () => dispatch(OnboardingActions.countriesRequest()),
  setSelectedPlan: (plan) => dispatch(actions.setSelectedPlan(plan)),
  setSwitchPlan: (plan) => dispatch(actions.setSwitchPlan(plan)),
  saveSubscription: (plan) => dispatch(actions.saveSubscription(plan)),
  updateSubscription: (plan) => dispatch(actions.updateSubscription(plan)),
  deleteSubscription: (categoryId) =>
    dispatch(actions.deleteSubscription(categoryId)),
  undoDeleteSubscription: (data) =>
    dispatch(actions.undoDeleteSubscription(data)),
  cancelSub: (id) =>
    dispatch(OnboardingActions.cancelSubRequest(id)),
  restoreCancel: (data) =>
    dispatch(OnboardingActions.restoreCancel(data)),
  undoCancelSub: (id) =>
    dispatch(OnboardingActions.undoCancelSubRequest(id)),
  changeSubscriptionReset: () => dispatch(actions.changeSubscriptionReset()),
  closeCartModal: () =>
    dispatch(actions.changeNavSetProp('isShowCartModal', false)),
  createIntent: data => dispatch(OnboardingActions.createIntentRequest(data)),
  clearIntent: () => dispatch(OnboardingActions.createIntentReset()),
  setChargebeeCardFetching: (value) => dispatch(OnboardingActions.setChargebeeCardFetching(value)),
  errorSave: (error) => dispatch(ErrorsActions.errorSave(error)),

  checkDiscountCode: code => dispatch(OnboardingActions.checkDiscountCodeRequest(code)),
  checkDiscountCodeReset: () => dispatch(OnboardingActions.checkDiscountCodeReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalManageSubscriptions);
