import React from 'react';
import { useSelector } from 'react-redux';

const AdSpotEye = () => {
  const theme = useSelector((state) => state?.nav?.theme);

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
          fill={theme === 'light' ? '#A1AAC8' : '#ffffff5c'} 
          fillRule="evenodd" 
          clipRule="evenodd" 
          d="M9.9993 3.33301C13.2319 3.33303 16.3809 5.26556 18.4838 8.95591C18.8525 9.60301 18.8525 10.3964 18.4838 11.0435C16.3809 14.7338 13.2319 16.6663 9.99929 16.6663C6.7667 16.6663 3.61773 14.7337 1.51484 11.0434C1.1461 10.3963 1.14609 9.60292 1.51484 8.95581C3.61773 5.26547 6.76671 3.33298 9.9993 3.33301ZM6.97846 9.99967C6.97846 8.33131 8.33093 6.97884 9.99929 6.97884C11.6677 6.97884 13.0201 8.33131 13.0201 9.99967C13.0201 11.668 11.6677 13.0205 9.99929 13.0205C8.33093 13.0205 6.97846 11.668 6.97846 9.99967Z"
        />
    </svg>
  );
};

export default AdSpotEye;
