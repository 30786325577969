import acc from "accounting";
import utils from "../../Utils/utils";
import React from 'react';

const icons = {
  'comments': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.3745 11.875H16.043C16.9634 11.875 17.7096 11.1288 17.7096 10.2083V4.79167C17.7096 3.87119 16.9634 3.125 16.043 3.125H7.5013C6.58083 3.125 5.83464 3.87119 5.83464 4.79167V6.45833M12.7096 6.45833H3.95964C3.03916 6.45833 2.29297 7.20453 2.29297 8.125V13.5417C2.29297 14.4621 3.03916 15.2083 3.95964 15.2083H5.0013V17.2917L8.7513 15.2083H12.7096C13.6301 15.2083 14.3763 14.4621 14.3763 13.5417V8.125C14.3763 7.20453 13.6301 6.45833 12.7096 6.45833Z" stroke="white" stroke-opacity="0.36" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>',
  'reactions': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.62435 8.95866H3.12435C2.66411 8.95866 2.29102 9.33175 2.29102 9.79199V16.042C2.29102 16.5022 2.66411 16.8753 3.12435 16.8753H5.62435M5.62435 16.8753V9.16699L8.93332 2.74369C9.07622 2.46631 9.36312 2.29199 9.67515 2.29199C10.698 2.29199 11.4834 3.20479 11.3258 4.21541L10.8461 7.29199H15.2151C16.7457 7.29199 17.9169 8.65495 17.6867 10.1681L16.9892 14.7514C16.8033 15.9728 15.7531 16.8753 14.5177 16.8753H5.62435Z" stroke="white" stroke-opacity="0.36" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>',
  'shares': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.1335 9.54097L11.3456 3.26215C10.9454 2.89197 10.2962 3.17581 10.2962 3.72096V6.66645C10.2962 6.89657 10.1062 7.08275 9.87612 7.0864C3.1384 7.19356 1.33789 9.93358 1.33789 16.8748C2.56267 14.4252 3.18742 12.9757 9.87532 12.9182C10.1054 12.9163 10.2962 13.103 10.2962 13.3331V16.2786C10.2962 16.8238 10.9454 17.1076 11.3456 16.7374L18.1335 10.4586C18.401 10.2112 18.401 9.78838 18.1335 9.54097Z" stroke="white" stroke-opacity="0.36" strokeWidth="1.5" strokeLinejoin="round"/></svg>',
}


export const chartConfig = (
  type,
  animation,
  theme,
  kind,
  isMobile = false,
  externalTooltip = false,
  withRawData=false,
  withReturns = false,
) => {
  return type === 'ad_spot' && externalTooltip ?
    (
      {
        animation: !!animation,
        response: false,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        layout: {
          autoPadding: false,
          padding: 0,
        },
        scales: {
          x: {
            adapters: {},
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MMM DD',
                week: 'MMM DD',
                month: 'MMM DD',
                quarter: 'MMM DD',
                year: 'MMM',
              },
              tooltipFormat: 'MMM DD, YYYY',
            },
            ticks: {
              maxRotation: 0,
              padding: 4,
              maxTicksLimit: isMobile ? 6 : 11,
              color: '#707BA0',
              font: {
                family: 'Inter, sans-serif',
                weight: 400,
                size: 12,
                lineHeight: '16px',
              },
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },

          y: {
            beginAtZero: true,
            ticks: {
              align: 'center',
              padding: 8,
              color: '#707BA0',
              stepSize: kind === 'one' && 0.1,
              font: {
                family: 'Inter, sans-serif',
                weight: 400,
                size: 12,
                lineHeight: '16px',
              },
              callback: function(value) {
                let realValue = value || 0;
                if (realValue >= 1000000) return `${realValue / 1000000}M`;
                if (realValue >= 1000) return `${realValue / 1000}K`;
                return kind === 'empty' ? value * 10 : realValue;
              },
            },
            grid: {
              display: true,
              drawBorder: false,
              drawTicks: false,
              color: theme === 'light' ? '#F2F3F8' : '#151E3A',
            },
          },
        },
        plugins: {
          'vertical-line-plugin': { theme: theme },
          tooltip: {
            enabled: false,
            events: ['mousemove', 'mouseout'],
            position: 'nearest',
            external: (context) => externalTooltipHandler(context, isMobile)
          },
        },
        interaction: {
          mode: 'index',
          intersect: false,
        }
      }
    )
    :
    type === 'ad_spot' ?
      (
        {
          animation: !!animation,
          response: false,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          layout: {
            autoPadding: false,
            padding: 0,
          },
          scales: {
            x: {
              adapters: {},
              type: 'time',
              time: {
                unit: 'day',
                displayFormats: {
                  day: 'MMM DD',
                  week: 'MMM DD',
                  month: 'MMM DD',
                  quarter: 'MMM DD',
                  year: 'MMM',
                },
                tooltipFormat: 'MMM DD, YYYY'
              },
              ticks: {
                maxRotation: 0,
                padding: 4,
                maxTicksLimit: isMobile ? 6 : 11,
                color: '#707BA0',
                font: {
                  family: 'Inter, sans-serif',
                  weight: 400,
                  size: 12,
                  lineHeight: '16px',
                },
              },
              grid: {
                display: false,
                drawBorder: false,
              }
            },

            y: {
              beginAtZero: true,
              ticks: {
                align: 'center',
                padding: 8,
                color: '#707BA0',
                stepSize: kind === 'one' && 0.1,
                font: {
                  family: 'Inter, sans-serif',
                  weight: 400,
                  size: 12,
                  lineHeight: '16px',
                },
                callback: function (value) {
                  let realValue = value || 0;
                  if (realValue >= 1000000) return `${realValue / 1000000}M`
                  if (realValue >= 1000) return `${realValue / 1000}K`
                  return kind === 'empty' ? value * 10 : realValue
                }
              },
              grid: {
                display: true,
                drawBorder: false,
                drawTicks: false,
                color: theme === 'light' ? '#F2F3F8' : '#151E3A',
              }
            },
          },
          plugins: {
            'vertical-line-plugin': {theme: theme},
            tooltip: {
              enabled: false,
              events: ['mousemove', 'mouseout'],
              position: 'nearest',
              external: (context) => externalTooltipSimpleHandler(context, isMobile)
            },
          },
          interaction: {
            mode: 'index',
            intersect: false,
          }
        }
      )
      :
      (
        {
          animation: !!animation,
          response: false,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          layout: {
            autoPadding: false,
            padding: 0,
          },
          scales: {
            x: {
              adapters: {},
              type: 'time',
              time: {
                unit: 'day',
                displayFormats: {
                  day: 'MMM DD',
                  week: 'MMM DD',
                  month: 'MMM DD',
                  quarter: 'MMM DD',
                  year: 'MMM',
                },
                tooltipFormat: 'MMM DD, YYYY'
              },
              ticks: {
                maxRotation: 0,
                padding: 4,
                maxTicksLimit: isMobile ? 6 : 11,
                color: '#707BA0',
                font: {
                  family: 'Inter, sans-serif',
                  weight: 400,
                  size: 12,
                  lineHeight: '16px',
                },
              },
              grid: {
                display: false,
                drawBorder: false,
              }
            },

            y: {
              beginAtZero: true,
              ticks: {
                align: 'center',
                padding: 8,
                color: '#707BA0',
                stepSize: kind === 'one' && 0.1,
                font: {
                  family: 'Inter, sans-serif',
                  weight: 400,
                  size: 12,
                  lineHeight: '16px',
                },
                callback: function (value) {
                  let realValue = value || 0;
                  if (realValue >= 1000000) return `${realValue / 1000000}M`
                  if (realValue >= 1000) return `${realValue / 1000}K`
                  return kind === 'empty' ? value * 10 : realValue
                }
              },
              grid: {
                display: true,
                drawBorder: false,
                drawTicks: false,
                color: theme === 'light' ? '#F2F3F8' : '#151E3A',
              }
            },
          },
          plugins: {
            'vertical-line-plugin': {theme: theme},
            tooltip: {
              padding: {x: 16, y: 12},
              backgroundColor: '#151E3A',
              caretPadding: 16,
              caretSize: 0,
              displayColors: withReturns,
              titleColor: '#FFFFFF',
              titleFont: {
                family: 'Inter, sans-serif',
                weight: 500,
                size: 16,
                lineHeight: '24px',
              },
              titleAlign: 'center',
              bodyFont: {
                family: 'Inter, sans-serif',
                weight: 400,
                size: 14,
                lineHeight: '20px',
              },
              bodyAlign: withReturns ? 'left' : 'center',

              //hide tooltip stacking for boundary points (min, max)
              filter: function (tooltipItem) {
                return !tooltipItem?.raw?.hiddenTooltip
              },
              callbacks: {
                label: function (context) {
                  let label = ''
                  if (context?.raw?.invalid) {
                    label = context?.raw?.message || 'Invalid data';
                  } else if (context.parsed.y !== null) {
                    label = new Intl.NumberFormat(
                      'en-US',
                      type === 'revenue' ? {style: 'currency', currency: 'USD'} : {}
                    ).format(context.parsed.y);
                  }
                  let word = context?.dataset?.label;
                  return withRawData || withReturns ?
                    ` ${word.charAt(0).toUpperCase() + word.slice(1)}: ${label}`
                    :
                    label;
                },
                labelTextColor: function (context) {
                  return '#FFFFFF'
                },
                labelColor: function(context) {
                  return {
                    borderColor: context?.dataset?.borderColor,
                    backgroundColor: context?.dataset?.borderColor,
                    borderWidth: 3,
                    borderRadius: 2,
                  }
                }
              },
            },
          },
          interaction: {
            mode: 'index',
            intersect: false,
          },
        }
      )
}

function createTooltip(chart) {
  let tooltipElement = chart.canvas.parentNode.querySelector('div');

  if (!tooltipElement) {
    tooltipElement = document.createElement('div');
    tooltipElement.classList.add('ad-spot-external-tooltip');
    chart.canvas.parentNode.appendChild(tooltipElement);
  }

  return tooltipElement;
}

function externalTooltipSimpleHandler(context, isMobile) {
  const {chart, tooltip} = context;

  const tooltipElement = createTooltip(chart);

  if (tooltip.opacity === 0) {
    tooltipElement.style.opacity = 0;
    return;
  }

  tooltipElement.addEventListener('mouseover', () => tooltipElement.style.opacity = 1);
  tooltipElement.addEventListener('mouseout', () => {
    tooltipElement.style.opacity = 0;
    tooltip.setActiveElements([], {x: 0, y: 0})
  });

  if (tooltip.body) {
    const titleText = tooltip.title || [];
    const bodyText = tooltip.body.map(el => el?.lines);

    const tooltipHeader = document.createElement('div');
    tooltipHeader.classList.add('ad-spot-external-tooltip-header');

    titleText.forEach(el => {
      const span = document.createElement('span');
      const text = document.createTextNode(el);
      span.appendChild(text);
      tooltipHeader.appendChild(span);
    });

    const tooltipSubHeader = document.createElement('div');
    tooltipSubHeader.classList.add('ad-spot-external-tooltip-subheader');

    bodyText.forEach(el => {
      const span = document.createElement('span');
      const text = document.createTextNode(acc.formatNumber(el, 0, ','));
      span.appendChild(text);
      tooltipSubHeader.appendChild(span);
    });

    const divider = document.createElement('div');
    divider.classList.add('ad-spot-external-tooltip-divider');

    let subTitleStatus;
    let statusWrapper;
    let dividerStatus;

    if (tooltip?.dataPoints?.[0]?.raw?.is_active !== null) {

      subTitleStatus = document.createElement('h4');
      subTitleStatus.classList.add('ad-spot-external-tooltip-subtitle');
      const subTitleStatusText = document.createTextNode('ad status');
      subTitleStatus.appendChild(subTitleStatusText);

      statusWrapper = document.createElement('div');
      statusWrapper.classList.add('ad-spot-external-tooltip-status-wrapper');

      const statusCircle = document.createElement('div');
      statusCircle.classList.add('ad-spot-external-tooltip-status-circle');

      if (tooltip?.dataPoints?.[0]?.raw?.is_active) {
        statusCircle.classList.add('active');
      }

      const statusText = document.createTextNode(tooltip?.dataPoints?.[0]?.raw?.is_active ? 'active': 'inactive');
      statusWrapper.appendChild(statusCircle);
      statusWrapper.appendChild(statusText);

      dividerStatus = document.createElement('div');
      dividerStatus.classList.add('ad-spot-external-tooltip-divider');
    }

    const subTitle = document.createElement('h4');
    subTitle.classList.add('ad-spot-external-tooltip-subtitle');
    const subTitleText = document.createTextNode('engagement types');
    subTitle.appendChild(subTitleText);

    const topWrapper = document.createElement('div');
    topWrapper.classList.add('ad-spot-external-tooltip-top-wrapper');

      Object.keys(tooltip?.dataPoints?.[0]?.raw?.breakdown)?.forEach((performer) => {
        const topPerformer = document.createElement('div');
        topPerformer.classList.add('ad-spot-external-tooltip-top-item');

        const topPerformerImageWrapper = document.createElement('div');
        topPerformerImageWrapper.classList.add('ad-spot-external-tooltip-top-item-image-wrapper');
        topPerformerImageWrapper.classList.add('simple');
        topPerformerImageWrapper.innerHTML = icons[performer];

        const topPerformerTitle = document.createElement('span');
        topPerformerTitle.classList.add('ad-spot-external-tooltip-top-item-title');
        topPerformerTitle.classList.add('simple');
        const topPerformerTitleText = document.createTextNode(`${performer}`);
        topPerformerTitle.appendChild(topPerformerTitleText);

        const topPerformerValue = document.createElement('span');
        topPerformerValue.classList.add('ad-spot-external-tooltip-top-item-value');
        const topPerformerValueText = document.createTextNode(acc.formatNumber(tooltip?.dataPoints?.[0]?.raw?.breakdown?.[performer], 0, ','));
        topPerformerValue.appendChild(topPerformerValueText);

        topPerformer.appendChild(topPerformerImageWrapper);
        topPerformer.appendChild(topPerformerTitle);
        topPerformer.appendChild(topPerformerValue);

        topWrapper.appendChild(topPerformer);
      })

    while (tooltipElement.firstChild) {
      tooltipElement.firstChild.remove()
    }

    tooltipElement.appendChild(tooltipHeader);
    tooltipElement.appendChild(tooltipSubHeader);
    tooltipElement.appendChild(divider);
    if (tooltip?.dataPoints?.[0]?.raw?.is_active !== null) {
      tooltipElement.appendChild(subTitleStatus);
      tooltipElement.appendChild(statusWrapper);
      tooltipElement.appendChild(dividerStatus);
    }
    tooltipElement.appendChild(subTitle);
    tooltipElement.appendChild(topWrapper);

    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
    tooltipElement.style.opacity = 1;

    tooltipElement.style.left = isMobile ?
      tooltip?.caretX > 250 ?
        tooltip?.caretX - 100 + 'px'
        :
        tooltip?.caretX < 100 ?
          100 + tooltip.caretX + 'px'
          :
          positionX + tooltip.caretX + 'px'
      :
      tooltip?.caretX > 900 ?
        - positionX - 112  + tooltip.caretX + 'px'
        :
        tooltip?.caretX < 90 ?
          115 + tooltip?.caretX/2 + 'px'
          :
          positionX + tooltip.caretX + 'px';
    tooltipElement.style.top = -20 + 'px';
  }
}

function externalTooltipHandler(context, isMobile) {
  const {chart, tooltip} = context;

  const tooltipElement = createTooltip(chart);

  if (tooltip.opacity === 0) {
    tooltipElement.style.opacity = 0;
    return;
  }

  tooltipElement.addEventListener('mouseover', () => tooltipElement.style.opacity = 1);
  tooltipElement.addEventListener('mouseout', () => {
    tooltipElement.style.opacity = 0;
    tooltip.setActiveElements([], {x: 0, y: 0})
  });

  if (tooltip.body) {
    const titleText = tooltip.title || [];
    const bodyText = tooltip.body.map(el => el?.lines);

    const tooltipHeader = document.createElement('div');
    tooltipHeader.classList.add('ad-spot-external-tooltip-header');

    titleText.forEach(el => {
      const span = document.createElement('span');
      const text = document.createTextNode(el);
      span.appendChild(text);
      tooltipHeader.appendChild(span);
    });

    const tooltipSubHeader = document.createElement('div');
    tooltipSubHeader.classList.add('ad-spot-external-tooltip-subheader');

    bodyText.forEach(el => {
      const span = document.createElement('span');
      const text = document.createTextNode(acc.formatNumber(el, 0, ','));
      span.appendChild(text);
      tooltipSubHeader.appendChild(span);
    });

    const divider = document.createElement('div');
    divider.classList.add('ad-spot-external-tooltip-divider');

    let subTitleStatus;
    let statusWrapper;
    let dividerStatus;

    if (tooltip?.dataPoints?.[0]?.raw?.is_active !== null) {

      subTitleStatus = document.createElement('h4');
      subTitleStatus.classList.add('ad-spot-external-tooltip-subtitle');
      const subTitleStatusText = document.createTextNode('ad status');
      subTitleStatus.appendChild(subTitleStatusText);

      statusWrapper = document.createElement('div');
      statusWrapper.classList.add('ad-spot-external-tooltip-status-wrapper');

      const statusCircle = document.createElement('div');
      statusCircle.classList.add('ad-spot-external-tooltip-status-circle');

      if (tooltip?.dataPoints?.[0]?.raw?.is_active) {
        statusCircle.classList.add('active');
      }

      const statusText = document.createTextNode(tooltip?.dataPoints?.[0]?.raw?.is_active ? 'active': 'inactive');
      statusWrapper.appendChild(statusCircle);
      statusWrapper.appendChild(statusText);

      dividerStatus = document.createElement('div');
      dividerStatus.classList.add('ad-spot-external-tooltip-divider');
    }

    const subTitle = document.createElement('h4');
    subTitle.classList.add('ad-spot-external-tooltip-subtitle');
    const subTitleText = document.createTextNode('top performers');
    subTitle.appendChild(subTitleText);

    const topWrapper = document.createElement('div');
    topWrapper.classList.add('ad-spot-external-tooltip-top-wrapper');

    if (tooltip?.dataPoints?.[0]?.raw?.top_performers?.length) {
      tooltip?.dataPoints?.[0]?.raw?.top_performers?.forEach((performer, index) => {
        const topPerformer = document.createElement('div');
        topPerformer.classList.add('ad-spot-external-tooltip-top-item');

        const topPerformerImageWrapper = document.createElement('div');
        topPerformerImageWrapper.classList.add('ad-spot-external-tooltip-top-item-image-wrapper');

        const topPerformerImage = document.createElement('img');
        topPerformerImage.classList.add('ad-spot-external-tooltip-top-item-image');
        topPerformerImage.setAttribute('src', performer?.thumbnail);
        topPerformerImage.setAttribute('alt', '');
        topPerformerImage.onerror = utils.addDefaultSrc;

        topPerformerImageWrapper.appendChild(topPerformerImage);

        const topPerformerTitle = document.createElement('span');
        topPerformerTitle.classList.add('ad-spot-external-tooltip-top-item-title');
        const topPerformerTitleText = document.createTextNode(
          performer?.is_current_ad ?
            'Current ad'
              :
            `Ad ${index + 1}`
        );
        topPerformerTitle.appendChild(topPerformerTitleText);
        if (!performer?.is_current_ad)
        topPerformerTitle.addEventListener('click', () => {
          let params = new URLSearchParams(window.location.search);
          let result = `/ad-spot/${performer?.advertiser?.id}/overview/?ad_id=${performer?.id}&domain=${performer?.domain_fld}`;
          if (params.get('internal_shop_id')) result += `&internal_shop_id=${params.get('internal_shop_id')}`
          window.open(result, '_self');
        })

        const topPerformerValue = document.createElement('span');
        topPerformerValue.classList.add('ad-spot-external-tooltip-top-item-value');
        const topPerformerValueText = document.createTextNode(acc.formatNumber(performer?.value, 0, ','));
        topPerformerValue.appendChild(topPerformerValueText);

        topPerformer.appendChild(topPerformerImageWrapper);
        topPerformer.appendChild(topPerformerTitle);
        topPerformer.appendChild(topPerformerValue);

        topWrapper.appendChild(topPerformer);
      })
    } else {
      const topPerformer = document.createElement('span');
      topPerformer.classList.add('empty-message');
      const emptyText = document.createTextNode('No Top Performers');
      topPerformer.appendChild(emptyText);
      topWrapper.appendChild(topPerformer);
    }

    while (tooltipElement.firstChild) {
      tooltipElement.firstChild.remove()
    }

    tooltipElement.appendChild(tooltipHeader);
    tooltipElement.appendChild(tooltipSubHeader);
    tooltipElement.appendChild(divider);
    if (tooltip?.dataPoints?.[0]?.raw?.is_active !== null) {
      tooltipElement.appendChild(subTitleStatus);
      tooltipElement.appendChild(statusWrapper);
      tooltipElement.appendChild(dividerStatus);
    }
    tooltipElement.appendChild(subTitle);
    tooltipElement.appendChild(topWrapper);

    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
    tooltipElement.style.opacity = 1;

    tooltipElement.style.left = isMobile ?
      tooltip?.caretX > 250 ?
        tooltip?.caretX - 100 + 'px'
        :
        tooltip?.caretX < 100 ?
          100 + tooltip.caretX + 'px'
          :
          positionX + tooltip.caretX + 'px'
      :
      tooltip?.caretX > 900 ?
        - positionX - 112  + tooltip.caretX + 'px'
        :
        tooltip?.caretX < 90 ?
          115 + tooltip?.caretX/2 + 'px'
          :
          positionX + tooltip.caretX + 'px';
    tooltipElement.style.top = -20 + 'px';
  }
}
