import React from 'react';

const TopProductsIcon = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.78087 0.375342C7.12588 0.806604 7.05596 1.4359 6.62469 1.78091L3.85078 4.00004H14.1492L11.3753 1.78091C10.944 1.4359 10.8741 0.806604 11.2191 0.375342C11.5641 -0.0559202 12.1934 -0.125842 12.6247 0.219168L17.6247 4.21917C17.8619 4.40894 18 4.69625 18 5.00004V15C18 17.2092 16.2091 19 14 19H4C1.79086 19 0 17.2092 0 15V5.00004C0 4.69625 0.13809 4.40894 0.375305 4.21917L5.37531 0.219168C5.80657 -0.125842 6.43586 -0.0559202 6.78087 0.375342ZM6 9.00004C6 8.44775 6.44772 8.00004 7 8.00004H11C11.5523 8.00004 12 8.44775 12 9.00004C12 9.55232 11.5523 10 11 10H7C6.44772 10 6 9.55232 6 9.00004Z" fill="#F9F9FC" />
    </svg>
  );
};

export default TopProductsIcon;
