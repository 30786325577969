import React from 'react';
import { useSelector } from 'react-redux';

const Champion = () => {
  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={theme === 'light' ? "#707BA0" : '#ffffff5c'}
        d="M10.0026 15.0003V12.5003M15.2077 3.95866H15.7077C16.8123 3.95866 17.7077 4.85409 17.7077 5.95866V6.04199C17.7077 7.4227 16.5884 8.54199 15.2077 8.54199M4.79102 3.95866H4.29102C3.18645 3.95866 2.29102 4.85409 2.29102 5.95866V6.04199C2.29102 7.4227 3.4103 8.54199 4.79102 8.54199M9.79102 12.7087H10.2077C12.9691 12.7087 15.2077 10.4701 15.2077 7.70866V4.29199C15.2077 3.18742 14.3123 2.29199 13.2077 2.29199H6.79102C5.68645 2.29199 4.79102 3.18742 4.79102 4.29199V7.70866C4.79102 10.4701 7.02959 12.7087 9.79102 12.7087ZM7.08268 17.7087H12.916C13.7214 17.7087 14.3743 17.0557 14.3743 16.2503V16.2503C14.3743 15.4449 13.7214 14.792 12.916 14.792H7.08268C6.27727 14.792 5.62435 15.4449 5.62435 16.2503V16.2503C5.62435 17.0557 6.27727 17.7087 7.08268 17.7087Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Champion;
