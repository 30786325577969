import React from 'react';
import {OverlayScrollbar} from "../../../Components/ScrollBar";
import { Divider } from 'antd';

const AdItemPageSkeleton = (
  {
    isMobile
  }
) => {
  return (
    <OverlayScrollbar visibility="hidden">
      <div className={"fadspot-page-inner-content"} style={{ display: "flex", flexDirection: "column" }}>
        {isMobile ? null : <div style={{ display: "flex", margin: "16px 32px", justifyContent: "space-between" }}>
          <span className="link skeleton" style={{height: 24, width: 160}}/>
          <span className="link skeleton" style={{height: 24, width: 40}}/>
        </div>}
        <div style={{ display: "flex", margin: isMobile ? "16px" : "16px 32px", gap: 16}}>
          {isMobile ? (
            <span className="link skeleton" style={{height: 40, width: "100%"}}/>
          ) : (
            <>
              <span className="link skeleton" style={{height: 54, width: "33.3%"}}/>
              <span className="link skeleton" style={{height: 54, width: "33.3%"}}/>
              <span className="link skeleton" style={{height: 54, width: "33.3%"}}/>
            </>
          )}
        </div>
        <Divider type="horizintal"/>
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", flexDirection: isMobile ? "column" : "row" }}>
          <span className="link skeleton" style={{height: isMobile ? 40 : 40, width: 69, margin: isMobile ? "24px 0px 16px 16px" : "24px 32px"}}/>
          <span className="link skeleton" style={{height: isMobile ? 40 : 40, width: isMobile ? "90%" : 196, margin: isMobile ? "0px 16px 16px" : "24px 32px"}}/>
        </div>
        <div className={'fadspot-page-items-wrapper'}>
          <span className="fadspot-page-item-wrapper link skeleton"/>
          {
            isMobile ?
              null
              :
              <span className="fadspot-page-item-wrapper link skeleton"/>
          }
          {
            isMobile ?
              null
              :
              <span className="fadspot-page-item-wrapper link skeleton"/>
          }
          {
            isMobile ?
              null
              :
              <span className="fadspot-page-item-wrapper link skeleton"/>
          }
          {
            isMobile ?
              null
              :
              <span className="fadspot-page-item-wrapper link skeleton"/>
          }
          {
            isMobile ?
              null
              :
              <span className="fadspot-page-item-wrapper link skeleton"/>
          }
        </div>
      </div>
      
    </OverlayScrollbar>
  );
};

export default AdItemPageSkeleton;
