import Creators, {AutoDSSelectors, AutoDSStoreTypes as constants} from './reducer';
import {openNotificationWithIcon} from "../../Components/Notification/index";
import React from "react";
import {DefaultMsg} from "../../Components/Notification/notification-message";
import * as services from "./services";
import { takeLatest, call, put, select } from 'redux-saga/effects';

const notification = (text, isImport=false) => openNotificationWithIcon({
  style: { minWidth: '716px' },
  className: 'notification notification--save',
  message:
    isImport ?
      <DefaultMsg
        text={
          <span style={{margin: 0}}>
            {text?.detail}
            <a href={text?.link?.url || '#'}
               rel="noopener noreferrer"
               target='_blank'
               style={{marginLeft: 4}}>
              {text?.link?.text}
            </a>
          </span>
        }
        icon="notification_success"
        iconWidth={48}
        iconHeight={48}
      />
        :
        <DefaultMsg
          icon="notification_success"
          iconOutline={true}
          text={text}
          iconWidth={48}
          iconHeight={48}
        />,
});

const actions = {
  deleteAutoStoreActions: {
    request: Creators.deleteAutoStoreRequest,
    success: Creators.deleteAutoStoreSuccess,
    errors: Creators.deleteAutoStoreFailure
  },
  getAutoStoreActions: {
    request: Creators.getAutoStoreRequest,
    success: Creators.getAutoStoreSuccess,
    errors: Creators.getAutoStoreFailure
  },
  recheckAutoStoresActions: {
    request: Creators.recheckAutoStoresRequest,
    success: Creators.recheckAutoStoresSuccess,
    errors: Creators.recheckAutoStoresFailure
  },
  connectAutoStoreActions: {
    request: Creators.connectAutoStoreRequest,
    success: Creators.connectAutoStoreSuccess,
    errors: Creators.connectAutoStoreFailure
  },
  importProductToAutoStoreActions: {
    request: Creators.importProductToAutoStoreRequest,
    success: Creators.importProductToAutoStoreSuccess,
    errors: Creators.importProductToAutoStoreFailure
  },
}

const eventsOptions = {
  [constants.DELETE_AUTO_STORE_REQUEST]: {
    api: services.deleteAutoStore,
    actions: actions.deleteAutoStoreActions,
  },
  [constants.GET_AUTO_STORE_REQUEST]: {
    api: services.getAutoStore,
    actions: actions.getAutoStoreActions,
  },
  [constants.RECHECK_AUTO_STORES_REQUEST]: {
    api: services.getAutoStore,
    actions: actions.recheckAutoStoresActions,
  },
  [constants.CONNECT_AUTO_STORE_REQUEST]: {
    api: services.connectAutoStore,
    actions: actions.connectAutoStoreActions,
    openErrorNotification: (error) => {
      openNotificationWithIcon({
        style: {minWidth: '716px'},
        className: 'notification notification--save notification-rename-error',
        message: (
          <DefaultMsg
            text={
              <span className="preset-notification-block">
                {error}
              </span>
            }
            icon="alert_triangle_error"
            iconWidth={24}
            iconHeight={24}
          />
        ),
      })},
    openNotification: text => notification(text),

  },
  [constants.IMPORT_PRODUCT_TO_AUTO_STORE_REQUEST]: {
    api: services.importProductToAutoStore,
    actions: actions.importProductToAutoStoreActions,
    openNotification: (text,link) => {
      openNotificationWithIcon({
        style: { width: '400px' },
        className: 'notification notification--save',
        message: (
          <DefaultMsg
          title={text?.detail}
            text={
              <p style={{ margin: 0 }}>
              <a href={text?.link?.url || '#'}
                 rel="noopener noreferrer"
                 target="_blank"
                 style={{ marginLeft: 4 }}
              >
                {text.link?.text}
              </a>
            </p>
            }
            icon="notification_success"
          />
        ),
      });
    },
    openErrorNotification: (error) => {
      openNotificationWithIcon({
        style: {minWidth: '716px'},
        className: 'notification notification--save notification-rename-error',
        message: (
          <DefaultMsg
            text={
              <span className="preset-notification-block">
                {error}
              </span>
            }
            icon="alert_triangle_error"
            iconWidth={24}
            iconHeight={24}
          />
        ),
      })}
  },
}

function* apiGenerator(action) {
  const provider = eventsOptions[action.type];

  try {
    const params = action.payload;
    const response = yield call(provider.api, params);

    if ((response?.data || response.status === 204) && response.ok) {
      yield put(provider.actions.success(response.data));
      if (action.type === 'IMPORT_PRODUCT_TO_AUTO_STORE_REQUEST' ||
        action.type === 'CONNECT_AUTO_STORE_REQUEST') {
        if (action.type === 'IMPORT_PRODUCT_TO_AUTO_STORE_REQUEST') {
          yield call(provider.openNotification, response?.data?.message);
        } else {
          yield call(provider.openNotification, 'You have successfully connected AutoDS to your Dropship account');
          setTimeout(() => window.close(), 300)
        }
      } else if (action.type === 'RECHECK_AUTO_STORES_REQUEST') {
        const autoDS = yield select(AutoDSSelectors.getAutoDS);
        if (response?.data?.email) {
          if (response?.data?.stores?.length) {
            if (autoDS?.importProductId) {
              yield put(Creators.changeVisibleModalAuto({ isVisibleModal: 'import', importProductId: autoDS?.importProductId }));
            } else {
              yield put(Creators.changeVisibleModalAuto({ isVisibleModal: null, importProductId: null }));
            }
          }
          else yield put(Creators.changeVisibleModalAuto({ isVisibleModal: 'import_unavailable', importProductId: null }));
        }
      }
    } else {
      const error = Object.values(response.data).length
        ? Object.values(response.data)[0].detail
        : null;
      yield put(provider.actions.errors({ errors: error }));
      if (action.type === 'CONNECT_AUTO_STORE_REQUEST' || action.type === 'IMPORT_PRODUCT_TO_AUTO_STORE_REQUEST') {
        yield call(provider.openErrorNotification, error);
      }
    }
  } catch (errors) {
    yield put(provider.actions.errors({ errors }));
  }
}

export default function* apiSaga() {
  yield takeLatest(constants.DELETE_AUTO_STORE_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_AUTO_STORE_REQUEST, apiGenerator);
  yield takeLatest(constants.RECHECK_AUTO_STORES_REQUEST, apiGenerator);
  yield takeLatest(constants.CONNECT_AUTO_STORE_REQUEST, apiGenerator);
  yield takeLatest(constants.IMPORT_PRODUCT_TO_AUTO_STORE_REQUEST, apiGenerator);
}
