import React from 'react';
import { useSelector } from 'react-redux';

const Clear = () => {
  const theme = useSelector((state) => state?.nav?.theme);
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.65871 5.00407L9.59548 1.61536C10.016 0.879602 10.9539 0.617545 11.6984 1.02781C12.4485 1.44116 12.7195 2.38076 12.3037 3.12646L10.3939 6.55177M4.95115 6.87985C3.82183 8.20649 2.54115 8.60696 0.832031 8.24488C1.51831 12.7939 9.41352 15.7526 10.1 9.95117M11.0975 10.0609L4.25908 6.29244C4.43211 5.98212 4.60513 5.67179 4.77815 5.36147C5.13558 4.72041 5.94389 4.4887 6.58663 4.84311L11.0902 7.32637C11.7362 7.68259 11.9701 8.49567 11.6109 9.14008C11.4398 9.44706 11.2686 9.75397 11.0975 10.0609Z"
        stroke={theme === 'light' ? '#225AEAD9' : 'rgba(255, 255, 255, 0.26)'}
        strokeOpacity="0.85"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Clear;
