import React from 'react';
import { useSelector } from 'react-redux';

const AdSpotViews = () => {
  const theme = useSelector((state) => state?.nav?.theme);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.6459 7.41278L0.994272 7.04146H0.994272L1.6459 7.41278ZM14.3522 7.41286L15.0038 7.04154L15.0038 7.04154L14.3522 7.41286ZM1.6459 8.58774L0.994271 8.95906H0.994272L1.6459 8.58774ZM14.3522 8.58782L15.0038 8.95914L14.3522 8.58782ZM2.29753 7.78411C3.80644 5.13614 5.95291 3.91698 7.99904 3.91699C10.0452 3.91701 12.1917 5.13621 13.7006 7.78418L15.0038 7.04154C13.2868 4.0284 10.6931 2.41701 7.99905 2.41699C5.30499 2.41697 2.71127 4.02833 0.994272 7.04146L2.29753 7.78411ZM0.994272 8.95906C2.71126 11.9722 5.30498 13.5836 7.99904 13.5836C10.6931 13.5836 13.2868 11.9723 15.0038 8.95914L13.7006 8.21649C12.1917 10.8645 10.0452 12.0836 7.99905 12.0836C5.95292 12.0836 3.80644 10.8644 2.29753 8.21642L0.994272 8.95906ZM0.994272 7.04146C0.655555 7.63587 0.655555 8.36465 0.994271 8.95906L2.29753 8.21642C2.22114 8.08236 2.22114 7.91817 2.29753 7.78411L0.994272 7.04146ZM13.7006 7.78418C13.777 7.91824 13.777 8.08243 13.7006 8.2165L15.0038 8.95914C15.3425 8.36473 15.3425 7.63595 15.0038 7.04154L13.7006 7.78418ZM9.41572 8.00033C9.41572 8.78273 8.78145 9.41699 7.99905 9.41699V10.917C9.60988 10.917 10.9157 9.61116 10.9157 8.00033H9.41572ZM7.99905 9.41699C7.21665 9.41699 6.58238 8.78273 6.58238 8.00033H5.08238C5.08238 9.61116 6.38822 10.917 7.99905 10.917V9.41699ZM6.58238 8.00033C6.58238 7.21792 7.21665 6.58366 7.99905 6.58366V5.08366C6.38822 5.08366 5.08238 6.38949 5.08238 8.00033H6.58238ZM7.99905 6.58366C8.78145 6.58366 9.41572 7.21792 9.41572 8.00033H10.9157C10.9157 6.3895 9.60988 5.08366 7.99905 5.08366V6.58366Z"
        fill={theme === 'light' ? '#707BA0' : '#ffffff42'}
      />
    </svg>
  );
};

export default AdSpotViews;
