import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import OnboardingActions from '../../Redux/OnboardingRedux';
import SettingCreators from '../../Containers/SettingPage/reducer';
import { ArrowSelectDown } from '../../Icon/img';
import { Divider } from 'antd';
import EditAddressModal from './EditAddressModal';
import { openNotificationWithIcon } from '../Notification';
import { DefaultMsg } from '../Notification/notification-message';

const BilAddress = (
  {
    userInfo,
    isMobile,
    onBoarding,
    getCountries,
    changeBillingAddress,
    saveFetching,
    saveResult,
    errors,
    resetErrors,
    resetResult,
  }
) => {

  const {t} = useTranslation();

  const stats = userInfo?.billing_info?.country?.name?.toLowerCase()?.includes('united states') ?
    [
      {
        label: 'First name',
        key: ['first_name'],
      },
      {
        label: 'Last name',
        key: ['last_name'],
      },
      {
        label: 'Country',
        key: ['country', 'name'],
      },
      {
        label: 'City',
        key: ['city'],
      },
      {
        label: 'State',
        key: ['state'],
      },
      {
        label: 'Postal code',
        key: ['postal_code'],
      },
      {
        label: 'Street and house number',
        key: ['address'],
      },
    ]
      :
    [
    {
      label: 'First name',
      key: ['first_name'],
    },
    {
      label: 'Last name',
      key: ['last_name'],
    },
    {
      label: 'Country',
      key: ['country', 'name'],
    },
    {
      label: 'City',
      key: ['city'],
    },
    {
      label: 'Postal code',
      key: ['postal_code'],
    },
    {
      label: 'Street and house number',
      key: ['address'],
    },
  ];

  const [modal, setModal] = useState(null);

  useEffect(() => {
    if (errors?.change_billing_address) {
      openNotificationWithIcon({
        key: `open${Date.now()}`,
        style: { width: '400px' },
        className: 'notification notification_delete',
        message: (
          <DefaultMsg text={'Something went wrong, kindly try again.'}
                      icon="notification_warning"
                      title={'Something went wrong'}
          />
        ),
      });
      resetErrors();
    }
    if (saveResult?.change_billing_address) {
      openNotificationWithIcon({
        key: `open${Date.now()}`,
        style: { width: '400px' },
        className: 'notification notification_delete',
        message: (
          <DefaultMsg text={null}
                      icon="notification_success"
                      title={'Billing address was successfully added'}
          />
        ),
      });
      resetResult();
    }
    if(modal) setModal(null);
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult, errors]);

  return (
    <div className="billing-subpage-block">
      <div className="billing-subpage-block-title-wrapper">
        <span className='billing-subpage-block-title'>
          {t('Billing address')}
        </span>
        <span className='billing-subpage-block-link'
              onClick={() => setModal('edit_address')}
        >
          {
            isMobile ?
              t(userInfo?.billing_info ?
                'Edit address'
                :
                'Add address'
              )
              :
              t(userInfo?.billing_info ?
                'Edit billing address'
                :
                'Add billing address'
              )
          }
          <ArrowSelectDown />
        </span>
      </div>
      <Divider style={{ margin: '16px 0' }} />
      <div className="billing-subpage-block-address-wrapper">
        {
          stats.map((el) => (
            <div className="item"
                 key={el?.label}
            >
              <span className="title">
                {t(el?.label)}
              </span>
              <span className="value">
              {
                el?.key?.length > 1 ?
                  userInfo?.billing_info?.[el?.key[0]]?.[el?.key?.[1]] || '-'
                  :
                  userInfo?.billing_info?.[el?.key[0]] || '-'
              }
              </span>
            </div>
          ))
        }
      </div>
      <EditAddressModal setModal={setModal}
                        visible={modal === 'edit_address'}
                        isMobile={isMobile}
                        isAdd={!Boolean(userInfo?.billing_info)}
                        loading={saveFetching}
                        onBoarding={onBoarding}
                        getCountries={getCountries}
                        billingInfo={userInfo?.billing_info}
                        changeBillingAddress={changeBillingAddress}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  onBoarding: state?.onBoarding,
  saveFetching: state.setting.saveFetching,
  saveResult: state.setting.saveResult,
  errors: state.setting.errors,
});

const mapDispatchToProps = (dispatch) => ({
  getCountries: () => dispatch(OnboardingActions.countriesRequest()),
  changeBillingAddress: (data) => dispatch(SettingCreators.changeBillingAddressRequest(data)),
  resetErrors: () => dispatch(SettingCreators.settingsErrorsReset()),
  resetResult: () => dispatch(SettingCreators.settingsSaveResultReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BilAddress);
