import React from 'react';
import { Col, Divider, Row } from 'antd';
import ProductDetailsCard from '../../ProductDetailsPage/components/ProductDetailsCard';

const FiltersBlockSkeleton = ({isMobile=false}) => {
  const renderHeaderSkeleton = (
    isMobile ?
      null
      :
    <>
      <div className="link-block skeleton database">
        <span className='link skeleton database' style={{ width: 181, height: 28 }}/>
      </div>

      <div className="link-block skeleton database">
        <span className='link skeleton database'/>
        <span className='link skeleton database'/>
      </div>
    </>
  );

  return (
    <>
      <ProductDetailsCard header={renderHeaderSkeleton} withLine={!isMobile}>
        <div className={'skeleton-body'} style={{ padding: isMobile && "0px 16px 0 16px" }}>
          <Row gutter={[48, 0]}>

            <Col xs={24} md={12} className="block-with-label">
              <div className="block-with-label_label header-skeleton-section" style={{ marginBottom: 24 }}>
                <span className="link skeleton" />
                <span className="link skeleton" />
              </div>
              {[
                { first: 32, second: 34 },
                {first: 36, second: 104},
                {first: 57, second: 120},
                {first: 99, second: 105},
                {first: 105, second: 104},
                {first: 54, second: 54},
              ].map(el => (
                <div key={el.first} className="min-max-input-wrapper skeleton">
                  <span className="link skeleton"/>
                  <div className="link-skeleton-sub-wrapper">
                    <span className="link skeleton" style={{width: el.first}}/>
                    <span className="link skeleton" style={{width: 24}}/>
                  </div>
                  <div className="link-skeleton-sub-wrapper">
                    <span className="link skeleton" style={{width: el.second}}/>
                    <span className="link skeleton" style={{width: 28}}/>
                  </div>
                </div>
              ))}
              <Divider className='min-max-skeleton-divider' type='vertical' />
            </Col>

            {
              isMobile ?
                null
                :
                <Col xs={24} md={12} className="block-with-label">
                  <div className="block-with-label_label"
                       style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 24}}
                  >
                <span className="link skeleton"
                      style={{width: 254, height: 24, display: 'block'}}
                />
                    <span className="link skeleton"
                          style={{width: 63, height: 20, display: 'block'}}
                    />
                  </div>
                  {[
                    {first: 73, second: 82},
                    {first: 75, second: 122},
                    {first: 155, second: 137},
                    {first: 175, second: 34},
                    {first: 170, second: 107},
                    {first: 106, second: 169},
                    {first: 103, second: 73},
                    {first: 133, second: 60},
                    {first: 138, second: 182},
                    {first: 131, second: 182},
                    {first: 128, second: 0},
                  ]
                    .map((el, index) => (
                      <div key={el.first} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 12, maxWidth: 600}}>
                        <div className="checkbox-skeleton-wrapper" style={{width: '48%'}}>
                <span className="link skeleton"
                      style={{width: 20, height: 20}}
                />
                          <span className="link skeleton"
                                style={{width: el.first, height: 20}}
                          />
                        </div>
                        <div className="checkbox-skeleton-wrapper" style={{width: '42%'}}>
                          {el.second !== 0 && <span className="link skeleton"
                                                    style={{width: 20, height: 20}}
                          />}
                          <span className="link skeleton"
                                style={{width: el.second, height: 20}}
                          />
                        </div>
                      </div>
                    ))}
                </Col>
            }

            <div className="advanced-filters-toggle-wrapper" style={{marginTop: 8, marginBottom: 16}}>
              <div className="advanced-filters-toggle-divider"/>
              <span className='link skeleton competitor-research' style={{width: 129, height: isMobile ? 16 : 20}}/>
              <div className="advanced-filters-toggle-divider"/>
            </div>

            <Divider style={{ margin: isMobile ? '0 23px' : '8px 23px 24px', minWidth: 'auto' }}/>

            <Col xs={24} md={24}>
              <div className="switch-wrapper">
                <div className="switch-options-wrapper">
                  {[145, 210, 180]
                    .map(el => (
                      <div key={el * Math.random() * (2 - 1) + 1} style={{display: 'flex', alignItems: 'center', marginRight: 16}}>
                        <span className="link skeleton" style={{width: 36, height: 24, marginRight: 5}} />
                        <span className="link skeleton" style={{width: el, height: 20}} />
                      </div>
                    ))}
                </div>
                <div className="switch-button-wrapper skeleton">
                  <span className="link skeleton" style={{width: isMobile ? "50%" : 116, height: 40}} />
                  <span className="link skeleton" style={{width: isMobile ? "50%" : 87, height: 40}} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </ProductDetailsCard>
      <div className={'fadspot-page-quick-search-wrapper'}>
        {isMobile ? null : (
          <span className={'fadspot-page-quick-search-title'}>
            <span className={'link skeleton'} style={{ width: 92, height: 20, display: "block" }} />
          </span>
        )}
        <div className="fadspot-page-quick-search-items" style={{ flexDirection: "row" }}>
          {
            [1, 2, 3].map(el => (
              <span key={el} className={'link skeleton'}
                    style={{ width: isMobile ? '33.3%' : 101 + el * 10, height: 32 }} />
            ))
          }
        </div>
      </div>
    </>
  )
}

export default FiltersBlockSkeleton
