import React from 'react';
import { Warning } from "../../../Icon/img";
import './ConfirmEmail.less';

const ConfirmEmail = ({ isMobile }) => {
  return (
    <div className={'confirm-email-banner'}>
      <div className="icon-container">
        <Warning color={'#F6B60E'} />
      </div>
      <div className="text-container">
        <div>
          <span>Please</span>
          <a href="/setting/account">verify your email address</a>
        </div>
        <div>
          <span>today to secure your account.</span>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
