import React from 'react';
import { useTranslation } from 'react-i18next';
import InputComponent from '../../../Components/Input';
import './InputFilter.less';

const InputFilter = (
  {
    placeholder,
    label,
    id,
    setInputFilters,
    inputFilters,
  }
) => {

  const {t} = useTranslation();

  const handleChange = (e, type) => {
    setInputFilters(prev => ({ ...prev, [id]: {...prev?.[id], [type]: e.target.value ? +e.target.value : '' } }));
  }

  return (
    <div className="input-filter">
      <span className="input-filter-label">
        {t(label)}
      </span>
      <div className="input-filter-input-wrapper">
        <InputComponent suffix={'MIN'}
                        className={'filter-input'}
                        placeholder={t(placeholder)}
                        onChange={(e) => handleChange(e, 'min')}
                        type="number"
                        value={inputFilters?.[id]?.min}
        />
        <InputComponent suffix={'MAX'}
                        className={'filter-input'}
                        placeholder={t(placeholder)}
                        onChange={(e) => handleChange(e, 'max')}
                        type="number"
                        value={inputFilters?.[id]?.max}
        />
      </div>
    </div>
  );
};

export default InputFilter;
