import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import acc from 'accounting';
import cls from 'classname';
import { Radio, Spin } from 'antd';
import Icon from '../../Icon';
import Images from '../../Images';
import utils, { categoryClassName } from '../../Utils/utils';
import ChargebeeActions from './reducer'
import ButtonComponent from "../Button";
import './styles.less';

const PlanCardUpcoming = (
  {
    category,
    plan,
    onDeletePlan,
    onSwitchPlan,
    switchPlan,
    typeBtn,
    isVisibleBtn,
    isDisabled,
    onDeleteSubscription,
    customClass,
    discount,
    isCurrentPlan,
    priceType,
    upgradeConfirm,
    paymentType,
    switchType
  }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const spinner = useSelector(state => state.chargebee.loading);
  const diffToPay = useSelector(state => state?.chargebee?.result?.amount_due) || 0;
  const subscription = useSelector(store => store?.auth?.userInfo?.subscriptions);
  const isSelect = !subscription.length

  const discountId = discount?.id || null

  useEffect(() => {
    if (upgradeConfirm && paymentType === 'now' && !isSelect) {
      let categoryIdWithPeriod = category?.id
      let data = {
        plan: { [categoryIdWithPeriod]: plan?.prices?.[priceType] },
        'discount_code': discount?.id || null,
        force: switchType === 'upgrade' && paymentType === 'now',
      };
      dispatch(ChargebeeActions.getChargeRequest(data))
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [discountId])

  let btn = (
    <ButtonComponent className={`only-icon ${categoryClassName[category?.name]}`}
      onClick={onDeleteSubscription ? onDeleteSubscription : () => onDeletePlan(category.id)}
    >
      <Icon role={'icon'} type={'notification_close'} width={24} height={24} />
    </ButtonComponent>);

  switch (typeBtn) {
    case 'radio': {
      btn = (
        <Radio className={'custom-radio'}
          disabled={isDisabled}
          checked={isDisabled || switchPlan?.id === plan?.id}
          onClick={() => onSwitchPlan(category.id, plan)}
        />);
      break;
    }
    default: {
      break;
    }
  }

  let price = plan?.price * utils.multiplyPriceType?.[plan?.price_type?.id];
  let newPrice = plan?.prices?.[priceType]?.price * utils.multiplyPriceType[priceType];
  let billing = isCurrentPlan ? plan?.price_type?.value : t(utils.toUpperLatter(priceType));

  let discountCash = 0

  if (discount) {
    discountCash = discount.type === 'value' ? discount.value : (newPrice / 100) * discount.value
  }

  return (
    <Spin spinning={spinner}>
      <div className={cls('plan-card-upcoming', customClass)}>

        <div className="plan-info-wrapper">
          <div className="info-wrp">
            <span className="info-title">{plan?.plan?.name || plan?.name}</span>
          </div>
        </div>
        <div className='plan-info-wrapper_only-text'>
          <div className="info-wrp">
            <span className="info-text">{t(upgradeConfirm && paymentType === 'now' ?
              'Billing now'
              :
              'Billing')}</span>
            <span className="info-title">{billing}</span>
          </div>

          <div className="info-wrp">
            <span className="info-text">{upgradeConfirm && paymentType === 'now' && !isSelect ?
              `$${acc.formatNumber(diffToPay / 100, 2, ',', ',')}`
              :
              discount ? `$${acc.formatNumber(newPrice - discountCash, 2, ',', ',')}` : t('Amount')}</span>
            <span className="info-title">
              {`$${acc.formatNumber(isCurrentPlan ? price : newPrice, 0, ',', ',')}`}
            </span>
          </div>
        </div>

        {isVisibleBtn ? btn : null}

      </div>
    </Spin>
  );
};

export default PlanCardUpcoming;
