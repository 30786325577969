import React, { useState } from 'react';
import cls from 'classname';
import { Divider } from 'antd';
import Icon from '../../../Icon';
import ArrowSelectDown from '../../../Icon/img/ArrowSelectDown';
import './CascadingModal.less';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import ButtonComponent from '../../../Components/Button';

const options = [
  {
    title: 'Reactions',
    key: 'reactions',
    items: ['like', 'love', 'care', 'haha', 'wow', 'sad', 'angry'],
  },
  {
    title: 'Most recent',
    key: 'most_recent',
  },
  {
    title: 'Oldest',
    key: 'oldest',
  },
  {
    title: 'Comments',
    key: 'comments',
  },
  {
    title: 'Shares',
    key: 'shares',
  },
  {
    title: 'Views',
    key: 'views',
  },
];

const CascadingModal = ({ value, setValue, setVisible }) => {
  const { t } = useTranslation();
  const [innerVisible, setInnerVisible] = useState(false);
  const [selectedSort, setSelectedSort] = useState(value || 'most_recent');

  return (
    <div className="cascading-dropdown-modal">
      <div className="cascading-dropdown-modal-header">
        <span className="cascading-dropdown-modal-header-sup">
          {t('Sorting')}
        </span>
        <span className="cascading-dropdown-modal-header-sub">
          {t('Select an option to sort your ads list.')}
        </span>
      </div>
      <Divider />
      <div className="cascading-dropdown-modal-wrapper">
        <ul className="cascading-dropdown-modal-menu">
          {options.map((el) =>
            el?.items ? (
              <div
                key={el?.key}
                className={cls('cascading-dropdown-modal-menu-item-advance', {
                  'ascading-dropdown-modal-menu-item-advance-active':
                    el?.items?.some((item) => item === value),
                })}
              >
                <span
                  onClick={() => {
                    setInnerVisible(true);
                  }}
                >
                  <span>{t(el?.title)}</span>
                  <ArrowSelectDown />
                </span>
                <Modal
                  onCancel={() => setInnerVisible(false)}
                  title={null}
                  width={300}
                  className="change-modal custom-modal adspot-sort-order"
                  getContainer={() => document.getElementById('global-wrap')}
                  open={innerVisible}
                  destroyOnClose
                  footer={null}
                  closeIcon={
                    <Icon
                      role="icon"
                      type="close_modal"
                      color="#707BA0"
                      opacity={1}
                    />
                  }
                >
                  <div className="cascading-modal-menu-wrapper-inner">
                    <span
                      className="cascading-modal-menu-inner-header"
                      onClick={() => setInnerVisible(false)}
                    >
                      <ArrowSelectDown />
                      {el?.title}
                    </span>
                    <ul className="cascading-modal-menu-inner">
                      {el?.items.map((elem) => (
                        <li
                          key={elem}
                          className={cls('cascading-modal-menu-item-inner', {
                            'cascading-modal-menu-item-inner-active':
                              elem === selectedSort,
                          })}
                          onClick={() => {
                            setTimeout(() => setSelectedSort(elem), 100);
                          }}
                        >
                          <span>
                            <Icon role="icon" type={`ad_spot_${elem}`} />
                            <span>{t(elem)}</span>
                          </span>
                          {elem === selectedSort && (
                            <Icon role={'icon'} type={'check_mark'} />
                          )}
                        </li>
                      ))}
                    </ul>
                    <Divider type="horizontal" />
                    <ButtonComponent
                      className="cascading-modal-button"
                      onClick={() => {
                        setValue(selectedSort);
                        setVisible(false);
                      }}
                    >
                      Confirm
                    </ButtonComponent>
                  </div>
                </Modal>
              </div>
            ) : (
              <li
                key={el?.key}
                className={cls('cascading-dropdown-modal-menu-item', {
                  'cascading-dropdown-modal-menu-item-active':
                    el?.key === value,
                })}
                onClick={() => {
                  setTimeout(() => setSelectedSort(el?.key), 300);
                }}
              >
                <span>{t(el?.title)}</span>
                {el?.key === selectedSort && (
                  <Icon role={'icon'} type={'check_mark'} />
                )}
              </li>
            ),
          )}
        </ul>
      </div>
      <Divider type="horizontal" />
      <ButtonComponent
        className="cascading-modal-button"
        onClick={() => {
          setValue(selectedSort);
          setVisible(false);
        }}
      >
        Confirm
      </ButtonComponent>
    </div>
  );
};

export default CascadingModal;
