import React, {useEffect, useRef} from 'react';
import cls from "classname";
import {Table} from "antd";
import StoreProductsModalTableSkeleton from "./StoreProductsModalTableSkeleton";
import {getColumns} from "./StoreProductsModalTableColumns";
import {useTranslation} from "react-i18next";

const StoreProductsModalTable = (
  {
    fetching,
    data,
    sortOrder,
    handleSort,
    handleChangePageNumber,
    isMobile=false,
  }
) => {

  const {t} = useTranslation();
  const tableRef = useRef(null);

  function scrollListener(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;

    if (currentTopScroll >= maxTopScroll - 45 && !fetching && (data?.count ? data?.count > data?.results?.length : true)) {
      handleChangePageNumber(prev => prev + 1);
      event.target.removeEventListener('scroll', scrollListener);
    }
  }

  useEffect(() => {
    if (!fetching) {
      const tableContent = tableRef.current?.parentNode;
      if (tableContent && data?.count ? data?.count > data?.results?.length : true) {
        tableContent.addEventListener('scroll', scrollListener);
      }
      return () => {
        if (tableContent) tableContent.removeEventListener('scroll', scrollListener);
      };
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [fetching]);

  return (
    <>
      {fetching && (data?.results?.length === 0 || !Object.keys(data).length || !data?.results) ? <StoreProductsModalTableSkeleton isMobile={isMobile} /> : null}
      <div className={cls('products-database-table-wrapper sales-tracker-stores-products-modal-table-wrapper', {
        'product-database-table-wrapper-hidden': (fetching && (data?.results?.length === 0 || !data?.results || !Object.keys(data).length)),
      })}>
          <div id={'product-database-table-body'} className="product-database-table-body sales-tracker-stores-table-body adspot-products-table-body">
          <Table
          components={{
          // eslint-disable-next-line react/prop-types
          table: ({className, children, ...other}) => (
          <table
        {...other}
          ref={tableRef}
          className={cls(className, 'other-wrapper')}
          >
        {children}
          </table>
          ),
        }}
          className="list-table"
          loading={fetching && (data?.count < 50 ? data?.results?.length > 0 : data?.results?.length >= 50)}
          rowClassName={'product-database-table-row'}
          rowKey={(record) => record.id}
          dataSource={data?.results?.length ? [...data?.results] : []}
          columns={getColumns({
          t,
          sortOrder,
        })}
          pagination={false}
          locale={{ emptyText: '-' }}
          scroll={{
          y: '65vh',
        }}
          sticky={true}
          sortDirections={['descend', 'ascend', null]}
          onChange={(pagination, filters, sorter) => {
          handleSort(sorter);
        }}
          />
          </div>
      </div>
    </>
  );
};

export default StoreProductsModalTable;
