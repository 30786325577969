import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from "react-router-dom";
import Icon from "../../../Icon";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import dayjs from "dayjs";
import Creators from '../reducer';
import acc from "accounting";
import cls from "classname";
import { dateOptions } from "../../../Utils/utils";
import MainBlockAdSpot from "../components/MainBlockAdSpot";
import AdItem from "../components/AdItem";
import Chart from "../../../Components/Charts/Chart";
import ChartBlock from "../../SalesTrackerPage/components/ChartBlock";
import { Divider, Modal, Spin } from "antd";
import Pagination from "../components/Pagination";
import CardInfoSkeleton from "../components/CardInfoSkeleton";
import MainBlockSkeleton from "../../SalesTrackerPage/components/MainBlockSkeleton";
import ChartInfoSkeleton from "../components/ChartInfoSkeleton";
import ButtonComponent from "../../../Components/Button";
import StoreAppsModal from "../../SalesTrackerPage/components/StoreAppsModal";
import StoreProductsModal from "../../SalesTrackerPage/components/StoreProductsModal";
import { OverviewAdvertiserWrapper } from "../components/OverviewAdvertiser";
import BlockStatistics from "../components/BlockStatistics";
import BlockStatisticsSkeleton from "../components/BlockStatisticsSkeleton";
import './AdItemOverview.less';

import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const SLIDE_PAGE_SIZE = 3;

const AdItemOverview = (
  {
    isMobile,
    overviewInfo,
    getOverviewMainInfo,
    getOverviewCardInfo,
    getOverviewChartInfo,
    getOverviewReport,
    getOverviewSimilar,
    innerPagesInfo,
    setInnerPagesDateFilters,
    setInnerPagesDateCheckedList,
    getStoreProductsModal,
    getStoreProductsModalNext,
    resetStoreProductsModal,
  }) => {

  const { t } = useTranslation();
  const carouselRef = useRef(null);
  const { search } = useLocation();
  let params = new URLSearchParams(search);
  const tableRef = useRef(null);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [sortOrder, setSortOrder] = useState('most_recent');
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [firstLoading, setFirstLoading] = useState(true);
  const [sameLandingPage, setSameLandingPage] = useState(false);
  const [historicalDataView, setHistoricalDataView] = useState(true);
  const [visible, setVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [isLineChart, setIsLineChart] = useState(true);

  const minDate = innerPagesInfo?.dateFilters?.report_period?.min;
  const maxDate = innerPagesInfo?.dateFilters?.report_period?.max;

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if ((currentTopScroll >= maxTopScroll * 0.95) && !overviewInfo?.similarInfo?.similarInfoLoading && overviewInfo?.similarInfo?.results?.length < +overviewInfo?.similarInfo?.count) {
      setPageNumber(prev => prev + 1);
      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  useEffect(() => {
    if (firstLoading) setTimeout(() => setFirstLoading(false), 1000);
    setSameLandingPage(false);
    setHistoricalDataView(true);
    getOverviewMainInfo(
      {
        ad_id: params.get('ad_id'),
        domain: params.get('domain'),
        ...(params.get('internal_shop_id') && { internal_shop_id: params.get('internal_shop_id') })
      });
    getOverviewCardInfo(
      {
        ad_id: params.get('ad_id'),
      });
    let page = document.querySelector('.os-viewport-native-scrollbars-invisible');
    page.scrollTo({ top: 0, behavior: "instant" });
  }, [search]);

  useEffect(() => {
    let min = dayjs().diff(dayjs(overviewInfo?.cardInfo?.min_calendar_date), 'days') < 30 ?
      dayjs(overviewInfo?.cardInfo?.min_calendar_date).format('YYYY-MM-DD')
      :
      dayjs().subtract(30, 'days').format('YYYY-MM-DD');
    let max = dayjs().format('YYYY-MM-DD');
    setInnerPagesDateFilters({ report_period: { min, max } });
    setInnerPagesDateCheckedList([{ id: 4, name: 'Last 30 days', value: 'Last 30 days', days: 30 }]);
  }, [overviewInfo?.cardInfo?.min_calendar_date])

  useEffect(() => {
    if (minDate && maxDate && overviewInfo?.cardInfo?.min_calendar_date) {
      getOverviewChartInfo(
        {
          ad_id: params.get('ad_id'),
          date_range: {
            min: minDate,
            max: maxDate
          },
          same_landing_pages: sameLandingPage,
          historical_data_view: historicalDataView
        });
    }
  }, [minDate, maxDate, sameLandingPage, historicalDataView, search]);

  useEffect(() => {
    getOverviewSimilar(
      {
        ad_id: params.get('ad_id'),
        page_number: pageNumber,
        page_size: pageSize,
        sort: sortOrder
      });
  }, [pageNumber, pageSize, sortOrder, search])

  useEffect(() => {
    const tableContent = tableRef.current;
    if (tableContent && overviewInfo?.similarInfo?.results?.length < +overviewInfo?.similarInfo?.count) {
      tableContent.addEventListener('scroll', onScrollHandlerFunc);
    }
    return () => {
      if (tableContent && !firstLoading) {
        tableContent.removeEventListener('scroll', onScrollHandlerFunc);
      }
    }
  }, [overviewInfo?.similarInfo?.results?.length, overviewInfo?.similarInfo?.count, tableRef?.current]);

  const modalBlocks = {
    storeProducts: <StoreProductsModal getStoreProductsModal={getStoreProductsModal}
                                       getStoreProductsModalNext={getStoreProductsModalNext}
                                       storeProductsModalTableDataLoading={overviewInfo?.storeProducts?.storeProductsInfoLoading}
                                       storeProductsModalTableData={overviewInfo?.storeProducts}
                                       storeId={params.get('internal_shop_id')}
                                       resetStoreProductsModal={resetStoreProductsModal}
                                       minDate={minDate}
                                       maxDate={maxDate}
                                       isMobile={isMobile}
    />,
    storeApps: <StoreAppsModal data={overviewInfo?.mainInfo?.store_apps} />,
  };

  const modalBlocksWidth = {
    storeProducts: 828,
    storeApps: 450
  };

  return (
    <div className='fadspot-page-inner-content fadspot-page-overview'>
      {
        overviewInfo?.mainInfo?.mainInfoLoading || firstLoading ?
          <BlockStatisticsSkeleton isMobile={isMobile} />
          :
          <BlockStatistics
            skeleton={overviewInfo?.mainInfo?.mainInfoLoading || firstLoading}
            isShopify={params.get('internal_shop_id')}
            data={overviewInfo?.mainInfo}
            setVisible={setVisible}
            setModal={setModal}
            isMobile={isMobile}
          />
      }
      {
        overviewInfo?.cardInfo?.cardInfoLoading || firstLoading || overviewInfo?.mainInfo?.mainInfoLoading ?
          <CardInfoSkeleton isMobile={isMobile}/>
          :
          <div className="fadspot-page-overview-post-wrapper">
            <div className="fadspot-page-overview-post">
              <div className="fadspot-page-overview-post-title">{t('Ad overview')}</div>
              <AdItem data={overviewInfo?.cardInfo}
                isInnerPage={true}
                isAlive={overviewInfo?.cardInfo?.status}
                withCreated={true}
              />
            </div>
            <div className="fadspot-page-overview-info-wrapper">
              {/*<div className="fadspot-page-overview-status fadspot-page-overview-info-block">*/}
              {/*  <h3>*/}
              {/*    {t('Ad Status')}*/}
              {/*  </h3>*/}
              {/*  <div>*/}
              {/*    <div className={cls("status circle", {*/}
              {/*      red: !overviewInfo?.cardInfo?.status*/}
              {/*    })}/>*/}
              {/*    <span>*/}
              {/*      {t(overviewInfo?.cardInfo?.status ? 'Active' : 'Inactive')}*/}
              {/*    </span>*/}
              {/*    <div className={cls("circle")}/>*/}
              {/*    {*/}
              {/*      overviewInfo?.cardInfo?.created ?*/}
              {/*      <span>*/}
              {/*        {*/}
              {/*          dayjs(overviewInfo?.cardInfo?.last_seen_date).startOf('day').diff(dayjs(overviewInfo?.cardInfo?.created).startOf('day'), 'days')}*/}
              {/*        <span> </span> {t('Days')*/}
              {/*      }*/}
              {/*      </span>*/}
              {/*      :*/}
              {/*      <span>*/}
              {/*        No Data*/}
              {/*      </span>*/}
              {/*    }*/}
              {/*  </div>*/}
              {/*</div>*/}

              {
                !Boolean(overviewInfo?.mainInfo?.related_advertisers) || overviewInfo?.mainInfo?.related_advertisers?.length <= 1 ?
                  null
                  :
                  <div className="fadspot-page-overview-advertisers fadspot-page-overview-info-block">
                    <div className={'fadspot-page-overview-advertisers-title-wrapper'}>
                      <div>
                        <h3>
                          {
                            overviewInfo?.mainInfo?.related_advertisers?.length === 1 ?
                              t('Same domain advertiser')
                              :
                              t('Same domain advertisers')
                          }
                          <Icon
                            type={'attention_outline'}
                            role="button"
                            width={16}
                            height={16}
                            tooltipProps={{
                              trigger: 'hover',
                              placement: isMobile ? 'bottom' : 'right',
                              overlayClassName: 'subscription-tooltip scheduled',
                              getPopupContainer: (trigger) => trigger.parentNode,
                            }}
                            titleText={t('Advertiser pages which have posts with links leading to the same domain')}
                          />
                        </h3>
                        <span className={'fadspot-page-overview-advertisers-title-value'}>
                          {
                            overviewInfo?.mainInfo?.related_advertisers?.length
                          }
                        </span>
                      </div>
                      { isMobile ? null : (
                        <div className={'fadspot-page-overview-advertisers-title-buttons'}>
                          <ButtonComponent className={'fadspot-page-overview-advertisers-title-button-prev'}
                            text={''}
                            disabled={currentSlide === 0}
                            onClick={() => carouselRef.current.prev()}
                            icon={<Icon width={10} height={10} type="arrow_forward_university"
                              role="icon" />}
                          />
                          <ButtonComponent className={'fadspot-page-overview-advertisers-title-button-next'}
                            text={''}
                            disabled={currentSlide === Math.ceil(overviewInfo?.mainInfo?.related_advertisers?.length / SLIDE_PAGE_SIZE) - 1 || overviewInfo?.mainInfo?.related_advertisers?.length < SLIDE_PAGE_SIZE}
                            onClick={() => carouselRef.current.next()}
                            icon={<Icon width={10} height={10} type="arrow_forward_university"
                              role="icon" />}
                          />
                        </div>
                      )}
                    </div>

                    <OverviewAdvertiserWrapper data={overviewInfo?.mainInfo?.related_advertisers || []}
                      pageSize={SLIDE_PAGE_SIZE}
                      carouselRef={carouselRef}
                      afterChange={setCurrentSlide}
                    />
                    { isMobile ? null : <Divider/> }
                  </div>
              }
              <div className="fadspot-page-overview-timeline fadspot-page-overview-info-block">
                <h3>
                  {t('Ad timeline')}
                </h3>
                <div>
                  <div className="item-wrapper">
                    <div className="item-image">
                      <Icon role={'icon'} type={'ad_spot_calendar'} />
                    </div>
                    <div className={'item-info'}>
                      <p>
                        {t('Creation date')}
                        <Icon
                          type={'attention_outline'}
                          role="button"
                          width={16}
                          height={16}
                          tooltipProps={{
                            trigger: 'hover',
                            placement: isMobile ? 'bottom' : 'right',
                            overlayClassName: 'subscription-tooltip scheduled',
                            getPopupContainer: (trigger) => trigger.parentNode,
                          }}
                          titleText={t('The date the ad was created')}
                        />
                      </p>
                      {
                        overviewInfo?.cardInfo?.created ?
                          <span>
                            {dayjs(overviewInfo?.cardInfo?.created).format('MMM DD, YYYY')} ({dayjs().utc().startOf('day').diff(dayjs(overviewInfo?.cardInfo?.created).startOf('day'), 'days')} {t(dayjs().utc().startOf('day').diff(dayjs(overviewInfo?.cardInfo?.created).startOf('day'), 'days') === 1 ? 'day)' : 'days ago)')}
                          </span>
                          :
                          <span>
                            {t('No data')}
                          </span>
                      }
                    </div>
                  </div>
                  {
                    overviewInfo?.cardInfo?.created ?
                      <div className="badge">
                        {dayjs(overviewInfo?.cardInfo?.first_seen_date).startOf('day').diff(dayjs(overviewInfo?.cardInfo?.created).startOf('day'), 'days')}
                        <span> </span> {t(dayjs(overviewInfo?.cardInfo?.first_seen_date).startOf('day').diff(dayjs(overviewInfo?.cardInfo?.created).startOf('day'), 'days') === 1 ? 'Day' : 'Days')}
                      </div>
                      :
                      <div className="badge">
                        {t('No data')}
                      </div>
                  }
                  <div className="item-wrapper">
                    <div className="item-image">
                      <Icon role={'icon'} type={'ad_spot_eye'} />
                    </div>
                    <div className={'item-info'}>
                      <p>
                        {t('Discovered')}
                        <Icon
                          type={'attention_outline'}
                          role="button"
                          width={16}
                          height={16}
                          tooltipProps={{
                            trigger: 'hover',
                            placement: isMobile ? 'bottom' : 'right',
                            overlayClassName: 'subscription-tooltip scheduled',
                            getPopupContainer: (trigger) => trigger.parentNode,
                          }}
                          titleText={t('The date the ad was first added to be tracked')}
                        />
                      </p>
                      <span>
                        {dayjs(overviewInfo?.cardInfo?.first_seen_date).format('MMM DD, YYYY')}
                      </span>
                    </div>
                  </div>
                  <div className="badge">
                    {dayjs(overviewInfo?.cardInfo?.last_seen_date).startOf('day').diff(dayjs(overviewInfo?.cardInfo?.first_seen_date).startOf('day'), 'days')}
                    <span> </span> {t(dayjs(overviewInfo?.cardInfo?.last_seen_date).startOf('day').diff(dayjs(overviewInfo?.cardInfo?.first_seen_date).startOf('day'), 'days') === 1 ? t('day') : t('days'))}
                  </div>
                  <div className="item-wrapper">
                    <div className="item-image item-image-last">
                      <Icon role={'icon'} type={'ad_spot_clock'} />
                    </div>
                    <div className={'item-info'}>
                      <p>
                        {/*{t(overviewInfo?.cardInfo?.status ? 'Active' : 'Inactive')}*/}
                        {t('Last seen active')}
                        <Icon
                          type={'attention_outline'}
                          role="button"
                          width={16}
                          height={16}
                          tooltipProps={{
                            trigger: 'hover',
                            placement: isMobile ? 'bottom' : 'right',
                            overlayClassName: 'subscription-tooltip scheduled',
                            getPopupContainer: (trigger) => trigger.parentNode,
                          }}
                          // titleText={t(overviewInfo?.cardInfo?.status ? 'The date the ad status was last checked' : 'The date the ad stopped running')}
                          titleText={t('The date the ad status was last checked')}
                        />
                      </p>
                      <span>
                        {dayjs(overviewInfo?.cardInfo?.last_seen_date).format('MMM DD, YYYY')}
                      </span>
                    </div>
                  </div>
                </div>
                { isMobile ? null : <Divider/> }
                </div>
              { isMobile ? null : 
                <div className={
                  cls("fadspot-page-overview-landing-same fadspot-page-overview-info-block", {
                    disabled: !overviewInfo?.similarInfo?.count
                  })
                }>
                  <h3>
                    {
                      overviewInfo?.similarInfo?.count === 1 ?
                        t('Ad sharing same URL')
                        :
                        t('Ads sharing same URL')
                    }
                  </h3>
                  <span onClick={() => {
                    if (overviewInfo?.similarInfo?.count > 0) {
                      document.getElementById('fadspot-page-items-wrapper-overview').scrollIntoView({
                        behavior: 'smooth',
                      })
                    }
                  }}
                  >
                    {
                      acc.formatNumber(overviewInfo?.similarInfo?.count, 0, ',')
                    }
                  </span>
                </div>
              }
            </div>
          </div>
      }

      {
        overviewInfo?.mainInfo?.mainInfoLoading || firstLoading ?
          <MainBlockSkeleton isMobile={isMobile} />
          :
          <MainBlockAdSpot
            data={{
              id: overviewInfo?.cardInfo?.id,
              ad_headline: overviewInfo?.cardInfo?.ad_headline,
              timeZone: overviewInfo?.mainInfo?.store_timezone,
              timeInfo: overviewInfo?.cardInfo?.min_calendar_date,
              dates: { min: minDate, max: maxDate },
            }}
            downloadReport={getOverviewReport}
            dateOptions={dateOptions}
            fileLoading={overviewInfo?.reportLoading}
            isMobile={isMobile}
          />
      }

      {
        overviewInfo?.chartInfo?.chartInfoLoading || firstLoading ?
          <ChartInfoSkeleton />
          :
          <ChartBlock type={'ad_spot'}
            subTitle={`${dayjs(minDate).format('MMM DD, YYYY')} - ${dayjs(maxDate).format('MMM DD, YYYY')}`}
            totalCount={overviewInfo?.chartInfo?.engagements_stats?.total_engagements}
            link={null}
            sameLandingPageToggleText={overviewInfo?.similarInfo?.count > 0 ? t('Same landing page ads') : null}
            sameLandingPage={sameLandingPage}
            setSameLandingPage={setSameLandingPage}
            historicalDataViewToggleText={[t('Daily'), t('Cumulative')]}
            historicalDataView={historicalDataView}
            setHistoricalDataView={setHistoricalDataView}
            isMobile={isMobile}
            isLineChart={isLineChart}
            setIsLineChart={setIsLineChart}
          >
            <Chart data={overviewInfo?.chartInfo?.engagements ? [...overviewInfo?.chartInfo?.engagements] : []}
              type={'ad_spot'}
              animation={false}
              isMobile={isMobile}
              externalTooltip={sameLandingPage}
              chartType={!isLineChart && 'bar'}
              height={isMobile ? null : '350px'}
            />
            <Divider style={{ margin: '16px auto' }} type={'horizontal'} />
            <div className="fadspot-page-overview-chart-stats">
              <div className="fadspot-page-overview-chart-stat">
                <div className="fadspot-page-overview-chart-stat-wrapper">
                  <div className="fadspot-page-overview-chart-stat-icon">
                    <Icon type={'ad_spot_chart_like'} role={'icon'} width={20} height={20}/>
                  </div>
                  <div className="fadspot-page-overview-chart-stat-info">
                    <p>
                      {t('Reactions')}
                      <Icon
                        type={'attention_outline'}
                        role="button"
                        width={16}
                        height={16}
                        tooltipProps={{
                          trigger: 'hover',
                          placement: isMobile ? 'bottom' : 'right',
                          overlayClassName: 'subscription-tooltip scheduled',
                          getPopupContainer: (trigger) => trigger.parentNode,
                        }}
                        titleText={t('We calculate Reactions based on the sum of reactions the ad receives, such as Like, Love, Haha, Wow, Sad, and Angry.')}
                      />
                    </p>
                  </div>
                </div>
                <span>
                  {acc.formatNumber(overviewInfo?.chartInfo?.engagements_stats?.total_reactions_count, 0, ',', '')}
                </span>
              </div>
              <div className="fadspot-page-overview-chart-stat">
                <div className="fadspot-page-overview-chart-stat-wrapper">
                  <div className="fadspot-page-overview-chart-stat-icon">
                    <Icon type={'ad_spot_chart_comments'} role={'icon'} width={20} height={20}/>
                  </div>
                  <div className="fadspot-page-overview-chart-stat-info">
                    <p>
                      {t('Comments')}
                    </p>
                  </div>
                </div>
                <span>
                {acc.formatNumber(overviewInfo?.chartInfo?.engagements_stats?.comments_count, 0, ',', '')}
                </span>
              </div>
              <div className="fadspot-page-overview-chart-stat">
                <div className="fadspot-page-overview-chart-stat-wrapper">
                  <div className="fadspot-page-overview-chart-stat-icon">
                  <Icon type={'ad_spot_chart_shares'} role={'icon'} width={20} height={20}/>
                  </div>
                  <div className="fadspot-page-overview-chart-stat-info">
                    <p>
                      {t('Shares')}
                    </p>
                  </div>
                </div>
                <span>
                {acc.formatNumber(overviewInfo?.chartInfo?.engagements_stats?.shares_count, 0, ',', '')}
                </span>
              </div>
            </div>
          </ChartBlock>
      }

      {overviewInfo?.chartInfo?.chartInfoLoading || overviewInfo?.mainInfo?.mainInfoLoading || overviewInfo?.cardInfo?.cardInfoLoading || firstLoading ? (
        <div className="same-destination-title" style={{ display: "flex" }}>
          <span className="link skeleton" style={{ width: 184, height: 24 }} />
        </div>
      ) : 
        overviewInfo?.similarInfo?.count > 0 ?
          <h3 className={'same-destination-title'}
              id={'fadspot-page-items-wrapper-overview'}
          >
            {
              t('Ads sharing same URL')
            }
          </h3>
          :
          null
      }

      {
        overviewInfo?.chartInfo?.chartInfoLoading || overviewInfo?.mainInfo?.mainInfoLoading || overviewInfo?.cardInfo?.cardInfoLoading || firstLoading ?
          <div className="fadspot-page-items-wrapper">
            {
              [1, 2, 3, 4, 5, 6].map(el => (
                <span key={el} className="fadspot-page-item-wrapper link skeleton"/>
              ))
            }
          </div>
          :
          overviewInfo?.similarInfo?.count > 0 ?
            <Spin spinning={overviewInfo?.similarInfo?.similarInfoLoading}>
              <div className="fadspot-page-items-wrapper"
                   ref={tableRef}
              >
                {
                  overviewInfo?.similarInfo?.results?.length ?
                    [...overviewInfo?.similarInfo?.results]?.map((el, index) => (
                      <AdItem key={el?.id + index}
                              data={{...el}}
                              isInnerPage={false}
                              isAlive={el?.status}
                      />
                    ))
                    :
                    null
                }
              </div>
            </Spin>
            :
            null
      }

      <Modal
        className={cls("change-modal custom-modal", {
          'store-products-opened': modal === 'storeProducts' || modal === 'storeApps'
        })}
        getContainer={() => document.getElementById('global-wrap')}
        open={visible}
        {...(isMobile ? { transitionName: '' } : null)}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#707BA0" opacity={1} />
        }
        width={modalBlocksWidth[modal]}
        footer={null}
        closable="true"
        onCancel={() => {
          setVisible(false);
        }}
        destroyOnClose
      >
        <Spin size="large" spinning={false}>
          {modalBlocks[modal]}
        </Spin>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state?.nav?.isMobile,
  userInfo: state.auth.userInfo,
  overviewInfo: state?.adSpot?.innerPagesInfo?.overview,
  innerPagesInfo: state?.adSpot?.innerPagesInfo,
  creditsLeft: state.adSpot.creditsLeft,
});

const mapDispatchToProps = (dispatch) => ({
  getOverviewMainInfo: (data) => dispatch(Creators.getOverviewMainInfoRequest(data)),
  getOverviewCardInfo: (data) => dispatch(Creators.getOverviewCardInfoRequest(data)),
  getOverviewChartInfo: (data) => dispatch(Creators.getOverviewChartInfoRequest(data)),
  getOverviewReport: (data) => dispatch(Creators.getOverviewReportRequest(data)),
  getOverviewSimilar: (data) => dispatch(Creators.getOverviewSimilarRequest(data)),
  setInnerPagesDateFilters: (data) => dispatch(Creators.setInnerPagesDateFilters(data)),
  setInnerPagesDateCheckedList: (data) => dispatch(Creators.setInnerPagesDateCheckedList(data)),
  getStoreProductsModal: (data) => dispatch(Creators.getOverviewStoreProductsModalRequest(data)),
  getStoreProductsModalNext: (data) => dispatch(Creators.getOverviewStoreProductsModalNextRequest(data)),
  resetStoreProductsModal: () => dispatch(Creators.resetOverviewStoreProductsModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdItemOverview);
