import React from 'react';

const AdSpotClock = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.76631 2.5H14.2337C14.6788 2.49999 15.0504 2.49998 15.3539 2.52478C15.6705 2.55065 15.9677 2.60657 16.2487 2.74978C16.6799 2.96949 17.0305 3.32007 17.2502 3.75127C17.3934 4.03232 17.4494 4.32954 17.4752 4.64611C17.5 4.94963 17.5 5.32117 17.5 5.76629V14.2337C17.5 14.6788 17.5 15.0504 17.4752 15.3539C17.4494 15.6705 17.3934 15.9677 17.2502 16.2487C17.0305 16.6799 16.6799 17.0305 16.2487 17.2502C15.9677 17.3934 15.6705 17.4494 15.3539 17.4752C15.0504 17.5 14.6788 17.5 14.2337 17.5H5.76629C5.32117 17.5 4.94963 17.5 4.64611 17.4752C4.32954 17.4494 4.03232 17.3934 3.75127 17.2502C3.32007 17.0305 2.96949 16.6799 2.74978 16.2487C2.60657 15.9677 2.55065 15.6705 2.52478 15.3539C2.49998 15.0504 2.49999 14.6788 2.5 14.2337V5.7663C2.49999 5.32118 2.49998 4.94963 2.52478 4.64611C2.55065 4.32954 2.60657 4.03233 2.74978 3.75127C2.96949 3.32007 3.32007 2.96949 3.75127 2.74978C4.03232 2.60657 4.32954 2.55065 4.64611 2.52478C4.94963 2.49998 5.32119 2.49999 5.76631 2.5ZM13.3559 8.70377C13.6 8.45969 13.6 8.06396 13.3559 7.81989C13.1118 7.57581 12.7161 7.57581 12.472 7.81989L9.16398 11.1279L7.93926 9.90322C7.69518 9.65914 7.29945 9.65914 7.05537 9.90322C6.8113 10.1473 6.8113 10.543 7.05537 10.7871L8.72204 12.4538C8.96612 12.6978 9.36184 12.6978 9.60592 12.4538L13.3559 8.70377Z"
        fill="#225AEA"
      />
    </svg>
  );
};

export default AdSpotClock;
