import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Layout, Card, Spin } from 'antd';
import AuthActions from './LoginPage/reducer';
import NewPasswordForm from '../Components/Forms/NewPasswordForm';
import Images from '../Images';

const NewPasswordPage = (
  {
    error,
    fetching,
    match,
    isMobile,
    ...props
  }) => {

  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');

  function handleSubmit(password) {
    if (fetching) return;
    props.createUserPassword(password, false, code);
  }

  useEffect(() => {
    document.title = 'Create New Password - Dropship';
  }, []);

  useEffect(() => {
    if (error) props.resetError();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [error]);

  return (
    <Layout>
      <Spin size="large" spinning={fetching}>
        <div className="login-content">
          <img src={Images.logo} alt="" className={'login-logo'} />
          <div className="login-card-wrapper">
            <Card className="login-card">
              <h2 className="login-card-title left">
                Create New Password
              </h2>
              <div className="login-card-subtitle left"
                   style={{marginBottom: 24}}
              >
                Your new password must be different from previously used passwords
              </div>
              <NewPasswordForm onSubmit={handleSubmit} error={error?.message?.[0]?.detail} />
            </Card>
          </div>
        </div>
      </Spin>
    </Layout>
  )
    ;
}

const mapStateToProps = (state) => ({
  error: state.auth.error,
  fetching: state.auth.fetching,
  isMobile: state.nav.isMobile,
});

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = (dispatch) => ({
  resetError: () => dispatch(AuthActions.authErrorReset()),
  createUserPassword: (password, check, confirm_code) =>
    dispatch(AuthActions.signUpRequest(password, check, confirm_code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordPage);
