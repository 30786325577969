import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import {
  checkboxOptions,
  inputOptions,
  callToActionOptions,
  domainOptions,
  languageOptions,
  providerOptions,
  countryOptions,
  statusOptions,
} from '../../../Utils/adLibraryMockedData';
import { Divider } from 'antd';
import ButtonComponent from '../../../Components/Button';
import Icon from '../../../Icon';
import InputFilter from './InputFilter';
import DropdownDateBlock from '../../ProductDatabasePage/components/DropdownDateBlock';
import DropdownBlockTest from '../../ProductDatabasePage/components/DropdownBlockTest';
import ActiveAdsets from './ActiveAdsets';
import './FiltersBlock.less';

const creativeOptions = ['all', 'video', 'photo', 'carousel'];

const dateOptions = [
  { id: 0, name: 'Last 7 days' },
  { id: 1, name: 'Last 30 days' },
  { id: 2, name: 'Last 90 days' },
  { id: 3, name: 'Last 6 months' },
  { id: 4, name: 'Last 12 months' },
];

const FiltersBlock = (
  {
    showFilters,
    isMobile,
    isChanged,
    setIsChanged,
    setInputText,
    disableShowFilters,
    filters
  },
) => {

  const { t } = useTranslation();

  console.log('trash', filters);

  const [visibleModal, setVisibleModal] = useState(null);
  const [creativeType, setCreativeType] = useState('all');
  const [checkedList, setCheckedList] = useState([...checkboxOptions?.map(el => el?.id)]);
  const [inputFilters, setInputFilters] = useState({
    ad_spend: { min: '', max: '' },
    selling_price: { min: '', max: '' },
    reach_eu: { min: '', max: '' },
    ads_running_time: { min: '', max: '' },
  });
  const [dropdownFilters, setDropdownFilters] = useState({
    ad_created_at: { min: null, max: null, id: null },
    last_seen_date: { min: null, max: null, id: null },
    product_created_at: { min: null, max: null, id: null },
    // active_adsets: { min: 0, max: 2596, },
    ad_copy_language: 'All',
    country: 'All',
    domain_tld: 'All',
    ad_status: 'All',
    // website_provider: 'All',
    call_to_action: 'All',
  });

  const resetFilters = (withSearch = true) => {
    setCreativeType('all');
    setCheckedList([...checkboxOptions?.map(el => el.id)]);
    setInputFilters({
      ad_spend: { min: '', max: '' },
      selling_price: { min: '', max: '' },
      reach_eu: { min: '', max: '' },
      ads_running_time: { min: '', max: '' },
      adset_amount: { min: '', max: '' },
    })
    setDropdownFilters({
      ad_created_at: { min: null, max: null, id: null },
      last_seen_date: { min: null, max: null, id: null },
      product_created_at: { min: null, max: null, id: null },
      // active_adsets: { min: 0, max: 2596, },
      ad_copy_language: 'All',
      country: 'All',
      domain_tld: 'All',
      ad_status: 'All',
      // website_provider: 'All',
      call_to_action: 'All',
    });
    setIsChanged(false);
    if (withSearch) {
      setInputText('');
    }
  }

  useEffect(() => {
    if (disableShowFilters) {
      resetFilters(false);
    }
  }, [disableShowFilters])

  return (
    <div className={cls('competitors-filter-wrapper adlibrary', {
      active: showFilters,
    })}
    >
      <div className="filters-wrapper">
        <div className="creative-wrapper">
          <span className="filters-title">
            {t('Creative type')}
          </span>
          <div className="creative-filter">
            {
              creativeOptions.map(el => (
                <span className={cls('creative-option', {
                  active: el === creativeType,
                })}
                      key={el}
                      onClick={() => setCreativeType(el)}
                >
                  {
                    el === 'all' ?
                      null
                      :
                      <Icon type={`ad_spot_${el}`} role={'icon'} />
                  }
                  {el}
                </span>
              ))
            }
          </div>
          <span className="filters-title">
            {t('Other')}
          </span>
          <div className="input-filters-wrapper">
            {
              inputOptions.map(el => (
                <InputFilter key={el?.key}
                             label={el?.label}
                             placeholder={el?.placeholder}
                             id={el?.key}
                             setInputFilters={setInputFilters}
                             inputFilters={inputFilters}
                />
              ))
            }
          </div>
        </div>
        {
          isMobile ?
            <Divider style={{ margin: '24px 0' }} />
            :
            null
        }
        <div className="categories-wrapper">
          <span className="filters-title">
            {t('Categories')}
            <sup>*</sup>
            <span className="filters-title-tag">
              beta
            </span>
          </span>
          <div className="categories-filter-wrapper">
            <div className={cls('categories-filter', {
              active: checkedList?.length === checkboxOptions?.length,
            })}
                 onClick={() => setCheckedList(checkedList?.length === checkboxOptions?.length ? [] : [...checkboxOptions?.map(el => el?.id)])}
            >
              <span className="categories-filter-checkbox" />
              <span className="categories-filter-label">
                {
                  checkedList?.length === checkboxOptions?.length ?
                    t('Deselect all')
                    :
                    t('Select all')
                }
              </span>
            </div>
            {
              checkboxOptions.map(el => (
                <div className={cls('categories-filter', {
                  active: checkedList?.includes(el?.id),
                })}
                     onClick={() => {
                       if (checkedList?.includes(el?.id)) {
                         let res = [...checkedList].filter(elem => elem !== el?.id);
                         setCheckedList(res);
                       } else {
                         setCheckedList(prev => ([...prev, el?.id]));
                       }
                     }}
                     key={el?.value}
                >
                  <span className="categories-filter-checkbox" />
                  <span className="categories-filter-label">
                    {
                      t(el?.value)
                    }
                  </span>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <Divider style={{ margin: '24px 0' }} />
      <div className="dropdowns-wrapper">
        <DropdownBlockTest label={'Ad status'}
                           id={'ad_status'}
                           dropdownFilters={dropdownFilters}
                           setDropdownFilters={setDropdownFilters}
                           iconType={'database_status'}
                           searchPlaceholder={null}
                           data={statusOptions}
                           visibleModal={visibleModal}
                           setVisibleModal={setVisibleModal}
                           isMobile={isMobile}
        />
        <DropdownBlockTest label={'Call to action'}
                           id={'call_to_action'}
                           dropdownFilters={dropdownFilters}
                           setDropdownFilters={setDropdownFilters}
                           iconType={'database_action'}
                           searchPlaceholder={'Search for call to action...'}
                           data={callToActionOptions}
                           visibleModal={visibleModal}
                           setVisibleModal={setVisibleModal}
                           isMobile={isMobile}
        />
        <DropdownBlockTest label={'Ad copy language'}
                           id={'ad_copy_language'}
                           dropdownFilters={dropdownFilters}
                           setDropdownFilters={setDropdownFilters}
                           iconType={'database_globe'}
                           searchPlaceholder={'Search for ad copy language...'}
                           data={languageOptions}
                           visibleModal={visibleModal}
                           setVisibleModal={setVisibleModal}
                           isMobile={isMobile}
        />
        <DropdownBlockTest label={'Country'}
                           id={'country'}
                           dropdownFilters={dropdownFilters}
                           setDropdownFilters={setDropdownFilters}
                           iconType={'database_globe'}
                           searchPlaceholder={'Search for country...'}
                           data={countryOptions}
                           visibleModal={visibleModal}
                           setVisibleModal={setVisibleModal}
                           isMobile={isMobile}
        />
        <DropdownBlockTest label={'Domain TLD'}
                           id={'domain_tld'}
                           dropdownFilters={dropdownFilters}
                           setDropdownFilters={setDropdownFilters}
                           iconType={'database_domain'}
                           searchPlaceholder={'Search for domain LTD...'}
                           data={domainOptions}
                           visibleModal={visibleModal}
                           setVisibleModal={setVisibleModal}
                           isMobile={isMobile}
        />
        <DropdownDateBlock label={'Ad creation date'}
                           id={'ad_created_at'}
                           iconType={'database_calendar'}
                           data={dateOptions}
                           dropdownFilters={dropdownFilters}
                           setDropdownFilters={setDropdownFilters}
                           hideTimeZone={true}
                           visibleModal={visibleModal}
                           setVisibleModal={setVisibleModal}
                           isMobile={isMobile}
        />
        {/*<DropdownBlockTest label={'Website Provider'}*/}
        {/*                   id={'website_provider'}*/}
        {/*                   dropdownFilters={dropdownFilters}*/}
        {/*                   setDropdownFilters={setDropdownFilters}*/}
        {/*                   iconType={'database_provider'}*/}
        {/*                   searchPlaceholder={'Search for website provider...'}*/}
        {/*                   data={providerOptions}*/}
        {/*                   visibleModal={visibleModal}*/}
        {/*                   setVisibleModal={setVisibleModal}*/}
        {/*                   isMobile={isMobile}*/}
        {/*/>*/}
        {/*<ActiveAdsets label={'Active ads'}*/}
        {/*              id={'active_adsets'}*/}
        {/*              dropdownFilters={dropdownFilters}*/}
        {/*              setDropdownFilters={setDropdownFilters}*/}
        {/*              iconType={'database_radar'}*/}
        {/*              visibleModal={visibleModal}*/}
        {/*              setVisibleModal={setVisibleModal}*/}
        {/*              isMobile={isMobile}*/}
        {/*/>*/}
        <DropdownDateBlock label={'Last seen date'}
                           id={'last_seen_date'}
                           iconType={'database_calendar'}
                           data={dateOptions}
                           dropdownFilters={dropdownFilters}
                           setDropdownFilters={setDropdownFilters}
                           hideTimeZone={true}
                           visibleModal={visibleModal}
                           setVisibleModal={setVisibleModal}
                           isMobile={isMobile}
        />
        <DropdownDateBlock label={'Product creation date'}
                           id={'product_created_at'}
                           iconType={'database_calendar'}
                           data={dateOptions}
                           dropdownFilters={dropdownFilters}
                           setDropdownFilters={setDropdownFilters}
                           hideTimeZone={true}
                           visibleModal={visibleModal}
                           setVisibleModal={setVisibleModal}
                           isMobile={isMobile}
        />
      </div>
      {
        isMobile ?
          null
          :
          <Divider style={{ margin: '24px 0' }} />
      }
      <div className="filters-btn-wrapper">
        <ButtonComponent className={'filters-btn-reset'}
                         text={t('Reset filter')}
                         onClick={() => resetFilters()}
        />
        <ButtonComponent className={cls('filters-btn-search', {
          disabled: !isChanged,
        })}
                         text={t('Search')}
                         onClick={() => console.log('search')}
        />
      </div>
    </div>
  );
};

export default FiltersBlock;
