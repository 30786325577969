import React from 'react';
import { useSelector } from 'react-redux';

const Rating = () => {
  const theme = useSelector((state) => state?.nav?.theme);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={theme === 'light' ? '#A1AAC8' : '#ffffff42'}
        d="M7.54343 1.45297C7.72694 1.07102 8.27306 1.07102 8.45657 1.45297L10.0356 4.73961C10.1095 4.89339 10.2565 4.99955 10.4262 5.02177L14.0593 5.49742C14.4817 5.55272 14.6507 6.07124 14.3413 6.36292L11.6852 8.86711C11.5606 8.9846 11.5043 9.15697 11.5356 9.32497L12.2026 12.9028C12.2803 13.3193 11.8383 13.6396 11.464 13.4379L8.24097 11.7005C8.09063 11.6194 7.90937 11.6194 7.75903 11.7005L4.53595 13.4379C4.16172 13.6396 3.7197 13.3194 3.79737 12.9028L4.46441 9.32497C4.49573 9.15697 4.43941 8.9846 4.31479 8.86711L1.6587 6.36292C1.34933 6.07124 1.51828 5.55272 1.94072 5.49742L5.57379 5.02177C5.74354 4.99955 5.89049 4.89339 5.96438 4.73961L7.54343 1.45297Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Rating;
