import React from 'react';

const AdSpotEmptyItems = () => {
  return (
    <svg
      width="208"
      height="85"
      viewBox="0 0 208 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="112.5" y="5.5" width="39" height="39" rx="9.5" fill="white" />
      <rect
        x="112.5"
        y="5.5"
        width="39"
        height="39"
        rx="9.5"
        stroke="#F2F3F8"
      />
      <rect x="0.5" y="5.5" width="39" height="39" rx="9.5" fill="white" />
      <rect x="0.5" y="5.5" width="39" height="39" rx="9.5" stroke="#F2F3F8" />
      <rect x="140.5" y="5.5" width="39" height="39" rx="9.5" fill="white" />
      <rect
        x="140.5"
        y="5.5"
        width="39"
        height="39"
        rx="9.5"
        stroke="#F2F3F8"
      />
      <rect x="28.5" y="5.5" width="39" height="39" rx="9.5" fill="white" />
      <rect x="28.5" y="5.5" width="39" height="39" rx="9.5" stroke="#F2F3F8" />
      <rect x="168.5" y="5.5" width="39" height="39" rx="9.5" fill="white" />
      <rect
        x="168.5"
        y="5.5"
        width="39"
        height="39"
        rx="9.5"
        stroke="#F2F3F8"
      />
      <rect x="56.5" y="5.5" width="39" height="39" rx="9.5" fill="white" />
      <rect x="56.5" y="5.5" width="39" height="39" rx="9.5" stroke="#F2F3F8" />
      <g filter="url(#filter0_dddddd_679_128604)">
        <g clipPath="url(#clip0_679_128604)">
          <rect x="80" y="1" width="48" height="48" rx="10" fill="white" />
          <g clipPath="url(#clip1_679_128604)">
            <path
              d="M3.23117 16.7682L3.23114 16.7682C1.43615 14.9732 0.427734 12.5386 0.427734 10.0001C0.427734 7.46164 1.43615 5.02711 3.23114 3.23212L3.23117 16.7682ZM3.23117 16.7682C5.02614 18.5631 7.46065 19.5716 9.99916 19.5716C12.5376 19.5716 14.9722 18.5631 16.7672 16.7682L16.7673 16.7682M3.23117 16.7682L16.7673 16.7682M16.7673 16.7682C18.5621 14.9732 19.5706 12.5386 19.5706 10.0001C19.5706 7.46163 18.5621 5.02712 16.7673 3.23214L16.7672 3.23212M16.7673 16.7682L16.7672 3.23212M16.7672 3.23212C14.9722 1.43712 12.5376 0.428711 9.99916 0.428711C7.46066 0.428711 5.02614 1.43713 3.23115 3.23212L16.7672 3.23212Z"
							fill="url(#paint0_linear_679_128604)"
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M106.65 20.7144C104.296 20.7144 104.001 22.6686 104.001 22.6686C104.001 22.6686 103.707 20.7144 101.352 20.7144C99.0873 20.7144 98.4273 23.0951 98.7037 24.3679C99.4323 27.7322 104.001 30.0894 104.001 30.0894C104.001 30.0894 108.57 27.7322 109.299 24.3679C109.574 23.0951 108.913 20.7144 106.65 20.7144Z"
              fill="url(#paint0_linear_679_128604)"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dddddd_679_128604"
          x="68"
          y="0"
          width="72"
          height="85"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_679_128604"
          />
          <feOffset />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_679_128604"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="0.5"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_679_128604"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_679_128604"
            result="effect2_dropShadow_679_128604"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.5"
            operator="erode"
            in="SourceAlpha"
            result="effect3_dropShadow_679_128604"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_679_128604"
            result="effect3_dropShadow_679_128604"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect4_dropShadow_679_128604"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_dropShadow_679_128604"
            result="effect4_dropShadow_679_128604"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="6"
            operator="erode"
            in="SourceAlpha"
            result="effect5_dropShadow_679_128604"
          />
          <feOffset dy="12" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="effect4_dropShadow_679_128604"
            result="effect5_dropShadow_679_128604"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="12"
            operator="erode"
            in="SourceAlpha"
            result="effect6_dropShadow_679_128604"
          />
          <feOffset dy="24" />
          <feGaussianBlur stdDeviation="12" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="effect5_dropShadow_679_128604"
            result="effect6_dropShadow_679_128604"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect6_dropShadow_679_128604"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_679_128604"
          x1="103.999"
          y1="16.4287"
          x2="103.999"
          y2="33.5716"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6680" />
          <stop offset="1" stopColor="#E61739" />
        </linearGradient>
        <clipPath id="clip0_679_128604">
          <rect x="80" y="1" width="48" height="48" rx="10" fill="white" />
        </clipPath>
        <clipPath id="clip1_679_128604">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(94 15)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AdSpotEmptyItems;
