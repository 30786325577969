import React, {useEffect, useRef, useState} from 'react';
import {Divider, Dropdown, Tag} from "antd";
import Icon from "../../../Icon";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import cls from "classname";
import InputComponent from "../../../Components/Input";
import utils from "../../../Utils/utils";
import Image from "../../../Components/Image";
import Images from "../../../Images";
import {setTimeout} from "core-js/internals/schedulers-fix";
import useDebounce from "../../../hooks/use-debounce";

const Suggestions = (
  {
    options=[],
    el,
    setInputFilters,
    suggestionsLoading,
    setInputFiltersSuggestions
  }) => {

  const {t} = useTranslation();
  const theme = useSelector(store => store.nav.theme);

  return (
    options?.length ?
      <div className={cls("dropdown-input-menu-items", {
        loading: suggestionsLoading
      })}>
        {options.map(elem => (
          <div key={elem?.id}
               className={'dropdown-input-menu-item'}
               onClick={() => {
                 setInputFilters(state => {
                   if (state[el?.key]?.includes(el?.key === 'page_name' ? elem?.name : elem?.domain)) return {...state, [el?.key]: state?.[el?.key]}
                   else return {
                     ...state,
                     [el?.key]: state[el?.key] ? state?.[el?.key] + `, ${el?.key === 'page_name' ? elem?.name : elem?.domain}` : `${el?.key === 'page_name' ? elem?.name : elem?.domain},`
                   }
                 });
                 setInputFiltersSuggestions(state => {
                   if (state[el?.key].filter(suggestion => suggestion?.id === elem?.id).length) return {...state, [el?.key]: [...state?.[el?.key]]}
                   else return {
                     ...state,
                     [el?.key]: [...state[el?.key], elem]
                   }
                 });
               }}
          >
            <div className="dropdown-input-menu-item-info">
              {
                el?.key === 'domain_name' ?
                  null
                  :
                  <div className="logo">
                    {
                      elem.logo ?
                        <img src={utils.changeImageSize(elem?.logo, 496)} alt="" onError={utils.addDefaultSrc}/>
                        :
                        <Image src={Images.defaultImg} small={true}/>
                    }
                  </div>
              }
              {
                el?.key === 'domain_name' ?
                  <div className="title">
                    <h4>
                      {elem?.domain}
                    </h4>
                  </div>
                  :
                  <div className="title">
                    <h4>
                      {elem?.name}
                    </h4>
                    <span>
                  {elem?.domain}
                </span>
                  </div>
              }
            </div>
            <span className={'dropdown-input-menu-item-count'}>
              {elem?.ad_count}{' '}Ads
            </span>
          </div>
        ))}
      </div>
      :
      <div className={cls("dropdown-input-menu-items empty", {
        loading: suggestionsLoading
      })}>
        <div className="empty-state empty-state-product-database">
          <div className="empty-state_icon">
            <Icon width={48} height={48}
                  role="icon"
                  type="empty_warning"
                  fill={theme === 'dark' ? '#707ba0' : '#BDC4DB'}
            />
          </div>
          <div className="empty-state-product-database-title">
            {t('No Results Found')}
            <div className="empty-state-product-database-subtitle">
              {t('kindly_change_search')}
            </div>
          </div>
        </div>
      </div>
      )
}

const InputProductFilter = (
  {
    el,
    onChangeInputFilter,
    onPasteInputFilter,
    inputFilters,
    setInputFilters,
    isMobile,
    type='static',
    suggestions=[],
    suggestionsLoading=false,
    getSuggestions,
    resetSuggestions,
    setInputFiltersSuggestions
  }
) => {
  //input ref for autofocus on wrapper click
  const inputRef = useRef(null);
  const { t } = useTranslation();

  //input local value
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 500);
  const [list, setList] = useState([]);
  //focus/blur input handler
  const [visible, setVisible] = useState(false);
  const [focused, setFocused] = useState(false);
  const [pasted, setPasted] = useState(false);
  const isDisabledTransition = useSelector(state => state.nav.disabledTransition);

  const dropdownOptions = useSelector(state => state?.adSpot?.presets?.smart_suggestions?.[0]?.keywords) ||
    [
    {text: '™', key: 'tm', isSup: false},
    {text: 'Portable', key: 'portable', isSup: false},
    {text: 'Adjustable', key: 'adjustable', isSup: false},
    {text: 'Multifunctional', key: 'multifunctional', isSup: false},
    {text: 'Multi', key: 'multi', isSup: false},
    {text: 'Anti', key: 'anti', isSup: false},
    {text: 'Professional', key: 'professional', isSup: false},
    {text: 'Smart', key: 'smart', isSup: false},
    {text: '3 in 1', key: '3_in_1', isSup: false},
    {text: '4 in 1', key: '4_in_1', isSup: false},
  ];

  //initial value
  const tagData = inputFilters[el?.key]?.split(',').filter(el => el.length && el !== 'null') || []

  //tag cross icon click handler
  const handleTagClose = tag => {
    let keyOfRemoved = dropdownOptions.find(e => e?.text === tag);
    if (keyOfRemoved) {
      let newList = list.filter(e => e !== keyOfRemoved?.key)
      setList(newList)
    }
    const newValue = tagData.filter(el => el !== tag).join(',');
    setInputFilters(state => ({ ...state, [el?.key]: newValue.length ? newValue : null}));
    setInputFiltersSuggestions(state => {
      let newState = {};
      for (let key in state) {
        newState[key] = [...state[key].filter(elem => key === 'page_name' ?  elem?.name.trim().toLowerCase() !== tag.trim().toLowerCase() : elem?.domain.trim().toLowerCase() !== tag.trim().toLowerCase())];
      }
      return {...newState};
    });
  }

  //input focus handler on wrapper click
  const focus = () => inputRef.current && inputRef.current.focus()

  //input change handler
  const handleChange = e => {
    setValue(e.target.value.replaceAll(',', ''));
  }

  //188 - ','; 13 - 'enter'
  const onKeyUp = e => {
    if (e.key === ',' || e.keyCode === 13) {
      if (doesContain(e.target.value.toLowerCase()) || tagData.filter(tag => tag.toLowerCase() === e.target.value.trim().toLowerCase()).length ) {
        setValue('')
        return
      }
      if(e.target.value) onChangeInputFilter(e, el?.key)
      setValue('')
    }
  }

  const doesContain = val => {
    let trimmedVal = val.trim();
    let valArray = trimmedVal.split(',')[0];
    return tagData.includes(trimmedVal) || tagData.includes(valArray.trim());
  }

  //create tag on blur
  const onBlur = (e) => {
    if(e.target.value) {
      if (doesContain(e.target.value.toLowerCase()) || tagData.filter(tag => tag.toLowerCase() === e.target.value.trim().toLowerCase()).length ) {
        setValue('')
        setFocused(false)
        return
      }
      !visible && onChangeInputFilter(e, el?.key, pasted)
    }
    setPasted(false);
    setValue('')
    setFocused(false)
  }

  //remove tag by backspace
  const onKeyDown = e => {
    if (e.keyCode === 8 && !value) {
      if (tagData.length) {
        const newValue = [...tagData];
        let removed = newValue.pop();
        let keyOfRemoved = dropdownOptions.find(e => e?.text === removed);
        if (keyOfRemoved) {
          let newList = list.filter(e => e !== keyOfRemoved?.key)
          setList(newList)
        }
        setInputFilters(state => ({ ...state, [el?.key]: newValue.length ? newValue.join(',') : null}));
        setInputFiltersSuggestions(state => {
          let newState = {};
          for (let key in state) {
            newState[key] = [...state[key].filter(elem => key === 'page_name' ?  elem?.name.trim().toLowerCase() !== removed.trim().toLowerCase() : elem?.domain.trim().toLowerCase() !== removed.trim().toLowerCase())];
          }
          return {...newState};
        });
      }
    }
  }

  const onPaste = e => {
    e.persist();
    setValue('');
    setFocused(false);
    setPasted(true);
    onPasteInputFilter(e, el?.key);
  }

  useEffect(() => {
    if (type === 'dynamic' && debouncedValue.length >=3) {
      setVisible(true)
    } else setVisible(false)
  }, [type, debouncedValue]);

  useEffect(() => {
    if (visible && debouncedValue?.length >=3) {
      getSuggestions({value: debouncedValue, key: el?.key})
    } else if (!visible) resetSuggestions()
  }, [visible, debouncedValue])

  return (
    type === 'static' ?
      <div className={cls('input-product-filter-wrapper', {
        'disabled-transition': isDisabledTransition
      })}>
        <div className='input-product-filter-title'>
          <span style={{marginRight: 4}}>
            {t(el?.title)}{el?.key === 'page_name' ? '/ID' : null}
          </span>

          <Icon type={'attention_outline'}
                role={'button'}
                width={16}
                height={16}
                tooltipProps={{
                  placement: 'right',
                  trigger: isMobile ? 'click' : 'hover',
                  arrow: true,
                  destroyTooltipOnHide: true,
                  overlayClassName: 'info-text_tooltip',
                  getPopupContainer: (trigger) => trigger.parentNode,
                }}
                titleText={t(el.tooltip)}
          />
        </div>
        <Dropdown
          overlayClassName={'dropdown-input-wrapper'}
          getPopupContainer={(trigger) => trigger.parentNode}
          placement={'bottom'}
          dropdownRender={() => (
            <div className="dropdown-input-menu" style={{ height: 248 }}>
            <span className="dropdown-input-menu-title">
              <Icon type={'energy'} role={'icon'} />
              {t('Smart Suggestions')}
            </span>

              <Divider type={'horizontal'} style={{margin: '10px auto 4px'}} />

              <div className="dropdown-input-menu-items">
                {dropdownOptions.filter(e => !list.includes(e?.key)).map(elem => (
                  <div key={elem?.key}
                       className={'dropdown-input-menu-item'}
                       onClick={() => {
                         let reg = new RegExp(`\b${elem?.text.toLowerCase()}\b`);
                         if (inputFilters[el?.key] &&  reg.test(inputFilters[el?.key])) {
                           let newData = inputFilters[el?.key].split(',').filter(e => e !== elem?.text).join(',')
                           setInputFilters(state => ({ ...state, [el?.key]: newData?.length ? newData : null}));
                           let newList = list.filter(e => e !== elem?.key);
                           setList(newList);
                         }
                         else {
                           setList(prev => [...prev, elem?.key])
                           setInputFilters(state => ({...state,
                             [el?.key]:  state[el?.key] === null ? elem?.text + ',' : state[el?.key] + ',' + elem?.text
                           }));
                         }
                       }}
                  >
                  <span className={'dropdown-input-menu-item-title'}>
                    {
                      elem?.isSup ?
                        <sup>{elem?.text}</sup>
                        :
                        elem?.text
                    }
                  </span>
                  </div>
                ))}
              </div>
            </div>
          )}
          trigger={['hover']}
        >
          <ul className='input-product-filter-list' onClick={e => {
            if (e.target === e.currentTarget) focus()
          }}>
            {tagData && tagData.map(item =>
              <li key={item}>
                <Tag closable
                     onClose={() => handleTagClose(item)}
                     closeIcon={<Icon role="icon" type="close_modal" color="#225aea" width={8} height={8} opacity={1} />}
                >
                  {item}
                </Tag>
              </li>
            )}

            <li style={{width: focused ? value.length + 1 + 'ch' : tagData.length ? 0 : '100%', height: !focused && tagData.length ? 0 : 30}}
            >
              <InputComponent name={el?.key}
                              placeholder={tagData.length || focused ? '' : t('Separate search query by commas')}
                              onChange={handleChange}
                              onKeyUp={onKeyUp}
                              onKeyDown={onKeyDown}
                              onPaste={onPaste}
                              onBlur={onBlur}
                              onFocus={() => setFocused(true)}
                              value={value}
                              ref={inputRef}
                              autoComplete='off'
              />
            </li>
          </ul>
        </Dropdown>
      </div>
      :
      <div className={cls('input-product-filter-wrapper', {
        'disabled-transition': isDisabledTransition
      })}>
        <div className='input-product-filter-title'>
          <span style={{marginRight: 4}}>
            {t(el?.title)}{el?.key === 'page_name' ? '/ID' : null}
          </span>

          <Icon type={'attention_outline'}
                role={'button'}
                width={16}
                height={16}
                tooltipProps={{
                  placement: 'right',
                  trigger: isMobile ? 'click' : 'hover',
                  arrow: true,
                  destroyTooltipOnHide: true,
                  overlayClassName: 'info-text_tooltip',
                  getPopupContainer: (trigger) => trigger.parentNode,
                }}
                titleText={t(el.tooltip)}
          />
        </div>
        <Dropdown
          overlayClassName={'dropdown-input-wrapper'}
          getPopupContainer={(trigger) => trigger.parentNode}
          placement={'bottom'}
          dropdownRender={() => (
            <div className="dropdown-input-menu" style={{ maxHeight: 248 }}>
            <span className="dropdown-input-menu-title">
              <Icon type={'energy_bulb'} role={'icon'} />
              {t('Suggestions')}
            </span>
              <Divider type={'horizontal'} style={{margin: '10px auto 4px'}} />
              <Suggestions el={el}
                           setInputFilters={setInputFilters}
                           options={suggestions}
                           suggestionsLoading={suggestionsLoading}
                           setInputFiltersSuggestions={setInputFiltersSuggestions}
              />
            </div>
          )}
          open={visible}
        >
          <ul className='input-product-filter-list' onClick={e => {
            if (e.target === e.currentTarget) focus()
          }}>
            {tagData && tagData.map(item =>
              <li key={item}>
                <Tag closable
                     onClose={() => handleTagClose(item)}
                     closeIcon={<Icon role="icon" type="close_modal" color="#225aea" width={8} height={8} opacity={1} />}
                >
                  {item}
                </Tag>
              </li>
            )}

            <li style={{width: focused ? value.length + 1 + 'ch' : tagData.length ? 0 : '100%', height: !focused && tagData.length ? 0 : 30}}
            >
              <InputComponent name={el?.key}
                              placeholder={tagData.length || focused ? '' : t('Separate search query by commas')}
                              onChange={handleChange}
                              onKeyUp={onKeyUp}
                              onKeyDown={onKeyDown}
                              onPaste={onPaste}
                              onBlur={e => setTimeout(() => onBlur(e), 100)}
                              onFocus={() => setFocused(true)}
                              value={value}
                              ref={inputRef}
                              autoComplete='off'
              />
            </li>
          </ul>
        </Dropdown>
      </div>
  )


}

export default InputProductFilter;
