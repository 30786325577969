import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Line, Bar } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import {
  Chart as ChartJS,
  Filler,
  LineElement,
  PointElement,
  LinearScale,
  Tooltip,
  CategoryScale,
  TimeScale,
  TimeSeriesScale,
  BarElement
} from 'chart.js';
import { chartConfig } from './ChartsConfig';
import EmptyChart from './EmptyChart';

const pluginInteraction = {
  id: 'vertical-line-plugin', //typescript crashes without id
  beforeDraw: function(chart, easing, opt) {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const activePoint = chart.tooltip._active[0];
      const ctx = chart.ctx;
      const x = activePoint.element.x;
      const topY = chart.scales.y.top;
      const bottomY = chart.scales.y.bottom;
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 6;
      ctx.strokeStyle = opt.theme === 'light' ? '#F2F6FF' : '#000E58';
      ctx.stroke();
      ctx.restore();
    }
  },
};

ChartJS.register(pluginInteraction, Filler, LineElement, PointElement, LinearScale, Tooltip, CategoryScale, TimeScale, TimeSeriesScale,BarElement);

const sortByDate = (a, b) => {
  a = new Date(a.date);
  b = new Date(b.date);
  return a > b ? 1 : a < b ? -1 : 0;
};

const getChartData = (data, type, withRawData = false, rawData, withReturns = false, returnsData = [], rawReturnsData = [],chartType) => {
  const chartBaseStyle = {
    borderColor: '#225AEA',
    borderWidth: chartType === 'bar' ? 0 : 2,
    lineTension: 0.075,
    fill: true,
    pointBackgroundColor: '#225AEA',
    pointBorderColor: 'rgba(0, 0, 0, 0.0)',
    pointHoverRadius: 7,
    pointHoverBorderWidth: 2,
    pointHoverBackgroundColor: '#225AEA',
    pointHoverBorderColor: '#FFFFFF',
  };
  
  const chartRawDataBaseStyle = {
    borderColor: '#1448CB',
    borderWidth: 2,
    lineTension: 0.075,
    fill: true,
    pointBackgroundColor: '#1448CB',
    pointBorderColor: 'rgba(0, 0, 0, 0.0)',
    pointHoverRadius: 7,
    pointHoverBorderWidth: 2,
    pointHoverBackgroundColor: '#1448CB',
    pointHoverBorderColor: '#FFFFFF',
  };
  
  const chartReturnsDataBaseStyle = {
    borderColor: '#DF7009',
    borderWidth: 2,
    lineTension: 0.075,
    fill: true,
    pointBackgroundColor: '#DF7009',
    pointBorderColor: 'rgba(0, 0, 0, 0.0)',
    pointHoverRadius: 7,
    pointHoverBorderWidth: 2,
    pointHoverBackgroundColor: '#DF7009',
    pointHoverBorderColor: '#FFFFFF',
  };
  
  const chartRawReturnsDataBaseStyle = {
    borderColor: '#B55700',
    borderWidth: 2,
    lineTension: 0.075,
    fill: true,
    pointBackgroundColor: '#B55700',
    pointBorderColor: 'rgba(0, 0, 0, 0.0)',
    pointHoverRadius: 7,
    pointHoverBorderWidth: 2,
    pointHoverBackgroundColor: '#B55700',
    pointHoverBorderColor: '#FFFFFF',
  };
  const inputData = data;
  const inputRawData = rawData || [];
  const inputReturnsData = returnsData;
  const inputRawReturnsData = rawReturnsData || [];

  inputData && inputData.sort(sortByDate);
  inputRawData && inputRawData.sort(sortByDate);
  inputReturnsData && inputReturnsData.sort(sortByDate);
  inputRawReturnsData && inputRawReturnsData.sort(sortByDate);

  const hasInvalid = inputData.some(el => el?.invalid);
  const hasRawInvalid = inputRawData.some(el => el?.invalid);
  const hasReturnsInvalid = inputReturnsData.some(el => el?.invalid);
  const hasRawReturnsInvalid = inputRawReturnsData.some(el => el?.invalid);

  let datasets = [];
  let rawDatasets = [];
  let returnsDatasets = [];
  let rawReturnsDatasets = [];
  const labels = [];
  const rawLabels = [];
  const returnsLabels = [];
  const rawReturnsLabels = [];

  const charts = [
    {
      label: type === 'ad_spot' ? null : type === 'unique_products_sold' ? 'unique sold' : type,
      xScale: 'date',
      yScale: 'value',
      progress: 'progress',
    },
  ];

  const rawCharts = [
    {
      label: 'raw',
      xScale: 'date',
      yScale: 'value',
      progress: 'progress',
    },
  ];

  const returnsCharts = [
    {
      label: 'returns',
      xScale: 'date',
      yScale: 'value',
      progress: 'progress',
    },
  ];

  const rawReturnsCharts = [
    {
      label: 'raw returns',
      xScale: 'date',
      yScale: 'value',
      progress: 'progress',
    },
  ];

  let width, height, gradient;

  function getGradient(ctx, chartArea) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      gradient.addColorStop(0, 'rgba(35, 124, 251, 0)');
      gradient.addColorStop(1, 'rgba(35, 124, 251, 0.1)');
    }

    return gradient;
  }

  for (let i in charts) {
    const chart = charts[i];

    for (let j in inputData) {

      if (labels.indexOf(inputData[j][chart.xScale]) < 0) {
        labels.push(inputData[j][chart.xScale]);
      }
      if (!datasets[i]) {
        datasets[i] = {
          data: [
            {
              x: inputData[j]?.invalid ? NaN : inputData[j][chart.xScale],
              y: inputData[j]?.invalid ? NaN : inputData[j][chart.yScale] || 0,
              invalid: inputData[j]?.invalid,
              message: inputData[j]?.message || '',
              top_performers: inputData[j]?.top_performers || [],
              is_active: inputData[j].hasOwnProperty('is_active') ? inputData[j]?.is_active : null,
              breakdown: inputData[j]?.breakdown || { 'reactions': 0, 'comments': 0, 'shares': 0 },
            },
          ],
          ...chartBaseStyle,
          segment: {
            borderDash: (ctx) => ctx?.p0?.raw?.is_active === false ? [6, 6] : undefined,
            borderColor: (ctx) => ctx?.p0?.raw?.is_active === false ? 'rgba(34, 90, 234, .5)' : undefined,  
          },
          stepped: type === 'price_dynamics' ? 'middle' : false,
          backgroundColor: chartType === 'bar' ? '#225AEA' : function(context) {
            const { ctx, chartArea } = context.chart;
            if (!chartArea) return;
            return getGradient(ctx, chartArea);
          },
          label: chart.label,
          pointRadius: inputData?.length > 1 ? 1 : 4,
        };
      } else {
        datasets[i].data.push({
          x: inputData[j]?.invalid ? NaN : inputData[j][chart.xScale],
          y: inputData[j]?.invalid ? NaN : inputData[j][chart.yScale] || 0,
          invalid: inputData[j]?.invalid,
          message: inputData[j]?.message || '',
          top_performers: inputData[j]?.top_performers || [],
          is_active: inputData[j].hasOwnProperty('is_active') ? inputData[j]?.is_active : null,
          breakdown: inputData[j]?.breakdown || { 'reactions': 0, 'comments': 0, 'shares': 0 },
        });
      }

      if (hasInvalid) {
        let firstLastValidValue = (!inputData[j]?.invalid && (inputData[+j + 1]?.invalid || inputData[j - 1]?.invalid));
        if (!datasets[1]) {
          datasets[1] = {
            data: [
              {
                x: (inputData[j]?.invalid || firstLastValidValue) ? inputData[j][chart.xScale] : NaN,
                y: (inputData[j]?.invalid || firstLastValidValue) ? inputData[j][chart.yScale] || 0 : NaN,
                invalid: inputData[j]?.invalid,
                hiddenTooltip: firstLastValidValue,
                message: inputData[j]?.message || '',
                top_performers: inputData[j]?.top_performers || [],
                is_active: inputData[j].hasOwnProperty('is_active') ? inputData[j]?.is_active : null,
                breakdown: inputData[j]?.breakdown || { 'reactions': 0, 'comments': 0, 'shares': 0 },
              },
            ],
            ...chartBaseStyle,
            segment: {
              borderDash: (ctx) => ctx?.p0?.raw?.is_active === false ? [6, 6] : undefined,
              borderColor: (ctx) => ctx?.p0?.raw?.is_active === false ? 'rgba(34, 90, 234, .5)' : undefined,
            },
            stepped: type === 'price_dynamics' ? 'middle' : false,
            borderColor: '#D71313',
            backgroundColor: function(context) {
              const { ctx, chartArea } = context.chart;
              if (!chartArea) return;
              return getGradient(ctx, chartArea);
            },
            label: chart.label,
            pointRadius: inputData?.length > 1 ? 1 : 4,
            pointBackgroundColor: '#D71313',
            pointHoverBackgroundColor: '#D71313',
          };
        } else {
          datasets[1].data.push({
            x: (inputData[j]?.invalid || firstLastValidValue) ? inputData[j][chart.xScale] : NaN,
            y: (inputData[j]?.invalid || firstLastValidValue) ? inputData[j][chart.yScale] || 0 : NaN,
            invalid: inputData[j]?.invalid,
            message: inputData[j]?.message || '',
            hiddenTooltip: firstLastValidValue,
          });
        }
      }
    }
  }

  if (withRawData) {
    for (let i in rawCharts) {
      const chart = rawCharts[i];

      for (let j in inputRawData) {

        if (rawLabels.indexOf(inputRawData[j][chart.xScale]) < 0) {
          rawLabels.push(inputRawData[j][chart.xScale]);
        }
        if (!rawDatasets[i]) {
          rawDatasets[i] = {
            data: [
              {
                x: inputRawData[j]?.invalid ? NaN : inputRawData[j][chart.xScale],
                y: inputRawData[j]?.invalid ? NaN : inputRawData[j][chart.yScale] || 0,
                invalid: inputRawData[j]?.invalid,
                message: inputRawData[j]?.message || '',
                top_performers: inputRawData[j]?.top_performers || [],
                is_active: inputRawData[j].hasOwnProperty('is_active') ? inputRawData[j]?.is_active : null,
                breakdown: inputRawData[j]?.breakdown || { 'reactions': 0, 'comments': 0, 'shares': 0 },
              },
            ],
            ...chartRawDataBaseStyle,
            backgroundColor: function(context) {
              const { ctx, chartArea } = context.chart;
              if (!chartArea) return;
              return getGradient(ctx, chartArea);
            },
            label: chart.label,
            pointRadius: inputRawData?.length > 1 ? 1 : 4,
          };
        } else {
          rawDatasets[i].data.push({
            x: inputRawData[j]?.invalid ? NaN : inputRawData[j][chart.xScale],
            y: inputRawData[j]?.invalid ? NaN : inputRawData[j][chart.yScale] || 0,
            invalid: inputRawData[j]?.invalid,
            message: inputRawData[j]?.message || '',
            top_performers: inputRawData[j]?.top_performers || [],
            is_active: inputRawData[j].hasOwnProperty('is_active') ? inputRawData[j]?.is_active : null,
            breakdown: inputRawData[j]?.breakdown || { 'reactions': 0, 'comments': 0, 'shares': 0 },
          });
        }

        if (hasRawInvalid) {
          let firstLastValidValue = (!inputRawData[j]?.invalid && (inputRawData[+j + 1]?.invalid || inputRawData[j - 1]?.invalid));
          if (!rawDatasets[1]) {
            rawDatasets[1] = {
              data: [
                {
                  x: (inputRawData[j]?.invalid || firstLastValidValue) ? inputRawData[j][chart.xScale] : NaN,
                  y: (inputRawData[j]?.invalid || firstLastValidValue) ? inputRawData[j][chart.yScale] || 0 : NaN,
                  invalid: inputRawData[j]?.invalid,
                  hiddenTooltip: firstLastValidValue,
                  message: inputRawData[j]?.message || '',
                  top_performers: inputRawData[j]?.top_performers || [],
                  is_active: inputRawData[j].hasOwnProperty('is_active') ? inputRawData[j]?.is_active : null,
                  breakdown: inputRawData[j]?.breakdown || { 'reactions': 0, 'comments': 0, 'shares': 0 },
                },
              ],
              ...chartRawDataBaseStyle,
              borderColor: '#D71313',
              backgroundColor: function(context) {
                const { ctx, chartArea } = context.chart;
                if (!chartArea) return;
                return getGradient(ctx, chartArea);
              },
              label: chart.label,
              pointRadius: inputRawData?.length > 1 ? 1 : 4,
              pointBackgroundColor: '#D71313',
              pointHoverBackgroundColor: '#D71313',
            };
          } else {
            rawDatasets[1].data.push({
              x: (inputRawData[j]?.invalid || firstLastValidValue) ? inputRawData[j][chart.xScale] : NaN,
              y: (inputRawData[j]?.invalid || firstLastValidValue) ? inputRawData[j][chart.yScale] || 0 : NaN,
              invalid: inputRawData[j]?.invalid,
              message: inputRawData[j]?.message || '',
              hiddenTooltip: firstLastValidValue,
            });
          }
        }
      }
    }

    if (withReturns) {
      for (let i in rawReturnsCharts) {
        const chart = rawReturnsCharts[i];

        for (let j in inputRawReturnsData) {

          if (rawReturnsLabels.indexOf(inputRawReturnsData[j][chart.xScale]) < 0) {
            rawReturnsLabels.push(inputRawReturnsData[j][chart.xScale]);
          }
          if (!rawReturnsDatasets[i]) {
            rawReturnsDatasets[i] = {
              data: [
                {
                  x: inputRawReturnsData[j]?.invalid ? NaN : inputRawReturnsData[j][chart.xScale],
                  y: inputRawReturnsData[j]?.invalid ? NaN : inputRawReturnsData[j][chart.yScale] || 0,
                  invalid: inputRawReturnsData[j]?.invalid,
                  message: inputRawReturnsData[j]?.message || '',
                  top_performers: inputRawReturnsData[j]?.top_performers || [],
                  is_active: inputRawReturnsData[j].hasOwnProperty('is_active') ? inputRawReturnsData[j]?.is_active : null,
                  breakdown: inputRawReturnsData[j]?.breakdown || { 'reactions': 0, 'comments': 0, 'shares': 0 },
                },
              ],
              ...chartRawReturnsDataBaseStyle,
              backgroundColor: function(context) {
                const { ctx, chartArea } = context.chart;
                if (!chartArea) return;
                return getGradient(ctx, chartArea);
              },
              label: chart.label,
              pointRadius: inputRawReturnsData?.length > 1 ? 1 : 4,
            };
          } else {
            rawReturnsDatasets[i].data.push({
              x: inputRawReturnsData[j]?.invalid ? NaN : inputRawReturnsData[j][chart.xScale],
              y: inputRawReturnsData[j]?.invalid ? NaN : inputRawReturnsData[j][chart.yScale] || 0,
              invalid: inputRawReturnsData[j]?.invalid,
              message: inputRawReturnsData[j]?.message || '',
              top_performers: inputRawReturnsData[j]?.top_performers || [],
              is_active: inputRawReturnsData[j].hasOwnProperty('is_active') ? inputRawReturnsData[j]?.is_active : null,
              breakdown: inputRawReturnsData[j]?.breakdown || { 'reactions': 0, 'comments': 0, 'shares': 0 },
            });
          }

          if (hasRawReturnsInvalid) {
            let firstLastValidValue = (!inputRawReturnsData[j]?.invalid && (inputRawReturnsData[+j + 1]?.invalid || inputRawReturnsData[j - 1]?.invalid));
            if (!rawReturnsDatasets[1]) {
              rawReturnsDatasets[1] = {
                data: [
                  {
                    x: (inputRawReturnsData[j]?.invalid || firstLastValidValue) ? inputRawReturnsData[j][chart.xScale] : NaN,
                    y: (inputRawReturnsData[j]?.invalid || firstLastValidValue) ? inputRawReturnsData[j][chart.yScale] || 0 : NaN,
                    invalid: inputRawReturnsData[j]?.invalid,
                    hiddenTooltip: firstLastValidValue,
                    message: inputRawReturnsData[j]?.message || '',
                    top_performers: inputRawReturnsData[j]?.top_performers || [],
                    is_active: inputRawReturnsData[j].hasOwnProperty('is_active') ? inputRawReturnsData[j]?.is_active : null,
                    breakdown: inputRawReturnsData[j]?.breakdown || { 'reactions': 0, 'comments': 0, 'shares': 0 },
                  },
                ],
                ...chartRawReturnsDataBaseStyle,
                borderColor: '#B55700',
                backgroundColor: function(context) {
                  const { ctx, chartArea } = context.chart;
                  if (!chartArea) return;
                  return getGradient(ctx, chartArea);
                },
                label: chart.label,
                pointRadius: inputRawData?.length > 1 ? 1 : 4,
                pointBackgroundColor: '#B55700',
                pointHoverBackgroundColor: '#B55700',
              };
            } else {
              rawReturnsDatasets[1].data.push({
                x: (inputRawReturnsData[j]?.invalid || firstLastValidValue) ? inputRawReturnsData[j][chart.xScale] : NaN,
                y: (inputRawReturnsData[j]?.invalid || firstLastValidValue) ? inputRawReturnsData[j][chart.yScale] || 0 : NaN,
                invalid: inputRawReturnsData[j]?.invalid,
                message: inputRawReturnsData[j]?.message || '',
                hiddenTooltip: firstLastValidValue,
              });
            }
          }
        }
      }
    }
  }

  if (withReturns) {
    for (let i in returnsCharts) {
      const chart = returnsCharts[i];

      for (let j in inputReturnsData) {

        if (returnsLabels.indexOf(inputReturnsData[j][chart.xScale]) < 0) {
          returnsLabels.push(inputReturnsData[j][chart.xScale]);
        }
        if (!returnsDatasets[i]) {
          returnsDatasets[i] = {
            data: [
              {
                x: inputReturnsData[j]?.invalid ? NaN : inputReturnsData[j][chart.xScale],
                y: inputReturnsData[j]?.invalid ? NaN : inputReturnsData[j][chart.yScale] || 0,
                invalid: inputReturnsData[j]?.invalid,
                message: inputReturnsData[j]?.message || '',
                top_performers: inputReturnsData[j]?.top_performers || [],
                is_active: inputReturnsData[j].hasOwnProperty('is_active') ? inputReturnsData[j]?.is_active : null,
                breakdown: inputReturnsData[j]?.breakdown || { 'reactions': 0, 'comments': 0, 'shares': 0 },
              },
            ],
            ...chartReturnsDataBaseStyle,
            backgroundColor: function(context) {
              const { ctx, chartArea } = context.chart;
              if (!chartArea) return;
              return getGradient(ctx, chartArea);
            },
            label: chart.label,
            pointRadius: inputReturnsData?.length > 1 ? 1 : 4,
          };
        } else {
          returnsDatasets[i].data.push({
            x: inputReturnsData[j]?.invalid ? NaN : inputReturnsData[j][chart.xScale],
            y: inputReturnsData[j]?.invalid ? NaN : inputReturnsData[j][chart.yScale] || 0,
            invalid: inputReturnsData[j]?.invalid,
            message: inputReturnsData[j]?.message || '',
            top_performers: inputReturnsData[j]?.top_performers || [],
            is_active: inputReturnsData[j].hasOwnProperty('is_active') ? inputReturnsData[j]?.is_active : null,
            breakdown: inputReturnsData[j]?.breakdown || { 'reactions': 0, 'comments': 0, 'shares': 0 },
          });
        }

        if (hasReturnsInvalid) {
          let firstLastValidValue = (!inputReturnsData[j]?.invalid && (inputReturnsData[+j + 1]?.invalid || inputReturnsData[j - 1]?.invalid));
          if (!returnsDatasets[1]) {
            returnsDatasets[1] = {
              data: [
                {
                  x: (inputReturnsData[j]?.invalid || firstLastValidValue) ? inputReturnsData[j][chart.xScale] : NaN,
                  y: (inputReturnsData[j]?.invalid || firstLastValidValue) ? inputReturnsData[j][chart.yScale] || 0 : NaN,
                  invalid: inputReturnsData[j]?.invalid,
                  hiddenTooltip: firstLastValidValue,
                  message: inputReturnsData[j]?.message || '',
                  top_performers: inputReturnsData[j]?.top_performers || [],
                  is_active: inputReturnsData[j].hasOwnProperty('is_active') ? inputReturnsData[j]?.is_active : null,
                  breakdown: inputReturnsData[j]?.breakdown || { 'reactions': 0, 'comments': 0, 'shares': 0 },
                },
              ],
              ...chartReturnsDataBaseStyle,
              borderColor: '#DF7009',
              backgroundColor: function(context) {
                const { ctx, chartArea } = context.chart;
                if (!chartArea) return;
                return getGradient(ctx, chartArea);
              },
              label: chart.label,
              pointRadius: inputRawData?.length > 1 ? 1 : 4,
              pointBackgroundColor: '#DF7009',
              pointHoverBackgroundColor: '#DF7009',
            };
          } else {
            returnsDatasets[1].data.push({
              x: (inputReturnsData[j]?.invalid || firstLastValidValue) ? inputReturnsData[j][chart.xScale] : NaN,
              y: (inputReturnsData[j]?.invalid || firstLastValidValue) ? inputReturnsData[j][chart.yScale] || 0 : NaN,
              invalid: inputReturnsData[j]?.invalid,
              message: inputReturnsData[j]?.message || '',
              hiddenTooltip: firstLastValidValue,
            });
          }
        }
      }
    }
  }

  return withRawData ?
    withReturns ?
      { datasets: [...datasets, ...rawDatasets, ...returnsDatasets, ...rawReturnsDatasets] }
      :
      { datasets: [...datasets, ...rawDatasets] }
    :
    withReturns ?
      { datasets: [...datasets, ...returnsDatasets] }
      :
      { datasets: datasets };
};

function Chart(props) {
  const {
    data,
    type,
    height,
    animation,
    loading,
    isMobile = false,
    externalTooltip = false,
    withRawData = false,
    rawData,
    withReturns = false,
    returnsData,
    rawReturnsData,
    chartType
  } = props;
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({ datasets: [] });
  const theme = useSelector(state => state.nav.theme);
  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) return;

    const chartData = getChartData(data, type, withRawData, rawData, withReturns, returnsData, rawReturnsData,chartType);
    setChartData(chartData);
    // eslint-disable-next-line
  }, [data]);

  const tempData = [...data]?.map(el => el?.value).filter(el => el > 1);

if (data?.length >= 1 && data.some(el => el.value !== 0)) {
  return (
    <div style={{ height: height || '235px', width: '100%', position: 'relative' }}>
      {
        ((data?.length === 1 && data[0]?.value === 1) || !tempData.length)
          ? (chartType === 'bar'
            ? <Bar ref={chartRef}
                    data={chartData}
                    options={chartConfig(type === 'price_dynamics' ? 'revenue' : type, animation, theme, 'one', isMobile, externalTooltip, withRawData, withReturns)}
              />
            : <Line ref={chartRef}
                    data={chartData}
                    options={chartConfig(type === 'price_dynamics' ? 'revenue' : type, animation, theme, 'one', isMobile, externalTooltip, withRawData, withReturns)}
              />)
          : (chartType === 'bar'
            ? <Bar ref={chartRef}
                    data={chartData}
                    options={chartConfig(type === 'price_dynamics' ? 'revenue' : type, animation, theme, 'default', isMobile, externalTooltip, withRawData, withReturns)}
              />
            : <Line ref={chartRef}
                    data={chartData}
                    options={chartConfig(type === 'price_dynamics' ? 'revenue' : type, animation, theme, 'default', isMobile, externalTooltip, withRawData, withReturns)}
              />)
      }
    </div>
  );
} else if (data.length >= 1 && data.every(el => el.value === 0)) {
  return (
    <div style={{ height: height || '235px', width: '100%', position: 'relative' }}>
      {
        chartType === 'bar'
          ? <Bar ref={chartRef}
                  data={chartData}
                  options={chartConfig(type === 'price_dynamics' ? 'revenue' : type, animation, theme, 'empty', isMobile, externalTooltip, withRawData, withReturns)}
            />
          : <Line ref={chartRef}
                  data={chartData}
                  options={chartConfig(type === 'price_dynamics' ? 'revenue' : type, animation, theme, 'empty', isMobile, externalTooltip, withRawData, withReturns)}
            />
      }
    </div>
  );
} else {
  return (
    <div className="empty-screen"
         style={{ height: height || '235px', width: '100%', position: 'relative' }}
    >
      <EmptyChart type={type} theme={theme} loading={loading} />
    </div>
  );
}
}

export default Chart;
